import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/rooms/COG_Image__00053_.png";
import doorSfx from "../../res/sfx/Torch Swing 06.wav";
import song from "../../res/music/Disorienting.mp3";
import {
  changeSong,
  GetDifficulty,
  GetNextRooms,
  GetDamage,
  playOneShot,
  GlobalContext,
  CAPDEPTH,
  GetRoomObjectByName,
} from "../room_handler";
import SkillCheckSelect from "../../components/SkillCheckSelect";
import { save } from "../../functions/save";
import { EntryPercResults } from "../../components/perception";

const bannerUrl = door;
const doorUrl = door;

const Hole = () => {
  const [display, changeDisplay] = useState("pre");
  const InstanceVars = useContext(GlobalContext);
  const [nextRooms, changeNextRooms] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (InstanceVars.current.nextRooms) {
      loadVals();
    } else {
      let nextRoomTemp = GetNextRooms(
        Hole_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Hole_Obj.exits)
      );
      save(InstanceVars.current, Hole_Obj, nextRoomTemp);
      InstanceVars.current.score.depth += 100;
      changeNextRooms(nextRoomTemp);
    }
  }, []);

  const loadVals = () => {
    let nextRoomTemp = [];
    InstanceVars.current.nextRooms.forEach((room) => {
      let tempRoom = GetRoomObjectByName(room.name);
      if (tempRoom) nextRoomTemp.push(tempRoom);
    });
    if (nextRoomTemp.length > 0) {
      delete InstanceVars.current.nextRooms;
      delete InstanceVars.current.currentRoom;
    } else {
      nextRoomTemp = GetNextRooms(
        Hole_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Hole_Obj.exits)
      );
    }
    changeNextRooms(nextRoomTemp);
  };

  const parseInput = (input) => {
    if (input == "next") {
      InstanceVars.current.score.skill += 50;
      changeDisplay("pass");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Hole</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        A deep hole in the floor. It's pitch black and you aren't sure how deep
        it goes.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = (i = 0, navigate) => {
  return (
    <button
      key={Hole_Obj.name + toString(i)}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Hole_Obj.route);
      }}
    >
      <img src={Hole_Obj.image} />
      <span className="entryText">A deep hole leading into the unknown</span>
      <EntryPercResults
        observation={{
          tier: 3,
          text: "You can see far enough down the that the bottom is visible and there's a good chance you'll survive.",
        }}
      />
    </button>
  );
};

export var Hole_Obj = {
  name: "Hole",
  room: Hole,
  entry: Entryway,
  route: "/CityOfGold/hole",
  exits: 1,
  prob: 5,
  image: doorUrl,
  faction: "none",
};

export default Hole;

//input: function
//output: level
const PreInput = (props) => {
  const [ableToContinue, setAbleToContinue] = useState(false);
  const navigate = useNavigate();

  //console.log(props.output);
  const submitHandler = (selection) => {
    let blank = false;
    selection.forEach((sel) => {
      if (sel.pass === undefined) blank = true;
      return;
    });
    if (!blank) {
      // console.log("next");
      setAbleToContinue(true);
    }
  };

  return (
    <div>
      <p className="roomText">
        You jump down the hole. You're not sure how far down it goes, so you'll
        need to make an Agility (Dex) check to roll and avoid damage when you
        land.
      </p>

      <span className="inputLabel">
        Choose a skill to use and then roll it. Input the number into the box.
        If you have a way of feasibly instant-succeeding on the check, select
        "instant." <br />
        Natural 1: input 0 in your box. <br />
        Natural 20: Add 1d6 to the roll and input the final score into your box.
      </span>
      <SkillCheckSelect
        options={[{ method: "agility (dex)", diffTier: 0, damTier: 0 }]}
        callback={submitHandler}
      />
      <input
        type="button"
        value="Continue"
        className="skillCheckButton skillCheckSubmit"
        disabled={!ableToContinue}
        onClick={() => props.output("next")}
      />
      <input
        type="button"
        value="Dead"
        className="skillCheckButton skillCheckDeath"
        disabled={!ableToContinue}
        onClick={() => {
          navigate("/CityOfGold/death");
        }}
      />
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  const navigate = useNavigate();
  const submitHandler = () => {
    navigate(props.rooms[0].route);
  };
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>Survived!</b> You land at the bottom of the hole.
        </div>
      </div>
      <button className="inputSubmit" onClick={submitHandler}>
        Stand and figure out where you are.
      </button>
    </div>
  );
};
