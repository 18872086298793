import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://ih1.redbubble.net/image.702551755.5966/flat,750x1000,075,f.u4.jpg";
const doorUrl = door;

var nextRooms;

const Fault = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Fault_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Fault_Obj);
  }

  const parseInput = (input) => {
    switch (input[0]) {
      case "partOne":
        changeDisplay("partOne");
        break;
      case "partTwo":
        changeDisplay("partTwo");
        break;
      case "partThree":
        changeDisplay("partThree");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "partOne":
        component = <PartOne output={parseInput} />;
        break;
      case "partTwo":
        component = <PartTwo output={parseInput} />;
        break;
      case "partThree":
        component = <PartThree rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Fault</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        A rough stone cavern looms before you, torn in half by a fault. The far
        side of the fault forms a cliff thirty feet high. Hundreds of holes
        shaped for hundreds of bodies spread all along the cliff face. Holes for
        heavy people, holes for children, holes for the tall and lanky, and
        hundreds of other holes lie in the wall. You walk along the fault,
        looking into the holes. The insides of the holes seem to have a ribbed
        texture to them. None of the holes seem right for you. Each hole is
        fitted exactly to an individual, and you don't see a single hole you
        think your body would fit into.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Fault_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Fault_Obj.route);
      }}
    >
      <img src={Fault_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Fault_Obj = {
  name: "Fault",
  room: Fault,
  entry: Entryway,
  route: "/halloween2022/fault",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Fault;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partOne":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        As you walk along the wall, your eyes rest on a hole. You instinctively
        know this hole is your own. It's tight. You'll need to take off any
        outer layers of clothing in order to fit, but you know it is yours.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Enter the Tunnel
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const PartOne = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partTwo":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        You take off your outer layers of clothing and squeeze into the hole.
        The stone walls are strangely warm, and the ribbing is smooth and
        comfortable. You might take a step back out of the hole to look it over,
        unsure if you should really be doing this. There is nowhere else to go,
        however. You will ultimately enter the hole.
        <br />
        <br />
        As you enter the hole for real, the smooth stone greets you like a fond
        friend. It hugs every inch of your skin as you take your first steps
        forward.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Press Forward
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const PartTwo = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partThree":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        Five minutes into shuffling down your tunnel, you realize the walls of
        your tunnel have gripped you. You might let out a few panicked breaths
        as you realize there is no going back. The ribbed texture of the walls
        allows you to move forward, but it stops you from moving backwards.
        <br />
        <br />
        There is no going back.
        <br />
        <br />
        With no choice, you press forward. Your bones creak as you walk, inch by
        inch and hour by hour. Your muscles burn as if stretching. Your skin
        feels as though it might tear despite the smoothness of the stone. Your
        sense of time lapses as you shuffle forward. After an indeterminate
        amount of time, you see the end of your tunnel.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partThree"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Leave your tunnel
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PartThree = (props) => {
  return (
    <div>
      <div className="roomText">
        The creature that leaves the tunnel is not the same as the person who
        entered.
        <img
          className="banner"
          src="https://bloody-disgusting.com/wp-content/uploads/2021/03/junji-ito-animation.png"
        />
        Your limbs have elongated and become little more than ropes of sinew and
        broken bone. Your skull flops over and your spine crumbles, no longer
        supported by the snug fit of the tunnel. You are helpless. An inhuman
        scream pushes its way out of your distended throat, filling the room
        beyond with horrible resonance. You fall... fall... fall into darkness.
        <br />
        <br />
        When you awake, you are whole again, back in the cavern with the
        man-sized holes in the wall. Where your hole once was, three new paths
        stand, awaiting you.
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
