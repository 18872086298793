import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/rooms/COG_Image__00318_.png";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/Disorienting.mp3";
import banner from "../../res/rooms/COG_Image__00214_.png";
import {
  CAPDEPTH,
  changeSong,
  GetNextRooms,
  GetRoomObjectByName,
  GlobalContext,
  playOneShot,
} from "../room_handler";
import SkillCheckSelect from "../../components/SkillCheckSelect";
import RandomRoomTreasure from "../../components/Loot";
import { save } from "../../functions/save";
import { EntryPercResults, Perception } from "../../components/perception";

const bannerUrl = banner;
const doorUrl = door;

const Sapping_Tree = () => {
  const [display, changeDisplay] = useState("pre");
  const InstanceVars = useContext(GlobalContext);
  const [nextRooms, changeNextRooms] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (InstanceVars.current.nextRooms) {
      loadVals();
    } else {
      let nextRoomTemp = GetNextRooms(
        Sapping_Tree_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Sapping_Tree_Obj.exits)
      );
      save(InstanceVars.current, Sapping_Tree_Obj, nextRoomTemp);
      InstanceVars.current.score.depth += 100;
      changeNextRooms(nextRoomTemp);
    }
  }, []);

  const loadVals = () => {
    let nextRoomTemp = [];
    InstanceVars.current.nextRooms.forEach((room) => {
      let tempRoom = GetRoomObjectByName(room.name);
      if (tempRoom) nextRoomTemp.push(tempRoom);
    });
    if (nextRoomTemp.length > 0) {
      delete InstanceVars.current.nextRooms;
      delete InstanceVars.current.currentRoom;
    } else {
      nextRoomTemp = GetNextRooms(
        Sapping_Tree_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Sapping_Tree_Obj.exits)
      );
    }
    changeNextRooms(nextRoomTemp);
  };

  const parseInput = (input) => {
    if (input == "next") {
      InstanceVars.current.score.skill += 200;
      changeDisplay("pass");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "pre":
      default:
        component = <PreInput output={parseInput} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Sapping Tree</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        A single tree stands tall in the center of a tall vaulted room with
        amber sap running down its trunk. A full canopy of orange and green
        leaves shrouds much of the ceiling. The bones of dead birds and small
        rodents litter the ground at your feet.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = (i = 0, navigate) => {
  return (
    <button
      key={Sapping_Tree_Obj.name + i}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Sapping_Tree_Obj.route);
      }}
    >
      <img src={Sapping_Tree_Obj.image} />
      <span className="entryText">A dark passage leading into the unknown</span>
      <EntryPercResults
        observation={{
          tier: 4,
          text: "A peculiar scent comes from this direction.",
        }}
        mysticism={{
          tier: 4,
          text: "You sense strong water magic at work ahead.",
        }}
        spirituality={{
          tier: 4,
          text: "You feel drained as you consider this path.",
        }}
      />
    </button>
  );
};

export var Sapping_Tree_Obj = {
  name: "Sapping_Tree",
  room: Sapping_Tree,
  entry: Entryway,
  route: "/CityOfGold/sapping_Tree",
  exits: 4,
  prob: 5,
  image: doorUrl,
  faction: "none",
};

export default Sapping_Tree;

//input: function
//output: level
const PreInput = (props) => {
  const [ableToContinue, setAbleToContinue] = useState(false);
  const navigate = useNavigate();

  //console.log(props.output);
  const submitHandler = (selection) => {
    let blank = false;
    selection.forEach((sel) => {
      if (sel.pass === undefined) blank = true;
      return;
    });
    if (!blank) {
      console.log("next");
      setAbleToContinue(true);
    }
  };

  return (
    <div>
      <p className="roomText">
        A peculiar smell fills the room. You can feel it draining you as you
        breathe it in. Roll Endurance (body) to hold your breath until the other
        side, or roll Alchemy (mind) to counteract the toxin.{" "}
      </p>
      <span className="inputLabel">
        Choose a skill to use and then roll it. Input the number into the box.
        If you have a way of feasibly instant-succeeding on the check, select
        "instant." <br />
        Natural 1: input 0 in your box. <br />
        Natural 20: Add 1d6 to the roll and input the final score into your box.
      </span>
      <SkillCheckSelect
        options={[
          { method: "endurance (body)", diffTier: 2, damTier: 0 },
          { method: "alchemy (mind)", diffTier: 3, damTier: 0 },
        ]}
        noDamage
        specialEffect="You feel severely ill from the toxin. For the next 1d4+1 rooms, you suffer disadvantage on all athletics and agility rolls. -- this can be cleansed with greater cleanse."
        callback={submitHandler}
      />
      <input
        type="button"
        value="Continue"
        className="skillCheckButton skillCheckSubmit"
        disabled={!ableToContinue}
        onClick={() => props.output("next")}
      />
      <input
        type="button"
        value="Dead"
        className="skillCheckButton skillCheckDeath"
        disabled={!ableToContinue}
        onClick={() => {
          navigate("/CityOfGold/death");
        }}
      />
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>Survived!</b> You made it past the toxins of the tree. Which path
          do you take from here?
        </div>
        <RandomRoomTreasure
          chance={0.05}
          chanceForCurse={0.2}
          tier={Math.floor(Math.random() * 3)}
        />
      </div>
      <Perception />
      <div className="nextHolder">
        {props.rooms.map((room, i) => {
          return room.entry(i, navigate);
        })}
      </div>
    </div>
  );
};
