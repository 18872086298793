import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/rooms/COG_Image__00283_.png";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/Disorienting.mp3";
import banner from "../../res/rooms/COG_Image__00065_.png";
import {
  CAPDEPTH,
  changeSong,
  GetNextRooms,
  GetRoomObjectByName,
  GlobalContext,
  playOneShot,
} from "../room_handler";
import { save } from "../../functions/save";
import { EntryPercResults, Perception } from "../../components/perception";

const bannerUrl = banner;
const doorUrl = door;

const Empty = () => {
  const InstanceVars = useContext(GlobalContext);
  const [nextRooms, changeNextRooms] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (InstanceVars.current.nextRooms) {
      loadVals();
    } else {
      let nextRoomTemp = GetNextRooms(
        Empty_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Empty_Obj.exits)
      );
      save(InstanceVars.current, Empty_Obj, nextRoomTemp);
      InstanceVars.current.score.depth += 100;
      changeNextRooms(nextRoomTemp);
    }
  }, []);

  const loadVals = () => {
    let nextRoomTemp = [];
    InstanceVars.current.nextRooms.forEach((room) => {
      let tempRoom = GetRoomObjectByName(room.name);
      if (tempRoom) nextRoomTemp.push(tempRoom);
    });
    if (nextRoomTemp.length > 0) {
      delete InstanceVars.current.nextRooms;
      delete InstanceVars.current.currentRoom;
    } else {
      nextRoomTemp = GetNextRooms(
        Empty_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Empty_Obj.exits)
      );
    }
    changeNextRooms(nextRoomTemp);
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Empty</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        An unnerving feeling washes over you as you enter the room. You look
        around and see it completely empty. Bare stone walls with nothing
        adorning them. Amidst all that you have seen, all that you have
        experienced here, nothing is more off putting. No room is simply empty.
      </p>
      <p className="roomText">
        You might make a perception check to find something in this room, but
        you would find nothing. It should be fine..right?
      </p>
      <Perception />
      <div className="nextHolder">
        {nextRooms.map((room, i) => {
          return room.entry(i, navigate);
        })}
      </div>
    </div>
  );
};

const Entryway = (i = 0, navigate) => {
  return (
    <button
      key={Empty_Obj.name + toString(i)}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);

        navigate(Empty_Obj.route);
      }}
    >
      <img src={Empty_Obj.image} />
      <span className="entryText">A dark cave leading into the unknown</span>
      <EntryPercResults
        observation={{
          tier: 3,
          text: "A well trodden path.",
        }}
        spirituality={{
          tier: 4,
          text: "You sense the safety of this passage.",
        }}
      />
    </button>
  );
};

export var Empty_Obj = {
  name: "Empty",
  room: Empty,
  entry: Entryway,
  route: "/CityOfGold/empty",
  exits: 4,
  prob: 5,
  image: doorUrl,
  faction: "none",
};

export default Empty;
