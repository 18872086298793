import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Music Box.wav";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/564x/a5/00/99/a5009991effae4e7504553d8e0b4b5f8.jpg";
const doorUrl = door;

var nextRooms;

const Nursery = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    nextRooms = GetNextRooms(Nursery_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Nursery_Obj);
  }

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Nursery</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You enter a dusty nursery, long abandoned. Whatever decoration once
        adorned the walls has long since faded. A crib with broken slats sits in
        one corner. A small mat lies on top with maggots crawling in and through
        it. Small wooden toys can be seen on the floor, dried and cracking. You
        hear a music box playing a haunting tune very faintly, but you can't
        locate the source.
      </p>
      <p className="roomText">Two paths lie before you.</p>
      <DemonScript />
      <div className="nextHolder">
        {nextRooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Nursery_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Nursery_Obj.route);
      }}
    >
      <img src={Nursery_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Nursery_Obj = {
  name: "Nursery",
  room: Nursery,
  entry: Entryway,
  route: "/halloween2022/nursery",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Nursery;
