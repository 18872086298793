function generateAmalgam() {

	const resistances = [
		"This creature is immune to the bleeding and bleeding out conditions.",
		"This creature takes half Fire damage.",
		"This creature takes half Water damage.",
		"This creature takes half Earth damage.",
		"This creature takes half Wind damage.",
		"This creature takes half Dark damage.",
		"This creature takes half Light damage.",
		"This creature takes half damage from sharp weapons.",
		"This creature takes half damage from blunt weapons.",
		"This creature takes half damage from ranged weapons.",
		"This creature has advantage on all Push Through rolls.",
		"This creature has advantage on all Mental Acuity rolls.",
		"This creature has advantage on all Quick Reflexes rolls.",
		`This creature has ${Math.floor(Math.random() * 10)} DR (Damage Resistance).`,
		"",
		"",
		"",
	]
	
	const activeAbilities = [
		`Pound: As a Standard Action, this creature makes a melee attack, but deals +${Math.ceil(Math.random() * 10)} bonus damage on-hit.`,
		"Shoot: As a Standard Action, this creature makes an attack out to a range of Ranged 2.",
		"Regenerate: As a Free Action, this creature can heal 10 HP.",
		"Spite: As a Free Action, this creature can deal 10 auto-hit damage to a target in melee.",
		"Explode: As a Standard Action, this creature moves up to its Dash distance to get as many enemies in OOR of it as possible, then explodes, dealing 30 AoE damage to everyone within OOR. It can only use this ability once per combat, and will do so as early as possible.",
		"Shatter: As a Standard Action, this creature makes a melee attack, but breaks a random limb of the the target on-hit. It can only use this ability twice per combat, and will do so as soon as possible.",
		"Mana Reave: As a Standard Action, this creature makes an attack out to a range of Long Distance 1. If it hits, it takes that much mana from the target instead of dealing damage.",
		"Cursed Strike: As a Standard Action, this creature makes an attack against what it sees as the most powerful enemy in the room at any range. For the next 1d4+1 rooms, that creature cannot regain the HP or THP this attack dealt. It can only use this twice per combat, and will try to hit the same target with both uses.",
	]
	
	const passiveAbilities = [
		"Flying: This creature can fly with all of its movement.",
		"Quick: This creature can Dash as a Free Action.",
		"Safe: This creature does not lose its proficiency to defence when it dashes.",
		"Pummel: Whenever this creature attacks, it attacks twice.",
		"Multi-target: Whenever this creature attacks, it attacks 2 targets if there is another target in range.",
		"Overwhelming: This creature has a third Free Action it can use every round.",
		"Brutal Crits: This creature adds 3d6 instead of 1d6 when it crits.",
		"Consistent Crits: This creature crits on a natural 19 or 20.",
		"Martyrdom: When this creature dies, it deals 100 auto-hit damage to whoever dealt the most damage to it.",
		"Entrapment: Stealth rolls to escape combat while this creature is alive are made at disadvantage.",
		"Slice: When this creature scores a critical hit, it severs a limb from its target.",
		"Keen Senses: This creature is uneffected by blindness, darkness of any kind, or illusions that only effect sight.",
		"Crippling Blows: All of this creature's attacks root the target for 1 round.",
	]
	
	const tactics = [
		"This enemy will use a random ability against a random valid target every round.",
		"This enemy is intelligent, but highly susceptible to being provoked. It will attack the easiest target to kill, but will immediately change targets to whoever last attacked it.",
		"This enemy attacks the closest target.",
		"This enemy is very intelligent, and is aware of any ability a character has used in the City of Gold. It will attack whoever will have the most difficult time recovering.",
		"This enemy will pick a random target when the fight starts, and will never change targets until that target is killed, even if it is only able to attack someone else."
	]

	const randomAc = 20 + Math.ceil(Math.random() * 20);
	const randomAttack = 25 + Math.ceil(Math.random() * 5)
	const randomSpeed = Math.ceil(Math.random() * 4)

	function getRandomItems(arr, num) {
		// Takes the array and number of items to take from the array.
		const shuffled = [...arr].sort(() => 0.5 - Math.random());

		return shuffled.slice(0, num);
	}

	const newAmalgam = {
		"name": "Amalgam",
		"hp": 100 + Math.ceil(Math.random() * 300),
		"thp": Math.ceil(Math.random() * 30),
		"ac": randomAc,
		"ac_noprof": randomAc - 5,
		"attack": `+${randomAttack}`,
		"mental_aquity": `+${10 + Math.ceil(Math.random() * 20)}`,
		"push_through": `+${10 + Math.ceil(Math.random() * 20)}`,
		"push_dc": 15 + Math.ceil(Math.random() * 10),
		"quick_reflexes": `+${10 + Math.ceil(Math.random() * 20)}`,
		"passive_perc": 10 + Math.ceil(Math.random() * 15),
		"init": `+${Math.ceil(Math.random() * 10)}`,
		"move": randomSpeed,
		"dash": randomSpeed * 2,
		"resistances": getRandomItems(resistances, 3).join(" "),
		"description": "This creature is an amalgamous, horrible combination of many of other creatures. You might have encountered this creature before, but no set of amalgams are exactly the same.",
		"abilities": [
			...getRandomItems(passiveAbilities, 3),
			"As a Standard Action, this creature makes a standard melee attack. It will only ever use this if all of its other active abilities are out of uses.",
			...getRandomItems(activeAbilities, 2),
		],
		"tactics": getRandomItems(tactics, 1),
		"imageurl": "/city_of_gold_res/enemies/amalgam.png",
		"faction": "theokyr",
		"score": 200,
		"tier": 9
	};

	return newAmalgam;
}

const amalgam = generateAmalgam();
const dynamicEnemies = [
	amalgam,
]

export { dynamicEnemies };
