import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/564x/44/03/e4/4403e4943de2d22e5f6a033351d0a6ba.jpg";
const doorUrl = door;

var nextRooms;

const Bridge = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Bridge_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Bridge_Obj);
  }

  const parseInput = (input) => {
    switch (input[0]) {
      case "partOne":
        changeDisplay("partOne");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "partOne":
        component = <PartOne rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Bridge</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You find yourself outside on a bridge, high up in the towers of the
        castle. If you could fly, you find that you still cannot. You look over
        the edge of the bridge. With how high up you are, falling off would mean
        certain death.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Bridge_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Bridge_Obj.route);
      }}
    >
      <img src={Bridge_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Bridge_Obj = {
  name: "Bridge",
  room: Bridge,
  entry: Entryway,
  route: "/halloween2022/bridge",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Bridge;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partOne":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        It's windy but not so windy you are in danger. You can cross the bridge
        without fear.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Cross the bridge
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const PartOne = (props) => {
  const [errText, setErrText] = useState("");

  return (
    <div>
      <p className="roomText">
        You make it to the other side. Three paths are available to you.
      </p>
      <DemonScript />

      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
