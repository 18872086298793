class CustomEventBus {
  listeners = [];

  subscribe(callback) {
    this.listeners.push(callback);
    return () => {
      this.listeners = this.listeners.filter(
        (listener) => listener !== callback
      );
    };
  }

  emit() {
    this.listeners.forEach((listener) => listener());
  }
}

export const eventBus = new CustomEventBus();
