import { useEffect, useState } from "react";
import { useAsyncError, useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Metal Rusty 2_3.wav";
import song from "../../res/music/Carousel.wav";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";
import "./gambling.css";

const bannerUrl =
  "https://cdnb.artstation.com/p/assets/images/images/000/014/869/large/demonic_jester_by_bennykusnoto-d6sjyqa.jpg";
const doorUrl =
  "https://i.pinimg.com/564x/0a/0f/eb/0a0feb5c6e652454f0944b0484a44c6d.jpg";

var nextRooms;

const Gambling = () => {
  const [display, changeDisplay] = useState("pre");
  const [endText, changeEndText] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Gambling_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Gambling_Obj);
  }

  const parseInput = (input) => {
    switch (input[0]) {
      case "partOne":
        changeDisplay("partOne");
        break;
      case "partTwo":
        changeDisplay("partTwo");
        break;
      case "finish":
        changeEndText(input[1]);
        changeDisplay("partThree");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "partOne":
        component = <PartOne output={parseInput} />;
        break;
      case "partTwo":
        component = <PartTwo output={parseInput} />;
        break;
      case "partThree":
        component = <PartThree text={endText} rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Wheel of Pain</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        The room you enter is filled with red smoke. Shortly after entry, you
        fall unconscious. No matter how good your endurance or how good your
        feats are, this smoke causes you to pass out.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Gambling_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Gambling_Obj.route);
      }}
    >
      <img src={Gambling_Obj.image} />
      <span className="entryText">
        A heavy golden door leading into the unknown
      </span>
    </button>
  );
};

export var Gambling_Obj = {
  name: "Gambling",
  room: Gambling,
  entry: Entryway,
  route: "/halloween2022/gambling",
  exits: 3,
  prob: 0.5,
  image: doorUrl,
};

export default Gambling;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partOne":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        You awaken across a table from another person. Feel free to describe
        this person as you see fit, you'll be roleplaying them as time goes on.
        They have children. They are innocent. They are not fateless and can
        die.
        <br />
        <br />
        You sit in an uncomfortable wooden chair that feels too small for you.
        Across the table, your arm is bound in an intricate device with
        electrical coils around the base (if you are immune or resistant to
        electricity, replace this device with a device you are not immune or
        resistant to). The person across the table is likewise bound in a
        similar fashion. Strong leather belts keep you in place, though your
        head is free to look around the room.
        <br />
        <br />
        There's not much to see. The room is very dark aside from the dim candle
        in the center of the metal table.
        <br />
        <br />
        Roleplay yourself talking with your counterpart. Perform at least three
        lines of roleplay before moving on.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Continue
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const PartOne = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partTwo":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <div className="roomText">
        "Isn't that heartwarming!? They're getting to know each other!" A shrill
        voice cuts through the darkness as bright magical lights suddenly
        illuminate the area. A Jester whose clothes and mask appear to be made
        of skin, with one eyeball on his hat tail stands close enough to smell
        the sick smell of blood and bile wafting from the Jester.
        <br />
        <br />
        Your table sits in the middle of a large ring with two dozen fleshy
        demonic creatures in the stands watching over you. The person across
        from you has never seen anything so terrifying or disgusting and they
        start wretching and panicking.
        <br />
        <br />
        "Isn't that cute. The panic is thrilling!" the Jester says. If you try
        to say anything the Jester will speak over you and if you continue to
        speak over him, he will shock you through the device on your arm for one
        point of damage to shut you up.
        <br />
        <br />
        "We're going to play a little game together, you and I. The winner
        leaves unharmed and the loser dies! Oh, but I never lose. You two will
        be facing each other! That means no matter which of you wins, I am the
        true winner here! Isn't that fantastic!?" The crowd cheers.
        <br />
        <br />
        "Shush shush," he hushes the crowd. "Now now, I have to explain the
        rules of the game! We can't have all day, unfortunately. I'm getting a
        facial spa later!" He pauses for dramatic effect. "The game of the day
        is the Wheel of Pain! I spin the wheel and dish out the pain! The
        numbers on the wheel go from one to thirty, and every number is
        highlighted by one of five colors! Each round, you guess a number or
        color and offer a wager. You can wager one, two, three, four, five, or
        six. These numbers, of course, correlate with pain settings on each of
        your devices. The higher the wager, the more deadly the punishment!
        Isn't that grand!?"
        <br />
        <br />
        The crowd chears.
        <br />
        <br />
        He holds up his hands. "Now, if you guess a number and you are on the
        money, your opponent adds three times your wager to their device's
        setting, and you subtract the same amount from your setting. If you
        guess a number and are the closest, your opponent's device setting is
        increased by the amount of your wager, and your setting is decreased by
        the amount of their wager. If there's a tie, both settings will increase
        by a number of my choosing! Last, if you guess a color, and you are
        correct, your opponent's setting is increased by double your wager, but
        if you guess wrong, your setting increases by double their wager, and
        their setting decreases by your wager. If you both guess a color and
        guess incorrectly, both of your settings increase by an amount of my
        choosing! Doesn't that sound fun!? You also cannot guess the same number
        or color as your opponent, and you cannot place a wager equal to your
        opponent. The person with the lowest setting will go first on each
        round. At the end of five rounds, your devices will go off, and you'll
        both be harmed according to the strength of the setting on your device!"
        <br />
        <br />
        "Now, there's a catch. To make this all more fun, after a number is
        chosen, I will remove it from the wheel, altering the probabilities of
        every spin thereafter! Are we ready to begin? Good, then let's go!"
      </div>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Continue
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

var wheelNums = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30,
];
var otherOptions = [
  "red",
  "yellow",
  "green",
  "blue",
  "purple",
  "refuse/struggle",
];
var wagerOptions = [1, 2, 3, 4, 5, 6];

//input: function
//output: level
const PartTwo = (props) => {
  const [errText, setErrText] = useState("");
  const [guessOptions, setGuessOptions] = useState(
    wheelNums.concat(otherOptions)
  );
  const [guess, setGuess] = useState(1);
  const [wager, setWager] = useState(1);
  const [opponentGuess, setOpponentGuess] = useState("n/a");
  const [opponentWager, setOpponentWager] = useState("n/a");
  const [prevGuess, setPrevGuess] = useState("n/a");
  const [prevWager, setPrevWager] = useState("n/a");
  const [opPrevGuess, setOpPrevGuess] = useState("n/a");
  const [opPrevWager, setOpPrevWager] = useState("n/a");
  const [round, setRound] = useState(1);
  const [spinResult, setSpinResult] = useState(0);
  const [result, setResult] = useState(
    '"As you are both at setting 0, the fateless will go first!"'
  );
  const [setting, setSetting] = useState(0);
  const [opSetting, setOpSetting] = useState(0);
  const [opHealth, setOpHealth] = useState(getDifficulty(5) + 5);
  const navigate = useNavigate();

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    let opValues;
    if (setting <= opSetting) {
      opValues = getOpValues();
    } else {
      opValues = { guess: opponentGuess, wager: opponentWager };
    }

    //generate the spin.
    let spin = wheelNums.splice(
      Math.floor(Math.random() * wheelNums.length),
      1
    );
    let color = getColor(spin);

    let tempResult;
    let playerIncrease = 0;
    let opIncrease = 0;
    //if player refuses or resists.
    if (guess == otherOptions[5]) {
      //player immediately takes 4d4 damage.
      tempResult =
        "You take 4d4 damage as your device shocks or otherwise damages you. Nothing happens to your opponent.";
    }
    //check if either guess is exactly the spin.
    else if (!isNaN(guess) && guess == spin) {
      //player's setting decreases by 3x player wager.
      playerIncrease = wager * -3;
      //op's setting increases by 3x player wager.
      opIncrease = wager * 3;
    } else if (!isNaN(opValues.guess) && opValues.guess == spin) {
      //player's setting increases by 3x op wager.
      playerIncrease = opValues.wager * 3;
      //op's setting decreases by 3x op wager.
      opIncrease = opValues.wager * -3;
    }
    //check if both guess a color and are incorrect.
    else if (
      otherOptions.indexOf(guess) != -1 &&
      otherOptions.indexOf(opValues.guess) != -1 &&
      guess != color &&
      opValues.guess != color
    ) {
      //generate random number.
      let rand = Math.ceil(Math.random * (15 - 5) + 5);
      //increase op's setting by number.
      opIncrease = rand;
      //increase player's setting by number.
      playerIncrease = rand;
    }
    //check if either guess a color.
    else if (otherOptions.indexOf(guess) != -1 && guess == color) {
      //op's setting increase by double player wager.
      opIncrease = wager * 2;
    } else if (
      otherOptions.indexOf(opValues.guess) != -1 &&
      opValues.guess == color
    ) {
      //player's setting increase by double op wager.
      playerIncrease = opValues.wager * 2;
    } else if (otherOptions.indexOf(guess) != -1 && guess != color) {
      //player's setting increase by double op wager.
      playerIncrease = opValues.wager * 2;
      //op's setting decrease by player wager.
      opIncrease = wager * -1;
    } else if (
      otherOptions.indexOf(opValues.guess) != -1 &&
      opValues.guess != color
    ) {
      //op's setting increase by double player wager.
      opIncrease = wager * 2;
      //player's setting decrease by op wager.
      playerIncrease = opValues.wager * -1;
    }
    //check if both are equidistant from spin.
    else if (Math.abs(guess - spin) == Math.abs(opValues.guess - spin)) {
      //generate random number.
      let rand = Math.ceil(Math.random * (15 - 5) + 5);
      //increase op's setting by number.
      opIncrease = rand;
      //increase player's setting by number.
      playerIncrease = rand;
    }
    //what happens if both guess a number.
    else if (Math.abs(guess - spin) < Math.abs(opValues.guess - spin)) {
      //player's setting decreased by op's wager.
      playerIncrease = opValues.wager * -1;
      //op's setting inreased by player's wager.
      opIncrease = wager * 1;
    } else if (Math.abs(guess - spin) > Math.abs(opValues.guess - spin)) {
      //player's setting increased by op's wager.
      playerIncrease = opValues.wager * 1;
      //op's setting decreased by player's wager.
      opIncrease = wager * -1;
    }
    //if anything else happens.
    else {
      tempResult =
        '"Not sure what happened there. Let\'s go again," the Jester says.';
    }

    if (tempResult == "" || !tempResult) {
      tempResult =
        "Your setting " +
        (playerIncrease >= 0 ? "increases" : "decreases") +
        " by " +
        playerIncrease +
        ". Opponent's setting " +
        (opIncrease >= 0 ? "increases" : "decreases") +
        " by " +
        opIncrease +
        ".";
    }

    //check if 5 rounds have passed. Deal damage if so.
    let playerDamage = 0;
    let opDamage = 0;
    if (round % 5 == 0) {
      playerDamage = Math.max(0, playerIncrease + setting);
      opDamage = getOpDamage(opIncrease + opSetting);
      tempResult = tempResult.concat(
        ' As the fifth round passes, the Jester laughs and announces the end of a fifth round, "Are you ready for some pain? Here we go!" Your devices go off. You take ',
        playerDamage,
        "d4-",
        playerDamage,
        " damage! Your opponent takes ",
        opDamage,
        " damage as they get shocked. Your devices both reset to 0."
      );
      let opResult;
      if (opHealth - opDamage > 16) {
        opResult =
          "Your opponent shrugs off whatever shock they get. They seem to be doing okay.";
      } else if (opHealth - opDamage > 12) {
        opResult =
          "Your opponent winces in pain. They're definitely starting to feel it.";
      } else if (opHealth - opDamage > 8) {
        opResult = "Your opponent might scream or cry as they are shocked.";
      } else if (opHealth - opDamage > 4) {
        opResult =
          "Your opponent is desperate and nearly histerical with the pain.";
      } else {
        opResult =
          "Your opponent is barely alive. They have fallen silent, kept alive only by sheer willpower.";
      }

      if (opHealth - opDamage <= 0) {
        tempResult = tempResult.concat(
          " As the final shock occurs, your opponent falls over dead. If you took any damage this round and died, your journey ends here. You have died. If you are dead, you do not escape with any keys, and you respawn after an hour in a random spot outside the castle."
        );
        props.output(["finish", tempResult]);
      }

      tempResult = tempResult.concat(
        " ",
        opResult,
        " If you died this round, you will respawn in a random part of the labarynth without any keys. You must wait an hour before entering the castle again."
      );
    }

    let wheelResult = "";
    if (wheelNums.length < 5) {
      wheelNums = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
      ];
      wheelResult =
        "The Jester speaks,\"No No NO, this simply will not do! We're almost out of numbers to choose from! Oh, I know! I'll put the numbers back up and we can keep going!\"";
    }

    tempResult = tempResult.concat(" ", wheelResult);

    //set result string
    setResult(tempResult);

    //set previous guesses/wagers
    setPrevGuess(guess);
    setPrevWager(wager);
    setOpPrevGuess(opValues.guess);
    setOpPrevWager(opValues.wager);

    //set op health
    setOpHealth(opHealth - opDamage);

    //set device settings
    let newSetting = round % 5 == 0 ? 0 : Math.max(0, setting + playerIncrease);
    let newOpSetting = round % 5 == 0 ? 0 : Math.max(0, opSetting + opIncrease);
    setSetting(newSetting);
    setOpSetting(newOpSetting);

    //set round
    setRound(round + 1);

    //set spin
    setSpinResult(spin);

    //reset guesses
    opValues = getOpValues();
    setOpponentGuess(newSetting <= newOpSetting ? "n/a" : opValues.guess);
    setOpponentWager(newSetting <= newOpSetting ? "n/a" : opValues.wager);

    //set guess options
    setGuessOptions(wheelNums.concat(otherOptions));
  };

  const getColor = (num) => {
    let temp = num % 5;
    return otherOptions[temp];
  };

  const getOpDamage = (num) => {
    let total = 0;
    for (let i = 0; i < num; i++) {
      total += Math.floor(Math.random() * 4);
    }
    return total;
  };

  const getOpValues = () => {
    let colorChance = 0.2;
    let tempGuess;
    let tempWager;

    const getBestColorGuess = () => {
      let totals = [0, 0, 0, 0, 0];
      for (let i = 0; i < wheelNums.length; i++) {
        let temp = wheelNums[i] % 5;
        totals[temp] = totals[temp] + 1;
      }
      let result = [0, 1];
      for (let i = 0; i < totals.length; i++) {
        if (result[0] == i) {
          continue;
        } else if (totals[i] > totals[result[0]]) {
          if (totals[result[0]] > totals[result[1]]) {
            result[1] = i;
          } else {
            result[0] = i;
          }
        }
      }
      //console.log(result);
      //console.log(totals);
      return result;
    };

    //generate guess
    if (guess == "n/a") {
      if (Math.random() < colorChance) {
        let colorGuess = getBestColorGuess();
        tempGuess =
          otherOptions[
            colorGuess[Math.floor(Math.random() * colorGuess.length)]
          ];
      } else {
        while (!tempGuess) {
          try {
            tempGuess =
              wheelNums[
                Math.floor(wheelNums.length / 2 + Math.random() * 10 - 5)
              ];
          } catch {
            tempGuess = null;
          }
        }
      }
    } else {
      if (Math.random() < colorChance) {
        while (!tempGuess || tempGuess == guess) {
          let colorGuess = getBestColorGuess();
          tempGuess =
            otherOptions[
              colorGuess[Math.floor(Math.random() * colorGuess.length)]
            ];
          //tempGuess = otherOptions[Math.floor(Math.random() * 5)];
        }
      } else {
        while (!tempGuess || tempGuess == guess) {
          try {
            if (wheelNums.indexOf(guess) == -1) {
              tempGuess =
                wheelNums[
                  Math.floor(wheelNums.length / 2 + Math.random() * 10 - 5)
                ];
            } else if (wheelNums.indexOf(guess) > wheelNums.length / 2) {
              tempGuess =
                wheelNums[
                  Math.floor(wheelNums.indexOf(guess) - Math.random() * 10)
                ];
            } else {
              tempGuess =
                wheelNums[
                  Math.floor(wheelNums.indexOf(guess) + Math.random() * 10)
                ];
            }
          } catch {
            tempGuess = null;
          }
        }
      }
    }

    //generate wager
    for (let i = 0; i < wagerOptions.length; i++) {
      if (
        (Math.random() < 0.4 - (round ^ 2) / (55 ^ 2) &&
          wagerOptions[i] != wager) ||
        i == wagerOptions.length - 1
      ) {
        tempWager = wagerOptions[i];
        break;
      }
    }
    if (tempWager == wager) {
      tempWager = wagerOptions[wagerOptions.length - 2];
    }

    return { guess: tempGuess, wager: tempWager };
  };

  return (
    <div>
      <div className="roomText">
        Instructions:
        <ul>
          <li>Guess a number or color, and input a wager, then hit submit.</li>
          <li>
            The person with the lowest setting always guesses and wagers first.
          </li>
          <li>After a number is chosen, it is removed from possible choices</li>
          <li>
            After five rounds, damage will be dealt based on the settings.
          </li>
          <li>Last person standing wins.</li>
          <li>
            From here on, roleplay the Jester's reaction and commentary to the
            game. In addition, neither you or your opponent are gagged and can
            both talk to each other. Your opponent may beg for their life,
            reminding you they have kids.
          </li>
        </ul>
        Rules in order of precedence:
        <ul>
          <li>
            If you guess a number exactly, your pain setting will decrease by
            three times your wager, and your opponent's will increase by the
            same amount.
          </li>
          <li>
            If you both guess a color and guess incorrectly, both of your
            settings increase by an amount of my choosing!
          </li>
          <li>
            If you guess a color and you are correct, your opponent's setting is
            increased by double your wager, but if you guess wrong, your setting
            increases by double their wager, and their setting decreases by your
            wager.
          </li>
          <li>
            If both numbers are equally close to the actual number, both of your
            settings increase by an amount of my choosing!
          </li>
          <li>
            The person who guesses a number closest to the actual number has
            their setting decreased by their opponent's wager, and their
            opponent's setting increased by their wager.
          </li>
        </ul>
      </div>
      <div className="gamblingHolder">
        <b>Round:</b>
        {round}
        <br />
        <b>Opponent's Last Guess:</b> {opPrevGuess}{" "}
        <b>Opponent's Last Wager:</b> {opPrevWager}
        <br />
        <b>Your Last Guess:</b> {prevGuess} <b>Your Last Wager:</b> {prevWager}
        <br />
        <b>Spin Result:</b> {spinResult} <b>Spin Color:</b>{" "}
        <span style={{ backgroundColor: getColor(spinResult) }}>
          {getColor(spinResult)}
        </span>
        <br />
        <b>Result:</b> {result}
        <br />
        <br />
        <b>Opponent's Guess:</b> {opponentGuess} <b>Opponent's Wager:</b>{" "}
        {opponentWager}
        <br />
        <b>Your Guess:</b> {guess} <b>Your Wager:</b> {wager}
        <br />
        <br />
        <b>Your Setting:</b> {setting} <b>Opponent's Setting:</b> {opSetting}
        <br />
        <br />
        <span className="numberHolder">
          <b>Possible Numbers:</b>
          {wheelNums.map((num) => {
            return (
              <span
                key={"span" + num}
                style={{
                  backgroundColor: getColor(num),
                  color: getColor(num) == "yellow" ? "black" : "white",
                }}
              >
                {num}
              </span>
            );
          })}
        </span>
      </div>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">Guess:</label>
        <select
          className="inputField"
          onChange={(e) => {
            setGuess(e.target.value);
          }}
        >
          {guessOptions.map((op) => {
            if (op != opponentGuess) {
              return (
                <option key={op} value={op}>
                  {op}
                </option>
              );
            } else {
              return;
            }
          })}
        </select>
        <label className="inputLabel">Wager:</label>
        <select
          className="inputField"
          onChange={(e) => {
            setWager(e.target.value);
          }}
        >
          {wagerOptions.map((op) => {
            if (op != opponentWager) {
              return (
                <option key={op} value={op}>
                  {op}
                </option>
              );
            } else {
              return;
            }
          })}
        </select>
        <button className="inputSubmit" type="submit">
          Submit
        </button>
        <label className="error">{errText}</label>
      </form>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2022/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};

//input rooms
const PartThree = (props) => {
  return (
    <div>
      <div className="roomText">
        {props.text}
        <br />
        <br />
        "Ohhhh what a gripping victory!" the Jester says. "Truly a marvel to
        behold. You stand alive while your counterpart is dead!" The crowd
        cheers and the Jester pouts, "Ohhh, here's your prize then.
        Unfortunately someone already came through and got the GRAND prize, but
        here, you can take this prize instead."
      </div>
      <div className="keyAcquire">
        <b>You are given five rasps!</b> If you manage to find an exit, message
        Erik Manley and he will submit the rasps.
      </div>
      <div className="roomText">There are three paths before you.</div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
