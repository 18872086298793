import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/rooms/COG_Image__00318_.png";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/Cthulhu Rescinds.wav";
import {
  CAPDEPTH,
  changeSong,
  GetDifficulty,
  getEnemies,
  GetNextRooms,
  GetRoomObjectByName,
  GlobalContext,
  playOneShot,
} from "../room_handler";
import "./combat.css";
import RandomRoomTreasure from "../../components/Loot";
import { save } from "../../functions/save";
import { EntryPercResults, Perception } from "../../components/perception";
import { eventBus } from "../../functions/CustomEventBus";

const doorUrl = door;

const Combat = () => {
  const [display, changeDisplay] = useState("pre");
  const InstanceVars = useContext(GlobalContext);
  const [passivePerc, changePassivePerc] = useState(10);
  const [enemies, changeEnemies] = useState([]);
  const [arena, changeArena] = useState({});
  const [points, changePoints] = useState(0);
  const [entrance, changeEntrance] = useState(1);
  const [enemyLocation, changeEnemyLocation] = useState("");
  const [combatMethod, changeCombatMethod] = useState("");
  const [winMethod, changeWinMethod] = useState("");
  const [nextRooms, changeNextRooms] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (InstanceVars.current.characters.length == 0) {
      navigate("/CityOfGold/");
    }

    let [enemyTemp, arenaTemp, pointsTemp] = getEnemies(
      InstanceVars,
      Math.random() < 0.65 ? "small" : Math.random() < 0.7 ? "medium" : "large"
    );

    let allEnemies = []
      .concat(
        enemyTemp.boss,
        enemyTemp.commander,
        enemyTemp.strong,
        enemyTemp.weak
      )
      .sort((a, b) => {
        return b.passive_perc - a.passive_perc;
      });

    let passiveTemp = allEnemies.length > 0 ? allEnemies[0].passive_perc : 10;

    passiveTemp +=
      passiveTemp *
        0.2 *
        (Math.max(Math.min(allEnemies.length - 1, 9), 0) / 9) +
      (Math.random() * 10 - 5);
    passiveTemp = Math.min(passiveTemp, 40);
    // console.log(enemyTemp);
    // console.log(InstanceVars.current.characters);

    changeEntrance(
      arenaTemp.exits[Math.floor(arenaTemp.exits.length * Math.random())]
    );
    changeEnemyLocation(
      arenaTemp.enemyLocations[
        Math.floor(arenaTemp.enemyLocations.length * Math.random())
      ]
    );
    // console.log(arenaTemp);

    if (InstanceVars.current.nextRooms) {
      loadVals();
    } else {
      let nextRoomTemp = GetNextRooms(
        Combat_Obj,
        InstanceVars,
        arenaTemp.exits.length - 1
      );
      save(InstanceVars.current, Combat_Obj, nextRoomTemp);
      InstanceVars.current.score.depth += 100;
      changeNextRooms(nextRoomTemp);
    }
    changeEnemies(enemyTemp);
    changeArena(arenaTemp);
    changePoints(pointsTemp);
    changePassivePerc(passiveTemp);
  }, []);

  const loadVals = () => {
    let nextRoomTemp = [];
    InstanceVars.current.nextRooms.forEach((room) => {
      let tempRoom = GetRoomObjectByName(room.name);
      if (tempRoom) nextRoomTemp.push(tempRoom);
    });
    if (nextRoomTemp.length > 0) {
      delete InstanceVars.current.nextRooms;
      delete InstanceVars.current.currentRoom;
    } else {
      nextRoomTemp = GetNextRooms(
        Combat_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Combat_Obj.exits)
      );
    }
    changeNextRooms(nextRoomTemp);
  };

  const parseInput = (input, pass = true) => {
    // console.log(input);
    if (input === "atk" || pass === false) {
      changeDisplay("combat");
    } else if (input === "snkAtk" && pass === true) {
      changeCombatMethod("sneak");
      changeDisplay("combat");
    } else if (input === "snk") {
      InstanceVars.current.score.skill += 200;
      changeWinMethod("sneak");
      changeDisplay("finish");
    } else if (input === "win") {
      InstanceVars.current.score.combat += points;
      changeWinMethod("win");
      changeDisplay("finish");
    } else if (input === "lose") {
      navigate("/CityOfGold/death");
    } else if (input === "escape") {
      changeWinMethod("escape");
      changeDisplay("finish");
    } else {
      changeDisplay("combat");
    }
  };

  const getComponent = () => {
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        return (
          <PreInput
            output={parseInput}
            enemies={enemies}
            arena={arena}
            passivePerc={passivePerc}
            points={points}
            entrance={entrance}
            enemyLocation={enemyLocation}
          />
        );
      case "combat":
        return (
          <CombatView
            output={parseInput}
            enemies={enemies}
            arena={arena}
            passivePerc={passivePerc}
            points={points}
            entrance={entrance}
            enemyLocation={enemyLocation}
            combatMethod={combatMethod}
          />
        );
      case "finish":
        return (
          <FinishView
            winMethod={winMethod}
            rooms={nextRooms}
            size={arena.size}
          />
        );
    }
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Combat</h1>
      <img className="combatMapBanner" src={arena.url} />
      <p className="descriptionText">
        You enter a room with enemies inside. You must defeat them or sneak
        around them before moving on.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = (i = 0, navigate) => {
  return (
    <button
      key={Combat_Obj.name + i}
      className="entry"
      onClick={() => {
        // console.log(location);
        playOneShot(doorSfx);
        navigate(Combat_Obj.route);
      }}
    >
      <img src={Combat_Obj.image} />
      <span className="entryText">A dark passage leading into the unknown</span>
      <EntryPercResults
        observation={{
          tier: 4,
          text: "You can hear one or more creatures in the distance.",
        }}
        spirituality={{
          tier: 5,
          text: "You sense danger down this passage.",
        }}
      />
    </button>
  );
};

export var Combat_Obj = {
  name: "Combat",
  room: Combat,
  entry: Entryway,
  route: "/CityOfGold/Combat",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Combat;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [approach, setApproach] = useState("atk");
  const [input, setInput] = useState([]);
  const InstanceVars = useContext(GlobalContext);

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    let pass = true;
    if (approach != "atk") {
      if (Math.min(...input) < props.passivePerc) pass = false;
    }
    // console.log(pass);
    props.output(approach, pass);
  };

  return (
    <div>
      <p className="roomText">
        {props.arena.description
          ? props.arena.description
          : "Error with generating room text"}
        <br />
        <br />
        You are located at entrance number: {props.entrance}
        <br />
        <br />
        {props.enemyLocation}
      </p>
      <div className="enemyFlexbox">
        {props.enemies.boss && props.enemies.boss.length > 0 ? (
          <div className="bossHolder enemyHolder">
            <span className="numEnemyHolder">{props.enemies.boss.length}x</span>
            <img
              className="enemyImage"
              alt="boss_preview"
              src={props.enemies.boss[0].imageurl}
            />
            <span className="enemyDescription">
              {props.enemies.boss[0].description}
            </span>
          </div>
        ) : (
          ""
        )}
        {props.enemies.commander && props.enemies.commander.length > 0 ? (
          <div className="commanderHolder enemyHolder">
            <span className="numEnemyHolder">
              {props.enemies.commander.length}x
            </span>
            <img
              className="enemyImage"
              alt="commander_preview"
              src={props.enemies.commander[0].imageurl}
            />
            <span className="enemyDescription">
              {props.enemies.commander[0].description}
            </span>
          </div>
        ) : (
          ""
        )}

        {props.enemies.strong && props.enemies.strong.length > 0 ? (
          <div className="strongHolder enemyHolder">
            <span className="numEnemyHolder">
              {props.enemies.strong.length}x
            </span>
            <img
              className="enemyImage"
              alt="strong_preview"
              src={props.enemies.strong[0].imageurl}
            />
            <span className="enemyDescription">
              {props.enemies.strong[0].description}
            </span>
          </div>
        ) : (
          ""
        )}

        {props.enemies.weak && props.enemies.weak.length > 0 ? (
          <div className="weakHolder enemyHolder">
            <span className="numEnemyHolder">{props.enemies.weak.length}x</span>
            <img
              className="enemyImage"
              alt="weak_preview"
              src={props.enemies.weak[0].imageurl}
            />
            <span className="enemyDescription">
              {props.enemies.weak[0].description}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">How would you like to handle this?</label>
        <select
          className="approachSelector"
          id="approachSelector"
          onChange={(e) => setApproach(e.target.value)}
        >
          <option value="atk">Attack</option>
          <option value="snk">Sneak</option>
          <option value="snkAtk">Sneak Attack</option>
        </select>
        {approach != "atk"
          ? InstanceVars.current.characters.map((char, i) => {
              return (
                <div key={"char_" + char.name}>
                  <label className="inputLabel">
                    Insert {char.name}'s Stealth-Dex roll:{" "}
                  </label>
                  <input
                    className="inputField"
                    type="number"
                    min="-5"
                    max="60"
                    required
                    onChange={(e) => {
                      let temp = [...input];
                      temp[i] = e.target.value;
                      setInput(temp);
                    }}
                  />
                </div>
              );
            })
          : ""}
        <button className="inputSubmit" type="submit">
          Next
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const CombatView = (props) => {
  const [escapeInput, setEscapeInput] = useState(0);
  const [failEscapeText, setFailEscapeText] = useState("");

  const submitHandler = (type) => {
    switch (type) {
      case "win":
        props.output("win");
        break;
      case "lose":
        props.output("lose");
        break;
      case "escape":
        if (escapeInput >= props.passivePerc + 5) {
          props.output("escape");
        } else {
          setFailEscapeText("Failed to escape. Enemies take their turn.");
          setEscapeInput(0);
        }
        break;
      case "instEscape":
        props.output("escape");
        break;
    }
  };

  return (
    <div>
      <p className="roomText">
        {props.arena.description
          ? props.arena.description
          : "Error with generating room text"}
        <br />
        <br />
        You are located at entrance number: {props.entrance}
        <br />
        <br />
        {props.enemyLocation}
        <br />
        <b style={{ color: "rgb(255, 102, 0)" }}>
          {props.combatMethod === "sneak"
            ? "Your enemy is unaware of your presence. The first turn of combat is a sneak attack."
            : "Your enemy is aware of your presence. Roll Initiative and begin combat."}
        </b>
        <br />
        <br />
        Room Features:
        <br />
      </p>
      <ul>
        {props.arena.features.map((feature) => {
          return <li>{feature}</li>;
        })}
      </ul>
      <div className="enemyFlexbox">
        {props.enemies.boss && props.enemies.boss.length > 0 ? (
          <div className="enemySection">
            <div className="bossHolder enemyStatHolder">
              <img
                className="enemyImage"
                alt="boss_preview"
                src={props.enemies.boss[0].imageurl}
              />
              <span className="enemyName">{props.enemies.boss[0].name}</span>
              <ul className="enemyStats">
                <li>
                  <b>HP: </b>
                  {props.enemies.boss[0].hp}
                </li>
                <li>
                  <b>THP: </b>
                  {props.enemies.boss[0].thp}
                </li>
                <li>
                  <b>AC: </b>
                  {props.enemies.boss[0].ac}
                </li>
                <li>
                  <b>AC (no prof): </b>
                  {props.enemies.boss[0].ac_noprof}
                </li>
                <li>
                  <b>Attack Bonus: </b>
                  {props.enemies.boss[0].attack}
                </li>
                <li>
                  <b>Mental Aquity: </b>
                  {props.enemies.boss[0].mental_aquity}
                </li>
                <li>
                  <b>Push Through: </b>
                  {props.enemies.boss[0].push_through}
                </li>
                <li>
                  <b>Push DC: </b>
                  {props.enemies.boss[0].push_dc}
                </li>
                <li>
                  <b>Quick Reflexes: </b>
                  {props.enemies.boss[0].quick_reflexes}
                </li>
                <li>
                  <b>Initiative: </b>
                  {props.enemies.boss[0].init}
                </li>
                <li>
                  <b>Move Speed: </b>
                  {props.enemies.boss[0].move}
                </li>
                <li>
                  <b>Dash Speed: </b>
                  {props.enemies.boss[0].dash}
                </li>
              </ul>
              <ul className="enemyAbilities">
                <li>
                  <b>Resistances: </b> <br />
                  {props.enemies.boss[0].resistances}
                </li>
                <li>
                  <b>Abilities: </b> <br />
                  <ul className="enemyAbilityList">
                    {props.enemies.boss[0].abilities.map((abil, i) => {
                      return (
                        <li key={props.enemies.boss[0].name + "_ability_" + i}>
                          {abil}
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li>
                  <b>Tactics: </b> <br />
                  {props.enemies.boss[0].tactics}
                </li>
                <li>
                  <b>Faction: </b> <br />
                  {props.enemies.boss[0].faction}
                </li>
              </ul>
            </div>
            <div className="enemyTrackingHolder">
              {props.enemies.boss.map((enemy, i) => {
                return (
                  <div key={enemy.name + "_" + i}>
                    <span>
                      <b className="enemyTrackingListName">
                        {enemy.name + " " + (i + 1)}
                      </b>
                    </span>{" "}
                    <span>
                      <b>HP:</b> <input defaultValue={enemy.hp} type="number" />{" "}
                      / {enemy.hp}
                    </span>
                    <span>
                      <b>THP:</b>{" "}
                      <input defaultValue={enemy.thp} type="number" /> /{" "}
                      {enemy.thp}{" "}
                    </span>
                    <span>
                      <b> Notes:</b>{" "}
                      <input className="enemyNotes" type="text" />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
        {props.enemies.commander && props.enemies.commander.length > 0 ? (
          <div className="enemySection">
            <div className="commanderHolder enemyStatHolder">
              <img
                className="enemyImage"
                alt="commander_preview"
                src={props.enemies.commander[0].imageurl}
              />
              <span className="enemyName">
                {props.enemies.commander[0].name}
              </span>
              <ul className="enemyStats">
                <li>
                  <b>HP: </b>
                  {props.enemies.commander[0].hp}
                </li>
                <li>
                  <b>THP: </b>
                  {props.enemies.commander[0].thp}
                </li>
                <li>
                  <b>AC: </b>
                  {props.enemies.commander[0].ac}
                </li>
                <li>
                  <b>AC (no prof): </b>
                  {props.enemies.commander[0].ac_noprof}
                </li>
                <li>
                  <b>Attack Bonus: </b>
                  {props.enemies.commander[0].attack}
                </li>
                <li>
                  <b>Mental Aquity: </b>
                  {props.enemies.commander[0].mental_aquity}
                </li>
                <li>
                  <b>Push Through: </b>
                  {props.enemies.commander[0].push_through}
                </li>
                <li>
                  <b>Push DC: </b>
                  {props.enemies.commander[0].push_dc}
                </li>
                <li>
                  <b>Quick Reflexes: </b>
                  {props.enemies.commander[0].quick_reflexes}
                </li>
                <li>
                  <b>Initiative: </b>
                  {props.enemies.commander[0].init}
                </li>
                <li>
                  <b>Move Speed: </b>
                  {props.enemies.commander[0].move}
                </li>
                <li>
                  <b>Dash Speed: </b>
                  {props.enemies.commander[0].dash}
                </li>
              </ul>
              <ul className="enemyAbilities">
                <li>
                  <b>Resistances: </b> <br />
                  {props.enemies.commander[0].resistances}
                </li>
                <li>
                  <b>Abilities: </b> <br />
                  <ul className="enemyAbilityList">
                    {props.enemies.commander[0].abilities.map((abil, i) => {
                      return (
                        <li
                          key={
                            props.enemies.commander[0].name + "_ability_" + i
                          }
                        >
                          {abil}
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li>
                  <b>Tactics: </b> <br />
                  {props.enemies.commander[0].tactics}
                </li>
                <li>
                  <b>Faction: </b> <br />
                  {props.enemies.commander[0].faction}
                </li>
              </ul>
            </div>
            <div className="enemyTrackingHolder">
              {props.enemies.commander.map((enemy, i) => {
                return (
                  <div key={enemy.name + "_" + i}>
                    <span>
                      <b className="enemyTrackingListName">
                        {enemy.name + " " + (i + 1)}
                      </b>
                    </span>{" "}
                    <span>
                      <b>HP:</b> <input defaultValue={enemy.hp} type="number" />{" "}
                      / {enemy.hp}
                    </span>
                    <span>
                      <b>THP:</b>{" "}
                      <input defaultValue={enemy.thp} type="number" /> /{" "}
                      {enemy.thp}{" "}
                    </span>
                    <span>
                      <b> Notes:</b>{" "}
                      <input className="enemyNotes" type="text" />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}

        {props.enemies.strong && props.enemies.strong.length > 0 ? (
          <div className="enemySection">
            <div className="strongHolder enemyStatHolder">
              <img
                className="enemyImage"
                alt="strong_preview"
                src={props.enemies.strong[0].imageurl}
              />
              <span className="enemyName">{props.enemies.strong[0].name}</span>
              <ul className="enemyStats">
                <li>
                  <b>HP: </b>
                  {props.enemies.strong[0].hp}
                </li>
                <li>
                  <b>THP: </b>
                  {props.enemies.strong[0].thp}
                </li>
                <li>
                  <b>AC: </b>
                  {props.enemies.strong[0].ac}
                </li>
                <li>
                  <b>AC (no prof): </b>
                  {props.enemies.strong[0].ac_noprof}
                </li>
                <li>
                  <b>Attack Bonus: </b>
                  {props.enemies.strong[0].attack}
                </li>
                <li>
                  <b>Mental Aquity: </b>
                  {props.enemies.strong[0].mental_aquity}
                </li>
                <li>
                  <b>Push Through: </b>
                  {props.enemies.strong[0].push_through}
                </li>
                <li>
                  <b>Push DC: </b>
                  {props.enemies.strong[0].push_dc}
                </li>
                <li>
                  <b>Quick Reflexes: </b>
                  {props.enemies.strong[0].quick_reflexes}
                </li>
                <li>
                  <b>Initiative: </b>
                  {props.enemies.strong[0].init}
                </li>
                <li>
                  <b>Move Speed: </b>
                  {props.enemies.strong[0].move}
                </li>
                <li>
                  <b>Dash Speed: </b>
                  {props.enemies.strong[0].dash}
                </li>
              </ul>
              <ul className="enemyAbilities">
                <li>
                  <b>Resistances: </b> <br />
                  {props.enemies.strong[0].resistances}
                </li>
                <li>
                  <b>Abilities: </b> <br />
                  <ul className="enemyAbilityList">
                    {props.enemies.strong[0].abilities.map((abil, i) => {
                      return (
                        <li
                          key={props.enemies.strong[0].name + "_ability_" + i}
                        >
                          {abil}
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li>
                  <b>Tactics: </b> <br />
                  {props.enemies.strong[0].tactics}
                </li>
                <li>
                  <b>Faction: </b> <br />
                  {props.enemies.strong[0].faction}
                </li>
              </ul>
            </div>
            <div className="enemyTrackingHolder">
              {props.enemies.strong.map((enemy, i) => {
                return (
                  <div key={enemy.name + "_" + i}>
                    <span>
                      <b className="enemyTrackingListName">
                        {enemy.name + " " + (i + 1)}
                      </b>
                    </span>{" "}
                    <span>
                      <b>HP:</b> <input defaultValue={enemy.hp} type="number" />{" "}
                      / {enemy.hp}
                    </span>
                    <span>
                      <b>THP:</b>{" "}
                      <input defaultValue={enemy.thp} type="number" /> /{" "}
                      {enemy.thp}{" "}
                    </span>
                    <span>
                      <b> Notes:</b>{" "}
                      <input className="enemyNotes" type="text" />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}

        {props.enemies.weak && props.enemies.weak.length > 0 ? (
          <div className="enemySection">
            <div className="weakHolder enemyStatHolder">
              <img
                className="enemyImage"
                alt="weak_preview"
                src={props.enemies.weak[0].imageurl}
              />
              <span className="enemyName">{props.enemies.weak[0].name}</span>
              <ul className="enemyStats">
                <li>
                  <b>HP: </b>
                  {props.enemies.weak[0].hp}
                </li>
                <li>
                  <b>THP: </b>
                  {props.enemies.weak[0].thp}
                </li>
                <li>
                  <b>AC: </b>
                  {props.enemies.weak[0].ac}
                </li>
                <li>
                  <b>AC (no prof): </b>
                  {props.enemies.weak[0].ac_noprof}
                </li>
                <li>
                  <b>Attack Bonus: </b>
                  {props.enemies.weak[0].attack}
                </li>
                <li>
                  <b>Mental Aquity: </b>
                  {props.enemies.weak[0].mental_aquity}
                </li>
                <li>
                  <b>Push Through: </b>
                  {props.enemies.weak[0].push_through}
                </li>
                <li>
                  <b>Push DC: </b>
                  {props.enemies.weak[0].push_dc}
                </li>
                <li>
                  <b>Quick Reflexes: </b>
                  {props.enemies.weak[0].quick_reflexes}
                </li>
                <li>
                  <b>Initiative: </b>
                  {props.enemies.weak[0].init}
                </li>
                <li>
                  <b>Move Speed: </b>
                  {props.enemies.weak[0].move}
                </li>
                <li>
                  <b>Dash Speed: </b>
                  {props.enemies.weak[0].dash}
                </li>
              </ul>
              <ul className="enemyAbilities">
                <li>
                  <b>Resistances: </b> <br />
                  {props.enemies.weak[0].resistances}
                </li>
                <li>
                  <b>Abilities: </b> <br />
                  <ul className="enemyAbilityList">
                    {props.enemies.weak[0].abilities.map((abil, i) => {
                      return (
                        <li key={props.enemies.weak[0].name + "_ability_" + i}>
                          {abil}
                        </li>
                      );
                    })}
                  </ul>
                </li>
                <li>
                  <b>Tactics: </b> <br />
                  {props.enemies.weak[0].tactics}
                </li>
                <li>
                  <b>Faction: </b> <br />
                  {props.enemies.weak[0].faction}
                </li>
              </ul>
            </div>
            <div className="enemyTrackingHolder">
              {props.enemies.weak.map((enemy, i) => {
                return (
                  <div key={enemy.name + "_" + i}>
                    <span>
                      <b className="enemyTrackingListName">
                        {enemy.name + " " + (i + 1)}
                      </b>
                    </span>{" "}
                    <span>
                      <b>HP:</b> <input defaultValue={enemy.hp} type="number" />{" "}
                      / {enemy.hp}
                    </span>
                    <span>
                      <b>THP:</b>{" "}
                      <input defaultValue={enemy.thp} type="number" /> /{" "}
                      {enemy.thp}{" "}
                    </span>
                    <span>
                      <b> Notes:</b>{" "}
                      <input className="enemyNotes" type="text" />
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="combatEndButtonHolder">
        <div>
          <label>Only use this option if all enemies are dead.</label>
          <input
            type="button"
            className="combatWonButton"
            value="Win Combat"
            onClick={() => {
              submitHandler("win");
            }}
          />
        </div>
        <div>
          <label>Only use this option if all allies are dead.</label>
          <input
            type="button"
            className="combatLostButton"
            value="Lose Combat"
            onClick={() => {
              submitHandler("lose");
            }}
          />
        </div>
        <div>
          <label>
            If you wish to escape the combat, everyone should roll an
            agility-dex or stealth-dex and put the lowest result in the box
            below before pushing the "escape" button. If you fail, the enemy
            takes their turn. you can attempt this once per turn. NOTE: You will
            get no points for this combat.
          </label>
          <br />
          <label style={{ color: "rgb(255, 102, 0)" }}>{failEscapeText}</label>
          <input
            type="number"
            className="combatRunInput"
            value={escapeInput}
            onChange={(e) => setEscapeInput(e.target.value)}
          />
          <input
            type="button"
            className="combatRunButton"
            value="Escape"
            onClick={() => {
              submitHandler("escape");
            }}
          />
        </div>
        <div>
          <label>
            Only use this option if everyone has a way to instantly escape
            combat (i.e. teleport spell) NOTE: You will get no points for this
            combat.
          </label>
          <input
            type="button"
            className="combatInstantRunButton"
            value="Instant Escape"
            onClick={() => {
              submitHandler("instEscape");
            }}
          />
        </div>
      </div>
    </div>
  );
};

//input rooms
const FinishView = (props) => {
  const navigate = useNavigate();
  const [successText, setSuccessText] = useState("");

  useEffect(() => {
    switch (props.winMethod) {
      case "sneak":
        setSuccessText(
          "You have successfully snuck past the enemy. Roleplay what happened as you sneak past the enemy. Which room did you escape to?"
        );
        break;
      case "win":
        setSuccessText(
          "You have successfully defeated the enemy. Which path will you take next?"
        );
        break;
      case "escape":
        setSuccessText(
          "You have successfully escaped the enemy. Roleplay your daring escape! Which room did you escape to?"
        );
        break;
    }
  }, []);

  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>Success!</b> {successText}
        </div>
        {props.winMethod === "win" ? (
          <RandomRoomTreasure
            chance={
              props.size === "small"
                ? 0.2
                : props.size === "medium"
                ? 0.3
                : props.size === "large"
                ? 0.5
                : props.size === "boss"
                ? 1
                : 0.1
            }
            chanceForCurse={0.2}
            tier={Math.round(
              Math.random() *
                (props.size === "small"
                  ? 1
                  : props.size === "medium"
                  ? 2
                  : props.size === "large"
                  ? 3
                  : props.size === "boss"
                  ? 5
                  : 1)
            )}
          />
        ) : (
          ""
        )}
      </div>
      <Perception />
      <div className="nextHolder">
        {props.rooms.map((room, i) => {
          return room.entry(i, navigate);
        })}
      </div>
    </div>
  );
};
