import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/metaldoor.jpg";
import doorSfx from "../../res/sfx/Metal Door Creak Open 1_2.wav";
import sfx from "../../res/sfx/Metal Gate Open 1_3.wav";
import song from "../../res/music/Stand Off.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://fategrouparchive.files.wordpress.com/2016/10/indiana-jones-and-the-temple-of-doom-spikes.jpg";
const doorUrl = door;

var nextRooms;

const Spikes = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    playOneShot(sfx);
    nextRooms = GetNextRooms(Spikes_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Spikes_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 1) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Spikes</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You step into a spacious room with walls of peeling paint... or is that
        blood? The floor is riddled with the bones of those that came before
        you. The air is stale with the faint smell of rusted metal. The room
        shakes--there's a sound coming from the ceiling. Are those..spikes?
        Metal spikes, old and crudely attached to some sort of wooden platform.
        With shuddering movement, it moves closer, and closer, intent on
        impaling and crushing you. Theres a door! Theres a door on the other
        side of the room! Quick!
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Spikes_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Spikes_Obj.route);
      }}
    >
      <img src={Spikes_Obj.image} />
      <span className="entryText">
        A heavy iron door leading into the unknown
      </span>
    </button>
  );
};

export var Spikes_Obj = {
  name: "Spikes",
  room: Spikes,
  entry: Entryway,
  route: "/halloween2022/spikes",
  exits: 1,
  prob: 5,
  image: doorUrl,
};

export default Spikes;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input)) {
      setErrText("");
      props.output([input]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        You only have moments to make it to the door on the other side of the
        room. Make an Agility (dex) check to avoid tripping on the bones.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your score below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You successfully traverse the skulls and bones that lay
          on the floor, reaching the other door just in time. Roleplay your
          success in reaching the other door!
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> The ceiling is coming down fast! Bones litter your path
          to the door. Roleplay your failure as the spikes eventually impale
          you.{" "}
          <b>
            You die as the spikes first impale you, and then slowly crush you
            into the ground.
          </b>
        </div>
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2022/death");
          }}
        >
          Click this if you are Dead
        </button>
      </div>
    </div>
  );
};
