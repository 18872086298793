import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Entrance from "./rooms/entrance";
import CurrentRoom from "./rooms/room_handler";
import Stairs from "./rooms/passage_rooms/stairs";
import Hallway from "./rooms/passage_rooms/hallway";
import Exit from "./rooms/exit_rooms/exit";
import Chute from "./rooms/passage_rooms/chute";
import Stairs2 from "./rooms/passage_rooms/stairs2";
import Hole from "./rooms/passage_rooms/hole";
import Empty from "./rooms/passage_rooms/empty";
import Combat from "./rooms/combat_rooms/combat";
import Pit from "./rooms/skill_rooms/pit_trap";
import BlockedWell from "./rooms/respite_rooms/blocked_well";
import ManaWell from "./rooms/respite_rooms/mana_well";
import SmallWell from "./rooms/respite_rooms/small_well";
import Atlas from "./rooms/skill_rooms/atlas";
import Axes from "./rooms/skill_rooms/axes";
import Blinded from "./rooms/skill_rooms/blinded";
import Grass from "./rooms/skill_rooms/grass";
import Murky_Water from "./rooms/skill_rooms/murky_water";
import Nappy_Waters from "./rooms/skill_rooms/nappy_waters";
import Remnant from "./rooms/skill_rooms/remnant";
import Sapping_Tree from "./rooms/skill_rooms/sapping_tree";
import Skywalker from "./rooms/skill_rooms/skywalker";
import Thorns from "./rooms/skill_rooms/thorn";
import Wonderwall from "./rooms/skill_rooms/wonderwall";
import Small_Hoard from "./rooms/treasure_rooms/small_hoard";
import Combat2 from "./rooms/combat_rooms/combat2";
import Loot_Divvy from "./rooms/loot_divvy";
import Boss_Combat from "./rooms/treasure_rooms/boss_combat";
import Death from "./rooms/death";
import Med_Hoard from "./rooms/treasure_rooms/med_hoard";
import Old_Battleground from "./rooms/treasure_rooms/old_battleground";

const COGRouterHandler = () => {
  return (
    <Routes>
      <Route path="/" element={<CurrentRoom />}>
        <Route index element={<Entrance />} />
        <Route path="stairs" element={<Stairs />} />
        <Route path="hallway" element={<Hallway />} />
        <Route path="exit" element={<Exit />} />
        <Route path="chute" element={<Chute />} />
        <Route path="stairs2" element={<Stairs2 />} />
        <Route path="hole" element={<Hole />} />
        <Route path="empty" element={<Empty />} />
        <Route path="combat" element={<Combat />} />
        <Route path="blocked_well" element={<BlockedWell />} />
        <Route path="mana_well" element={<ManaWell />} />
        <Route path="small_well" element={<SmallWell />} />
        <Route path="atlas" element={<Atlas />} />
        <Route path="axes" element={<Axes />} />
        <Route path="blinded" element={<Blinded />} />
        <Route path="grass" element={<Grass />} />
        <Route path="murky_water" element={<Murky_Water />} />
        <Route path="nappy_waters" element={<Nappy_Waters />} />
        <Route path="pit" element={<Pit />} />
        <Route path="remnant" element={<Remnant />} />
        <Route path="sapping_Tree" element={<Sapping_Tree />} />
        <Route path="skywalker" element={<Skywalker />} />
        <Route path="thorns" element={<Thorns />} />
        <Route path="wonderwall" element={<Wonderwall />} />
        <Route path="small_hoard" element={<Small_Hoard />} />
        <Route path="combat2" element={<Combat2 />} />
        <Route path="loot_divvy" element={<Loot_Divvy />} />
        <Route path="boss_combat" element={<Boss_Combat />} />
        <Route path="death" element={<Death />} />
        <Route path="med_hoard" element={<Med_Hoard />} />
        <Route path="old_battleground" element={<Old_Battleground />} />
      </Route>
    </Routes>
  );
};

export default COGRouterHandler;
