import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Metal Rusty 2_3.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GetRoomOfType,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://miro.medium.com/max/640/1*xWn92_vNJ6Gyho7SNFtZnQ.jpeg";
const doorUrl =
  "https://i.pinimg.com/564x/0a/0f/eb/0a0feb5c6e652454f0944b0484a44c6d.jpg";

var nextRooms;

const DoorsRiddle = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(DoorsRiddle_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(DoorsRiddle_Obj);
  }

  const parseInput = (input) => {
    switch (input[0]) {
      case "gold":
        changeDisplay("exit");
        break;
      case "red":
      case "purple":
      case "black":
        changeDisplay("death");
        break;
      case "green":
        changeDisplay("continue");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "exit":
        component = <TranspOut rooms={nextRooms} />;
        break;
      case "death":
        component = <TranspDeath rooms={nextRooms} />;
        break;
      case "continue":
        component = <Continue rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Doors</h1>
      <img className="banner" src={bannerUrl} />
      <div className="descriptionText">
        As you step into the room, the door disappears behind you. You stand
        within an unadorned room of stone. Seven free standing doorways stand in
        the center of the room, each without a door and each a different color
        and material.
        <br />
        Door descriptions (in order from left to right):
        <ul>
          <li>
            <b style={{ color: "red" }}>Red Doorway:</b> The red doorway is of a
            normal proportion. It's made of rusted iron painted over with a deep
            crimson. As you get close, you feel an aura that brings fond
            memories of positive moments as a child.
          </li>
          <li>
            <b style={{ color: "green" }}>Green Doorway:</b> The green doorway
            is squat, such that you would need to crawl in order to make it
            through. As you get closer, you realize the the doorway is made of a
            semi-transparent glass. You feel an aura of joviality, bringing
            memories of funny moments in your more recent life.
          </li>
          <li>
            <b style={{ color: "tan" }}>Brown Doorway:</b> The brown doorway is
            narrow, such that you would have to turn sideways to get through.
            It's made of a deep brown sandstone and smells like desert rain. As
            you draw near, you feel a terrible dread like something bad might
            happen in the near future.
          </li>
          <li>
            <b style={{ color: "gold" }}>Gold Doorway:</b> The gold doorway is
            tall but slim, reaching almost to the ceiling. As you approach, you
            realize this doorway is made of fools gold. It brings memories of
            adventure in youth as you draw near.
          </li>
          <li>
            <b style={{ color: "rgb(255,0,255)" }}>Purple Doorway:</b> The
            purple doorway is of a normal proportion. It's made of tarnished
            copper painted over with a rich velvet purple and smells of perfume.
            It brings memories of love and passion from your more recent life.
          </li>
          <li>
            <b style={{ color: "white" }}>White Doorway:</b> The white doorway
            is wide, but squat, such that you would need to crawl in order to
            make it through, and appears to be made of a bright white birch,
            stained an even further white. This doorway gives an aura of
            anticipation and dreams, casting your mind to your future goals and
            ambitions.
          </li>
          <li>
            <b style={{ color: "grey" }}>Black Doorway:</b> The black doorway is
            is tall and wide, and appears to be made of a charred wood. This
            doorway gives an aura of anxiety as you draw near, bringing memories
            of past disappointments and regrets during your youth.
          </li>
        </ul>
      </div>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={DoorsRiddle_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(DoorsRiddle_Obj.route);
      }}
    >
      <img src={DoorsRiddle_Obj.image} />
      <span className="entryText">
        A heavy golden door leading into the unknown
      </span>
    </button>
  );
};

export var DoorsRiddle_Obj = {
  name: "DoorsRiddle",
  room: DoorsRiddle,
  entry: Entryway,
  route: "/halloween2022/doors",
  exits: 3,
  prob: 0.5,
  image: doorUrl,
};

export default DoorsRiddle;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");
  const [inertText, setInertText] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option == "brown" || option == "white") {
      setInertText(
        "You walk through the " + option + " door. Nothing appears to happen."
      );
    } else {
      props.output([option]);
    }
  };

  return (
    <div>
      <div className="roomText">
        You see a metal plackard on the ground, embossed with lettering in
        common. It reads:
        <br />
        <div className="readingText">
          Danger lies before you, while safety lies behind.
          <br />
          Two of us can help you, whichever you would find.
          <br />
          One among us seven will let you move ahead.
          <br />
          Four among these doorways will transport you instead.
          <br />
          Two among our number are simply passing lies,
          <br />
          While four among our number would lead to your demise.
          <br />
          Choose, unless to stay here, you wish for evermore.
          <br />
          To help you in your choice, we give you these clues four:
          <br />
          First, locate the doors inert, their pathway is secure,
          <br />
          One door to the right of each will transport you for sure.
          <br />
          Second, you should lay your eyes on doors of slender make,
          <br />
          Neither door will kill you, for either you could take.
          <br />
          Third though they are different, look to doors that hide.
          <br />
          If you would move forward, in neither walk inside.
          <br />
          Fourth check on the doors of promise, for different though they seem,
          <br />
          they're twins if you should walk through them, their differences a
          dream.
        </div>
      </div>
      <div className="secondaryText" color="orange">
        {inertText}
      </div>
      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="red"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Step through the Red Door
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="green"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Step through the Green Door
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="brown"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Step through the Brown Door
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="gold"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Step through the Gold Door
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="purple"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Step through the Purple Door
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="white"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Step through the White Door
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="black"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Step through the Black Door
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const TranspOut = (props) => {
  const navigate = useNavigate();
  const room = GetRoomOfType("exit");
  return (
    <div>
      <div className="roomText">
        As you step through the doorway, you are transported somewhere.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(room.route);
          }}
        >
          Figure out Where you are
        </button>
      </div>
    </div>
  );
};

//input rooms
const TranspDeath = (props) => {
  const navigate = useNavigate();
  const room = GetRoomOfType("death");
  return (
    <div>
      <div className="roomText">
        As you step through the doorway, you are transported somewhere.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(room.route);
          }}
        >
          Figure out Where you are
        </button>
      </div>
    </div>
  );
};

//input rooms
const Continue = (props) => {
  return (
    <div>
      <div className="roomText">
        As you get down on your hands and knees and crawl through the doorway,
        you feel a tearing sensation as your spirit is ripped out of your body.
        It's over as quickly as it begins and you're left staring down at your
        corpse.
        <h2 className="deathText">You are dead</h2>
        <div style={{ paddingTop: "400vh" }}>
          But you are not transported out of the castle. Every time you've died
          in this place in the past, you've been transported out. This doesn't
          make sense.
          <br />
          <br />
          You look around. You might even step through the doors once again in
          your ghostly form. The doors do nothing to you as a ghost. You feel
          around the room for a while and realize one of the room's walls can be
          pushed through in your ghostly form. After whatever precautions you
          take, you step through the wall.
          <br />
          <br />
          On the other side of the wall, a white bag of rasps hangs in the air
          with mist gently curling around it.
          <div className="keyAcquire">
            <b>You have acquired five rasps!</b> This used to be the location of
            the Ghost key, but it has since been claimed! Now you must escape
            the castle with your life. Make your way to an exit, or take your
            chances to find a key. If you do escape, message @Beriale Wardhaven
            (Erik Manley) and he will give you the rasps.{" "}
            <b>NOTE: You can only claim these rasps one time.</b>
          </div>
          After grabbing the bag of rasps, you are immediately resurrected
          without penalty. The bag lays solid in the palm of your hand. Three
          paths appear in the opposite end of the room.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
