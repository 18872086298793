import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import door from "../../res/generic_door.png";
import DifficultyMeter from "../../gui/difficultyMeter";
import doorSfx from "../../res/sfx/Necrotic Curse II.mp3";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GetRoomOfType,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/564x/3a/05/ab/3a05ab407c02ecffa20b133da87b292e.jpg";
const doorUrl =
  "https://i.pinimg.com/564x/3a/05/ab/3a05ab407c02ecffa20b133da87b292e.jpg";

var nextRooms;

const Barrier = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Barrier_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Barrier_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 5;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 1; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (input[0] == "turnBack") {
      changeDisplay("turnBack");
    } else if (successes >= 3) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "turnBack":
        component = <TurnBack rooms={nextRooms} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Barrier</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        Wind rushes by you with a pulsating rhythm. You approach a red wall of
        sigils and magical circles. A script you don't recognize rims the
        circles and demonic runes emblazon the design. You step up to it and
        touch it. Wind rushes past you as a solid barrier halts you.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Barrier_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Barrier_Obj.route);
      }}
    >
      <img src={Barrier_Obj.image} />
      <span className="entryText">
        A magical barrier bars your path down a hallway
      </span>
    </button>
  );
};

export var Barrier_Obj = {
  name: "Barrier",
  room: Barrier,
  entry: Entryway,
  route: "/halloween2022/barrier",
  exits: 2,
  prob: 2,
  image: doorUrl,
};

export default Barrier;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if ((!isNaN(input) && !isNaN(input2) && !isNaN(input3)) || option !== "") {
      setErrText("");
      props.output([option, input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        There's no pushing through. You can either attempt to dispel the magical
        barrier using a mysticism (mind) check, or turn back.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="turnBack"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Turn Back
        </button>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> After working complex magic of your own, you manage to
          dispel the magical circle, gaining access to the path beyond.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">{GetRoomOfType("key").entry()}</div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> After attempting for several minutes to get through the
          barrier, you conclude that your efforts are insufficient to pass
          through the barrier. Your efforts have left you drained.{" "}
          <b>Suffer -1d6</b> on your next skill roll.
          <br />
          <br />
          You turn around and see the area you came from is no longer there.
          Instead there are two doors.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const TurnBack = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          You turn around to leave the barrier and see the area you came from is
          no longer there. Instead there are two doors.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
