import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl =
  "https://i.pinimg.com/564x/3f/40/ee/3f40eef5c27c451cc3fc797afee7d9dc.jpg";
const doorUrl = door;

var nextRooms;

const Family = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Family_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Family_Obj);
  }

  const parseInput = (input) => {
    switch (input[1]) {
      case "optionOne":
        changeDisplay("optionOne");
        break;
      case "optionTwo":
        changeDisplay("optionTwo");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "optionOne":
        component = <OptionOneView rooms={nextRooms} />;
        break;
      case "optionTwo":
        component = <OptionTwoView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Family</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        The dark room flickers orange from four candles atop a table. Four
        chairs, and four people sit at the table motionless. As you move closer,
        the light reveals their features. A man, a woman, a boy and a girl, all
        with very similar features. A family left to rot. Their skin is decayed,
        emitting a putrid smell. Eyes hang out of sockets with jaws unhinged. A
        slight glint catches your eye, and you see strings attached to limbs and
        mouths.{" "}
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Family_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Family_Obj.route);
      }}
    >
      <img src={Family_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Family_Obj = {
  name: "Family",
  room: Family,
  entry: Entryway,
  route: "/halloween2022/family",
  exits: 1,
  prob: 5,
  image: doorUrl,
};

export default Family;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "optionOne":
          props.output([0, option]);
          break;
        case "optionTwo":
          props.output([0, option]);
          break;
        case "optionThree":
          props.output([0, option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">You do not see any exits from this chamber.</p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="optionOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Blow out a Candle
        </button>

        <button
          className="inputSubmit"
          type="submit"
          value="optionTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Investigate the Strings
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const OptionOneView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        You blow out a candle and the rest follow suit, casting the room in
        darkness. Gasping breaths shatter the darkness before a green glow comes
        to life in the eye sockets of the corpses, the only source of light.
        "Join us for dinner?" The boys head spins around. "Yes, Join us for
        dinner." The rest repeat. The floor disappears beneath you, and air
        rushes by as you decend into darkness before a blinding green flash
        ignites. You come to moments later laying down on a floor.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(props.rooms[0].route);
          }}
        >
          Stand and Figure out Where you are
        </button>
      </div>
    </div>
  );
};

//input rooms
const OptionTwoView = (props) => {
  let navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        You touch a string and the first body crumbles to ash. The rest follow
        suit. A deep and angry voice yells from the surroundings. "My puppets!"
        Wind whips your clothes and hair about as a large hand looms from the
        ceiling, its aim to crush you. You close your eyes, bracing for the
        impact of the hand, but the wind stops and no impact comes.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(props.rooms[0].route);
          }}
        >
          Open your Eyes
        </button>
      </div>
    </div>
  );
};
