import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Alice Celeste.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://images.fineartamerica.com/images-medium-large-5/broken-doll-crying-perfect-lazybones.jpg";
const doorUrl = door;

var nextRooms;

const Dolls = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    nextRooms = GetNextRooms(Dolls_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Dolls_Obj);
  }

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Dolls</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You find yourself caged. Chains and shackles hang on the walls of your
        cell. Dolls gaze at you, locked in the chains. Shackles bind the dolls
        in place around their necks or waists. Doll parts lay strewn about the
        floor, some without bodies, others with eyes or hair, but all covered in
        some sort of black grease or soot. With your entry, the room begins to
        shake, the wind blowing through with a deep howl causing the chains to
        rattle. Sounds of cracking and twisting fill the air while the doll eyes
        turn your direction. Doll mouths begin to speak in myriad voices, heads
        slowoly turning round and around, eyes blinking. "Play with us. Free us,
        Play with us." They repeat, some crawl towards your ankles. Time to get
        out!
      </p>
      <p className="roomText">
        Your cell door swings open at a touch. Two paths lie before you.
      </p>
      <DemonScript />
      <div className="nextHolder">
        {nextRooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Dolls_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Dolls_Obj.route);
      }}
    >
      <img src={Dolls_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Dolls_Obj = {
  name: "Dolls",
  room: Dolls,
  entry: Entryway,
  route: "/halloween2022/dolls",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Dolls;
