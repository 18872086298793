import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/metaldoor.jpg";
import doorSfx from "../../res/sfx/Metal Door Creak Open 1_2.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://static.wikia.nocookie.net/cinder-spires/images/a/a7/Spirestone_-_black_stone_room.jpg";
const doorUrl = door;

var nextRooms;

const Spikepit = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Spikepit_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Spikepit_Obj);
  }

  const parseInput = (input) => {
    let rollOneTier = 4;
    let rollTwoTier = 3;
    let rollThreeTier = 4;
    let rollFourTier = 4;
    let difficultyOne = getDifficulty(rollOneTier);
    let difficultyTwo = getDifficulty(rollTwoTier);
    let difficultyThree = getDifficulty(rollThreeTier);
    let difficultyFour = getDifficulty(rollFourTier);
    switch (input[1]) {
      case "rollOne":
        if (input[0] >= difficultyOne + 3) {
          changeDisplay("passOne");
        } else if (input[0] >= difficultyOne - 3) {
          changeDisplay("semiOne");
        } else {
          changeDisplay("failOne");
        }
        break;
      case "rollTwo":
        if (input[0] >= difficultyTwo) {
          changeDisplay("passTwo");
        } else {
          changeDisplay("failTwo");
        }
        break;
      case "rollThree":
        if (input[0] >= difficultyThree) {
          changeDisplay("passThree");
        } else {
          changeDisplay("failThree");
        }
        break;
      case "rollFour":
      case "rollFive":
        if (input[0] >= difficultyFour) {
          changeDisplay("passFour");
        } else {
          changeDisplay("failFour");
        }
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "passOne":
        component = <PassOneView output={parseInput} />;
        break;
      case "failOne":
        component = <FailOneView output={parseInput} />;
        break;
      case "semiOne":
        component = <SemiOneView output={parseInput} />;
        break;
      case "passTwo":
        component = <PassTwoView rooms={nextRooms} />;
        break;
      case "FailTwo":
        component = <FailTwoView output={parseInput} />;
        break;
      case "passThree":
        component = <PassThreeView rooms={nextRooms} />;
        break;
      case "failThree":
        component = <FailThreeView rooms={nextRooms} />;
        break;
      case "passFour":
      case "passFive":
        component = <PassFourView rooms={nextRooms} />;
        break;
      case "failFour":
      case "failFive":
        component = <FailFourView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Empty Room</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        The square room is conspicuously empty. Some chains hang from the walls
        and ceiling at strange intervals, but nothing else seems to stand out
        about this room. That in itself is fishy. No room in this place is just
        *empty.*
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Spikepit_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Spikepit_Obj.route);
      }}
    >
      <img src={Spikepit_Obj.image} />
      <span className="entryText">
        A heavy iron door leading into the unknown
      </span>
    </button>
  );
};

export var Spikepit_Obj = {
  name: "Spikepit",
  room: Spikepit,
  entry: Entryway,
  route: "/halloween2022/room1",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Spikepit;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input)) {
      setErrText("");
      props.output([input, "rollOne"]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        No room in this place is just *empty.* Roll an observation (perc) or a
        tinkering (perc) check to see if you can spot any hazards.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input the results of your roll below. <br />
          Natural 1: input 0. <br />
          Natural 20: roll an extra 1d6 and input the total into the box.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

const PassOneView = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input)) {
      setErrText("");
      props.output([input, "rollTwo"]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        You notice a mechanism in the floor and walls, barely perceptible. It
        seems to be a trap intended to drop you into a pit of some kind. Perform
        a Tinkering (Mind) check to disarm the trap OR Athletics (Body) to jump
        over the drop doors. Careful! Regardless of the check you choose, if you
        fail, the trap might be set off and you'll be in danger of immediately
        falling!
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input the results of your roll below. <br />
          Natural 1: input 0. <br />
          Natural 20: roll an extra 1d6 and input the total into the box.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

const SemiOneView = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input)) {
      setErrText("");
      props.output([input, "rollThree"]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <img
        className="banner"
        src="https://static.tvtropes.org/pmwiki/pub/images/dnd_forgotte_realms_spike_trap.png"
      />
      <p className="roomText">
        You don't notice anything. Nothing appears to be about to go off.
        However, as you cautiously take a few steps forward, you start hearing
        clicking and mechanical whirring and you're able to move back just in
        time to stop yourself from falling as the floor swings open, leaving a
        narrow ledge that you can stand on. Below you is a pit with wicked
        spikes pointing up at you. Your only chance is to jump to the other
        side, where two doors await you. Roll an Athletics (body) check to jump
        to the other side.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input the results of your roll below. <br />
          Natural 1: input 0. <br />
          Natural 20: roll an extra 1d6 and input the total into the box.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

const FailOneView = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input)) {
      setErrText("");
      props.output([input, "rollFour"]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <img
        className="banner"
        src="https://static.tvtropes.org/pmwiki/pub/images/dnd_forgotte_realms_spike_trap.png"
      />
      <p className="roomText">
        You don't notice anything. Nothing appears to be about to go off. You
        take a few cautious steps forward and, too late, you hear a mechanical
        whirring as the doors below you drop. Wicked spikes impale the air below
        you. Roll an agility (dex) check to try to lunge and grab the ledge!
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input the results of your roll below. <br />
          Natural 1: input 0. <br />
          Natural 20: roll an extra 1d6 and input the total into the box.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

const FailTwoView = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input)) {
      setErrText("");
      props.output([input, "rollFive"]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        <img
          className="banner"
          src="https://static.tvtropes.org/pmwiki/pub/images/dnd_forgotte_realms_spike_trap.png"
        />
        As you make your attempt to jump or disarm the trap, you make a misstep
        and the trap doors open, leaving only a small ledge that you could have
        been standing on. Quick! Make an agility (dex) check to lunge for the
        ledge on the far side!
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input the results of your roll below. <br />
          Natural 1: input 0. <br />
          Natural 20: roll an extra 1d6 and input the total into the box.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassTwoView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You either disarm the trap, or leap across safely.
          Whatever the pit had in store for you, you live another day. Two paths
          lie before you.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
//input rooms
const PassThreeView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You safely leap across the pit. The spikes remain
          unbloodied and you live another day. Two paths lie before you.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
//input rooms
const PassFourView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You barely manage to leap for the far ledge and grab
          on. You live another day. Two paths lie before you.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailThreeView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> You don't leap far enough and slam into the far wall of
          the pit. Scrabbling for purchase, you slip down until you enter
          freefall, slamming onto the spikes below.{" "}
          <b>
            You remain alive for a few seconds, stunned at the spikes impaling
            your corpse before you die.
          </b>
        </div>
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2022/death");
          }}
        >
          Click this if you are Dead
        </button>
      </div>
    </div>
  );
};

//input rooms
const FailFourView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> You barely grab onto the far edge, but it's not enough,
          your fingers are slipping. One by one your fingers give out.
          Scrabbling for purchase, you slip down until you enter freefall,
          slamming onto the spikes below.{" "}
          <b>
            You remain alive for a few seconds, stunned at the spikes impaling
            your corpse before you die.
          </b>
        </div>
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2022/death");
          }}
        >
          Click this if you are Dead
        </button>
      </div>
    </div>
  );
};
