import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/564x/2c/3c/28/2c3c284ad05a23bc4fc61a9ee71a165e.jpg";
const doorUrl = door;

var nextRooms;

const Classroom = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    nextRooms = GetNextRooms(Classroom_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Classroom_Obj);
  }

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Classroom</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        The room is dark, there stands but a table with a candle before you and
        a door on its opposite side. On the table, it looks to be a stack of
        papers large in size. You approach it slowly, hushed whispers that echo
        around you speaking inaudible words. You look over the papers carefully,
        hesitantly. They contain some of the most harrowing details one could
        ever see:
        <br />
        <br />
        "Student Loan Deliquency Notice"
        <br />
        "Past Due"
        <br />
        "Amount Due : $838,462.12"
        <br />
        <br />
        At its bottom is something even more harrowing, your name, signed at the
        bottom. You dont even remeber doing it, but its certainly your
        signature!
        <br />
        <br />
        The psychological horror of this is inescapable. It invades your mind,
        pervasive in its inexorable entirety. Your breath hitches as you realize
        it's true. Your student loan has come due. The unceasing tide of anxiety
        washes over you as you realize you will not recover. You take{" "}
        <b style={{ color: "red" }}>1 damage</b> as the irresistable horror
        pushes through your mental defenses, crumbled by the terrible truth.
      </p>
      <p className="roomText">
        You have no choice but to press forward. Only one path lies before you.
      </p>
      <DemonScript />
      <div className="nextHolder">
        {nextRooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2022/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Classroom_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Classroom_Obj.route);
      }}
    >
      <img src={Classroom_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Classroom_Obj = {
  name: "Classroom",
  room: Classroom,
  entry: Entryway,
  route: "/halloween2022/classroom",
  exits: 1,
  prob: 5,
  image: doorUrl,
};

export default Classroom;
