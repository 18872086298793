import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/metaldoor.jpg";
import doorSfx from "../../res/sfx/Metal Door Creak Open 1_2.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/564x/61/a5/4c/61a54cc7031ab4cc707f99f8af1c3378.jpg";
const doorUrl = door;

var nextRooms;

const Dungeon = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Dungeon_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Dungeon_Obj);
  }

  const parseInput = (input) => {
    switch (input[0]) {
      case "partOne":
        changeDisplay("partOne");
        break;
      case "partTwo":
        changeDisplay("partTwo");
        break;
      case "partThree":
        changeDisplay("partThree");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "partOne":
        component = <PartOne output={parseInput} />;
        break;
      case "partTwo":
        component = <PartTwo output={parseInput} />;
        break;
      case "partThree":
        component = <PartThree rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Dungeon</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        Metal bars line the walls as far as you can see. The dungeon smells of
        dust and little else. Each cell holds a stone bedframe, the last dregs
        of browned and cracked blankets scatter the ground. Shackles and hooks
        and anchoring rings line the walls of each cell. The stone floor is
        permanently stained brown and red, especially in each cell.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Dungeon_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Dungeon_Obj.route);
      }}
    >
      <img src={Dungeon_Obj.image} />
      <span className="entryText">A steel door leading into the unknown</span>
    </button>
  );
};

export var Dungeon_Obj = {
  name: "Dungeon",
  room: Dungeon,
  entry: Entryway,
  route: "/halloween2022/dungeon",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Dungeon;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partOne":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        You enter a cell block. Each of the cells lies empty. Your footfalls
        disturb the dust, leaving your footprints clear. There is another door
        leading to another cell block ahead.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Enter the next cell block
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const PartOne = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partTwo":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        The next cell block has rows of heavy steel doors with no bars. A small
        sliding door at knee level can reveal what is beyond, or be used to
        insert plates of food or whatever else is given. You might stare in
        through the slots and see long dried skeletons in the darkness beyond.
        There is another steel door at the far end leading to another area.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Enter the next area
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const PartTwo = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partThree":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        You enter a torture room with three dozen different torture devices.
        Only {Math.ceil(Math.random() * 12)} of the devices are occupied by
        dried skeletons. The next room is barred by a heavy barred door that's
        slightly ajar swings on rusty hinges.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partThree"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Proceed to the next room
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PartThree = (props) => {
  return (
    <div>
      <div className="roomText">
        You enter a small square room with three paths.
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
