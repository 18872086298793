import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl =
  "https://i.pinimg.com/564x/d1/1b/00/d11b001023de6c356d593b33451e1da5.jpg";
const doorUrl = door;

var nextRooms;

const Gore = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    nextRooms = GetNextRooms(Gore_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Gore_Obj);
  }

  const navigate = useNavigate();
  const submitHandler = () => {
    navigate(nextRooms[0].route);
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Gore</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You shield your eyes. The room you enter nearly blinds you with how
        white it is. It only takes you a few moments for your eyes to adjust.
        Crimson stains smear the white, corrupting it and soaking it with the
        slick wetness of blood. Limbs lie on the ground around you. Hundreds of
        them. Hands, legs, feet, arms, and no two alike. A dozen mechanical
        contraptions contain people strapped to them. Bones pop as a crank
        turns, stretching a man beyond his breaking point. No screams sound as
        his arms slowly rip at the seams, inch by inch, and you know it's only a
        matter of time before his arms join the throng. Another contraption
        holds a man upside down, pinned at his ankles by metal stakes. Water
        fills a basin up to his mouth and he sputters for air, but likewise does
        not scream. Other machines hold people currently tortured within them.
      </p>
      <p className="roomText">
        As you try to move, you realize that you too are shackled in place. Your
        feet are chained to the ground and your arms spread in the same manner.
        Something on the floor twitches. Another writhes. Liberated limbs come
        to life as they drag themselves toward you, some still bleeding from
        recent wounds. You cannot run, and you cannot resist. They grab you, one
        after the other. More pile on and you can feel the weight and stink of
        them. So many pile atop you that they light begins to fade from view.
        You feel yourself falling... falling... falling.
        <br />
        <br />
        And then you stop. You are free from the shackles.
      </p>
      <button className="inputSubmit" onClick={submitHandler}>
        Stand and figure out where you are.
      </button>
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Gore_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Gore_Obj.route);
      }}
    >
      <img src={Gore_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Gore_Obj = {
  name: "Gore",
  room: Gore,
  entry: Entryway,
  route: "/halloween2022/gore",
  exits: 1,
  prob: 5,
  image: doorUrl,
};

export default Gore;
