import door from "../../res/frankenstein-light-switch-1.png";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import doorSfx from "../../res/sfx/Switch and Lever 2_2.wav";
import song from "../../res/music/Disorienting.mp3";
import banner from "../../res/chute.png";
import {
  changeSong,
  GetNextRooms,
  playOneShot,
  CAPDEPTH,
  GlobalContext,
  GetRoomObjectByName,
} from "../room_handler";
import SkillCheckSelect from "../../components/SkillCheckSelect";
import { save } from "../../functions/save";
import { EntryPercResults, Perception } from "../../components/perception";

const bannerUrl = banner;
const doorUrl = door;

const Chute = () => {
  const [display, changeDisplay] = useState("pre");
  const InstanceVars = useContext(GlobalContext);
  const [nextRooms, changeNextRooms] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (InstanceVars.current.nextRooms) {
      loadVals();
    } else {
      let nextRoomTemp = GetNextRooms(Chute_Obj, InstanceVars);
      save(InstanceVars.current, Chute_Obj, nextRoomTemp);
      InstanceVars.current.score.depth += 100;
      changeNextRooms(nextRoomTemp);
    }
  }, []);

  const loadVals = () => {
    let nextRoomTemp = [];
    InstanceVars.current.nextRooms.forEach((room) => {
      let tempRoom = GetRoomObjectByName(room.name);
      if (tempRoom) nextRoomTemp.push(tempRoom);
    });
    if (nextRoomTemp.length > 0) {
      delete InstanceVars.current.nextRooms;
      delete InstanceVars.current.currentRoom;
    } else {
      nextRoomTemp = GetNextRooms(
        Chute_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Chute_Obj.exits)
      );
    }
    changeNextRooms(nextRoomTemp);
  };

  const parseInput = (input) => {
    if (input == "next") {
      InstanceVars.current.score.skill += 50;
      changeDisplay("pass");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Chute</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        A long chute of stone. Who knows how deep it goes?
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = (i = 0, navigate) => {
  return (
    <button
      key={Chute_Obj.name + i}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Chute_Obj.route);
      }}
    >
      <img src={Chute_Obj.image} />
      <span className="entryText">There is a lever. Do you pull it?</span>
      <EntryPercResults
        observation={{
          tier: 4,
          text: "You see the seams of a trap door beneath the lever.",
        }}
      />
    </button>
  );
};

export var Chute_Obj = {
  name: "Chute",
  room: Chute,
  entry: Entryway,
  route: "/CityOfGold/chute",
  exits: 1,
  prob: 3,
  image: doorUrl,
  faction: "none",
};

export default Chute;

//input: function
//output: level
const PreInput = (props) => {
  const [ableToContinue, setAbleToContinue] = useState(false);
  const navigate = useNavigate();

  //console.log(props.output);
  const submitHandler = (selection) => {
    let blank = false;
    selection.forEach((sel) => {
      if (sel.pass === undefined) blank = true;
      return;
    });
    if (!blank) {
      // console.log("next");
      setAbleToContinue(true);
    }
  };

  return (
    <div>
      <p className="roomText">
        A trap door opens beneath you and you fall down into a chute. You slide
        fast as you pick up speed along smooth stone.
        <br />
        <br />
        Roll an Agility (Dex) check to right yourself!
      </p>

      <span className="inputLabel">
        Choose a skill to use and then roll it. Input the number into the box.
        If you have a way of feasibly instant-succeeding on the check, select
        "instant." <br />
        Natural 1: input 0 in your box. <br />
        Natural 20: Add 1d6 to the roll and input the final score into your box.
      </span>
      <SkillCheckSelect
        options={[{ method: "agility (dex)", diffTier: 0, damTier: 0 }]}
        callback={submitHandler}
      />
      <input
        type="button"
        value="Continue"
        className="skillCheckButton skillCheckSubmit"
        disabled={!ableToContinue}
        onClick={() => props.output("next")}
      />
      <input
        type="button"
        value="Dead"
        className="skillCheckButton skillCheckDeath"
        disabled={!ableToContinue}
        onClick={() => {
          navigate("/CityOfGold/death");
        }}
      />
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>Survived!</b> As you slide to the bottom of the chute, you find
          yourself in a tiny stone room with spiderwebs in the corners. Only one
          path stands available to you.
        </div>
      </div>
      <Perception />
      <div className="nextHolder">
        {props.rooms.map((room, i) => {
          return room.entry(i, navigate);
        })}
      </div>
    </div>
  );
};
