import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/rooms/COG_Image__00318_.png";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/Disorienting.mp3";
import banner from "../../res/rooms/COG_Image__00146_.png";
import {
  CAPDEPTH,
  changeSong,
  GetDifficulty,
  GetNextRooms,
  GetRoomObjectByName,
  GlobalContext,
  playOneShot,
} from "../room_handler";
import SkillCheckSelect from "../../components/SkillCheckSelect";
import RandomRoomTreasure from "../../components/Loot";
import { save } from "../../functions/save";
import { EntryPercResults, Perception } from "../../components/perception";

const bannerUrl = banner;
const doorUrl = door;

const Axes = () => {
  const [display, changeDisplay] = useState("pre");
  const InstanceVars = useContext(GlobalContext);
  const [nextRooms, changeNextRooms] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (InstanceVars.current.nextRooms) {
      loadVals();
    } else {
      let nextRoomTemp = GetNextRooms(
        Axes_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Axes_Obj.exits)
      );
      save(InstanceVars.current, Axes_Obj, nextRoomTemp);
      InstanceVars.current.score.depth += 100;
      changeNextRooms(nextRoomTemp);
    }
  }, []);

  const loadVals = () => {
    let nextRoomTemp = [];
    InstanceVars.current.nextRooms.forEach((room) => {
      let tempRoom = GetRoomObjectByName(room.name);
      if (tempRoom) nextRoomTemp.push(tempRoom);
    });
    if (nextRoomTemp.length > 0) {
      delete InstanceVars.current.nextRooms;
      delete InstanceVars.current.currentRoom;
    } else {
      nextRoomTemp = GetNextRooms(
        Axes_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Axes_Obj.exits)
      );
    }
    changeNextRooms(nextRoomTemp);
  };

  const parseInput = (input) => {
    if (input == "next") {
      InstanceVars.current.score.skill += 400;
      changeDisplay("pass");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "pre":
      default:
        component = <PreInput output={parseInput} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Axes</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        A plain stone room with long black cracks. As soon as you step foot into
        the room, dozens of axes of bone and sinew begin swinging throughout the
        room from all surfaces.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = (i = 0, navigate) => {
  return (
    <button
      key={Axes_Obj.name + i}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Axes_Obj.route);
      }}
    >
      <img src={Axes_Obj.image} />
      <span className="entryText">A dark passage leading into the unknown</span>
      <EntryPercResults
        observation={{
          tier: 4,
          text: "The path ahead is fairly undisturbed.",
        }}
        spirituality={{
          tier: 5,
          text: "Feelings of shock and pain come from this direction.",
        }}
      />
    </button>
  );
};

export var Axes_Obj = {
  name: "Axes",
  room: Axes,
  entry: Entryway,
  route: "/CityOfGold/axes",
  exits: 4,
  prob: 4,
  image: doorUrl,
  faction: "scourge",
};

export default Axes;

//input: function
//output: level
const PreInput = (props) => {
  const [ableToContinue, setAbleToContinue] = useState(false);
  const navigate = useNavigate();

  //console.log(props.output);
  const submitHandler = (selection) => {
    let blank = false;
    selection.forEach((sel) => {
      if (sel.pass === undefined) blank = true;
      return;
    });
    if (!blank) {
      console.log("next");
      setAbleToContinue(true);
    }
  };

  return (
    <div>
      <p className="roomText">
        Roll athletics (body) or agility (dex) to make it past the axes without
        taking damage!
      </p>
      <span className="inputLabel">
        Choose a skill to use and then roll it. Input the number into the box.
        If you have a way of feasibly instant-succeeding on the check, select
        "instant." <br />
        Natural 1: input 0 in your box. <br />
        Natural 20: Add 1d6 to the roll and input the final score into your box.
      </span>
      <SkillCheckSelect
        options={[
          { method: "athletics (body)", diffTier: 4, damTier: 4 },
          { method: "agility (dex)", diffTier: 4, damTier: 4 },
        ]}
        callback={submitHandler}
      />
      <input
        type="button"
        value="Continue"
        className="skillCheckButton skillCheckSubmit"
        disabled={!ableToContinue}
        onClick={() => props.output("next")}
      />
      <input
        type="button"
        value="Dead"
        className="skillCheckButton skillCheckDeath"
        disabled={!ableToContinue}
        onClick={() => {
          navigate("/CityOfGold/death");
        }}
      />
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>Survived!</b> You made it past the axes to the other side. Which
          path do you take from here?
        </div>
        <RandomRoomTreasure
          chance={0.05}
          chanceForCurse={0.2}
          tier={Math.floor(Math.random() * 3)}
        />
      </div>
      <Perception />
      <div className="nextHolder">
        {props.rooms.map((room, i) => {
          return room.entry(i, navigate);
        })}
      </div>
    </div>
  );
};
