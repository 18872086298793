import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Metal Door Creak Open 1_2.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://img.atlasobscura.com/lzQkHdL5YyvQKJ3l2o1hiduUAN8ZRTmQtgXNu3LX9jc/rt:fit/w:600/q:81/sm:1/scp:1/ar:1/aHR0cHM6Ly9hdGxh/cy1kZXYuczMuYW1h/em9uYXdzLmNvbS91/cGxvYWRzL3BsYWNl/X2ltYWdlcy8xM2Q5/NTA2ZmU5M2Q3M2Vh/NDdfSU1HXzQ3Mjct/NzY4eDEwMjQuanBn.jpg";
const doorUrl =
  "https://upload.wikimedia.org/wikipedia/commons/f/f5/Iron_Door_in_the_Smuggling_tunnel_in_Salem%2C_Ma..jpg";

var nextRooms;

const Aquaeduct = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Aquaeduct_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Aquaeduct_Obj);
  }

  const parseInput = (input) => {
    switch (input[0]) {
      case "partOne":
        changeDisplay("partOne");
        break;
      case "partTwo":
        changeDisplay("partTwo");
        break;
      case "partThree":
        changeDisplay("partThree");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "partOne":
        component = <PartOne output={parseInput} />;
        break;
      case "partTwo":
        component = <PartTwo output={parseInput} />;
        break;
      case "partThree":
        component = <PartThree rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Aquaeduct</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You stand on a narrow walkway next to a thin stream of water which
        rushes by you down the length of a tunnel. The arched stone walls
        glisten with damp. The stale air smells of salt. It looks like it opens
        up further down. Perhaps it is best to follow?{" "}
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Aquaeduct_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Aquaeduct_Obj.route);
      }}
    >
      <img src={Aquaeduct_Obj.image} />
      <span className="entryText">
        A heavy iron grate ahead of you. You hear water beyond.
      </span>
    </button>
  );
};

export var Aquaeduct_Obj = {
  name: "Aquaeduct",
  room: Aquaeduct,
  entry: Entryway,
  route: "/halloween2022/aquaeduct",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Aquaeduct;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partOne":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        The dark tunnel continues as far as you can see, the end invisible in
        the darkness. Behind you where the water comes are iron bars blocking
        your path. There is only one way to go.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Follow the tunnel
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const PartOne = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partTwo":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        Dozens of branching paths split from your path, and eventually a blank
        wall forces you to choose one. There doesn't seem to be any indication
        which tunnels go where.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Pick a tunnel at random
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const PartTwo = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partThree":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        You continue down your chosen tunnel, and might come across other
        branches, and you might even go down those branches.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partThree"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Continue walking
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PartThree = (props) => {
  return (
    <div>
      <div className="roomText">
        Eventually, you see a room at the end of your tunnel. As you enter the
        room, you see three paths.
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
