import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GetRandomRoom,
  GlobalVars,
  playOneShot,
  Rooms,
} from "../room_handler";

const bannerUrl =
  "https://i.pinimg.com/564x/ed/c4/46/edc446c1b089639aed13994c3815c3d2.jpg";
const doorUrl = door;

var nextRooms;

const Gold = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Gold_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Gold_Obj);
  }

  const parseInput = (input) => {
    switch (input[1]) {
      case "optionOne":
        let rasps = input[0];
        GlobalVars.depth += rasps * 2;
        if (GlobalVars.depth * GlobalVars.difficultyRamp > GlobalVars.maxRamp) {
          GlobalVars.maxRamp = GlobalVars.depth * GlobalVars.difficultyRamp;
        }
        changeDisplay("optionOnePass");
        break;
      case "optionTwo":
        changeDisplay("optionOneFail");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "optionOnePass":
        component = <PassOptionOneView rooms={nextRooms} />;
        break;
      case "optionOneFail":
        component = <FailOptionOneView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Gold</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        The room glistens with a golden light. Light reflects off huge mounds of
        what appears to be coins. Wait! Rasps! These are Rasps! Heaps of rasps
        pile to the ceiling, blocking all the walls.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Gold_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Gold_Obj.route);
      }}
    >
      <img src={Gold_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Gold_Obj = {
  name: "Gold",
  room: Gold,
  entry: Entryway,
  route: "/halloween2022/gold",
  exits: 1,
  prob: 0.5,
  image: doorUrl,
};

export default Gold;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "optionOne":
          props.output([input, option]);
          break;
        case "optionTwo":
          props.output([0, option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        There are way too many rasps here to pass up, but you can only carry so
        many at a time. You can choose to take up to 20 rasps with you from the
        pile. If you manage to leave the castle with any rasps in your pockets,
        message Erik Manley and he will award them to you.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          You may insert a number between 1 and 20 to fill your pockets and then
          attempt to leave, or you can immediately attempt to leave.
        </label>
        <input
          className="inputField"
          type="number"
          min="1"
          max="20"
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button
          className="inputSubmit"
          type="submit"
          value="optionOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Take Some Rasps
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="optionTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Try to Find an Exit
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassOptionOneView = (props) => {
  let room;
  let counter = 0;
  while (!room && counter < 20) {
    room =
      Rooms.death_rooms.rooms[
        Math.floor(Rooms.death_rooms.rooms.length * Math.random())
      ];
    counter++;
  }
  //console.log(room);
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          You stuff some rasps into your pockets. If you manage to find an exit,
          message Erik Manley and he will submit the rasps. You dig through the
          piles of remaining rasps, trying to find an exit. Finally, you do find
          an exit.
        </div>
      </div>
      <div className="nextHolder">
        {room
          ? room.entry()
          : props.rooms.map((room) => {
              return room.entry();
            })}
      </div>
    </div>
  );
};

//input rooms
const FailOptionOneView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          You dig through the piles of rasps, trying to find an exit, but you do
          not take any of the rasps for yourself. Finally, you do find an exit.
        </div>
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
