import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/metaldoor.jpg";
import doorSfx from "../../res/sfx/Metal Door Creak Open 1_2.wav";
import song from "../../res/music/Organ & Strings.wav";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/564x/fe/fc/3f/fefc3fa3da69154164dd751bbf53562d.jpg";
const doorUrl = door;

var nextRooms;

const Vampire = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    nextRooms = GetNextRooms(Vampire_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Vampire_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 4;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 1) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Vampire</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        The room is decorated with black drapes that turn red in the light. The
        smell of unwashed body odor chokes the air, and a feeling of deep
        blackness and anxiety grips you. It is the unmistakeable feeling of dark
        magic at work. A small library adorns a bookshelf next to a chair. A
        fireplace flickers, lit by an everburning fire, no wood to be seen. A
        bed with red and brown staining the sheets sits on the far side of the
        room. Two doors lead out of this room next to the bed, but the room is
        not unoccupied. A man sits in a tall black throne, hunched over with his
        head in his hands. He wears a wonderously soiled victorian suit, and he
        appears to be having an argument with himself. He notices you, looking
        up at you with bloodshot eyes. Blood stains his chin and Vampire fangs
        are easily seen in the corners of his mouth. <br />
        <br /> "You dare approach me again?" He yells while standing up and
        approaching you. "What will you give me in exchange for your insolence
        this time? I have kept you alive this long, offered you power and
        passage. I shall sooner drink from you to cure this fog if your offering
        bears offence."
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Vampire_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Vampire_Obj.route);
      }}
    >
      <img src={Vampire_Obj.image} />
      <span className="entryText">
        A heavy iron door leading into the unknown
      </span>
    </button>
  );
};

export var Vampire_Obj = {
  name: "Vampire",
  room: Vampire,
  entry: Entryway,
  route: "/halloween2022/vampire",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Vampire;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2)) {
      setErrText("");
      props.output([input, input2]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        Make two skill checks from any of the following: Persuasion (Cha) to
        give an offering and convince the Vampire of its value, OR Performance
        (Cha) to entertain the vampire with a dance, song, or something else, OR
        Deception (Cha) to deceive him into allowing you to pass. Be careful, it
        looks like he bites...
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your two scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Rolls
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> The Vampire considers your words. He doesn't kill you
          immediately. Instead, he says, "This must matter enough to you, to
          offer such a thing to me...You may pass." His response remains the
          same so long as your offering is valued. Roleplay your success as you
          make way for one of the doors behind the throne.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> The Vampire considers your words. "Your Offering is not
          accepted." Roleplay your failure as he approaches and overpowers you.
          No matter who or what you are, even if you are a vampire yourself, he
          bleeds you dry.{" "}
          <b>
            You die, feeling the blood sucking out of your body as you go cold,
            and then numb, and then your brain function ceases.
          </b>
        </div>
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2022/death");
          }}
        >
          Click this if you are Dead
        </button>
      </div>
    </div>
  );
};
