import seedrandom from "seedrandom";

const ALLMODS = require("../data/modifiers.json");

export const GetModifiers = (seed) => {
  let myRand;
  if (!seed) {
    //create the weekly changing random generator
    let date = new Date();
    let year = new Date(date.getFullYear(), 0, 1);
    let days = Math.floor((date - year) / (24 * 60 * 60 * 1000));
    let week = Math.ceil((date.getDay() + 2 + days) / 7);
    // console.log("week: " + week + ", year: " + year.getFullYear());
    myRand = new seedrandom(
      week.toString() + year.getFullYear().toString() + "2"
    );
  } else {
    myRand = new seedrandom(seed);
  }
  //get the number of mods that will be generated
  let numMods = Math.round(4 * Math.pow(myRand(), 2) + 1);
  // console.log("number of modifiers: " + numMods);

  //get all possible modifiers from the json file
  let mods = ALLMODS.modifiers;
  // console.log(mods);

  //select mods from the possible mods
  let selectedMods = [];
  let repeat = 0;
  for (let i = 0; i < numMods; i++) {
    if (repeat >= 10) break;
    else if (mods.length <= 0) break;
    //select a mod and take it out of the list of possibles.
    let temp = mods.splice(Math.floor(mods.length * myRand()), 1);
    //check for mod conflicts
    if (temp[0].modConflicts.length > 0) {
      let conflict = undefined;
      temp[0].modConflicts.forEach((e) => {
        if (conflict === undefined) {
          conflict = selectedMods.find((f) => {
            return f.name === e;
          });
        }
      });
      //if the chosen mod conflicts with another mod, select a new mod.
      if (conflict !== undefined) {
        i--;
        repeat++;
        continue;
      }
    }
    selectedMods[i] = temp[0];
  }
  // console.log(selectedMods);
  // console.log(mods);
  return selectedMods;
};

export default GetModifiers;
