import { useState, useRef, useEffect, useId } from "react";
import { NavLink } from "react-router-dom";
import postSubmittionData from "../../GoogleAPI/postReward.js"
import "./Page1.css";
import song from "./birds39-forest-20772.mp3"
import song2 from "./birds39-forest-20772.ogg"

export default function Home () {
  const [bar1Display, setBar1Display] = useState("cutTrees");
  const [bar2Display, setBar2Display] = useState("");
  const [bar3Display, setBar3Display] = useState("");
  const firstTreeStatus = useRef(true);
  const overKillAmount = useRef(0);

  const bar1Area = () => {
    switch (bar1Display) {
      case "cutTrees":
        return <InputArea1 setBar1Display={setBar1Display} setBar2Display={setBar2Display} firstTreeStatus={firstTreeStatus} overKillAmount={overKillAmount} />
      case "secondTree":
        return <ContinueButton1 setBar1Display={setBar1Display} setBar2Display={setBar2Display} />
      case "":
        return <div className="step-complete">Step 2 Complete</div>
      default:
        return <p>This doesn't match any of the inputs, so you've landed on default.</p>
    }
  }

  const bar2Area = () => {
    switch (bar2Display) {
      case "pushTrees":
        return <InputArea2 setBar2Display={setBar2Display} setBar3Display={setBar3Display} overKillAmount={overKillAmount} />
      case "step3Complete":
        return <div className="step-complete">Step 3 Complete</div>
      case "":
        return <></>
      default:
        return <p>This doesn't match any of the inputs, so you've landed on default.</p>
    }
  }

  const bar3Area = () => {
    switch (bar3Display) {
      case "candyStore":
        return <CandyStore setBar3Display={setBar3Display} overKillAmount={overKillAmount} />
      case "":
        return <></>
      default:
        return <p>This doesn't match any of the inputs, so you've landed on default.</p>
    }
  }

  return (
    <div className="treeChoppingHome">
    <div className="audioContainer">
      <audio controls loop autoPlay={!JSON.parse(localStorage.getItem("disableAutoPlay"))}>
        <source src={song} type="audio/mpeg" />
        <source src={song2} type="audio/ogg" />
        Your browser does not support the audio element.
      </audio>
    </div>
      <div className="blurContainer">
        <div className="TitleBar">
          <h1>Welcome to Gathering Wood!</h1>
          <NavLink to="/">Back</NavLink>
        </div>
        <p className="context">This DIY can be found in <a href="https://discord.com/channels/563442211623010304/710910759281885184" target="_blank" rel="noreferrer">terravissens-forge-and-woodworking-shop</a> and is completed in <a href="https://discord.com/channels/563442211623010304/565154321717526534" target="_blank" rel="noreferrer">the-splintered-south-wood</a>. For the original DIY post, see <a href="https://discord.com/channels/563442211623010304/710910759281885184/1077020692660371516" target="_blank" rel="noreferrer">Here</a>.</p>
        <p className="BulkText">
          Worron Terravissen is proud of his craft and loves sharing it with the rest of the planes. Using the Terravissen's tools you can assist with his labors. <br/>
          <br/>
          Gathering Wood: Reward: 2 Candies
          <br/>
          Worron needs lots of material and after getting a writ of approval from the courthouse, he has been given permission to take what wood he needs from the northern section of <a href="https://discord.com/channels/563442211623010304/565154321717526534" target="_blank" rel="noreferrer">the-splintered-south-wood.</a><br/>
          Should you undertake this important mission, here are the steps:<br/>
          <br/>
          Step 1: Walk to the <a href="https://discord.com/channels/563442211623010304/565154321717526534" target="_blank" rel="noreferrer">the-splintered-south-wood!</a><br/>
          <br/>
          Step 2: Find and knock over a couple trees. To cut down a tree, we'll mechanically treat it like a punching bag with some extra ways to attack it. It's got 30 HP and 10 AC. You can attack it with Skills, Weapons, or Spells. Narrate how you're attempting to complete this task in <a href="https://discord.com/channels/563442211623010304/565154321717526534" target="_blank" rel="noreferrer">the-splintered-south-wood!</a> along with what you're rolling. While you can technically attempt to topple a tree with any kind of skill or attack, it either needs to be one that easily makes sense or you have to get creative with your narration.
          <br/>
          - For any kind of attack or check, input the result of your roll below.<br/>
          - If you're using a specific spell, roll a spell attack against the tree's AC of 10. If you hit, input the spell's damage below.<br/>
        </p>
        {bar1Area()}
        <p>
          Step 3: Ya gotta lug it back to the shop. Roll 3 Athletics rolls. If the sum total is over 50, it takes you ten minutes to bring the wood to the shop. If you do not succeed, after 5 minutes you may add another Athletics check to your roll, if you are successful after this roll, it takes you another 10 minutes to arrive at the shop for recompense for your labor.<br/>
          - You can move the logs using a magic element proficiency instead of Athletics, but you need to narrate yourself using said element in some creative way to move the log.<br/>
        </p>
        {bar2Area()}
        <p>
          Once you've chosen how to spend your candies, this website will handle submitting the rewards for you! (You may only perform this quest once a week for reward. For personal satisfaction or better rapport with the Terravissens, have at it eternally!) If you have any questions about this DIY, please DM QM : NORTHEAST.<br/>
        </p>
        {bar3Area()}
      </div>
    </div>
  )
}

const InputArea1 = (props) => {
  const removalMethodSelectId = useId();
  const rollInputId = useId();
  const [treeTS, setTreeTS] = useState(30);
  const [outputText1, setOutputText1] = useState("");

  //Take the inputs and adjust the tree's remaining TS.
  const handleSubmit = (e) => {
    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    const form = e.target;
    const formData = new FormData(form);

    // Turn the form data into a standard object.
    const formJson = Object.fromEntries(formData.entries());
    const methodChoice = formJson.removalMethodChoice
    const currentInput = formJson.currentRollInput

    localStorage.setItem("previousMethod", methodChoice);

    setOutputText1("");
    let newTreeTS = treeTS;
    switch (methodChoice) {
      case "skillCheck": //Athletics and a weapon attack do the same thing.
      case "attack":
        const currentDamage = currentInput - 10;
        if (currentDamage < 0) break;
        newTreeTS = treeTS - currentDamage;
        updateTreeTS(newTreeTS);
        break;
      case "specificSpell":
        newTreeTS = treeTS - currentInput;
        updateTreeTS(newTreeTS);
        break;
      default:
        setOutputText1("You forgot to pick an input type.");
        break;
    }
  }

  function updateTreeTS (newTS) {
    switch (true) {
      case newTS > 30:
        setOutputText1("Somehow you healed the tree over 30, so  that's neat.")
        break;
      case newTS <= 0: // Catch a tree kill.
        setOutputText1("");
        if (newTS >= -100) {
          props.overKillAmount.current += Math.abs(newTS);
        }
        setTreeTS(0);
        onTreeDown();
        break;
      case newTS <= 30: // Doesn't trigger when the tree is negative because that's already caught above.
        setTreeTS(newTS);
        break;
      default:
        setOutputText1("Idk man ig tree TS isn't a number anymore? Who knows.");
        break;
    }
  }

  function onTreeDown () {
    if (props.firstTreeStatus.current) {
      props.firstTreeStatus.current = false;
      props.setBar1Display("secondTree");
    } else {
      props.setBar1Display("");
      props.setBar2Display("pushTrees");
    }
  }

  function defaultMethod () {
    const previousMethod = localStorage.getItem("previousMethod");
    if (!previousMethod) {
      return "defaultGoBrrr"
    } else {
      return previousMethod
    }
  }

  return (
    <>
      <div className="Progress1Container">
      <form className="TreeChoppingForm" onSubmit={handleSubmit}>
        <label className="AccessibilityLabel" htmlFor={removalMethodSelectId}>Choose your tree removal method:</label>
        <select defaultValue={defaultMethod()} name="removalMethodChoice">
          <option value="defaultGoBrrr" disabled>Choose your tree removal method.</option>
          <option value="skillCheck">Skill Check</option>
          <option value="attack">Attack</option>
          <option value="specificSpell">Specific Spell</option>
        </select>
        <label className="RollHolderLabel" htmlFor={rollInputId}>Input your roll result or spell damage here:</label>
        <input type="number" className='RollHolder' id={rollInputId} min={-5} max={9999} name="currentRollInput" title="" placeholder="" />
        <button type="submit">Submit</button>
      </form>
      <p className="RemainingTag">Remaining Tree TS: {treeTS}</p>
    </div>
    <p className="OutputText">{outputText1}</p>
  </>
  )

}

const ContinueButton1 = (props) => {

  const continueYes = () => {
    props.setBar1Display("cutTrees");
  }

  return (
    <div className="continueButtonBarHolder">
      <p>First Tree Down!</p>
      <div className="continueButtonHolder">
        <button type="button" onClick={continueYes}>Continue</button>
      </div>
    </div>
  )
}

const InputArea2 = (props) => {
  const rollInputId = useId()
  const [outputText2, setOutputText2] = useState("");
  const [pushInputsContainer, setPushInputsContainer] = useState();
  const [runningTotal, setRunningTotal] = useState(0);

  useEffect( (rollInputId) => {
    // Initialize the first three inputs.
    let rollInputList = [];
    for (let i = 0; i < 3; i++) {
      rollInputList.push(<input type="number" className='RollHolder' id={rollInputId} min={-5} max={9999} name={`rollInput${i}`} key={i} />);
    }
    setPushInputsContainer(rollInputList);
  }, []);

  const handleSubmit = (e) => {
    //Stop the page from resetting.
    e.preventDefault();

    // Read the form data
    const form = e.target;
    const formData = new FormData(form);

    // Turn the form data into a standard object.
    const formJson = Object.fromEntries(formData.entries());

    setOutputText2("");
    // Add the totals together.
    let currentTotal = 0;
    for (let item in formJson) {
      if (formJson[item] === '') { // Check for a missing value.
        setOutputText2("You forgot to submit a number.");
        return;
      }
      currentTotal += Number(formJson[item]);
    }

    // Set the new total and detect success.
    setRunningTotal(currentTotal);
    if (currentTotal < 50) {
      let newInputs = pushInputsContainer.slice();
      const inputNumber = newInputs.length;
      newInputs.push(<input type="number" className='RollHolder' id={rollInputId} min={-5} max={9999} name={`rollInput${inputNumber}`} key={inputNumber} />);
      setPushInputsContainer(newInputs);
    } else { // This runs upon success.
      if (currentTotal >= 200) {
        props.overKillAmount.current += currentTotal;
      }
      setOutputText2("Step 3 Complete");
      props.setBar2Display("step3Complete");
      props.setBar3Display("candyStore");
    }
  }

  return (
    <>
      <div className="Progress2Container">
        <form className="TreePushingForm" onSubmit={handleSubmit}>
          <label className="RollHolderLabel" htmlFor={rollInputId}>Input your roll results here:</label>
          {pushInputsContainer}
          <button type="submit">Submit</button>
        </form>
        <p>Your running total: {runningTotal} - Your goal: 50</p>
      </div>
      <p className="OutputText">{outputText2}</p>
    </>
  )
}

const CandyStore = (props) => {
  const [outputText3, setOutputText3] = useState("");
  const [candyStoreVisible, setCandyStoreVisible] = useState(true);

  const handleSubmit = (e) => {
    //Stop the page from resetting.
    e.preventDefault();

    // Read the form data
    const form = e.target;
    const formData = new FormData(form);

    // Turn the form data into a standard object.
    const formJson = Object.fromEntries(formData.entries());
    
    const rasps = Number(formJson.raspsChosen);
    const advFavor = Number(formJson.advChosen);
    const champFavor = Number(formJson.champChosen);
    const rewardTotal = rasps + advFavor + (2 * champFavor);

    if (rewardTotal > 2) {
      setOutputText3("That's more than 2 candies!");
    } else if (rewardTotal === 1) {
      setOutputText3("You've got 1 more candy to spend!");
    } else if (rewardTotal <= 0) {
      setOutputText3("No this won't break anything, sucks to suck.")
    } else if (rewardTotal === 2) { // Only Valid rewardTotal.
      const selectedChars = JSON.parse(localStorage.getItem("selectedChars")); // Selected characters list.
      const characterName = (JSON.stringify(selectedChars)==="[]") ? "" : selectedChars[0].value; // Makes sure a character is selected. Catches some errors.
      const submissionData = {
        "characterName": characterName,
        "DIYName": "Terravissen Harvesting Logs (QM: NORTHEAST)",
        "rasps": rasps,
        "advFavor": advFavor,
        "champFavor": champFavor
      }
      console.log(submissionData);
      if (props.overKillAmount.current > 0) {
        submissionData.otherData = `${props.overKillAmount.current} <- That's the overkill amount on this submission.`
      }
      setCandyStoreVisible(false);
      setOutputText3("Submitting Rewards...");
      postSubmittionData(submissionData).then( (result) => setOutputText3(result));
    } else {
      setOutputText3("If you're seeing this, you've broken something in a way that shouldn't be possible. Wack.")
    }
  }

    // The rewards input bar.
    function showCandyStore(isShowing) {
      if (isShowing) {
        return (
          <form className="candyStoreForm" onSubmit={handleSubmit}>
            <label>Rasps: <input title="Whatthis?" type="number" className='raspsHolder' id={31} min={-5} max={9999} name={`raspsChosen`} key={"raspsChosen"} /></label>
            <label>Adventurer Favor: <input type="number" className='advHolder' id={32} min={-5} max={9999} name={`advChosen`} key={"advChosen"} /></label>
            <label>Champion Favor: <input type="number" className='champHolder' id={33} min={-5} max={9999} name={`champChosen`} key={"champChosen"} /></label>
            <button type="submit">Submit</button>
        </form>
        )
      } else {
        return <></>
      }
    }

    return (
      <>
        {showCandyStore(candyStoreVisible)}
        <p className="OutputText">{outputText3}</p>
      </>
    )
}
