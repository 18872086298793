export default async function fetchCharData() {
	const characterData = sessionStorage.getItem("characterData");
	if (characterData !== null) {
	  // If sessionStorage already has characterData in it, return that data.
	  return JSON.parse(characterData);
	}
  
	try {
	  const response = await fetch(
		"https://script.google.com/macros/s/AKfycby8bYnrJ6Ekv7nsdB4cHnqsaLoD3-iKcQW98hrIKeKw0tf1FE44l19HY0XTXKzDmLiH/exec",
		{
		  redirect: "follow",
		  method: "POST",
		  body: JSON.stringify({
			whichType: "topLevelGet",
			whichForm: "standardDIY",
			args: ["getSubmissions"],
		  }),
		  headers: {
			"Content-Type": "text/plain;charset=utf-8",
		  },
		}
	  );
  
	  if (!response.ok) {
		// Handle non-OK HTTP status codes as an error
		throw new Error(`HTTP error! Status: ${response.status}`);
	  }
  
	  const body = await response.text();
	  try {
		const parsedData = JSON.parse(body);
		sessionStorage.setItem("characterData", JSON.stringify(parsedData));
		return parsedData;
	  } catch (e) {
		console.error(e);
		throw new Error("Error parsing response");
	  }
	} catch (e) {
	  console.error(e);
	  throw new Error("Network error");
	}
  }
