import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/metaldoor.jpg";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import sfx from "../../res/sfx/DistortedWolf.wav";
import song from "../../res/music/Forest Scary.wav";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/736x/04/74/23/04742371e82232cabada77f8599789a0.jpg";
const doorUrl =
  "https://i.pinimg.com/564x/f9/f6/22/f9f622fb1a9afcfca075c4203e65d860.jpg";

var nextRooms;

const Werewolf = () => {
  const [display, changeDisplay] = useState("pre");
  const [nextRooms, setNextRooms] = useState(GetNextRooms(Werewolf_Obj));
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    playOneShot(sfx);
  }, []);

  if (!nextRooms) {
    setNextRooms(GetNextRooms(Werewolf_Obj));
  }
  console.log(nextRooms);

  const parseInput = (input) => {
    let rollTier = 3;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 2) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Into the Forest</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        As you step through the exit from the castle, you find yourself in an
        unfamiliar section of dark woods. Mist curls its way around your ankles
        and dead tree limbs protrude at dagger-like angles. The soft wind brings
        a smell on the air like blood and wet dog.
        <br />
        <br />
        As you continue into the woods, you hear a wolfish howl nearby. The
        trees respond to this howl, reaching for the moon as you catch a glimpse
        of the full moon high above. You hear something in the distance, coming
        towards you fast.{" "}
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Werewolf_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Werewolf_Obj.route);
      }}
    >
      <img src={Werewolf_Obj.image} />
      <span className="entryText">You see a way out of the castle</span>
    </button>
  );
};

export var Werewolf_Obj = {
  name: "Werewolf",
  room: Werewolf,
  entry: Entryway,
  route: "/halloween2022/exit2",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Werewolf;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2)) {
      setErrText("");
      props.output([input, input2]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        Make two skill checks from any of the following (mix and match if you
        wish): Make a Stealth (Dex) check to hide, an Athletics (Body) check to
        run away, an Intimidation (Body) to scare the creature away, or a
        Fieldcraft (Spirit) check to outwit the creature.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Rolls
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> The werewolf comes through the trees. Its fur is
          mottled and slick with blood. it bares its teeth and howls. Roleplay
          yourself succeeding at the methods you employed to escape from the
          werewolf. After your escape, you see a building in the distance with
          three doors in it.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> The werewolf comes through the trees. Its fur is
          mottled and slick with blood. it bares its teeth and howls. Roleplay
          yourself failing at the methods you employ. The werewolf eventually
          catches you and tears you to shreds. Without your weapons, magic, or
          special abilities, you are helpless against it.{" "}
          <b>You die watching the werewolf feasting on your guts.</b>
        </div>
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2022/death");
          }}
        >
          Click this if you are Dead
        </button>
      </div>
    </div>
  );
};
