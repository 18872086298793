import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/Cave.wav";
import song2 from "../../res/music/Dark Horsemen.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  getDamage,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl =
  "https://i.pinimg.com/736x/7a/89/ca/7a89cafaafae1a392dd232588eec8350.jpg";
const doorUrl =
  "https://i.pinimg.com/736x/7a/89/ca/7a89cafaafae1a392dd232588eec8350.jpg";

var nextRooms;

const Slime = () => {
  const [display, changeDisplay] = useState("pre");
  const [attempts, setAttempts] = useState(3);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Slime_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Slime_Obj);
  }

  const parseInput = (input) => {
    let percTier = 4;
    let optionOneTier = 5;
    let optionTwoTier = 5;
    let optionThreeTier = 5;
    let difficulty;
    switch (input[0]) {
      case "perc":
        difficulty = getDifficulty(percTier);
        if (input[1] >= difficulty) {
          setAttempts(3);
          changeDisplay("perc");
        } else {
          setAttempts(1);
          changeDisplay("perc");
        }
        break;
      case "optionOne":
        difficulty = getDifficulty(optionOneTier);
        for (let i = 1; i < input.length; i++) {
          if (input[i] >= difficulty) {
            changeDisplay("optionOnePass");
            break;
          } else {
            changeDisplay("optionOneFail");
          }
        }
        break;
      case "optionTwo":
        difficulty = getDifficulty(optionTwoTier);
        for (let i = 1; i < input.length; i++) {
          if (input[i] >= difficulty) {
            changeDisplay("optionTwoPass");
            break;
          } else {
            changeDisplay("optionTwoFail");
          }
        }
        break;
      case "optionThree":
        difficulty = getDifficulty(optionThreeTier);
        for (let i = 1; i < input.length; i++) {
          if (input[i] >= difficulty) {
            changeDisplay("optionThreePass");
            break;
          } else {
            changeDisplay("optionThreeFail");
          }
        }
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "perc":
        playOneShot(song2);
        component = (
          <PostPerceptionView output={parseInput} attempts={attempts} />
        );
        break;
      case "optionOnePass":
        component = <PassOptionOneView rooms={nextRooms} />;
        break;
      case "optionOneFail":
        component = <FailOptionOneView rooms={nextRooms} />;
        break;
      case "optionTwoPass":
        component = <PassOptionTwoView rooms={nextRooms} />;
        break;
      case "optionTwoFail":
        component = <FailOptionTwoView rooms={nextRooms} />;
        break;
      case "optionThreePass":
        component = <PassOptionThreeView rooms={nextRooms} />;
        break;
      case "optionThreeFail":
        component = <FailOptionThreeView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Cave</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        As the door closes behind you, you enter an underground cave system. The
        walls and floor of this cave have been scoured perfectly clean. No
        specks of dust or dirt cover the bare walls. The cave leads off in two
        directions, and magical lights can be seen every twenty feet down the
        length and you can see the cave curves in the distance down one way, and
        a door in the other direction. You approach the door and find it locked.{" "}
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Slime_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Slime_Obj.route);
      }}
    >
      <img src={Slime_Obj.image} />
      <span className="entryText">A dark cave leading into the unknown</span>
    </button>
  );
};

export var Slime_Obj = {
  name: "Slime",
  room: Slime,
  entry: Entryway,
  route: "/halloween2022/cave1",
  exits: 2,
  prob: 0.5,
  image: doorUrl,
};

export default Slime;

const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input)) {
      setErrText("");
      props.output(["perc", input]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        You thought you heard something behind you. Roll an Observation (perc)
        check.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your result below. <br />
          Natural 1: input 0. <br />
          Natural 20: Add 1d6 to the roll.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const PostPerceptionView = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState(0);
  const [input2, setInput2] = useState(0);
  const [input3, setInput3] = useState(0);
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "" || (input == "" && input2 == "" && input3 == "")) {
      setErrText("");
      props.output([option, input, input2, input3]);
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        You hear a squishing squelching sound approaching. Squinting down the
        length of the cave, you see a wall of green slime slowly crawling its
        way towards you, engulfing the magical lights as it passes them. You see
        bones, armor, coins and scraps of clothing inside the slime. In
        addition, you see a large iron key that looks like it fits the lock deep
        inside its slime.
      </p>

      {props.attempts > 1 ? (
        <span>
          You notice the slime early enough that you think you have three
          attempts to retrieve the key from the slime before it engulfs you.
        </span>
      ) : (
        <span>
          You don't notice the slime until it's nearly upon you! You only have
          tiem for one attempt before it engulfs you!
        </span>
      )}

      <p className="roomText">
        Make an Endurance (body) check to muscle your way through the slime and
        retrieve the key, OR Academics (mind) to find a clever way of retrieving
        the key, OR Alchemy (mind) to find a way to neutralize the slime long
        enough to get the key and escape!
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Choose one of the three skill check types and make all allowed checks
          using that skill. Input the values in the boxes below and click the
          corresponding button.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        {props.attempts > 1 ? (
          <div>
            <input
              className="inputField"
              type="number"
              min="-5"
              max="60"
              onChange={(e) => {
                setInput2(e.target.value);
              }}
            ></input>
            <input
              className="inputField"
              type="number"
              min="-5"
              max="60"
              onChange={(e) => {
                setInput3(e.target.value);
              }}
            ></input>
          </div>
        ) : (
          ""
        )}
        <button
          className="inputSubmit"
          type="submit"
          value="optionOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Submit Athletics (body) checks
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="optionTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Submit Academics (Mind) checks
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="optionThree"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Submit Alchemy (Mind) checks
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassOptionOneView = (props) => {
  let damage = getDamage(["2d4", "1d10", "2d6", "2d8", "3d6"]);
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> The slime burns as you put your arm into its depths to
          try to retrieve the key. Your flesh begins to melt and your clothes
          burn off where your arm goes in. You take{" "}
          <b style={{ color: "red" }}>{damage} damage.</b> You can feel the
          slime trying to pull you in, and you almost slip, but you're barely
          able to pull yourself out as you drag they key along with you. If you
          survived the damage, you use the key to quickly get through the door.
        </div>
        <div className="keyAcquire">
          <b>They key in your hands turns to five rasps!</b> This key was
          already acquired. If you do escape, message @Beriale Wardhaven (Erik
          Manley) and he will give you the rasps.
        </div>
        <br />
        <br />
        Two paths lie before you.
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailOptionOneView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> The slime burns as you put your arms into its deptsh to
          try to retrieve the key. Your flesh begins to melt and your clothes
          burn off where your arm goes in. You push yourself to the edge, trying
          to endure the pain, but it proves too much. You pull your arm back.
          The slime burns everything as it digests all in its wake. You
          frantically work one more time to get the key out of it, but your
          efforts are not enough. It engulfs you fully. Its viscous fluid
          quickly moves you to the center of the slimy mass. You can't breathe,
          but something keeps you alive on the edge of passing out. You're awake
          for all of it as the gellatinous fluid starts digesting the outer
          layers of your skin and pours down your nose and throat, overtaking
          your stomach fluids as it digests you from this inside. You are kept
          alive in this cube for an unholy amount of time with no hope of escape
          or rescue.{" "}
          <b>Eventually, the blissful escape of death engulfs you.</b>
          <h2 className="deathText">You are dead</h2>
          You are revived in a random part of the maze with your memories
          intact. You must wait an hour before trying again.
        </div>
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2022/death");
          }}
        >
          Click this if you are Dead
        </button>
      </div>
    </div>
  );
};

//input rooms
const PassOptionTwoView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You manage to find a clever way of retrieving the key.
          Roleplay what that method was. You managed to avoid touching the slime
          creature with your bare skin, and so take no damage from it. After
          retrieving the key, you quickly use it to unlock the door before the
          slime creature can engulf you.
        </div>
        <div className="keyAcquire">
          <b>They key in your hands turns to five rasps!</b> This key was
          already acquired. If you do escape, message @Beriale Wardhaven (Erik
          Manley) and he will give you the rasps.
        </div>
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailOptionTwoView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> You frantically work to get the key out of it, trying
          everything you can think of, but your efforts are not enough. It
          engulfs you fully. Its viscous fluid quickly moves you to the center
          of the slimy mass. You can't breathe, but something keeps you alive on
          the edge of passing out. You're awake for all of it as the gellatinous
          fluid starts digesting the outer layers of your skin and pours down
          your nose and throat, overtaking your stomach fluids as it digests you
          from this inside. You are kept alive in this cube for an unholy amount
          of time with no hope of escape or rescue.{" "}
          <b>Eventually, the blissful escape of death engulfs you.</b>
        </div>
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2022/death");
          }}
        >
          Click this if you are Dead
        </button>
      </div>
    </div>
  );
};

//input rooms
const PassOptionThreeView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You manage to find the right mixture to neutralize the
          slime. Roleplay what that method was. You managed to avoid touching
          the slime creature with your bare skin, and so take no damage from it.
          After retrieving the key, you quickly use it to unlock the door before
          the slime creature can engulf you.
        </div>
        <div className="keyAcquire">
          <b>They key in your hands turns to five rasps!</b> This key was
          already acquired. If you do escape, message @Beriale Wardhaven (Erik
          Manley) and he will give you the rasps.
        </div>
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailOptionThreeView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> You frantically work to neutralize the slime, trying
          everything you can think of, but your efforts are not enough. It
          engulfs you fully. Its viscous fluid quickly moves you to the center
          of the slimy mass. You can't breathe, but something keeps you alive on
          the edge of passing out. You're awake for all of it as the gellatinous
          fluid starts digesting the outer layers of your skin and pours down
          your nose and throat, overtaking your stomach fluids as it digests you
          from this inside. You are kept alive in this cube for an unholy amount
          of time with no hope of escape or rescue.{" "}
          <b>Eventually, the blissful escape of death engulfs you.</b>
        </div>
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2022/death");
          }}
        >
          Click this if you are Dead
        </button>
      </div>
    </div>
  );
};
