import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/metaldoor.jpg";
import doorSfx from "../../res/sfx/Metal Door Creak Open 1_2.wav";
import sfx from "../../res/sfx/Alchemy 4.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = "https://i.ytimg.com/vi/d0QSQX63Png/maxresdefault.jpg";
const doorUrl = door;

var nextRooms;

const Acidpit = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    playOneShot(sfx);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Acidpit_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Acidpit_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 3;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 1) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Acidpit</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You enter a room with a pungent smell that sours your nose, like that of
        burned hair. The air is enough to make even the most careful of breaths
        burn in your throat. The room is small and you can easily see to the
        opposite end. Ten Feet ahead of your entry lies the ledge, a green glow
        emitting from below it. Liquid bubbles and simmers as you peek over that
        edge, leaving it quite evident, it is certainly no bathwater. Nothing
        but a ten foot gap stands between you and your next path.{" "}
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Acidpit_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Acidpit_Obj.route);
      }}
    >
      <img src={Acidpit_Obj.image} />
      <span className="entryText">
        A heavy iron door leading into the unknown
      </span>
    </button>
  );
};

export var Acidpit_Obj = {
  name: "Acidpit",
  room: Acidpit,
  entry: Entryway,
  route: "/halloween2022/acidpit",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Acidpit;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input)) {
      setErrText("");
      props.output([input]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        You must jump it. There is no other choice. Roll an athletics (body)
        check to run and jump across the gap. Careful, its a long way down, and
        that liquid does not seem welcoming.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input the result of your check below.
          <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You narrowly make the jump! Roleplay yourself
          succeeding. Two paths are available before you.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> You attempt your jump and fail. Roleplay yourself
          falling into acid!{" "}
          <b>
            Your flesh melts off your bones as it first eats away your clothes,
            and then your skin and muscle, and finally your bones. You die with
            nothing more as a sign of you passing than a dark spot on the
            surface of the acid.
          </b>
        </div>
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2022/death");
          }}
        >
          Click this if you are Dead
        </button>
      </div>
    </div>
  );
};
