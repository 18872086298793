import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../gui/difficultyMeter";
import doorSfx from "../res/sfx/Misc II.wav";
import song from "../res/music/Game Over.mp3";
import {
  CAPDEPTH,
  GlobalContext,
  changeSong,
  playOneShot,
} from "./room_handler";
import { deleteSave } from "../functions/save";

const bannerUrl =
  "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/25af0516362765.562aa9000ed9a.jpg";

const Death = () => {
  const navigate = useNavigate();
  const InstanceVars = useContext(GlobalContext);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    playOneShot(doorSfx);
    InstanceVars.current.death = true;
    InstanceVars.current.treasures_earned = [];
    InstanceVars.current.rasps_earned = 0;
    deleteSave();
  }, []);
  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Death</h1>
      <img className="banner" src={bannerUrl} />
      <div className="descriptionText">
        <h2 className="deathText">You are dead</h2>
        You become a ghost under the normal rules. Find someone to resurrect you
        as normal. You must wait one week before attempting the city of Gold
        again.
        <br />
        <br />
        [Experience created by Erik Manley with collaboration from members of
        the Gameplay team and Story team.]
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/CityOfGold/loot_divvy/");
          }}
        >
          CLick to submit your favor
        </button>
      </div>
    </div>
  );
};

export default Death;
