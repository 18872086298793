import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  getDamage,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/564x/72/15/fe/7215fe944f7fcc8fc76c106b2819fd4f.jpg";
const doorUrl = door;

var nextRooms;

const Shadow = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Shadow_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Shadow_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 3;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 2) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Shadow</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        A bright light shines at the opposing end of this large room. Numerous
        shadows stumble towards the light, their eye sockets glowing red. They
        stare at and move toward the light.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Shadow_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Shadow_Obj.route);
      }}
    >
      <img src={Shadow_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Shadow_Obj = {
  name: "Shadow",
  room: Shadow,
  entry: Entryway,
  route: "/halloween2022/shadow",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Shadow;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">Roll three spirituality (spirit) checks.</p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You sense a malice in the light and recognize that you
          shouldn't look at it for too long. If you were to approach the light,
          you would notice a cliff edge leading to a fall of unknown distance.
          Aside from this, there are two paths to your right leading out of the
          room.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  let damage = getDamage(["2d4", "1d10", "2d6", "2d8", "3d6"]);
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> You are intrigued by the light. The longer you look at
          it, the more it seems to draw you in. You want to grab it, to take it,
          and you slowly start walking towards it. You miss a step and stumble,
          falling off the edge of a sheer drop, down down down. Take{" "}
          <b> {damage} damage</b> as you hit the bottom. If you are still alive
          and recovered, you see two paths at the bottom of your fall.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2022/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
