import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/rooms/COG_Image__00061_.png";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/Mother of Deceit.wav";
import {
  CAPDEPTH,
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalContext,
  playOneShot,
} from "../room_handler";
import { EntryPercResults } from "../../components/perception";

const bannerUrl = door;
const doorUrl = door;

const Exit = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  const navigate = useNavigate();
  const InstanceVars = useContext(GlobalContext);
  useEffect(() => {
    changeSong(song);
  }, []);

  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Exit</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">You find a safe path out of the ruins.</p>
      <p className="roomText">
        We hope you enjoyed your stay and hope to see you again next week. Click
        on the link below to discover what treasure you got and divvy them up
        between the members of your party.
        <br />
        <br />
        [Experience created by Erik Manley with contributions by Farwulf Svarti,
        and Kendall Merrit with other contributions by members of the gameplay
        team.]
      </p>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/CityOfGold/loot_divvy/");
        }}
      >
        Click here to go to the treasure screen.
      </button>
    </div>
  );
};

const Entryway = (i = 0, navigate) => {
  return (
    <button
      key={Exit_Obj.name + i}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Exit_Obj.route);
      }}
    >
      <img src={Exit_Obj.image} />
      <span className="entryText">You see a way out of the ruins</span>
      <EntryPercResults
        observation={{
          tier: 4,
          text: "The path is old and unused",
        }}
        spirituality={{
          tier: 4,
          text: "You sense residual feelings of avoidance and rejection.",
        }}
      />
    </button>
  );
};

export var Exit_Obj = {
  name: "Exit",
  room: Exit,
  entry: Entryway,
  route: "/CityOfGold/exit",
  exits: 5,
  prob: 5,
  image: doorUrl,
};

export default Exit;
