import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/Schizophrenic Forest.wav";
import placque from "../../res/Vayle_Demonic_Script.jpg";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl =
  "https://c4.wallpaperflare.com/wallpaper/845/482/371/fog-forest-nature-twilight-wallpaper-preview.jpg";
const doorUrl =
  "https://i.pinimg.com/564x/f9/f6/22/f9f622fb1a9afcfca075c4203e65d860.jpg";

var nextRooms;

const ForestKey = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(ForestKey_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(ForestKey_Obj);
  }

  const parseInput = (input) => {
    switch (input[0]) {
      case "optionOne":
        changeDisplay("optionOnePass");
        break;
      case "pushOpen":
        GlobalVars.keys += 1;
        changeDisplay("pushOpen");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput rooms={nextRooms} output={parseInput} />;
        break;
      case "optionOnePass":
        component = <PartOne output={parseInput} />;
        break;
      case "pushOpen":
        component = <PushOpen rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Forest</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You exit the castle into a forest of tall white trees. Mist curls around
        your ankles. Anxiety fills you and all your instincts tell you something
        evil is near. You look back at the castle only to see it has
        disappeared. Whispers can be heard through the trees. Just on the edge
        of recognition, the whispers seem to be telling you something, but
        strain as you might, you can't make them out. You might be hearing
        things.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={ForestKey_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(ForestKey_Obj.route);
      }}
    >
      <img src={ForestKey_Obj.image} />
      <span className="entryText">You see a way out of the castle</span>
    </button>
  );
};

export var ForestKey_Obj = {
  name: "ForestKey",
  room: ForestKey,
  entry: Entryway,
  route: "/halloween2022/forest",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default ForestKey;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("notVamp");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    let password = "Zalim";
    console.log(input2);
    if (input.toLowerCase() == password.toLowerCase() && input2 == "notVamp") {
      setErrText("");
      props.output([option]);
    } else {
      setErrText("Nothing happens. Perhaps try something else?");
    }
  };

  return (
    <div>
      <p className="roomText">
        You see something through the trees. A tall stone pillar. Your feeling
        of dread deepens as you get closer, but something draws you to it. As
        you approach, the pillar materializes into a demonic statue carved out
        of blackened stone. The statue rests on a platform. There is an
        inscription on the base that appears to be in a different language. You
        do not know what it says.
      </p>
      <img className="banner" src={placque} />
      <p className="roomText">
        On the other side of the statue is a tall hill. Built into the side of
        the hill are three doors. It seems as though you can leave at any time,
        or stay to try to figure out what this means.
      </p>
      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Maybe there's a password or something. Try saying a few things. Or
          just leave.
        </label>
        <input
          className="inputField"
          type="text"
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <label className="inputLabel">Are you a vampire?</label>
        <select
          className="inputField"
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        >
          <option key="notVamp" value="notVamp">
            I am not a Vampire
          </option>
          <option key="vamp" value="vamp">
            I am a Vampire
          </option>
        </select>
        <button
          className="inputSubmit"
          type="submit"
          value="optionOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Speak the Password
        </button>
        <label className="error">{errText}</label>
      </form>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const PartOne = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "pushOpen":
          GlobalVars.keys += 1;
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> As you speak the password, the entire platform rumbles
          and shakes. Stone grinds on stone as the platform moves aside,
          revealing a cob webbed descent of stairs with an ever-burning torch
          near the top.
          <br />
          <br />
          You descend down the stairs, step by step. At the bottom is a
          sarcophagus carved with demonic runes. The top of the stone coffin
          shows the laying form of a fearsome demon. The area around the demon
          sports a number of torture devices with heaps of human skeletons at
          their feet, long dusted from time.
          <img
            className="banner"
            src="https://www.wga.hu/art/zgothic/gothic/5/07p_1401.jpg"
          />
        </div>
      </div>
      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="pushOpen"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Push open the sarcophagus
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PushOpen = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          Inside the sarcophagus is a well preserved demon corpse. Its skin is
          leathery and stiff. Its snub nose stands curled in an eternal snarl.
          Its fangs and sharp teeth are bared for all to see. Its hands are
          curled around a key with a green jewel in its hilt. As you peel back
          the fingers to get the key, the bones crack and pop and the skin
          resists stretching of any kind, but you do eventually get the key out
          of the corpse's hands.
          <div className="keyAcquire">
            <b>You have acquired the Forest Key!</b> This is one of the five
            keys you've been sent in to retrieve! Now you must escape the castle
            with your life. Make your way to an exit, or take your chances to
            find a second key. If you do escape, message @Beriale Wardhaven
            (Erik Manley) to let him know you found a key.
          </div>
          Heading back up the tomb's stairs, you are confronted with three paths
          to exit this forest.
        </div>
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
