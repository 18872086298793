import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  getDamage,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/564x/85/78/5e/85785e07be5de8954e57dfa07a9916eb.jpg";
const doorUrl = door;

var nextRooms;

const PuzzleBox = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(PuzzleBox_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(PuzzleBox_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 2) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Mechanical Puzzle</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You stand on a long platform leading to a circular cliff edge covered in
        rocks and small stone. Water rushes out of circular aquaeducts that line
        the large cavern ahead of you. The water falls below the cliff edge into
        a whirlpool that drains out of a pool. Across the other side, you see an
        exit. Machinery lines the walls, ceiling, and part of the floor
        connected to three pedestals with buttons. Pressing one of the buttons
        causes a mechanism to rattle and whirr. Chains lift a platform out of
        the water that runs a quarter of the way across the gap. Releasing the
        button lowers the platform back into the water.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={PuzzleBox_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(PuzzleBox_Obj.route);
      }}
    >
      <img src={PuzzleBox_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var PuzzleBox_Obj = {
  name: "PuzzleBox",
  room: PuzzleBox,
  entry: Entryway,
  route: "/halloween2022/puzzle",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default PuzzleBox;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        Roll three tinker checks to study the mechanism and determine how the
        puzzle works.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> Studying the mechanism for quite a while, you see a
          switch in some gears that can be switched to lock them in place while
          you get the other buttons and jam them in similar fashion. After a bit
          of tinkering, you're able to jam each section of walkway in place and
          walk across the rickety chain bridge to the other side. Two paths lie
          before you.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  let damage = getDamage(["2d4", "1d10", "2d6", "2d8", "3d6"]);
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> You study the mechanism for quite a while but struggle
          to get the full picture of any of it. After trying for long enough,
          you decide to give up and climb down the cliff to swim across the
          pool. Roleplay your attempt as you sputter and nearly drown, but even
          if you can hold your breath or breathe underwater, there are many
          rocks and stones spinning around the pool and you are ground up as you
          attempt to make your way across. Eventually, you do make it to the
          other side a bit worse for wear. Take <b>{damage} damage</b> in your
          attempt. If you are still alive, there are two paths that lie before
          you.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2022/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
