import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/metaldoor.jpg";
import doorSfx from "../../res/sfx/Metal Door Creak Open 1_2.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/564x/a3/de/21/a3de21d97d456ca3efaa08df05f76d06.jpg";
const doorUrl = door;

var nextRooms;

const Spider = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Spider_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Spider_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 3;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 2) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Not so Itsy</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        The room is cold, damp and littered with string. No, not string. Giant
        cobwebs. Green hues of light scatter through the corners. Chittering
        echoes leave an uneasy fealing under your skin. what looks to be
        strings, much like webs hanging from the ceiling. Green hues of light
        scattered throughout its corners, chittering echos leave an uneasy
        feeling under your skin. Roll a Fieldcraft or Observation Check
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Spider_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Spider_Obj.route);
      }}
    >
      <img src={Spider_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Spider_Obj = {
  name: "Spider",
  room: Spider,
  entry: Entryway,
  route: "/halloween2022/spider",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Spider;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        Roll three fieldcraft (spirit) checks or observation (perc) checks to
        safely navigate.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You know that touching the web will alert the spider to
          your presence. You gingerly step around the webs, extremely careful
          not to get caught in any of them. The room is larger than you
          expected, and you see a huge spider in the distance. It seems to be
          eating something. It hears something and stops its meal. It looks
          around, spotting you. You make a break for it, almost getting stuck in
          the webs, but you manage to make it to the exit where two paths lay
          before you! Quick choose a path, the spider is gaining on you!
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> You brave a path through the sticky webs, brushing them
          aside as you pass, hoping beyond hope that you do not run into any
          spiders in this terrible place. Something hits your leg and you look
          down to see a strut of spider silk wrapped around your leg. You look
          behind you to see a giant spider larger than a horse preparing to
          shoot you again. You try to break free from the lasso around your leg
          but cannot. It pulls your leg out from under you and you fall over,
          tangling yourself in webs along the ground. It's over you in a moment,
          and it bites you, digging two daggerlike fangs deep into your abdomen.
          You are paralyzed in moments as it dextrously works its pedipalps to
          cacoon you tightly in a web. It hangs you up in its lair. You are
          conscious but paralyzed for hours, listening to it finish its previous
          meal before it comes to you. You are awake as it starts,{" "}
          <b>but soon die as it devours you alive.</b>
        </div>
      </div>
      <DemonScript />
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2022/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
