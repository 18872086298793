import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl =
  "https://i.pinimg.com/originals/77/8d/45/778d45ca8e14153d1d54433d8948fa4e.jpg";
const doorUrl =
  "https://i.pinimg.com/564x/f9/f6/22/f9f622fb1a9afcfca075c4203e65d860.jpg";

var nextRooms;

const Exit = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  const navigate = useNavigate();
  useEffect(() => {
    changeSong(song);
  }, []);

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Exit</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You leave the castle safely. You must wait one hour before venturing in
        once more.
      </p>
      <p className="roomText">
        We hope you enjoyed your stay. If you have any keys, please report which
        keys you've earned to Erik Manley. <br />
        <br />
        [Experience created by Erik Manley and Farwulf Svarti]
      </p>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2022/");
        }}
      >
        Click here to return to the Entrance
      </button>
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Exit_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Exit_Obj.route);
      }}
    >
      <img src={Exit_Obj.image} />
      <span className="entryText">You see a way out of the castle</span>
    </button>
  );
};

export var Exit_Obj = {
  name: "Exit",
  room: Exit,
  entry: Entryway,
  route: "/halloween2022/exit",
  exits: 5,
  prob: 5,
  image: doorUrl,
};

export default Exit;
