import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import GatheringWood from "./DIYs/GatheringWood/Page1";
import HalloweenMain from "./DIYs/Halloween 2022/HalloweenMain";
import COGMain from "./DIYs/CityOfGold/COGMain";

const RouterHandler = () => {
  return (
    <BrowserRouter>
      <main>
        <Routes>
          <Route index element={<Home />} />
          <Route path="GatheringWood" element={<GatheringWood />} />
          <Route path="Halloween2022/*" element={<HalloweenMain />} />
          <Route path="CityOfGold/*" element={<COGMain />} />
        </Routes>
      </main>
    </BrowserRouter>
  );
};

export default RouterHandler;
