import { useCallback, useContext, useEffect, useState } from "react";
import { GetDifficulty, GlobalContext } from "../rooms/room_handler";
import SkillCheckSelect from "./SkillCheckSelect";
import { eventBus } from "../functions/CustomEventBus";

export const Perception = () => {
  const InstanceVars = useContext(GlobalContext);
  const [needPerc, setNeedPerc] = useState(false);

  useEffect(() => {
    initPercRoll();
  }, []);

  const initPercRoll = () => {
    if (
      !InstanceVars.current.perception ||
      InstanceVars.current.perception.depth === undefined ||
      InstanceVars.current.depth - InstanceVars.current.perception.depth >= 5
    ) {
      setNeedPerc(true);
      InstanceVars.current.perception = {
        depth:
          InstanceVars.current.perception &&
          InstanceVars.current.perception.depth !== undefined
            ? InstanceVars.current.perception.depth
            : -5,
        mysticism: 0,
        observation: 0,
        spirituality: 0,
      };
    }
  };

  const submitHandler = (selection) => {
    let pass = false;
    let maxMyst = 0;
    let maxSpi = 0;
    let maxObs = 0;
    for (let sel of selection) {
      if (sel.pass && sel.pass === true) {
        switch (sel.method) {
          case "Observation (perc)":
            maxObs = Math.max(maxObs, sel.value);
            break;
          case "Spirituality (perc)":
            maxSpi = Math.max(maxSpi, sel.value);
            break;
          case "Mysticism (perc)":
            maxMyst = Math.max(maxMyst, sel.value);
            break;
        }
        pass = true;
      }
    }

    InstanceVars.current.perception = {
      depth: InstanceVars.current.depth,
      mysticism: pass ? maxMyst : 0,
      observation: pass ? maxObs : 0,
      spirituality: pass ? maxSpi : 0,
    };
    eventBus.emit();
  };

  return (
    <div>
      {needPerc ? (
        <>
          <span className="inputLabel">
            If you would like to perform a perception check to figure out what
            lies ahead, choose a skill to use and then roll it. Input the number
            into the box. This perception check is good for 5 rooms. <br />
            Natural 1: input 0 in your box. <br />
            Natural 20: Add 1d6 to the roll and input the final score into your
            box.
          </span>
          <SkillCheckSelect
            options={[
              { method: "Observation (perc)", diffTier: 0, damTier: 0 },
              { method: "Spirituality (perc)", diffTier: 0, damTier: 0 },
              { method: "Mysticism (perc)", diffTier: 0, damTier: 0 },
            ]}
            noDamage
            noInstant
            noOther
            specialEffect="The highest number rolled will be compared to the target number of each room."
            callback={submitHandler}
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export const EntryPercResults = (props) => {
  const InstanceVars = useContext(GlobalContext);
  const [obsText, setObsText] = useState("");
  const [mystText, setMystText] = useState("");
  const [spiText, setSpiText] = useState("");
  const [obsDiff, setObsDiff] = useState();
  const [mystDiff, setMystDiff] = useState();
  const [spiDiff, setSpiDiff] = useState();

  const [refresh, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    const unsubscribe = eventBus.subscribe(() => {
      forceUpdate();
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    checkPerception(InstanceVars.current.perception);
  }, [refresh]);

  const checkPerception = async (perc) => {
    let tempObsDiff = obsDiff;
    if (!tempObsDiff)
      tempObsDiff = props.observation
        ? await GetDifficulty(InstanceVars, props.observation.tier)
        : 50;

    let tempMystDiff = mystDiff;
    if (!tempMystDiff)
      tempMystDiff = props.mysticism
        ? await GetDifficulty(InstanceVars, props.mysticism.tier)
        : 50;

    let tempSpiDiff = spiDiff;
    if (!tempSpiDiff)
      tempSpiDiff = props.spirituality
        ? await GetDifficulty(InstanceVars, props.spirituality.tier)
        : 50;

    if (perc && perc.observation && perc.observation >= tempObsDiff)
      setObsText(
        props.observation && props.observation.text
          ? props.observation.text
          : ""
      );
    else setObsText("");

    if (perc && perc.mysticism && perc.mysticism >= tempMystDiff)
      setMystText(
        props.mysticism && props.mysticism.text ? props.mysticism.text : ""
      );
    else setMystText("");

    if (perc && perc.spirituality && perc.spirituality >= tempSpiDiff)
      setSpiText(
        props.spirituality && props.spirituality.text
          ? props.spirituality.text
          : ""
      );
    else setSpiText("");

    setObsDiff(tempObsDiff);
    setMystDiff(tempMystDiff);
    setSpiDiff(tempSpiDiff);
  };

  return (
    <div className="percResults">
      {obsText != "" ? (
        <span>
          <b>Observation:</b> {obsText}
        </span>
      ) : (
        ""
      )}
      {mystText != "" ? (
        <span>
          <b>Mysticism:</b> {mystText}
        </span>
      ) : (
        ""
      )}
      {spiText != "" ? (
        <span>
          <b>Spirit:</b> {spiText}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};
