import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/rooms/COG_Image__00283_.png";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/Disorienting.mp3";
import banner from "../../res/rooms/COG_Image__00136_2.png";
import {
  CAPDEPTH,
  changeSong,
  GetDifficulty,
  GetNextRooms,
  GetRoomObjectByName,
  GlobalContext,
  playOneShot,
} from "../room_handler";
import SkillCheckSelect from "../../components/SkillCheckSelect";
import RandomRoomTreasure from "../../components/Loot";
import { save } from "../../functions/save";
import { EntryPercResults, Perception } from "../../components/perception";

const bannerUrl = banner;
const doorUrl = door;

const Atlas = () => {
  const [display, changeDisplay] = useState("pre");
  const InstanceVars = useContext(GlobalContext);
  const [nextRooms, changeNextRooms] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (InstanceVars.current.nextRooms) {
      loadVals();
    } else {
      let nextRoomTemp = GetNextRooms(
        Atlas_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Atlas_Obj.exits)
      );
      save(InstanceVars.current, Atlas_Obj, nextRoomTemp);
      InstanceVars.current.score.depth += 100;
      changeNextRooms(nextRoomTemp);
    }
  }, []);

  const loadVals = () => {
    let nextRoomTemp = [];
    InstanceVars.current.nextRooms.forEach((room) => {
      let tempRoom = GetRoomObjectByName(room.name);
      if (tempRoom) nextRoomTemp.push(tempRoom);
    });
    if (nextRoomTemp.length > 0) {
      delete InstanceVars.current.nextRooms;
      delete InstanceVars.current.currentRoom;
    } else {
      nextRoomTemp = GetNextRooms(
        Atlas_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Atlas_Obj.exits)
      );
    }
    changeNextRooms(nextRoomTemp);
  };

  const parseInput = (input) => {
    if (input == "next") {
      InstanceVars.current.score.skill += 400;
      changeDisplay("pass");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "pre":
      default:
        component = <PreInput output={parseInput} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Atlas</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        A large cavernous room with shafts of harsh light streaming through the
        ceiling. The air is hot and stuffy. You can feel your skin baking in it.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = (i = 0, navigate) => {
  return (
    <button
      key={Atlas_Obj.name + i}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Atlas_Obj.route);
      }}
    >
      <img src={Atlas_Obj.image} />
      <span className="entryText">A dark cave leading into the unknown</span>
      <EntryPercResults
        observation={{
          tier: 4,
          text: "A faint ozone scent comes from below.",
        }}
        mysticism={{
          tier: 3,
          text: "You sense strong light magic at work ahead.",
        }}
        spirituality={{
          tier: 5,
          text: "Residual feelings of caution come from this direction.",
        }}
      />
    </button>
  );
};

export var Atlas_Obj = {
  name: "Atlas",
  room: Atlas,
  entry: Entryway,
  route: "/CityOfGold/atlas",
  exits: 4,
  prob: 5,
  image: doorUrl,
  faction: "theokyr",
};

export default Atlas;

//input: function
//output: level
const PreInput = (props) => {
  const [ableToContinue, setAbleToContinue] = useState(false);
  const navigate = useNavigate();

  //console.log(props.output);
  const submitHandler = (selection) => {
    let blank = false;
    selection.forEach((sel) => {
      if (sel.pass === undefined) blank = true;
      return;
    });
    if (!blank) {
      console.log("next");
      setAbleToContinue(true);
    }
  };

  return (
    <div>
      <p className="roomText">
        Each light shaft is burning hot. There are so many of them that it's
        difficulty to see where one ends and another begins. There's one heavy
        reflective shield on the ground next to your feet that could be used to
        cross safely, but it's very heavy, and it will only fit one person under
        it. If you want to use the shield, roll an athletics (body) check to
        hold it above your head long enough to cross. If you want to take your
        chances carefully going past the lights, roll agility (dex) to cross
        safely.
      </p>
      <span className="inputLabel">
        Choose a skill to use and then roll it. Input the number into the box.
        If you have a way of feasibly instant-succeeding on the check, select
        "instant." <br />
        Natural 1: input 0 in your box. <br />
        Natural 20: Add 1d6 to the roll and input the final score into your box.
      </span>
      <SkillCheckSelect
        options={[
          { method: "athletics (body)", diffTier: 2, damTier: 5 },
          { method: "agility (dex)", diffTier: 4, damTier: 5 },
        ]}
        specialEffect="You have the charred 3 status effect for the next 5 rooms. (charred: Character takes 3 extra damage any time they take fire damage for the duration)"
        callback={submitHandler}
      />
      <input
        type="button"
        value="Continue"
        className="skillCheckButton skillCheckSubmit"
        disabled={!ableToContinue}
        onClick={() => props.output("next")}
      />
      <input
        type="button"
        value="Dead"
        className="skillCheckButton skillCheckDeath"
        disabled={!ableToContinue}
        onClick={() => {
          navigate("/CityOfGold/death");
        }}
      />
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>Survived!</b> You successfully made it to the other side. Where do
          you go from here?
        </div>
        <RandomRoomTreasure
          chance={0.05}
          chanceForCurse={0.2}
          tier={Math.floor(Math.random() * 3)}
        />
      </div>
      <Perception />
      <div className="nextHolder">
        {props.rooms.map((room, i) => {
          return room.entry(i, navigate);
        })}
      </div>
    </div>
  );
};
