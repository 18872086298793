import "./COGMain.css";
import COGRouterHandler from "./COGRouterHandler";

function COGMain() {
  return (
    <div className="COGApp">
      <COGRouterHandler />
    </div>
  );
}

export default COGMain;
