import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/rooms/COG_Image__00318_.png";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/Disorienting.mp3";
import banner from "../../res/rooms/COG_Image__00119_.png";
import {
  CAPDEPTH,
  changeSong,
  GetNextRooms,
  GetRoomObjectByName,
  GlobalContext,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import { save } from "../../functions/save";
import { EntryPercResults, Perception } from "../../components/perception";

const bannerUrl = banner;
const doorUrl = door;

const ManaWell = () => {
  const InstanceVars = useContext(GlobalContext);
  const [nextRooms, changeNextRooms] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (InstanceVars.current.nextRooms) {
      loadVals();
    } else {
      let nextRoomTemp = GetNextRooms(
        ManaWell_Obj,
        InstanceVars,
        Math.ceil(Math.random() * ManaWell_Obj.exits)
      );
      save(InstanceVars.current, ManaWell_Obj, nextRoomTemp);
      InstanceVars.current.score.depth += 100;
      changeNextRooms(nextRoomTemp);
    }
  }, []);

  const loadVals = () => {
    let nextRoomTemp = [];
    InstanceVars.current.nextRooms.forEach((room) => {
      let tempRoom = GetRoomObjectByName(room.name);
      if (tempRoom) nextRoomTemp.push(tempRoom);
    });
    if (nextRoomTemp.length > 0) {
      delete InstanceVars.current.nextRooms;
      delete InstanceVars.current.currentRoom;
    } else {
      nextRoomTemp = GetNextRooms(
        ManaWell_Obj,
        InstanceVars,
        Math.ceil(Math.random() * ManaWell_Obj.exits)
      );
    }
    changeNextRooms(nextRoomTemp);
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Mana Well</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        A well of glowing blue liquid stands in the center of this room. The
        spartan room is clear of soot and grime. Sparks of magical energy dance
        about the room. For reasons unknown, no Scourge or Theokyr will enter
        this place. You are safe for now. If you attempt to contain this liquid,
        it will dissipate into the aether.
      </p>
      <p className="roomText">
        Being in the presence of this mana well makes you feel invigorated.
        <b style={{ color: "lightblue" }}>You gain a mana reset.</b>
      </p>
      <Perception />
      <div className="nextHolder">
        {nextRooms.map((room, i) => {
          return room.entry(i, navigate);
        })}
      </div>
    </div>
  );
};

const Entryway = (i = 0, navigate) => {
  return (
    <button
      key={ManaWell_Obj.name + toString(i)}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(ManaWell_Obj.route);
      }}
    >
      <img src={ManaWell_Obj.image} />
      <span className="entryText">
        A dark passage leading to a blue glow at the end
      </span>
      <EntryPercResults
        observation={{
          tier: 4,
          text: "The path ahead is fairly undisturbed.",
        }}
        mysticism={{
          tier: 4,
          text: "You sense a link to the river of elements in this direction.",
        }}
        spirituality={{
          tier: 4,
          text: "You sense feelings of Sanctity from this direction.",
        }}
      />
    </button>
  );
};

export var ManaWell_Obj = {
  name: "ManaWell",
  room: ManaWell,
  entry: Entryway,
  route: "/CityOfGold/mana_well",
  exits: 4,
  prob: 1,
  image: doorUrl,
};

export default ManaWell;
