import door from "../../res/frankenstein-light-switch-1.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import doorSfx from "../../res/sfx/Switch and Lever 2_2.wav";
import song from "../../res/music/Insidious.mp3";
import banner from "../../res/chute.png";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  getDamage,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Chute = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    nextRooms = GetNextRooms(Chute_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Chute_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 0;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 1) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Chute</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        A long chute of stone. Who knows how deep it goes?
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Chute_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Chute_Obj.route);
      }}
    >
      <img src={Chute_Obj.image} />
      <span className="entryText">There is a lever. Do you pull it?</span>
    </button>
  );
};

export var Chute_Obj = {
  name: "Chute",
  room: Chute,
  entry: Entryway,
  route: "/halloween2022/chute",
  exits: 1,
  prob: 5,
  image: doorUrl,
};

export default Chute;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input)) {
      setErrText("");
      props.output([input]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        A trap door opens beneath you and you fall down into a chute. You slide
        fast as you pick up speed along smooth stone.
        <br />
        <br />
        Roll an Agility (Dex) check to right yourself!
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input the results of your roll below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: input the result in all boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>

        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You are quickly able to right yourself. As you slide to
          the bottom of the chute, you find yourself in a tiny stone room with
          spiderwebs in the corners. Only one path stands available to you.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  let damage = getDamage(["1", "1", "1", "2", "3"]);
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> You fail to right yourself in time, taking{" "}
          <b>{damage} damage</b> as you hit the bottom with a clatter. If you
          survive your fall, you find yourself in a tiny stone room with
          spiderwebs in the corners. Only one path stands available to you.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2022/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
