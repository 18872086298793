export default async function postSubmittionData(submissionData) {
	/*
		BELOW IS THE FORMAT submissionData MUST CONFORM TO, EXCEPTIONS LISTED. If a key doesn't have "REQUIRED" written next to it, this function will fill in an empty answer to keep things from breaking.
		"characterName": REQUIRED - String
		"DIYName": REQUIRED - String, Currently takes only the listed options: Terravissen Harvesting Logs (QM: NORTHEAST)
		"autoBool": String, always "Yes"
		"advFavor": String/Number, must contain an integer
		"champFavor": String/Number, must contain an integer
		"rasps": String/Number, must contain an integer
		"otherRewards": String,
		"otherData": String
		*/
	if (typeof submissionData === "string") {
		submissionData = JSON.parse(submissionData);
	}
	// Validates the data received and fills in defaults that are needed but empty. Does not stop the package from sending, only sends errors.
	if (!("characterName" in submissionData)) {
		console.error("No character name given.");
		return "No character name given.";
	}
	if (!typeof submissionData["characterName"] === "string") {
		console.error(
			"The character's name isn't a string, this is most likely because an object was submitted instead of a name."
		);
		return "The character's name isn't a string, this is most likely because an object was submitted instead of a name.";
	}
	if (!("DIYName" in submissionData)) {
		console.error("No DIY Name given.");
		return "No DIY Name given.";
	}
	if (!("autoBool" in submissionData)) {
		submissionData.autoBool = "Yes";
	}
	const defaultToEmptyString = [
		"rasps",
		"advFavor",
		"champFavor",
		"otherRewards",
		"otherData",
	];
	for (let i in defaultToEmptyString) {
		if (!(defaultToEmptyString[i] in submissionData)) {
			submissionData[defaultToEmptyString[i]] = "";
		}
	}

	// Checks if there's already a submission for this DIY this week.
	const characterData = JSON.parse(sessionStorage.getItem("characterData")); // All character data.
	const previousSubmissions =
		characterData["submissions"][submissionData.characterName];
	if (previousSubmissions === undefined) {
		// Checks there are submissions.
		characterData["submissions"][submissionData.characterName] = [];
	} else if (previousSubmissions.includes(submissionData.DIYName)) {
		// If they've already submitted this DIY this week.
		submissionData.rasps = "";
		submissionData.advFavor = "";
		submissionData.champFavor = "";
		submissionData.otherRewards = "";
		submissionData.otherData +=
			"This submission was zerod out during submission for being a repeat within the same week.";
		console.log(
			`The submission for ${submissionData.characterName} was zerod out due to being an extra submission within the same week.`
		);
	}
	characterData["submissions"][submissionData.characterName].push(
		`${submissionData.DIYName}`
	);
	sessionStorage.setItem("characterData", JSON.stringify(characterData)); // Updates the local data with the submission.

	return await fetch(
		"https://script.google.com/macros/s/AKfycby8bYnrJ6Ekv7nsdB4cHnqsaLoD3-iKcQW98hrIKeKw0tf1FE44l19HY0XTXKzDmLiH/exec",
		{
			redirect: "follow",
			method: "POST",
			body: JSON.stringify({
				whichType: "topLevelPost",
				whichForm: "standardDIY", // This form will currently only submit to the defaul DIY form.
				submissionType: "DIY",
				// "args": ["getSubmissions"]
				data: JSON.stringify(submissionData),
			}),
			headers: {
				"Content-Type": "text/plain;charset=utf-8",
			},
		}
	)
		.then((response) => response.clone().text())
		.then((body) => {
			try {
				return JSON.parse(body);
			} catch {
				return body;
			}
		})
		.catch(console.error);
}
// postSubmittionData().then(parsedResponse => console.log(parsedResponse));
