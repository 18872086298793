import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/rooms/COG_Image__00318_.png";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/Disorienting.mp3";
import banner from "../../res/rooms/pit_trap.png";
import {
  CAPDEPTH,
  changeSong,
  GetNextRooms,
  GetRoomObjectByName,
  GlobalContext,
  playOneShot,
} from "../room_handler";
import SkillCheckSelect from "../../components/SkillCheckSelect";
import RandomRoomTreasure from "../../components/Loot";
import { save } from "../../functions/save";
import { EntryPercResults, Perception } from "../../components/perception";

const bannerUrl = banner;
const doorUrl = door;

const Pit = () => {
  const [display, changeDisplay] = useState("pre");
  const InstanceVars = useContext(GlobalContext);
  const [nextRooms, changeNextRooms] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (InstanceVars.current.nextRooms) {
      loadVals();
    } else {
      let nextRoomTemp = GetNextRooms(
        Pit_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Pit_Obj.exits)
      );
      save(InstanceVars.current, Pit_Obj, nextRoomTemp);
      InstanceVars.current.score.depth += 100;
      changeNextRooms(nextRoomTemp);
    }
  }, []);

  const loadVals = () => {
    let nextRoomTemp = [];
    InstanceVars.current.nextRooms.forEach((room) => {
      let tempRoom = GetRoomObjectByName(room.name);
      if (tempRoom) nextRoomTemp.push(tempRoom);
    });
    if (nextRoomTemp.length > 0) {
      delete InstanceVars.current.nextRooms;
      delete InstanceVars.current.currentRoom;
    } else {
      nextRoomTemp = GetNextRooms(
        Pit_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Pit_Obj.exits)
      );
    }
    changeNextRooms(nextRoomTemp);
  };

  const parseInput = (input) => {
    if (input == "next") {
      InstanceVars.current.score.skill += 200;
      changeDisplay("pass");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "pre":
      default:
        component = <PreInput output={parseInput} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Pit</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        A blank room with a dirt floor. Heavy stone bricks lay cracked and
        scattered through walls of ancient plaster.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = (i = 0, navigate) => {
  return (
    <button
      key={Pit_Obj.name + i}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Pit_Obj.route);
      }}
    >
      <img src={Pit_Obj.image} />
      <span className="entryText">A dark passage leading into the unknown</span>
      <EntryPercResults
        observation={{
          tier: 4,
          text: "The path ahead is fairly undisturbed.",
        }}
        spirituality={{
          tier: 5,
          text: "You sense residual feelings of brief but sharp terror in this direction.",
        }}
      />
    </button>
  );
};

export var Pit_Obj = {
  name: "Pit",
  room: Pit,
  entry: Entryway,
  route: "/CityOfGold/pit",
  exits: 4,
  prob: 5,
  image: doorUrl,
  faction: "none",
};

export default Pit;

//input: function
//output: level
const PreInput = (props) => {
  const [ableToContinue, setAbleToContinue] = useState(false);
  const navigate = useNavigate();

  //console.log(props.output);
  const submitHandler = (selection) => {
    let blank = false;
    selection.forEach((sel) => {
      if (sel.pass === undefined) blank = true;
      return;
    });
    if (!blank) {
      console.log("next");
      setAbleToContinue(true);
    }
  };

  return (
    <div>
      <p className="roomText">
        As you proceed forward into the room, the floor falls away, revealing a
        pit trap! Roll a check to get out of the way (agility (dex)), catch
        yourself (athletics (body)), or see it just before it goes off
        (fieldcraft (perc))
      </p>
      <span className="inputLabel">
        Choose a skill to use using the drop down menu and then roll it. Input
        the number into the box. <br /> If you have a way of feasibly
        instant-succeeding on the check, select "instant." <br />
        Natural 1: input 0 in your box. <br />
        Natural 20: Add 1d6 to the roll and input the final score into your box.
      </span>
      <SkillCheckSelect
        options={[
          { method: "agility (dex)", diffTier: 2, damTier: 2 },
          { method: "athletics (body)", diffTier: 3, damTier: 2 },
          { method: "fieldcraft (perc)", diffTier: 3, damTier: 2 },
        ]}
        callback={submitHandler}
      />
      <input
        type="button"
        value="Continue"
        className="skillCheckButton skillCheckSubmit"
        disabled={!ableToContinue}
        onClick={() => props.output("next")}
      />
      <input
        type="button"
        value="Dead"
        className="skillCheckButton skillCheckDeath"
        disabled={!ableToContinue}
        onClick={() => {
          navigate("/CityOfGold/death");
        }}
      />
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>Survived!</b> You have survived the trap and successfully proceeded
          to the other side. What room do you enter next?
        </div>
        <RandomRoomTreasure
          chance={0.05}
          chanceForCurse={0.2}
          tier={Math.floor(Math.random() * 3)}
        />
      </div>
      <Perception />
      <div className="nextHolder">
        {props.rooms.map((room, i) => {
          return room.entry(i, navigate);
        })}
      </div>
    </div>
  );
};
