import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import sfx from "../../res/sfx/Infected Mind Ambience.mp3";
import song from "../../res/music/Growing Whispers.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  getDamage,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/564x/55/8c/71/558c7190ffb4b4dc087136fa7ed77379.jpg";
const doorUrl = door;

var nextRooms;

const Madness = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    playOneShot(sfx);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Madness_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Madness_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 2) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Madness</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        A putrid smell permeates the room. A pedestal stands in the center of
        the room surrounded by six pillars. Hovering above the pedestal is a
        stone orb covered in spikes. As you are looking, the color of the orb
        changes to yellow as energy bleeds out in red and gold. Your mind feels
        heavy... difficult to think. Anger and confusion wash over you in a wave
        that seems to intensify. It slowly returns back to its grey stone state,
        only to repeat again moments later.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Madness_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Madness_Obj.route);
      }}
    >
      <img src={Madness_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Madness_Obj = {
  name: "Madness",
  room: Madness,
  entry: Entryway,
  route: "/halloween2022/madness",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Madness;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        Roll three mental acuity checks to resist the effects!
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> It's more difficult that you'd imagine, the closer you
          to get to it, the more mad you feel. But you have to make it past to
          exit the room. Luckily you steel your mind enough to make it past. Two
          paths lie before you.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  playOneShot(sfx);
  let damage = getDamage(["1", "2", "2", "4", "6"]);
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> You can feel it building more and more as you are
          within its presence. You feel a violent headache come over you and
          take <b>{damage} damage</b> as yellow energy bursts from your mind.
          You fall to your hands and knees and crawl toward the exit whilst this
          madness seems to make a return.{" "}
          <b>
            For the remainder of this run, roleplay yourself as seeing things or
            going mad. You suffer -1d4 on every roll until you leave the castle.
          </b>
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2022/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
