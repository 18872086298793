import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  getDamage,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://www.thevenetianmasks.com/wp-content/uploads/2020/12/masks-2415706_1920-e1608106815809.jpg";
const doorUrl = door;

var nextRooms;

const Masks = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Masks_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Masks_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 1;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 1) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Masks</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        Colors dance around the room. Your foot sinks into the red carpet
        covering the floor. Full face masks, shining in all the colors of the
        rainbow, adorned with masterwork designs, cover the walls. Some masks
        have fallen from their perches, resting on the ground, and some even
        appear pinned to the ceiling. A tinkling fills the room, a slight
        whisper of ballroom music and laughter. A hush fills the room as eyes
        open, dozens of them peering at you through the masks. They stare with
        an unflinching gaze.
        <br />
        <br />
        'Smile for me. Wear me. Speak for me. Smile for me.' they whisper.
        <br />
        <br />
        The air gets cold and a shiver runs down your spine. Your breath mists
        from your mouth. No exits can be seen.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Masks_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Masks_Obj.route);
      }}
    >
      <img src={Masks_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Masks_Obj = {
  name: "Masks",
  room: Masks,
  entry: Entryway,
  route: "/halloween2022/masks",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Masks;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input)) {
      setErrText("");
      props.output([input]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        Something isn't right here. Make a Mysticism (Mind) check to figure out
        what.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your result below. <br />
          Natural 1: input 0. <br />
          Natural 20: Add 1d6 to the roll
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You are drawn to a certain mask. Made especially for
          you. Describe this mask. No eyes fill the sockets. No whispers assault
          your mind through its mouth, but it calls to you all the same.
          Something, perhaps curiousity, perhaps something else, draws you to
          put the mask on.
          <br />
          <br />A gust blows through the room, and as quickly as it comes, it's
          gone once again. The masks, the carpet, the colors. All of it gone,
          leaving a bare room with three doors on one side.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  const submitHandler = () => {
    navigate(props.rooms[0].route);
  };
  let damage = getDamage(["1", "1", "1", "1d4", "1d6"]);
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> You see nothing as the whispers grow to a crescendo
          until they are screaming at you.
          <br />
          <br />
          'SMILE FOR ME. WEAR ME. SPEAK FOR ME. SMILE FOR ME,' They yell.
          <br />
          <br />
          It grows and grows until you question if silence ever truly existed,
          and the air shakes with their resonance. You are thrown through an
          open door which slams behind you. <b>You take {damage} damage.</b>
        </div>
      </div>
      <button className="inputSubmit" onClick={submitHandler}>
        Stand and figure out where you are.
      </button>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2022/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
