import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/rooms/COG_Image__00125_.png";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/Disorienting.mp3";
import banner from "../../res/rooms/COG_Image__00125_.png";
import {
  CAPDEPTH,
  changeSong,
  GetNextRooms,
  GetRoomObjectByName,
  GlobalContext,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import { save } from "../../functions/save";
import { EntryPercResults, Perception } from "../../components/perception";

const bannerUrl = banner;
const doorUrl = door;

const SmallWell = () => {
  const InstanceVars = useContext(GlobalContext);
  const [nextRooms, changeNextRooms] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (InstanceVars.current.nextRooms) {
      loadVals();
    } else {
      let nextRoomTemp = GetNextRooms(SmallWell_Obj, InstanceVars);
      save(InstanceVars.current, SmallWell_Obj, nextRoomTemp);
      InstanceVars.current.score.depth += 100;
      changeNextRooms(nextRoomTemp);
    }
  }, []);

  const loadVals = () => {
    let nextRoomTemp = [];
    InstanceVars.current.nextRooms.forEach((room) => {
      let tempRoom = GetRoomObjectByName(room.name);
      if (tempRoom) nextRoomTemp.push(tempRoom);
    });
    if (nextRoomTemp.length > 0) {
      delete InstanceVars.current.nextRooms;
      delete InstanceVars.current.currentRoom;
    } else {
      nextRoomTemp = GetNextRooms(
        SmallWell_Obj,
        InstanceVars,
        Math.ceil(Math.random() * SmallWell_Obj.exits)
      );
    }
    changeNextRooms(nextRoomTemp);
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Mana Crack</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        As you walk down the corridor, you see a glowing blue crack in the wall.
        The area within 30 feet of the crack is completely free from dust and
        grime. The crack itself flows with a strange blue liquid that sparks and
        crackles. For reasons unknown, no theokyr or scourge will come near this
        area. If you attempt to contain this liquid, it will dissipate into the
        aether.
      </p>
      <p className="roomText">
        Being in the presence of this mana crack makes you feel invigorated.
        <b style={{ color: "lightblue" }}>You gain *half* of a mana reset.</b>
      </p>
      <Perception />
      <div className="nextHolder">
        {nextRooms.map((room, i) => {
          return room.entry(i, navigate);
        })}
      </div>
    </div>
  );
};

const Entryway = (i = 0, navigate) => {
  return (
    <button
      key={SmallWell_Obj.name + toString(i)}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(SmallWell_Obj.route);
      }}
    >
      <img src={SmallWell_Obj.image} />
      <span className="entryText">
        There is a crack in the wall, do you investigate?
      </span>
      <EntryPercResults
        mysticism={{
          tier: 2,
          text: "You sense a link to the river of elements in this direction.",
        }}
        spirituality={{
          tier: 3,
          text: "The crack has a feeling of sanctity about it.",
        }}
      />
    </button>
  );
};

export var SmallWell_Obj = {
  name: "SmallWell",
  room: SmallWell,
  entry: Entryway,
  route: "/CityOfGold/small_well",
  exits: 1,
  prob: 3,
  image: doorUrl,
};

export default SmallWell;
