import { useCallback, useContext, useEffect, useState } from "react";
import { GetDamage, GetDifficulty, GlobalContext } from "../rooms/room_handler";
import "./SkillCheckSelect.css";

const SkillCheckSelect = (props) => {
  const [selections, setSelections] = useState([]);
  const [options, setOptions] = useState([]);
  const InstanceVars = useContext(GlobalContext);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  //props.options = [{method, diffTier, damTier}]

  useEffect(() => {
    let ops = props.options;
    let maxTier = 0;
    let maxDam = 0;
    let maxDiff = 0;
    for (let i = 0; i < ops.length; i++) {
      ops[i].difficulty = GetDifficulty(InstanceVars, ops[i].diffTier);
      maxTier = Math.max(maxTier, ops[i].diffTier);
      maxDam = Math.max(maxDam, ops[i].damTier);
      maxDiff = Math.min(Math.max(maxDiff, ops[i].difficulty + 5), 40);
    }

    if (!props.noOther) {
      let index = ops.findIndex((item) => {
        return item.method == "other";
      });
      index = index == -1 ? ops.length : index;
      ops[index] = {
        method: "other",
        diffTier: maxTier,
        damTier: maxDam,
        difficulty: maxDiff,
      };
    }

    let tempArray = Array(InstanceVars.current.characters.length);
    for (let i = 0; i < tempArray.length; i++) {
      tempArray[i] = {
        name: "",
        method: "",
        check: false,
        pass: undefined,
        value: 0,
      };
    }
    setOptions(ops);
    setSelections(tempArray);
  }, []);

  const changeMethodHandler = (e, name, i) => {
    e.preventDefault();
    let check = true;
    //console.log(e.target.value);
    switch (e.target.value) {
      case "instant":
      case "blank":
        check = false;
        break;
      default:
        check = true;
    }

    let tempSelection = {
      name: name,
      method: e.target.value,
      check: check,
    };

    let tempList = [...selections];
    tempList[i] = { ...tempList[i], ...tempSelection };
    setSelections(tempList);
  };

  const changeValueHandler = (e, i) => {
    e.preventDefault();

    let tempSelection = {
      value: e.target.value,
    };

    let tempList = [...selections];
    tempList[i] = { ...tempList[i], ...tempSelection };
    setSelections(tempList);
  };

  const checkHandler = (e, i) => {
    e.preventDefault();
    if (!selections[i].method || selections[i].method === "blank") return;
    let pass = false;
    // console.log(selections);
    let method =
      selections[i] && selections[i].method ? selections[i].method : "instant";
    switch (method) {
      case "instant":
        pass = true;
        break;
      default:
        let op = options.find((val) => {
          return val.method === method;
        });
        if (selections[i].value >= op.difficulty) {
          pass = true;
        }
    }
    //console.log(pass);
    let temp = selections;
    temp[i].pass = pass;
    if (!pass) {
      let op = options.find((val) => {
        return val.method === temp[i].method;
      });
      let damTier = op.damTier;
      let damage = GetDamage(InstanceVars, damTier);
      temp[i].damage = damage;
    }
    props.callback(temp);
    // console.log(temp);
    setSelections(temp);
    forceUpdate();
  };

  // console.log(selections);

  return (
    <table className="skillCheckSelector">
      <thead>
        <tr className="skillCheckHeadLine">
          <td className="skillCheckName">Name</td>
          <td className="skillCheckMethod">Method</td>
          <td className="skillCheckRoll">Roll</td>
          <td className="skillCheckResult">Result</td>
        </tr>
      </thead>
      <tbody>
        {InstanceVars.current.characters.map((char, i) => {
          return (
            <tr key={"checkline_" + char.name} className="skillCheckSelLine">
              <td>{char.name}</td>
              <td>
                <select
                  value={
                    selections[i] && selections[i].method
                      ? selections[i].method
                      : ""
                  }
                  disabled={
                    selections[i] && selections[i].pass != undefined
                      ? true
                      : false
                  }
                  onChange={(e) => changeMethodHandler(e, char.name, i)}
                >
                  <option value="blank"></option>
                  {options.map((op) => {
                    return (
                      <option key={op.method} value={op.method}>
                        {op.method}
                      </option>
                    );
                  })}
                  {props.noInstant && props.noInstant === true ? (
                    ""
                  ) : (
                    <option value="instant">instant</option>
                  )}
                </select>
              </td>
              <td>
                {selections[i] && selections[i].check ? (
                  <input
                    type="number"
                    min="0"
                    max="40"
                    required
                    disabled={
                      selections[i] && selections[i].pass != undefined
                        ? true
                        : false
                    }
                    value={
                      selections[i] && selections[i].value
                        ? selections[i].value
                        : ""
                    }
                    onChange={(e) => changeValueHandler(e, i)}
                  />
                ) : (
                  ""
                )}
              </td>
              <td>
                <div className="skillCheckResultCell">
                  <input
                    type="button"
                    value="submit"
                    disabled={
                      selections[i] && selections[i].pass !== undefined
                        ? true
                        : false
                    }
                    onClick={(e) => checkHandler(e, i)}
                  />
                  {!selections[i] || selections[i].pass === undefined ? (
                    <span className="checkWarning">
                      {selections[i] && selections[i].method === "instant"
                        ? "Note: only select this option if your method does not appear in the dropdown menu AND you have a way of bypassing this obstacle without rolling (i.e. teleport spell or flight)."
                        : ""}
                      {selections[i] && selections[i].method === "other"
                        ? "Note: only use this option if you have an alternate check you could feasilby use that's not listed (this is always the hardest option to pass)."
                        : ""}
                    </span>
                  ) : selections[i].pass === true ? (
                    <span
                      className="checkResultText"
                      style={{ color: "green" }}
                    >
                      Check passed!{" "}
                      {props.succeedEffect ? props.succeedEffect : ""}
                    </span>
                  ) : (
                    <span className="checkResultText" style={{ color: "red" }}>
                      Check failed!{" "}
                      {props.noDamage && props.noDamage === true
                        ? ""
                        : char.name +
                          " takes " +
                          selections[i].damage +
                          " damage! "}
                      {props.specialEffect ? props.specialEffect : ""}
                    </span>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SkillCheckSelect;
