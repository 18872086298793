import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/white_noise_sounds.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl = "https://media.tenor.com/cqm2X5OvcYIAAAAC/white-noise.gif";
const doorUrl = door;

var nextRooms;

const Noise = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Noise_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Noise_Obj);
  }

  const parseInput = (input) => {
    switch (input[1]) {
      case "optionOne":
        changeDisplay("optionOne");
        break;
      case "optionTwo":
        changeDisplay("optionTwo");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "optionOne":
        component = <OptionOneView rooms={nextRooms} />;
        break;
      case "optionTwo":
        component = <OptionTwoView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Noise</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        Noise such as you've never heard before assaults your hearing. It drowns
        out everything in a deafening ocean of hissing. It nags at your thoughts
        and drags your mind through a sea of pulsing pain. You can feel it in
        your bones, through your body, and under your skin. The room itself
        contains a lone nightstand with a lantern atop it alongside several
        objects -- a green colored card, and a matchbook. Behind the noise, deep
        in its depths, you think you can make out a voice.
        <br />
        <br />
        "Light... Understanding... Door... Green... Weakness... Silent...
        Dark..."
        <br />
        <br />
        The voice repeats these phrases over and over.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Noise_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Noise_Obj.route);
      }}
    >
      <img src={Noise_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Noise_Obj = {
  name: "Noise",
  room: Noise,
  entry: Entryway,
  route: "/halloween2022/noise",
  exits: 1,
  prob: 5,
  image: doorUrl,
};

export default Noise;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "optionOne":
          props.output([0, option]);
          break;
        case "optionTwo":
          props.output([0, option]);
          break;
        case "optionThree":
          props.output([0, option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        With so few options, you try to put what's available to use.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="optionOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Burn the Card
        </button>

        <button
          className="inputSubmit"
          type="submit"
          value="optionTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Turn off the Lantern
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const OptionOneView = (props) => {
  let navigate = useNavigate();
  changeSong("");
  return (
    <div>
      <div className="roomText">
        You strike the match, careful to keep the flame alive as you light the
        green card on fire. As you bring the flame closer to the paper, you see
        a light off to your right, growing in intensity. As the card catches
        fire, the light grows closer... the light looks to be... fire! Coming
        fast! Before anything can be done about it, the flames overwhelm you
        with the intense sense of heat only do die out, leaving you in a blank
        space of pure whiteness.{" "}
        <b>The overwhelming noise stops, leaving you in deafening silence.</b> A
        black outlined rectangle appears behind you.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(props.rooms[0].route);
          }}
        >
          Walk Through the Rectangle
        </button>
      </div>
    </div>
  );
};

//input rooms
const OptionTwoView = (props) => {
  let navigate = useNavigate();
  changeSong("");
  return (
    <div>
      <div className="roomText">
        You turn the dial of the lantern and the light goes dim, the sound dying
        with it until the sound stops altogether. There you stand with nothing
        but a dim light and an empty room, alone with your thoughts, which seem
        to scream in your head.{" "}
        <b>The overwhelming noise stops, leaving you in deafening silence.</b> A
        wind blows past you, the table fades away, and the room itself speeds
        far, far away into the distance. The room snaps back into reality, only,
        it is a different area of the castle.
      </div>
      <div className="nextHolder">
        <button
          className="inputSubmit"
          onClick={() => {
            navigate(props.rooms[0].route);
          }}
        >
          Look around and figure out where you are
        </button>
      </div>
    </div>
  );
};
