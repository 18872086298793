import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GetRoomOfType,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://www.carls-fallout-4-guide.com/gamepictures/nuka-world/quests/the-gauntlet/three-doors.jpg";
const doorUrl = door;

var nextRooms;

const Riddles = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Riddles_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Riddles_Obj);
  }

  const parseInput = (input) => {
    switch (input[0]) {
      case "leftDoor":
        changeDisplay("leftDoor");
        break;
      case "middleDoor":
        changeDisplay("middleDoor");
        break;
      case "rightDoor":
        changeDisplay("rightDoor");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "leftDoor":
        component = <LeftDoor output={parseInput} />;
        break;
      case "middleDoor":
        component = <MiddleDoor output={parseInput} />;
        break;
      case "rightDoor":
        component = <RightDoor rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Riddles</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You enter a room with three doors. Each doors has a label on it. The far
        left door says "
        {GlobalVars.depth % 2 == 0
          ? "Behind this door lies a death by dismemberment at the hands of a thousand tendrils."
          : "The door on the right is Evil"}
        " the middle door says "
        {GlobalVars.depth % 2 == 0
          ? "Behind this door lies the crushing death of a black hole."
          : "This door will kill you."}
        " The far right door says "
        {GlobalVars.depth % 2 == 0
          ? "Behind this door lies a death at the jaws of a dozen starving beasts."
          : "The door on the left is a liar."}
        "
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Riddles_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Riddles_Obj.route);
      }}
    >
      <img src={Riddles_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Riddles_Obj = {
  name: "Riddles",
  room: Riddles,
  entry: Entryway,
  route: "/halloween2022/riddles",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Riddles;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      playOneShot(doorSfx);
      props.output([option]);
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">Choose one of the three doors to go through.</p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="leftDoor"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Enter the left door
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="middleDoor"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Enter the middle door
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="rightDoor"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Enter the right door
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const LeftDoor = (props) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          You open the door to a multitude of black tendrils moving and
          squirming about. One long tentacle reaches out to you and plucks you
          from where you stand. The tentacles burn your skin as they grip you
          tightly.
          <b>
            Roleplay your demise as each of your limbs are pulled off one by
            one.
          </b>
        </div>

        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2022/death");
          }}
        >
          Click this if you are Dead
        </button>
      </div>
    </div>
  );
};

//input: function
//output: level
const MiddleDoor = (props) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          You enter a room with a multitude of stars and a bright ring of light
          circling around a huge black sphere. You are quickly sucked into the
          black hole.{" "}
          <b>
            Roleplay your demise as you are turned into spaghetti at the event
            horizon.
          </b>
        </div>

        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2022/death");
          }}
        >
          Click this if you are Dead
        </button>
      </div>
    </div>
  );
};

//input rooms
const RightDoor = (props) => {
  let keydoor = GetRoomOfType("key");
  return (
    <div>
      <div className="roomText">
        You enter a room with the bones of a dozen beasts littering the ground,
        long dead from starvation. You may proceed to the next room.
      </div>
      <DemonScript />
      <div className="nextHolder">{keydoor.entry()}</div>
    </div>
  );
};
