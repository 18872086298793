import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  getDamage,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/originals/c9/f3/8b/c9f38b02081c659f9c29cd90513f4e72.jpg";
const doorUrl = door;

var nextRooms;

const Holes = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Holes_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Holes_Obj);
  }

  const parseInput = (input) => {
    changeDisplay("pass");
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Holes</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You enter a room of rough stone pockmarked with holes. Holes in the
        floor, ceiling, and walls. You can't see more than a few inches deep
        into any of the holes. There are no exits that you can see. Just holes.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Holes_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Holes_Obj.route);
      }}
    >
      <img src={Holes_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Holes_Obj = {
  name: "Holes",
  room: Holes,
  entry: Entryway,
  route: "/halloween2022/holes",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Holes;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [progress, setProgress] = useState({ iterations: 0, progress: 0 });
  const [bite, setBite] = useState(false);
  const [totalRequired] = useState(Math.random() * 40 + 20);
  const navigate = useNavigate();

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    const difficultyTier = 2;
    let totalGained = 1;
    if (progress.iterations % 5 == 0 && progress.progress > 0) {
      let difficulty = getDifficulty(difficultyTier);
      if (input >= difficulty + 5) {
        totalGained += 10;
        setErrText(
          "You have a clear recollection of the holes you've touched and gain a great amount of progress."
        );
      } else if (input >= difficulty) {
        totalGained += 5;
        setErrText(
          "You recall which holes you've touched and gain additional progress."
        );
      } else if (input >= difficulty - 5) {
        totalGained -= 5;
        setErrText(
          "You forget some of the holes you've touched and lose some progress."
        );
      } else {
        totalGained -= 10;
        setErrText("You forget much and lose a lot of progress.");
      }
    }
    setProgress({
      iterations: progress.iterations + 1,
      progress: progress.progress + totalGained,
    });
    if (Math.random() * 100 >= 95 && progress.iterations > 0) {
      setBite(true);
    } else {
      setBite(false);
    }
    if (progress.progress >= totalRequired) {
      props.output([totalRequired]);
    }
  };

  return (
    <div>
      <p className="roomText">
        You don't see any way to move forward. You have no choice but to go
        questing in the holes for anything that can help you progress forward.
        <br />
        <br />
        <b>Holes Checked: {progress.progress}</b>
        {bite ? (
          <span>
            <br />
            You take <b style={{ color: "red" }}>1d4 damage</b> as you put your
            hand into a hole and something bites you
          </span>
        ) : (
          ""
        )}
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        {progress.iterations % 5 == 0 && progress.iterations > 0 ? (
          <label className="inputLabel">
            You're struggling to recall which holes you've tried. Roll a history
            (mind) check to remember which holes you've already tried. <br />
            Natural 1: input 0 <br />
            Natural 20: add 1d6 to your total and input the final total into the
            box.
          </label>
        ) : (
          ""
        )}
        {progress.iterations % 5 == 0 && progress.iterations > 0 ? (
          <input
            className="inputField"
            type="number"
            min="-5"
            max="60"
            required
            onChange={(e) => {
              setInput(e.target.value);
            }}
          ></input>
        ) : (
          ""
        )}
        <button className="inputSubmit" type="submit">
          Check a few holes
        </button>
        <label className="inputLabel">{errText}</label>
      </form>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2022/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You put your hand into a hole and finally hear a click.
          A section of wall slides away to reveal two paths.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
