import fetchCharData from "../GoogleAPI/GetChars.js";
import Select from "react-select";
import useSWR from "swr";
import { useState, useEffect } from "react";
import { useRef } from "react";

// Gets selectedChars to exist if it doesn't on first load.
if (localStorage.getItem("selectedChars") === null) {
	localStorage.setItem("selectedChars", "[]");
}

const styles = {};

export default function CharSelectMenu() {
	const [selectedOptions, setSelectedOptions] = useState(
		JSON.parse(localStorage.getItem("selectedChars"))
	); // This is the list shoved into localStorage as well as being returned.
	const { data, error, isLoading } = useSWR("q", fetchCharData);

	const charData = useRef();
	const [charOptions, setCharOptions] = useState([]);

	// Keep localStorage updated.
	useEffect(() => {
		localStorage.setItem("selectedChars", JSON.stringify(selectedOptions));
	}, [selectedOptions]);

	//initialize the chardata
	useEffect(() => {
		if (data) {
			charData.current = data;
			let tempOptions = [];
			for (let i in charData.current.charList) {
				tempOptions.push({
					value: `${charData.current.charList[i]}`,
					label: `${charData.current.charList[i]}`,
				});
			}
			setCharOptions(tempOptions);
		}
	}, [data]);

	if (error) return [<p>Failed to load Character Data.</p>, undefined, selectedOptions];
	if (isLoading) return [<p>Loading...</p>, undefined, selectedOptions];

	// WHEN USING THIS COMPONENT, CALL IT AT THE TOP AND RENDER IT WITH CharSelectMenu[0].
	// This is so that CharSelectMenu[1] can be the values currently selected.
	//Using just <div>{CharSelectMenu}</div> won't work with how I'm setting this up.
	return [
		// Render the data as a select menu.
		<Select
			options={charOptions}
			isMulti
			styles={styles}
			className="char-select-menu"
			onChange={setSelectedOptions}
			defaultValue={JSON.parse(localStorage.getItem("selectedChars"))}
		/>,
		charData.current,
		selectedOptions,
	];
}
