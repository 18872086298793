import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/Disorienting.mp3";
import banner from "../../res/rooms/COG_Image__00006_.png";
import {
  CAPDEPTH,
  changeSong,
  GetNextRooms,
  GetRoomObjectByName,
  GlobalContext,
  playOneShot,
} from "../room_handler";
import { save } from "../../functions/save";
import { EntryPercResults, Perception } from "../../components/perception";

const bannerUrl = banner;
const doorUrl = banner;

const Hallway = () => {
  const InstanceVars = useContext(GlobalContext);
  const [nextRooms, changeNextRooms] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (InstanceVars.current.nextRooms) {
      loadVals();
    } else {
      let nextRoomTemp = GetNextRooms(
        Hallway_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Hallway_Obj.exits) + 1
      );
      save(InstanceVars.current, Hallway_Obj, nextRoomTemp);
      InstanceVars.current.score.depth += 100;
      changeNextRooms(nextRoomTemp);
    }
  }, []);

  const loadVals = () => {
    let nextRoomTemp = [];
    InstanceVars.current.nextRooms.forEach((room) => {
      let tempRoom = GetRoomObjectByName(room.name);
      if (tempRoom) nextRoomTemp.push(tempRoom);
    });
    if (nextRoomTemp.length > 0) {
      delete InstanceVars.current.nextRooms;
      delete InstanceVars.current.currentRoom;
    } else {
      nextRoomTemp = GetNextRooms(
        Hallway_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Hallway_Obj.exits)
      );
    }
    changeNextRooms(nextRoomTemp);
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Crossroads</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        Your footsteps echo in the long hallway and leave prints in the
        undisturbed dust blanketing the floor. Dull torches burn in sconces
        along the walls, sustaining their sickly flame off the residual magic in
        the air. You come to a crossroads with multiple different paths.
      </p>
      <p className="roomText">Choose a path. Choose wisely.</p>
      <Perception />
      <div className="nextHolder">
        {nextRooms.map((room, i) => {
          return room.entry(i, navigate);
        })}
      </div>
    </div>
  );
};

const Entryway = (i = 0, navigate) => {
  return (
    <button
      key={Hallway_Obj.name + toString(i)}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Hallway_Obj.route);
      }}
    >
      <img src={Hallway_Obj.image} />
      <span className="entryText">A dark hallway stretches before you.</span>
      <EntryPercResults
        observation={{
          tier: 3,
          text: "A well trodden path.",
        }}
        spirituality={{
          tier: 4,
          text: "You sense the safety of this passage.",
        }}
      />
    </button>
  );
};

export var Hallway_Obj = {
  name: "Hallway",
  room: Hallway,
  entry: Entryway,
  route: "/CityOfGold/hallway",
  exits: 5,
  prob: 5,
  image: doorUrl,
  faction: "none",
};

export default Hallway;
