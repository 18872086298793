import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/564x/ed/3b/e1/ed3be1496759fc9c0cbe1ad8ab26eda2.jpg";
const doorUrl = door;

var nextRooms;

const Catacombs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    nextRooms = GetNextRooms(Catacombs_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Catacombs_Obj);
  }

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Catacombs</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        The air is still here. More still than you thought air could be. It even
        seems to resist sound as your foot falls on cobbled stone. No, not
        cobbled stone. Skulls. Skulls packed tight together on all faces of the
        long corridor. Dirt and mortar squeezes its way through the seams
        between the skulls. Whispers twist the air around you as the skulls
        judge you from their hollow eyes. At the end of the hallway lays a
        rectangular island, atop it, what looks to be a casket. As you approach
        the casket, you find a corpse, twisted and bruised. Its face is
        famillair to you. It resembles that of your most treasured loved one.
        Half of their face has decayed, the other half, pristine. As you get
        closer, and perhaps touch the corpse, they wake up, their one good eye
        opens as they turn their head to you speaking in a raspy tone.
        <br />
        <br />
        "You let me die. You could have stopped this. Why did you have to exsist
        in my life? Why did you abandon me?" Wails of voices cry out as skulls
        begin to come to life, replaced by a great many strange and horrific
        faces.
      </p>
      <p className="roomText">
        A door appears on the other side of the casket. As you move toward the
        door, your loved one begs for you to return. Begs for you not to abandon
        them. If you try to bring them with you, they will begin to crumble in
        your arms. If you choose to remain with them, you will eventually die of
        starvation. You must press forward and leave them behind. They will
        scream and beg and cry as you leave.
      </p>
      <DemonScript />
      <div className="nextHolder">
        {nextRooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Catacombs_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Catacombs_Obj.route);
      }}
    >
      <img src={Catacombs_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Catacombs_Obj = {
  name: "Catacombs",
  room: Catacombs,
  entry: Entryway,
  route: "/halloween2022/catacombs",
  exits: 1,
  prob: 5,
  image: doorUrl,
};

export default Catacombs;
