import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/metaldoor.jpg";
import doorSfx from "../../res/sfx/Metal Door Creak Open 1_2.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/564x/e7/81/bb/e781bbf50301bc136f9fad714366259d.jpg";
const doorUrl = door;

var nextRooms;

const Gargoyle = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Gargoyle_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Gargoyle_Obj);
  }

  const parseInput = (input) => {
    let optionOneTier = 3;
    let optionTwoTier = 5;
    let optionThreeTier = 2;
    let difficulty;
    switch (input[1]) {
      case "stealth":
        difficulty = getDifficulty(optionOneTier);
        if (input[0] >= difficulty) {
          changeDisplay("optionOnePass");
        } else {
          changeDisplay("optionOneFail");
        }
        break;
      case "athletics":
        difficulty = getDifficulty(optionTwoTier);
        if (input[0] >= difficulty) {
          changeDisplay("optionTwoPass");
        } else {
          changeDisplay("optionTwoFail");
        }
        break;
      case "approach":
        changeDisplay("approach");
        break;
      case "approachResult":
        difficulty = getDifficulty(optionThreeTier);
        if (input[0] >= difficulty) {
          changeDisplay("optionThreePass");
        } else {
          changeDisplay("optionThreeFail");
        }
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "approach":
        component = <Approach output={parseInput} />;
        break;
      case "optionOnePass":
        component = <PassOptionOneView rooms={nextRooms} />;
        break;
      case "optionOneFail":
        component = <FailOptionOneView rooms={nextRooms} />;
        break;
      case "optionTwoPass":
        component = <PassOptionTwoView rooms={nextRooms} />;
        break;
      case "optionTwoFail":
        component = <FailOptionTwoView rooms={nextRooms} />;
        break;
      case "optionThreePass":
        component = <PassOptionThreeView rooms={nextRooms} />;
        break;
      case "optionThreeFail":
        component = <FailOptionThreeView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Gargoyle</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You enter a large cave of deep grey stone. A wide opening at the top
        shines moonlight into the cavern as the only source of light. Loose
        rocks and boulders adorn long crumbled ruins as a part of the castle
        fell into this cavern. In defiance of the destruction of time, Five
        pillers stand tall, one in each corner and one in the center of the
        room. Adorning these pillars are the visages of dozens of humans wailing
        and crying. Unclear if it's just beautiful stonework or a foretelling of
        the future, your eyes continue to look around. Atop each pillar stands a
        statue, hunched over. They appear to be Gargoyles, complete with sharp
        teeth, tongues hissing, and great stone wings on their backs. The exit
        can be seen on the far side of the room. You stand at the mouth of a
        delapidated hallway high above the cavern floor, but you can see a path
        leading down.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Gargoyle_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Gargoyle_Obj.route);
      }}
    >
      <img src={Gargoyle_Obj.image} />
      <span className="entryText">
        A heavy iron door leading into the unknown
      </span>
    </button>
  );
};

export var Gargoyle_Obj = {
  name: "Gargoyle",
  room: Gargoyle,
  entry: Entryway,
  route: "/halloween2022/gargoyle",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Gargoyle;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "" || (input == "" && input2 == "")) {
      setErrText("");
      switch (option) {
        case "stealth":
          props.output([input, option]);
          break;
        case "athletics":
          props.output([input2, option]);
          break;
        case "approach":
          props.output(["0", option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        You can choose one of three ways to handle this room. You can use the
        crumbled structure to sneak, thinking it best to avoid the pillars. You
        can use athletics to make a break for it, or you can approach the
        pillars.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          If you use stealth, input the result of your stealth (dex) check below
          and submit.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button
          className="inputSubmit"
          type="submit"
          value="stealth"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Perform Stealth Check
        </button>
        <label className="inputLabel">
          If you use athletics, input the result of your athletics (body) check
          below and submit.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <button
          className="inputSubmit"
          type="submit"
          value="athletics"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Perform Athletics Check
        </button>
        <label className="inputLabel">
          If you approach the pillars, click the button below.
        </label>
        <button
          className="inputSubmit"
          type="submit"
          value="approach"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Approach the Pillars
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

const Approach = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input)) {
      setErrText("");
      props.output([input, "approachResult"]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        You approach the pillars. The gargoyles each turn their heads to gaze at
        you. Their stone necks grate as they swivel, tracking you as you walk.
        One gargoyle speaks, "Find the Pillar, once your own! Play the game or
        become as stone!"
        <br />
        <br />
        Perplexed, you look at each pillar. You can see there is a complex set
        of grooves in the floor as well as a button in the shape of a head on
        each pillar. Each groove winds around the floor in complex loops,
        intertwining with grooves going to each other pillar. Only one groove
        leaves the first pillar. If you've been here before, the grooves are
        different from the last time you saw it. <br />
        <br />
        "Hurry hurry, you're out of time! Take too long, the bell will chime!" A
        gargoyle says. <br />
        <br /> You have to play the game. Make an academics (mind) check to
        follow the groove to the correct pillar before the time runs out.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input the results of your academics (mind) check below.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassOptionOneView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You decide to sneak, using the crumbled ruins and
          boulders as cover from the Gargoyles. Stone grates stone as one
          gargoyle begins moving, looking about the room. Even still, you
          succeed. You make it to the far end of the room without any gargoyle
          seeing you. Three doors stand available for you.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailOptionOneView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> You decide to sneak, using the crumbled ruins and
          boulders as cover from the Gargoyles. Stone grates stone as one
          gargoyle begins moving, looking about the room. It lays eyes on your
          hiding spot. Great wings flap as it slowly comes to check out your
          location. As it gets closer, your hiding spot becomes clear. It sees
          you and screeches, "Become as Eternal and True like stone! Run for
          freedom once called your own!" <br />
          <br /> You have no chance to make it to the doors. The gargoyle
          cackles if you try anyway. It swoops down and grabs you flying you to
          its pillar. It presses you against the cold stone, and you feel
          yourself being claimed by it. The stone grows over you. You scream as
          your skin begins burning as it hardens. You can feel yourself turning
          to stone as you sink further and further into the pillar.{" "}
          <b>
            The stone reaches your face and flows down your throat, hardening
            the soft tissues as you struggle to breath. Your vision closes off
            as your eyes turn to stone, and moments before you fully become
            stone, you suffocate to death.
          </b>
        </div>
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2022/death");
          }}
        >
          Click this if you are Dead
        </button>
      </div>
    </div>
  );
};

//input rooms
const PassOptionTwoView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You decide to make a break for it, sprinting down the
          slope and making a run for the doors on the opposite side of the room.
          All five gargoyles scream in delight as their great wings flap and
          they take flight, chasing after you. You run, jump, and climb as the
          gargoyles swoop down. You pick the closest door to you and barely make
          it in before the gargoyles thump into the door, screaming behind it.
          Which door did you go through?
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailOptionTwoView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> You decide to make a break for it, sprinting down the
          slope and making a run for the doors on the opposite side of the room.
          All five gargoyles scream in delight as their great wings flap and
          they take flight, chasing after you. You run, jump, and climb as the
          gargoyles swoop down. You almost make it, but two gargoyles snatch you
          up inches from the doorhandle. They fly you to the stone pillar.
          Struggle as you might, there is no resisting. They press you against
          the cold stone, and you feel yourself being claimed by it. The stone
          grows over you. You scream as your skin begins burning as it hardens.
          You can feel yourself turning to stone as you sink further and further
          into the pillar.{" "}
          <b>
            The stone reaches your face and flows down your throat, hardening
            the soft tissues as you struggle to breath. Your vision closes off
            as your eyes turn to stone, and moments before you fully become
            stone, you suffocate to death.
          </b>
        </div>
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2022/death");
          }}
        >
          Click this if you are Dead
        </button>
      </div>
    </div>
  );
};

//input rooms
const PassOptionThreeView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You follow the line carefully as it winds around. You
          know there's a clock but you have no idea how much time you have. With
          the pressure on, you follow the line, perhaps slipping once or twice,
          but eventually you are able to find the pillar it winds to and you
          press the button in the shape of a head. The gargoyles each let out an
          annoyed cry, "Press the stone, pass the trial. Leave you must, for
          your own survival." They point the way to the three doors.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailOptionThreeView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> You follow the line carefully as it winds around. You
          know there's a clock but you have no idea how much time you have. With
          the pressure on, you follow the line, but you get confused and forget
          which line you're following. You start over, but there isn't any time,
          and as the gargoyles start cackling, you know your mistake was fatal.
          You might lunge for a button at random, but it's too late. One of the
          gargoyles swoops down and grabs you. Struggle as you might, there is
          no resisting. It presses you against the cold stone of its pillar, and
          you feel yourself being claimed by it. The stone grows over you. You
          scream as your skin begins burning as it hardens. You can feel
          yourself turning to stone as you sink further and further into the
          pillar.{" "}
          <b>
            The stone reaches your face and flows down your throat, hardening
            the soft tissues as you struggle to breath. Your vision closes off
            as your eyes turn to stone, and moments before you fully become
            stone, you suffocate to death.
          </b>
        </div>
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2022/death");
          }}
        >
          Click this if you are Dead
        </button>
      </div>
    </div>
  );
};
