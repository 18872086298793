import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  getDamage,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/564x/c6/39/8b/c6398b41bc2ac191955bf78900219a57.jpg";
const doorUrl = door;

var nextRooms;

const Snakes = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Snakes_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Snakes_Obj);
  }

  const parseInput = (input) => {
    let rollTierOne = 3;
    let rollTierTwo = 4;
    let successes = 0;
    let difficultyOne = getDifficulty(rollTierOne);
    let difficultyTwo = getDifficulty(rollTierTwo);
    for (let i = 1; i < input.length; i++) {
      if (input[i] >= difficultyOne && input[0] == "rollOne") {
        successes += 1;
      } else if (input[i] >= difficultyTwo && input[0] == "rollTwo") {
        successes += 1;
      }
    }
    if (successes >= 3 && input[0] == "rollOne") {
      changeDisplay("pass");
    } else if (input[0] == "rollOne") {
      changeDisplay("fail");
    } else if (successes >= 1 && input[0] == "rollTwo") {
      changeDisplay("passTwo");
    } else {
      changeDisplay("failTwo");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView output={parseInput} />;
        break;
      case "passTwo":
        component = <PassTwo rooms={nextRooms} />;
        break;
      case "failTwo":
        component = <FailTwo rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Snakes</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        The sound of air escaping slowly whispers through the air. Torches lit
        on the wall reveal a blank stone room. You do notice anything else until
        you look up. Many holes mar the cieling above you. Dark branches and
        vines wiggle their way out of the holes. Something is moving up there,
        its long and writhing. It takes you a moment to realize that sound isn't
        air escaping, it's the sound of snakes. The room shakes and many of the
        slithery creatures fall to the ground, some next to you, some on you. As
        the shaking continues, even more fall from the holes until there's a
        downpour of snakes, covering the floor and causing several agitated
        snakes to land on your shoulders and head.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Snakes_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Snakes_Obj.route);
      }}
    >
      <img src={Snakes_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Snakes_Obj = {
  name: "Snakes",
  room: Snakes,
  entry: Entryway,
  route: "/halloween2022/snakes",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Snakes;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output(["rollOne", input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        Perform three animal handling checks to prevent yourself from being
        bitten and reach the other side of the room unharmed.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You slowly move among the snakes, watching carefully as
          you approach the exit on the opposite side of the room. What are you,
          a snake charmer?
        </div>
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input)) {
      setErrText("");
      props.output(["rollTwo", input]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  let damage = getDamage(["1", "2", "1d4", "1d6", "1d8"]);

  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> You've been bitten! This can't be good.{" "}
          <b>Take {damage} damage</b> and immediately make a non-magical healing
          check to counteract the venom!
        </div>
      </div>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your result below. <br />
          Natural 1: input 0. <br />
          Natural 20: Add 1d6 to the roll.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassTwo = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You manage to get the venom out by cutting yourself and
          sucking the venom out.
          <br />
          <br />
          Despite the bite, you manage to make it to the other side. Two paths
          lie before you.
        </div>
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailTwo = (props) => {
  const navigate = useNavigate();
  let damage = getDamage(["1", "2", "1d4", "1d6", "1d8"]);
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>Failed</b> You can feel the poison coursing through your veins as
          you aren't quick enough to get it out. For the next 1d4 rooms, take
          {damage} damage each time you enter a new room. Until you leave the
          castle, you suffer -1d6 on all rolls. <br />
          <br />
          Despite the bite, you manage to make it to the other side. Two paths
          lie before you.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2022/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
