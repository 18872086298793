import "./player.css";

const Player = (props) => {
  let temp = props.song;
  if (!temp) {
    temp = "";
  }
  return (
    <div className="musicPlayer">
      <audio
        id="music"
        controls
        autoPlay={!JSON.parse(localStorage.getItem("disableAutoPlay"))}
        loop
      >
        <source src={temp} type="audio/mpeg" />
        <source src={temp} type="audio/wav" />
        Your browser does not support audio
      </audio>
    </div>
  );
};

export const OneShot = (props) => {
  return (
    <div className="musicPlayer">
      <audio
        id="oneShot"
        autoPlay={!JSON.parse(localStorage.getItem("disableAutoPlay"))}
      >
        <source src={""} type="audio/mpeg" />
        <source src={""} type="audio/wav" />
        Your browser does not support audio
      </audio>
    </div>
  );
};

export default Player;
