import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  getDamage,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = "https://dnd-wiki.org/w/images/5/5f/Swarm_of_Rats.png";
const doorUrl = door;

var nextRooms;

const Rodents = () => {
  const [display, changeDisplay] = useState("pre");
  const [pass, setPass] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Rodents_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Rodents_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    setPass(successes);
    changeDisplay("pass");
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView successes={pass} rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Rodents</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You enter a room with a number of stone platforms interspersed above a
        writhing mass of black chittering. Rats--Thousands of rats chittering
        and crawling over one another to reach you. They aren't climbing high
        enough to reach your platform, but the platforms are too far apart to
        jump across.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Rodents_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Rodents_Obj.route);
      }}
    >
      <img src={Rodents_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Rodents_Obj = {
  name: "Rodents",
  room: Rodents,
  entry: Entryway,
  route: "/halloween2022/rodents",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Rodents;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        You must move from one platform to another. If you try to make a break
        for it, its a certainty that the rats will get you. You have to use what
        you know about rats and animals to distract them somehow, or make
        yourself seem a less tasty prospect. Roll three animal handling (spirit)
        checks and put the results in the box below.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  const navigate = useNavigate();
  let damage = getDamage(["d6", "d8", "d10", "d12", "d12"]);
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          After running from platform to platform, you make it to the other
          side. You take{" "}
          {3 - props.successes > 0 ? (
            <b style={{ color: "red" }}>
              {3 - props.successes}
              {damage}
            </b>
          ) : (
            <b style={{ color: "green" }}>No</b>
          )}{" "}
          Damage! Roleplay your success or death. If you are still alive, you
          have two doors before you and can proceed.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2022/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
