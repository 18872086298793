import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Carousel.wav";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  getDamage,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/736x/a1/cf/02/a1cf0284a252c95116d6c1b05fe260d9.jpg";
const doorUrl = door;

var nextRooms;

const FunHouse = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    nextRooms = GetNextRooms(FunHouse_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(FunHouse_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 1;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 1) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>FunHouse</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You enter a large ball room. Warm lights wash over a sea of men and
        women dressed in black and red. Butlers carry platters of drinks.
        Patrons laugh consistantly. In fact, at any given moment someone is
        laughing--And you notice everyone is laughing, looking at you and
        laughing. They are uncontrollable in their laughter. The guests begin to
        double over laughing violently, spilling over their drinks, falling onto
        the floor or stumbling. Some go so far as to stand on tables or chairs
        and laugh. They seemed in a perpetual state of unending laughter.
        Suddenly something seems a bit funny to you...
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={FunHouse_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(FunHouse_Obj.route);
      }}
    >
      <img src={FunHouse_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var FunHouse_Obj = {
  name: "FunHouse",
  room: FunHouse,
  entry: Entryway,
  route: "/halloween2022/funhouse",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default FunHouse;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        A wave of laughter is washing over you. Roll a Mental Acquity check to
        resist!
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your roll results below. <br />
          Natural 1: input 0. <br />
          Natural 20: roll an extra 1d6 and add it to your roll.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You are able to resist laughing, though you may still
          find it amusing to watch others laughing themselves. Theres an exit on
          the opposite side calling your name.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  let damage = getDamage(["1", "1", "1", "2", "3"]);
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> You cant help yourself, no one even said anything but
          even that seemed hilarious. You want to stop, but you cant! You take{" "}
          <b>{damage} Damage</b> from laughing uncontrollably Stumbling towards
          the exit on the otherside.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2022/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
