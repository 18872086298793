import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/collapse.png";
import doorSfx from "../../res/sfx/Torch Swing 06.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  getDamage,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = door;
const doorUrl = door;

var nextRooms;

const Hole = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Hole_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Hole_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 3;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes > 0) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Hole</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        A deep hole in the floor. It's pitch black and you aren't sure how deep
        it goes.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Hole_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Hole_Obj.route);
      }}
    >
      <img src={Hole_Obj.image} />
      <span className="entryText">A deep hole leading into the unknown</span>
    </button>
  );
};

export var Hole_Obj = {
  name: "Hole",
  room: Hole,
  entry: Entryway,
  route: "/halloween2022/hole",
  exits: 1,
  prob: 5,
  image: doorUrl,
};

export default Hole;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input)) {
      setErrText("");
      props.output([input]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        You jump down the hole. You're not sure how far down it goes, so you'll
        need to make an Agility (Dex) check to roll and avoid damage when you
        land.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your results below. <br />
          Natural 1: input 0. <br />
          Natural 20: Add 1d6 to the roll.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
      <DemonScript />
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  const navigate = useNavigate();
  const submitHandler = () => {
    navigate(props.rooms[0].route);
  };
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You take no damage as you land in the next room.
        </div>
      </div>
      <button className="inputSubmit" onClick={submitHandler}>
        Stand and figure out where you are.
      </button>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  const submitHandler = () => {
    navigate(props.rooms[0].route);
  };
  let damage = getDamage(["2d4", "1d10", "2d6", "2d8", "3d6"]);
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> You fall further than you were expecting and land
          sharply. Take {damage} damage. If you're still alive, continue onward.
        </div>
      </div>
      <button className="inputSubmit" onClick={submitHandler}>
        Stand and figure out where you are.
      </button>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2022/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
