import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/rooms/COG_Image__00283_.png";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import banner from "../../res/rooms/COG_Image__00264_.png";
import song from "../../res/music/Disorienting.mp3";
import {
  CAPDEPTH,
  changeSong,
  GetNextRooms,
  GetRoomObjectByName,
  GlobalContext,
  playOneShot,
} from "../room_handler";
import RandomRoomTreasure from "../../components/Loot";
import { save } from "../../functions/save";
import { EntryPercResults, Perception } from "../../components/perception";

const bannerUrl = banner;
const doorUrl = door;

const Small_Hoard = () => {
  const InstanceVars = useContext(GlobalContext);
  const [nextRooms, changeNextRooms] = useState([]);
  const [toggleUnveil, changeToggleUnveil] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (InstanceVars.current.nextRooms) {
      loadVals();
    } else {
      let nextRoomTemp = GetNextRooms(
        Small_Hoard_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Small_Hoard_Obj.exits)
      );
      save(InstanceVars.current, Small_Hoard_Obj, nextRoomTemp);
      InstanceVars.current.score.depth += 100;
      changeNextRooms(nextRoomTemp);
    }
  }, [InstanceVars]);
  const loadVals = () => {
    let nextRoomTemp = [];
    InstanceVars.current.nextRooms.forEach((room) => {
      let tempRoom = GetRoomObjectByName(room.name);
      if (tempRoom) nextRoomTemp.push(tempRoom);
    });
    if (nextRoomTemp.length > 0) {
      delete InstanceVars.current.nextRooms;
      delete InstanceVars.current.currentRoom;
    } else {
      nextRoomTemp = GetNextRooms(
        Small_Hoard_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Small_Hoard_Obj.exits)
      );
    }
    changeNextRooms(nextRoomTemp);
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Unremarkable Room</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        A small, unadorned room. Completely unremarkable. There is a patch of
        dirt in one corner that appears to have been recently dug out.
      </p>
      {!toggleUnveil ? (
        <>
          <p className="roomText">Would you like to dig out the hole?</p>
          <input
            value="Dig up the treasure"
            type="button"
            className="takeTreasureButton"
            onClick={() => {
              changeToggleUnveil(true);
            }}
          />{" "}
        </>
      ) : (
        <RandomRoomTreasure chance={1} chanceForCurse={0.2} tier={0} />
      )}
      <Perception />
      <div className="nextHolder">
        {nextRooms.map((room, i) => {
          return room.entry(i, navigate);
        })}
      </div>
    </div>
  );
};

const Entryway = (i = 0, navigate) => {
  return (
    <button
      key={Small_Hoard_Obj.name + toString(i)}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Small_Hoard_Obj.route);
      }}
    >
      <img src={Small_Hoard_Obj.image} />
      <span className="entryText">A dark cave leading into the unknown</span>
      <EntryPercResults
        observation={{
          tier: 3,
          text: "The path ahead is fairly undisturbed.",
        }}
        spirituality={{
          tier: 4,
          text: "You sense residual greed from a creature that recently passed this way.",
        }}
      />
    </button>
  );
};

export var Small_Hoard_Obj = {
  name: "Small_Hoard",
  room: Small_Hoard,
  entry: Entryway,
  route: "/CityOfGold/small_hoard",
  exits: 1,
  prob: 20,
  image: doorUrl,
};

export default Small_Hoard;
