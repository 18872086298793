import { useContext, useEffect, useState } from "react";
import { CAPDEPTH, GlobalContext } from "../rooms/room_handler";
import "./Loot.css";

const ALLTREASURE = require("../data/treasure.json");

const getTreasure = (InstanceVars, tier = 0, chanceForCurse = 0.2) => {
  let treasureMetric = InstanceVars.current.treasure_rating;

  //set up lower and upper bounds for treasure value
  let lowerBound = 1;
  let upperBound = 5;
  //set up a multiplier based on treasure rating.
  switch (treasureMetric) {
    case 0:
      upperBound = 10;
      break;
    case 1:
      upperBound = 15;
      break;
    case 2:
      lowerBound = 1.5;
      upperBound = 20;
      break;
    case 3:
      lowerBound = 2;
      upperBound = 25;
      break;
    case 4:
      lowerBound = 3;
      upperBound = 30;
      break;
    default:
      lowerBound = 1;
      upperBound = 5;
  }

  if (tier > 5 || tier < 0) {
    throw "Tier cannot be higher than five or lower than zero";
  }
  let diffCurve = 6;
  switch (tier) {
    case 0:
      lowerBound *= 1;
      upperBound *= 0.4;
      break;
    case 1:
      lowerBound *= 2;
      upperBound *= 0.7;
      break;
    case 2:
      lowerBound *= 3;
      upperBound *= 1;
      break;
    case 3:
      lowerBound *= 4;
      upperBound *= 1.3;
      break;
    case 4:
      lowerBound *= 5;
      upperBound *= 1.6;
      break;
    case 5:
      lowerBound *= 6;
      upperBound *= 2;
      break;
    default:
  }

  //create a multiplier based on the number of characters in the group.
  let playerMult = Math.min(
    20,
    Math.max(
      1,
      8 * Math.pow((InstanceVars.current.characters.length - 1) / 10, 1.4) + 1
    )
  );

  lowerBound *= playerMult;
  upperBound *= playerMult;

  //add a modifier if there are any applicable mods
  let [modMult, modCurseMult, modItemNumMult, modBoundMult] =
    getTreasureMultipliers(InstanceVars);

  upperBound *= modBoundMult;
  lowerBound *= modBoundMult;

  //create a random curve for difficulty based on depth.
  diffCurve = Math.max(
    diffCurve - diffCurve * (0.9 / CAPDEPTH) * InstanceVars.current.depth,
    0.1
  );
  //create a random multiplier based on the curve in the previous step
  let randomMult = Math.pow(Math.random(), diffCurve);

  //calculate the random number of points allotted for the treasure.

  let rovingLowerBound =
    lowerBound +
    ((upperBound - lowerBound) / 2) * (InstanceVars.current.depth / CAPDEPTH);
  let rovingUpperBound = rovingLowerBound + (upperBound - lowerBound) / 2;
  let randPoints =
    (rovingLowerBound + (rovingUpperBound - rovingLowerBound) * randomMult) *
    modMult;

  let giveItem = false;
  if (Math.random() < 0.5) giveItem = true;

  let returnItems = [];
  let returnRasps = randPoints;
  if (giveItem) {
    [returnItems, returnRasps] = getRandomItems(
      randPoints,
      rovingUpperBound,
      rovingLowerBound,
      Math.ceil(
        Math.random() * InstanceVars.current.characters.length * modItemNumMult
      ),
      chanceForCurse * modCurseMult
    );
  }

  return [returnItems, returnRasps];
};

const getRandomItems = (
  points,
  upperBound,
  lowerBound,
  numItems = 1,
  chanceForCurse = 0.2
) => {
  //function that fetches random treasure
  let itemsToReturn = [];
  let totalPoints = points;
  let max = upperBound < points ? upperBound : points;
  let itemList = ALLTREASURE.treasures.filter(
    (item) => item.value < max && item.value > lowerBound
  );

  let totalChance = 0;
  itemList.forEach((item) => {
    totalChance += item.dropChance;
    item.totalChance = totalChance;
  });

  itemList.sort((a, b) => {
    return a.totalChance - b.totalChance;
  });

  for (let i = 0; i < numItems; i++) {
    if (totalPoints < max) {
      max = totalPoints;
      itemList = itemList.filter(
        (item) => item.value < max && item.value > lowerBound
      );

      totalChance = 0;
      itemList.forEach((item) => {
        totalChance += item.dropChance;
        item.totalChance = totalChance;
      });

      itemList.sort((a, b) => {
        return a.totalChance - b.totalChance;
      });
    }
    if (itemList.length == 0) break;

    //pick a random item based on the item's chance.
    let itemSelect = Math.random() * totalChance;
    let item = itemList.find((item) => {
      return itemSelect <= item.totalChance;
    });

    if (!item)
      throw "Error in chance calculation! No item at position " + itemSelect;

    let curseItem = Math.random() <= chanceForCurse ? true : false;

    if (curseItem) {
      let curse = getRandomCurse(item);
      if (curse) {
        item.curse = curse;
        totalPoints -= item.value + curse.value;
      } else {
        totalPoints -= item.value;
      }
    } else {
      totalPoints -= item.value;
    }

    itemsToReturn.push(item);
  }
  return [itemsToReturn, totalPoints];
};

export const getRandomCurse = (item) => {
  //function that fetches a random curse
  let validCurses = ALLTREASURE.curses.filter(
    (curse) =>
      curse.value + parseInt(item.value) >= 0 &&
      curse.type.toLowerCase() === item.type.toLowerCase()
  );
  if (validCurses.length === 0) return;
  let totalChance = 0;
  validCurses.forEach((curse) => {
    totalChance += curse.curseChance;
    curse.totalChance = totalChance;
  });

  validCurses.sort((a, b) => {
    return a.totalChance - b.totalChance;
  });

  //pick a random item based on the item's chance.
  let curseSelect = Math.random() * totalChance;
  let curse = validCurses.find((curse) => {
    return curseSelect <= curse.totalChance;
  });

  if (!curse)
    throw "Error in chance calculation! No curse at position " + curseSelect;

  curse.cureConditions = [];
  let allConditions = [...ALLTREASURE.cureConditions];
  for (let i = 0; i < curse.tier; i++) {
    let randIndex = Math.floor(Math.random() * allConditions.length);
    curse.cureConditions.push(allConditions.splice(randIndex, 1)[0]);
  }

  return curse;
};

export const TreasureRoomTreasure = (props) => {
  //component that allows treasure to appear in a treasure room or boss room.
  const [items, setItems] = useState([]);
  const [rasps, setRasps] = useState(0);
  const [raspInput, setRaspInput] = useState(0);
  const [raspError, setRaspError] = useState("");
  const [raspDisabled, setRaspDisabled] = useState(false);
  const [itemError, setItemError] = useState([]);

  const InstanceVars = useContext(GlobalContext);

  useEffect(() => {
    let tier = props.tier ?? 0;
    let [returnItems, returnRasps] = getTreasure(
      InstanceVars,
      tier,
      props.chanceForCurse ?? 0.2
    );
    setItems(returnItems);
    setRasps(Math.floor(returnRasps));
    setItemError(new Array(returnItems.length));
  }, []);

  const takeItemHandler = (e, itemTaken, i) => {
    e.preventDefault();
    InstanceVars.current.treasures_earned.push(itemTaken);
    let errorChange = [...itemError];
    if (itemTaken.cursed) {
      errorChange[i] =
        "This item was cursed! Check the drawer on the left side to see what curse you must deal with!";
    } else {
      errorChange[i] = "Item taken.";
    }
    setItemError(errorChange);
  };

  const takeRaspsHandler = (e, raspsTaken) => {
    e.preventDefault();
    if (raspsTaken > rasps || raspsTaken < 0) {
      setRaspError("Please select a valid number of rasps");
      return;
    }
    InstanceVars.current.rasps_earned += raspsTaken;
    let raspsCursed =
      Math.random() <= (props.chanceForCurse ?? 0.2) ? true : false;
    let raspCurse;
    if (raspsCursed) {
      raspCurse = getRandomCurse({
        value: parseInt(raspsTaken),
        type: "rasps",
      });
      if (!raspCurse) {
        raspsCursed = false;
      }
    }

    if (raspsCursed) {
      InstanceVars.current.extra_curses.push(raspCurse);
      setRaspError(
        "The rasps you took were cursed! You took " +
          raspsTaken +
          " rasps! The effects of this curse are spread evenly through your party. Check the drawer on the left side to see what curse you must deal with!"
      );
      setRaspDisabled(true);
    } else {
      setRaspError("You took " + raspsTaken + " rasps!");
      setRaspDisabled(true);
    }
  };

  return (
    <div className="treasureHolder">
      <span className="treasureHeader">There is treasure in this room!</span>
      {items.length > 0 ? (
        <div className="treasureItemHolder">
          <span className="treasureItemList">Items:</span>
          {items.map((item, i) => {
            return (
              <div className="treasureItemListing">
                <span className="treasureItemDescription">
                  {item.description}
                </span>
                <input
                  type="button"
                  value="Take Item"
                  className="treasureItemTakeButton"
                  disabled={itemError[i] != undefined}
                  onClick={(e) => takeItemHandler(e, item, i)}
                />
                <span className="treasureItemError">{itemError[i]}</span>
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}
      {rasps > 0 ? (
        <div className="treasureRaspsHolder">
          <span className="treasureRaspsDisplay">Rasps Available: {rasps}</span>
          <br />
          <span className="treasureRaspsSelectText">
            How many rasps do you want to take?
          </span>
          <input
            type="number"
            value={raspInput}
            max={rasps}
            className="treasureRaspTakeField"
            disabled={raspDisabled}
            onChange={(e) => setRaspInput(e.target.value)}
          />
          <input
            type="button"
            value="Take Rasps"
            className="treasureRaspsTakeButton"
            disabled={raspDisabled}
            onClick={(e) => takeRaspsHandler(e, parseInt(raspInput))}
          />
          <span className="treasureRaspsErrorText">{raspError}</span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const RandomRoomTreasure = (props) => {
  //componenet that allows treasure to appear in any room at random.
  const [treasurePresent, changeTreasurePresent] = useState(false);
  const InstanceVars = useContext(GlobalContext);

  useEffect(() => {
    let diffCurve = Math.max(
      6 - 6 * (0.9 / CAPDEPTH) * InstanceVars.current.depth,
      0.1
    );
    let select = Math.pow(Math.random(), diffCurve);
    let chance = props.chance ?? 0.05;

    InstanceVars.current.modifiers.forEach((mod) => {
      switch (mod.name) {
        case "Treasure Rich":
          chance *= 2;
          break;
        case "Powerful Treasures":
          chance *= 0.5;
          break;
        default:
          break;
      }
    });

    if (select > 1 - chance) {
      changeTreasurePresent(true);
    }
  }, []);

  if (treasurePresent) {
    return (
      <div>
        <p style={{ color: "green" }}>
          You find treasure in this room! You might find the treasure on a
          corpse, buried, hidden, in a chest of some sort, or something else
          entirely. Roleplay it out!
        </p>
        <TreasureRoomTreasure
          tier={props.tier ?? 0}
          chanceForCurse={props.chanceForCurse ?? 0.2}
        />
      </div>
    );
  } else {
    return "";
  }
};

const getTreasureMultipliers = (InstanceVars) => {
  let mult = 1;
  let curseMult = 1;
  let numMult = 1;
  let boundMult = 1;
  InstanceVars.current.modifiers.forEach((mod) => {
    switch (mod.name) {
      case "Treasure Rich":
        mult += 0.5;
        break;
      case "Powerful Treasures":
        mult -= 0.3;
        numMult -= 0.5;
        boundMult += 0.75;
        break;
      case "Curse Saturated":
        curseMult += 1;
        break;
      case "Cleansed Area":
        curseMult -= 0.5;
        break;
      default:
        break;
    }
  });

  return [mult, curseMult, numMult, boundMult];
};

export default RandomRoomTreasure;
