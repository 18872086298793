import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/metaldoor.jpg";
import doorSfx from "../../res/sfx/Metal Door Creak Open 1_2.wav";
import song from "../../res/music/Haunting Ambience B.mp3";
import banner from "../../res/grin.png";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Blink = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Blink_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Blink_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 3;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 2) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Blink</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        The room is pitch black. As you step in the door slams shut behind you.
        You turn around to reach for its handle, but you are met with nothing
        but air. Nothing can be seen, not even your hands before you. All that
        remains is a faint light, small and flickering, like that of a candle.
        You cant judge how far it is away, but the darkness around it is nearly
        enough to snuff it out. Above the light looks to be a pair of green
        glowing eyes, and a toothy smile along with it. From here its relatively
        small. Till you blink, then somehow it sems bigger..? No...Closer. You
        blink once more, only for the eyes and smile to grow closer. Another
        blink and it appears larger and closer! Stop! Stop Blinking!
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Blink_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Blink_Obj.route);
      }}
    >
      <img src={Blink_Obj.image} />
      <span className="entryText">
        A heavy iron door leading into the unknown
      </span>
    </button>
  );
};

export var Blink_Obj = {
  name: "Blink",
  room: Blink,
  entry: Entryway,
  route: "/halloween2022/blink",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Blink;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        Make three Endurance (Perc) rolls to prevent yourself from blinking as
        you make your way to the exit. If you are unable to see, put 0 in all
        boxes.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> Preventing yourself from blinking proves more difficult
          then you remember. But you approach the light. The eyes and smile move
          away from you, watching you yet avoiding you all the same. The light
          is enough to show your paths behind it. Roleplay your Success!
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> It's much harder for you to keep your eyes open, dry
          eyes or burning retinas, something in your eyes! You blink a few more
          times and the eyes and mouth make fast approach, growing in size,
          until its mouth gapes open large enough to swallow you whole. Slick
          walls close around you as you move through airless corridors of
          fathomless depths. Wiggle, squirm, claw as you might, nothing will
          penetrate the walls around you. Suffocation takes you before you hit
          the creature's stomach.{" "}
          <b>
            You die, gasping for breath as you move further and further into the
            creature.
          </b>
        </div>
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2022/death");
          }}
        >
          Click this if you are Dead
        </button>
      </div>
    </div>
  );
};
