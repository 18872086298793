import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/564x/0c/73/5b/0c735b8f79fe7bf28bdad3c57a896806.jpg";
const doorUrl = door;

var nextRooms;

const Visitor = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Visitor_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Visitor_Obj);
  }

  const parseInput = (input) => {
    switch (input[0]) {
      case "optionOne":
        changeDisplay("optionOnePass");
        break;
      case "optionTwo":
        changeDisplay("optionTwoPass");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "optionOnePass":
        component = <PassOptionOneView rooms={nextRooms} />;
        break;
      case "optionTwoPass":
        component = <PassOptionTwoView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Visitor</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">An empty room with an inhabitant.</p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Visitor_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Visitor_Obj.route);
      }}
    >
      <img src={Visitor_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Visitor_Obj = {
  name: "Visitor",
  room: Visitor,
  entry: Entryway,
  route: "/halloween2022/visitor",
  exits: 2,
  prob: 1,
  image: doorUrl,
};

export default Visitor;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "optionOne":
          props.output([option]);
          break;
        case "optionTwo":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <div className="roomText">
        As you enter this featureless room, you see a person sitting in the
        corner with their head buried in their knees which are up to their
        chest. They are emaciated and weak, and it looks like you woke them up.
        Describe this person in your roleplay, and describe your conversation
        with them.
        <br />
        <br />
        Criteria:
        <ul>
          <li>They have been there for a long time</li>
          <li>They are shadows of their former self</li>
          <li>You are their only hope to make it out of the castle</li>
          <li>They will ask you to take them with you</li>
        </ul>
      </div>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Do you take them with you, or leave them here to die?
        </label>
        <button
          className="inputSubmit"
          type="submit"
          value="optionOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Take them with you
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="optionTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Leave them to die
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassOptionOneView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>You decide to take them with you.</b> Roleplay them as your
          companion for the remainder of your castle session. If you die, they
          die. If you fail, they fail. If you succeed, they succeed, but you
          must help them. As a result of the extra burden they represent,{" "}
          <b style={{ color: "red" }}>
            you suffer -2 on all skill rolls as a result of helping this person
            along.
          </b>
          <br />
          <br />
          <b>
            At any time, you can sacrifice this person to gain +10 on any one
            roll so long as you can justify their sacrifice actually helping you
            through roleplay.
          </b>{" "}
          Afterwards, they die to the room you sacrificed them to.
          <br />
          <br />
          If you successfully manage to exit with the person, they survive and
          are grateful, but have nothing to give you for your efforts. They may
          repay you in the future. Message Erik Manley or Farwulf if you manage
          to exit with this person.
          <br />
          <br />
          You see two exits to the room.
        </div>
      </div>

      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const PassOptionTwoView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>You decide to leave them where they are.</b> They may beg, they may
          cry, but you can't afford the extra burden they represent. You might
          even kill the person, if that's the sort of thing you'd do.
          <br />
          <br />
          You see two exits to the room.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
