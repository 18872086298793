import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/metaldoor.jpg";
import doorSfx from "../../res/sfx/Metal Door Creak Open 1_2.wav";
import sfx from "../../res/sfx/Stone Move 4_5.wav";
import song from "../../res/music/Stand Off.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/originals/6c/72/f6/6c72f617e1d222fd9a86d4451bc20e26.png";
const doorUrl = door;

var nextRooms;

const Boulder = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    playOneShot(sfx);
    nextRooms = GetNextRooms(Boulder_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Boulder_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 2) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Boulder</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You step into a wide sloped hallway with rounded floors. You see marks
        along the stone walls as if something slid across them. The sound of
        something lowering behind you reveals a stone platform and a huge
        polished black boulder. "Boom!" It rings as it falls down onto the
        lowered ramp, stone sliding on stone. It's coming towards you fast! Run!
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Boulder_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Boulder_Obj.route);
      }}
    >
      <img src={Boulder_Obj.image} />
      <span className="entryText">
        A heavy iron door leading into the unknown
      </span>
    </button>
  );
};

export var Boulder_Obj = {
  name: "Boulder",
  room: Boulder,
  entry: Entryway,
  route: "/halloween2022/boulder",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Boulder;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        Make three Athletics (Body) skill checks to outrun the boulder. Mind
        your footing, one mistep and it may very well flatten you.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Rolls
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> The boulder rolls after you, slamming into a halting
          overhang as you make it to a platform with three doors. Roleplay your
          success in making it to the other side.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> The bolder rolls after you, but you trip or are unable
          to outrun it, falling down.{" "}
          <b>
            The last thing you feel before your death is your bones crunching as
            the boulder flattens you.
          </b>
        </div>
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2022/death");
          }}
        >
          Click this if you are Dead
        </button>
      </div>
    </div>
  );
};
