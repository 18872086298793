import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/rooms/COG_Image__00283_.png";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/Disorienting.mp3";
import banner from "../../res/rooms/COG_Image__00212_.png";
import {
  CAPDEPTH,
  changeSong,
  GetNextRooms,
  GetRoomObjectByName,
  GlobalContext,
  playOneShot,
} from "../room_handler";
import SkillCheckSelect from "../../components/SkillCheckSelect";
import RandomRoomTreasure from "../../components/Loot";
import { save } from "../../functions/save";
import { EntryPercResults, Perception } from "../../components/perception";

const bannerUrl = banner;
const doorUrl = door;

const Remnant = () => {
  const [display, changeDisplay] = useState("pre");
  const InstanceVars = useContext(GlobalContext);
  const [nextRooms, changeNextRooms] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (InstanceVars.current.nextRooms) {
      loadVals();
    } else if (nextRooms.length === 0) {
      let nextRoomTemp = GetNextRooms(
        Remnant_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Remnant_Obj.exits)
      );
      save(InstanceVars.current, Remnant_Obj, nextRoomTemp);
      InstanceVars.current.score.depth += 100;
      changeNextRooms(nextRoomTemp);
    }
  }, []);

  const loadVals = () => {
    let nextRoomTemp = [];
    InstanceVars.current.nextRooms.forEach((room) => {
      let tempRoom = GetRoomObjectByName(room.name);
      if (tempRoom) nextRoomTemp.push(tempRoom);
    });
    if (nextRoomTemp.length > 0) {
      delete InstanceVars.current.nextRooms;
      delete InstanceVars.current.currentRoom;
    } else {
      nextRoomTemp = GetNextRooms(
        Remnant_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Remnant_Obj.exits)
      );
    }
    changeNextRooms(nextRoomTemp);
  };

  const parseInput = (input) => {
    if (input == "next") {
      InstanceVars.current.score.skill += 400;
      changeDisplay("pass");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "pre":
      default:
        component = <PreInput output={parseInput} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Remnant Ashes</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        A desolate wasteland of fog and dark, jagged rocks looms before you.
        Subterrannean trees spear the sky, burnt to a crisp and brittle to the
        touch. You cannot see the ceiling through the fog. Red motes of cinders
        drift through the air and a smell of burning smoke drifts about.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = (i = 0, navigate) => {
  return (
    <button
      key={Remnant_Obj.name + i}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Remnant_Obj.route);
      }}
    >
      <img src={Remnant_Obj.image} />
      <span className="entryText">A dark cave leading into the unknown</span>
      <EntryPercResults
        observation={{
          tier: 4,
          text: "The scent of burning comes faint from below.",
        }}
        mysticism={{
          tier: 3,
          text: "You sense strong fire magic at work ahead.",
        }}
        spirituality={{
          tier: 5,
          text: "You sense the residual feelings of creatures that avoid this path.",
        }}
      />
    </button>
  );
};

export var Remnant_Obj = {
  name: "Remnant",
  room: Remnant,
  entry: Entryway,
  route: "/CityOfGold/remnant",
  exits: 4,
  prob: 5,
  image: doorUrl,
  faction: "none",
};

export default Remnant;

//input: function
//output: level
const PreInput = (props) => {
  const [ableToContinue, setAbleToContinue] = useState(false);
  const navigate = useNavigate();

  //console.log(props.output);
  const submitHandler = (selection) => {
    let blank = false;
    selection.forEach((sel) => {
      if (sel.pass === undefined) blank = true;
      return;
    });
    if (!blank) {
      console.log("next");
      setAbleToContinue(true);
    }
  };

  return (
    <div>
      <p className="roomText">
        You see a gently drifting cinder touch a tree, shattering it into dust
        and fire. You can fly or run, but with how many cinders there are in the
        air, either option is equally dangerous. Roll an agility (dex) check to
        avoid touching a cinder and to avoid being near a tree when a cinder
        hits it.
      </p>
      <span className="inputLabel">
        Choose a skill to use and then roll it. Input the number into the box.
        If you have a way of feasibly instant-succeeding on the check, select
        "instant." <br />
        Natural 1: input 0 in your box. <br />
        Natural 20: Add 1d6 to the roll and input the final score into your box.
      </span>
      <SkillCheckSelect
        options={[{ method: "agility (dex)", diffTier: 4, damTier: 5 }]}
        specialEffect="All damage is dealt as fire damage. You are hit by a Cinder, possibly more. When the cinder hits it explodes, sending you flying back ten feet and setting you on fire. On top of the other damage taken, you take an additional 5 fire damage and you are afflicted with the burning condition."
        callback={submitHandler}
      />
      <input
        type="button"
        value="Continue"
        className="skillCheckButton skillCheckSubmit"
        disabled={!ableToContinue}
        onClick={() => props.output("next")}
      />
      <input
        type="button"
        value="Dead"
        className="skillCheckButton skillCheckDeath"
        disabled={!ableToContinue}
        onClick={() => {
          navigate("/CityOfGold/death");
        }}
      />
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>Survived!</b> You made it to the other side of the wasteland. Where
          do you go from here?
        </div>
        <RandomRoomTreasure
          chance={0.05}
          chanceForCurse={0.2}
          tier={Math.floor(Math.random() * 3)}
        />
      </div>
      <Perception />
      <div className="nextHolder">
        {props.rooms.map((room, i) => {
          return room.entry(i, navigate);
        })}
      </div>
    </div>
  );
};
