import { useEffect, useState } from "react";
import defaultSong from "../res/music/Down Time Piano.wav";
import { Entrance_Obj } from "./entrance";
import { Tightrope_Obj } from "./skill_rooms/tightrope";
import { Stairs_Obj } from "./passage_rooms/stairs";
import { Hallway_Obj } from "./passage_rooms/hallway";
import { Chute_Obj } from "./passage_rooms/chute";
import { Nursery_Obj } from "./odd_rooms/nursery";
import { FunHouse_Obj } from "./odd_rooms/funhouse";
import { ForestKey_Obj } from "./key_rooms/forestKey";
import { Exit_Obj } from "./exit_rooms/exit";
import { Stairs2_Obj } from "./passage_rooms/stairs2";
import { Werewolf_Obj } from "./death_rooms/werewolf";
import Player, { OneShot } from "../gui/player";
import DifficultyMeter from "../gui/difficultyMeter";
import { Outlet } from "react-router-dom";
import { Spikes_Obj } from "./death_rooms/spikes";
import { Boulder_Obj } from "./death_rooms/boulder";
import { Vampire_Obj } from "./death_rooms/vampire";
import { Gargoyle_Obj } from "./death_rooms/gargoyle";
import { Spikepit_Obj } from "./death_rooms/spikepit";
import { Blink_Obj } from "./death_rooms/blink";
import { Holes_Obj } from "./skill_rooms/holes";
import { Blades_Obj } from "./skill_rooms/blades";
import { Rodents_Obj } from "./skill_rooms/rodents";
import { Slime_Obj } from "./key_rooms/slimeKey";
import { Acidpit_Obj } from "./death_rooms/acidpit";
import { Classroom_Obj } from "./odd_rooms/classroom";
import { Masks_Obj } from "./odd_rooms/masks";
import { Catacombs_Obj } from "./odd_rooms/catacombs";
import { Gore_Obj } from "./odd_rooms/gore";
import { Gold_Obj } from "./odd_rooms/gold";
import { Family_Obj } from "./odd_rooms/family";
import { Dolls_Obj } from "./odd_rooms/doll";
import { Fault_Obj } from "./odd_rooms/fault";
import { Noise_Obj } from "./odd_rooms/noise";
import { Butcher_Obj } from "./odd_rooms/butcher";
import { Hole_Obj } from "./passage_rooms/hole";
import { Aquaeduct_Obj } from "./passage_rooms/aquaeduct";
import { Sewer_Obj } from "./passage_rooms/sewer";
import { Dungeon_Obj } from "./passage_rooms/dungeon";
import { Bridge_Obj } from "./passage_rooms/bridge";
import { Empty_Obj } from "./passage_rooms/empty";
import { Snakes_Obj } from "./skill_rooms/snake";
import { Barrier_Obj } from "./skill_rooms/barrier";
import { Visitor_Obj } from "./odd_rooms/visitor";
import { Masquerade_Obj } from "./key_rooms/masquerade";
import { Gambling_Obj } from "./key_rooms/gambling";
import { DoorsRiddle_Obj } from "./key_rooms/doorsRiddle";
import { Shadow_Obj } from "./skill_rooms/shadow";
import { Black_Obj } from "./skill_rooms/black";
import { PuzzleBox_Obj } from "./skill_rooms/puzzle";
import { Ghost_Obj } from "./skill_rooms/ghost";
import { HellHound_Obj } from "./skill_rooms/hellhound";
import { Madness_Obj } from "./skill_rooms/madness";
import { Mirrors_Obj } from "./skill_rooms/mirrors";
import { Spider_Obj } from "./death_rooms/spider";
import { Riddles_Obj } from "./death_rooms/riddle";
export const GlobalVars = {
  level: 1,
  depth: 0,
  difficultyRamp: 0.5,
  maxRamp: 10,
  maxDifficulty: 40,
  baseDiff: [6, 8, 10, 12, 14, 16],
  keys: 0,
};

export const Rooms = {
  passage_rooms: {
    name: "passage",
    rooms: [
      Stairs_Obj,
      Hallway_Obj,
      Chute_Obj,
      Stairs2_Obj,
      Hole_Obj,
      Aquaeduct_Obj,
      Sewer_Obj,
      Dungeon_Obj,
      Bridge_Obj,
      Empty_Obj,
    ],
    chance: 50,
    startChance: 50,
    endChance: 10,
    total: 0,
  },
  odd_rooms: {
    name: "odd",
    rooms: [
      Nursery_Obj,
      FunHouse_Obj,
      Classroom_Obj,
      Masks_Obj,
      Catacombs_Obj,
      Gore_Obj,
      Gold_Obj,
      Family_Obj,
      Dolls_Obj,
      Fault_Obj,
      Noise_Obj,
      Butcher_Obj,
      Visitor_Obj,
    ],
    chance: 50,
    startChance: 50,
    endChance: 20,
    total: 0,
  },
  skill_rooms: {
    name: "skill",
    rooms: [
      Tightrope_Obj,
      Holes_Obj,
      Blades_Obj,
      Rodents_Obj,
      Snakes_Obj,
      Barrier_Obj,
      Shadow_Obj,
      Black_Obj,
      PuzzleBox_Obj,
      Ghost_Obj,
      HellHound_Obj,
      Madness_Obj,
      Mirrors_Obj,
    ],
    chance: 50,
    startChance: 20,
    endChance: 50,
    total: 0,
  },
  death_rooms: {
    name: "death",
    rooms: [
      Werewolf_Obj,
      Spikes_Obj,
      Boulder_Obj,
      Vampire_Obj,
      Gargoyle_Obj,
      Spikepit_Obj,
      Blink_Obj,
      Acidpit_Obj,
      Spider_Obj,
      Riddles_Obj,
    ],
    chance: 5,
    startChance: 5,
    endChance: 50,
    total: 0,
  },
  key_rooms: {
    name: "key",
    rooms: [
      ForestKey_Obj,
      DoorsRiddle_Obj,
      Slime_Obj,
      Masquerade_Obj,
      Gambling_Obj,
    ],
    chance: 0,
    startChance: 0,
    endChance: 4,
    total: 0,
  },
  exit: {
    name: "exit",
    rooms: [Exit_Obj],
    chance: 30,
    startChance: 30,
    endChance: 10,
    total: 0,
  },
};

export const GetRandomRoom = () => {
  //select the type of room.
  try {
    let total = 0;
    for (const set in Rooms) {
      if (Rooms[set].rooms.length > 0) {
        total += Rooms[set].chance;
        Rooms[set].total = total;
      } else {
        Rooms[set].total = 0;
      }
    }
    let rand = Math.random() * total;
    let type = Rooms.exit;
    for (const set in Rooms) {
      if (rand < Rooms[set].total) {
        type = Rooms[set];
        break;
      }
    }
    //console.log(type);

    //select the individual room.
    total = 0;
    for (let i = 0; i < type.rooms.length; i++) {
      if (type.rooms[i]) {
        total += type.rooms[i].prob;
        type.rooms[i].total = total;
      }
    }
    rand = Math.random() * total;
    let chosenRoom = Rooms.exit.rooms[0];
    for (let i = 0; i < type.rooms.length; i++) {
      if (rand < type.rooms[i].total) {
        chosenRoom = type.rooms[i];
        break;
      }
    }
    //console.log(chosenRoom);
    //console.log(Rooms);
    return chosenRoom;
  } catch (e) {
    console.log(e);
  }
};

export const GetRoomOfType = (type) => {
  let room;
  let counter = 0;
  let setType;
  for (const set in Rooms) {
    if (Rooms[set].name == type) {
      setType = Rooms[set];
      break;
    }
  }
  if (!setType) {
    throw "No rooms of type: " + type;
  }

  while (!room && counter < 20) {
    room = setType.rooms[Math.floor(setType.rooms.length * Math.random())];
    counter++;
  }

  if (!room) {
    return GetRandomRoom();
  } else {
    return room;
  }
};

export const GetNextRooms = (current) => {
  //get the next rooms in the rotation.
  let next = [];
  let counter = 0;
  while (next.length < current.exits && counter != 20) {
    let temp = GetRandomRoom();
    if (
      !next.includes(temp) &&
      temp != current &&
      !(
        Rooms.key_rooms.rooms.includes(current) &&
        Rooms.exit.rooms.includes(temp)
      )
    ) {
      next.push(temp);
    }
    counter += 1;
  }

  //remove the current room from the rotation, if applicable.
  if (
    !Rooms.exit.rooms.includes(current) &&
    !Rooms.passage_rooms.rooms.includes(current)
  ) {
    for (const a in Rooms) {
      const temp = Rooms[a].rooms.indexOf(current);
      if (temp > -1) {
        Rooms[a].rooms.splice(temp, 1);
        break;
      }
    }
  }

  //reset depth
  if (current == Entrance_Obj) {
    GlobalVars.depth = 0;
  } else {
    GlobalVars.depth += 1;
  }

  updateRoomChances();

  return next;
};

export const getDifficulty = (tier) => {
  let difficulty = GlobalVars.baseDiff;
  let level = GlobalVars.level;
  if (level <= 5) {
    difficulty = GlobalVars.baseDiff;
  } else if (level <= 10) {
    difficulty = [7, 8, 11, 13, 15, 17];
  } else if (level <= 15) {
    difficulty = [8, 10, 13, 16, 19, 23];
  } else {
    difficulty = [10, 12, 16, 20, 23, 28];
  }
  return Math.min(
    difficulty[tier] +
      Math.min(
        Math.floor(GlobalVars.depth * GlobalVars.difficultyRamp),
        GlobalVars.maxRamp
      ),
    GlobalVars.maxDifficulty
  );
};

export const getDamage = (damages) => {
  if (damages.length == 0) {
    throw "getDamageTier requires at least one input";
  }
  if (damages.length < 5) {
    for (let i = 1; i < 5; i++) {
      if (damages[i] == null) {
        damages[i] = damages[i - 1];
      }
    }
  }
  let level = GlobalVars.level;
  let dTier = level / 5.0;

  let damage = damages[0];
  if (dTier < 1) {
    damage = damages[0];
  } else if (dTier < 2) {
    damage = damages[1];
  } else if (dTier < 3) {
    damage = damages[2];
  } else if (dTier < 4) {
    damage = damages[3];
  } else {
    damage = damages[4];
  }
  console.log(damage + " from: " + damages);
  return damage;
};

export const updateRoomChances = () => {
  const depth = GlobalVars.depth;
  const keys = GlobalVars.keys;
  var d = Math.min((depth * GlobalVars.difficultyRamp) / GlobalVars.maxRamp, 1);

  for (const type in Rooms) {
    Rooms[type].chance =
      Rooms[type].startChance +
      (Rooms[type].endChance - Rooms[type].startChance) * d;
  }

  if (
    depth < GlobalVars.maxRamp / (GlobalVars.difficultyRamp * 2) &&
    keys > 0
  ) {
    GlobalVars.depth = GlobalVars.maxRamp / (GlobalVars.difficultyRamp * 2);
  }

  switch (keys) {
    case 1:
      Rooms.key_rooms.endChance = 1;
      Rooms.exit.endChance = 6;
      Rooms.exit.startChance = 10;
      break;
    case 2:
      Rooms.key_rooms.endChance = 0.5;
      Rooms.exit.endChance = 3;
      Rooms.exit.startChance = 10;
      break;
    case 3:
      Rooms.key_rooms.endChance = 0.25;
      Rooms.exit.endChance = 1.5;
      Rooms.exit.startChance = 10;
      break;
    case 4:
      Rooms.key_rooms.endChance = 0.125;
      Rooms.exit.endChance = 0.75;
      Rooms.exit.startChance = 10;
      break;
    case 5:
      Rooms.key_rooms.endChance = 0;
      Rooms.exit.endChance = 0.325;
      Rooms.exit.startChance = 10;
      break;
    default:
      break;
  }
};

export const changeSong = (url) => {
  const player = document.getElementById("music");
  if (player && player.src.indexOf(url) == -1) {
    player.src = url;
    player.volume = 0.5;
  }
};

export const playOneShot = (url) => {
  const player = document.getElementById("oneShot");
  if (player && player.src != url) {
    player.src = url;
    player.volume = 0.5;
  }
};

const CurrentRoom = () => {
  return (
    <div className="roomHolder">
      <Player song={""} />
      <OneShot />

      <Outlet />
    </div>
  );
};

export default CurrentRoom;
