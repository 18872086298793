import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Metal Rusty 2_3.wav";
import bellSfx from "../../res/sfx/threeBell.wav";
import song from "../../res/music/La Classicale.wav";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";

const bannerUrl = "https://i.redd.it/pdrwxh759o571.jpg";
const doorUrl =
  "https://i.pinimg.com/564x/0a/0f/eb/0a0feb5c6e652454f0944b0484a44c6d.jpg";

var bellCount = 0;

var nextRooms;

const Masquerade = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Masquerade_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Masquerade_Obj);
  }

  const parseInput = (input) => {
    switch (input[0]) {
      case "greatHall":
      case "blueRoom":
      case "purpleRoom":
      case "greenRoom":
      case "orangeRoom":
      case "whiteRoom":
      case "violetRoom":
      case "blackRoom":
      case "guess":
        changeDisplay(input[0]);
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "greatHall":
        component = <GreatHall output={parseInput} />;
        break;
      case "blueRoom":
        component = <BlueRoom output={parseInput} />;
        break;
      case "purpleRoom":
        component = <PurpleRoom output={parseInput} />;
        break;
      case "greenRoom":
        component = <GreenRoom output={parseInput} />;
        break;
      case "orangeRoom":
        component = <OrangeRoom output={parseInput} />;
        break;
      case "whiteRoom":
        component = <WhiteRoom output={parseInput} />;
        break;
      case "violetRoom":
        component = <VioletRoom output={parseInput} />;
        break;
      case "blackRoom":
        component = <BlackRoom output={parseInput} />;
        break;
      case "guess":
        component = <End rooms={nextRooms} />;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Masquerade</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You enter a grand ballroom with vaulted cielings and a single crystal
        chandelier bright enough to light the whole space in a thousand crystal
        hues. Painted murals of cherubim and seraphim color the cielings in
        stark relief. Checkerboard patterns decorate the marble floor and a ten
        table buffet lines one wall. Long dining tables ring a dance floor with
        a grand fountain of the purest red wine in the center. Hundreds of
        guests dance and laugh and feast with one another. This is the estate of
        Lord Greylin, the wealthiest lord in Vayle. He sits on a throne of
        marble and gold, laughing uproariously with his brother. This is the
        third day of the Masquerade.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Masquerade_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Masquerade_Obj.route);
      }}
    >
      <img src={Masquerade_Obj.image} />
      <span className="entryText">
        A heavy golden door leading into the unknown
      </span>
    </button>
  );
};

export var Masquerade_Obj = {
  name: "Masquerade",
  room: Masquerade,
  entry: Entryway,
  route: "/halloween2022/masquerade",
  exits: 3,
  prob: 0.5,
  image: doorUrl,
};

export default Masquerade;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      bellCount++;
      if (bellCount >= 10) {
        playOneShot(bellSfx);
        bellCount = 0;
      }
      setErrText("");
      props.output([option]);
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        You enter the Masquerade dressed in equal measure to the other
        partygoers. Perhaps even more richly. You are a Lord or Lady in your own
        right. You recall your past and yet you also do not recall your past.
        You recall a castle and yet you do not recall the castle. You recall
        your task and yet you do not recall your task. You feel out of place,
        and yet you do not feel out of place. Your memories war with one
        another, but the self that is a part of the Masquerade is winning for
        now. Go, enjoy yourself. Eat, drink, be merry, and let your worries
        trouble you no more.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="greatHall"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Eat, Drink, and be Merry
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

const hallGossip = [
  "I think my mistress is around here somewhere...",
  "Such a tragedy, I heard they got sick in Castle Erenhar after Lady Ocato fell ill.",
  "This plague has been hard on all of us. But no matter, we're partying. Isn't the point to forget about the silly plague?",
  "We are Lords, not common lepers! We're above getting sick in our palace.",
  "Thank God for Lord Greylin putting this party on. If he hadn't shut the doors, we'd be out there with those terrible wretches.",
  "What do you think of my hair dear? I just woke up, does it look okay?",
  "I'm nervous, should I ask Lady Tilde on a date? What if she turns me down?",
  "Do you see that? Is that Lord Risham dancing with Lady Opal? Unbelievable.",
  "Did you hear about the latest scandal in the Restan household?",
  "I don't believe all these people. Partying as if nothing is going on. Can't they see I'm missing my pocketwatch!?",
  "My sister fell ill last week. I left her behind without a second thought. No way I'm catching the plague.",
  "I hope my dogs are okay. I left enough food out for them, but who knows what those terrible wretches will do for food?",
  "I heard the priest spouting off some silliness about the end of times again. Ridiculous. After the plague is over, we'll leave and the world will continue with us at the helm. This is the best thing that could have happened to us.",
];

//input: function
//output: level
const GreatHall = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");
  const [gossip, setGossip] = useState(
    hallGossip[Math.floor(Math.random() * hallGossip.length)]
  );

  const randomGossip = () => {
    bellCount++;
    if (bellCount >= 10) {
      playOneShot(bellSfx);
      bellCount = 0;
    }
    setGossip(hallGossip[Math.floor(Math.random() * hallGossip.length)]);
  };

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      bellCount++;
      if (bellCount >= 10) {
        playOneShot(bellSfx);
        bellCount = 0;
      }
      setErrText("");
      props.output([option]);
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        You are in the greathall. People party and dance around the fountain and
        feast on the buffet. Groups of people can be seen laughing and chatting.
        Some people seem tired, and others seem lively. Servers walk around with
        trays of food and drink, and other staff can be seen cleaning up this or
        that. The ball is an uproarious affair.
      </p>
      <p className="secondaryText">
        {bellCount == 0
          ? "Everyone stops as the black clock tolls, sounding the hour. Its sickly bell overtaking all conversation with its incredible noise."
          : '"' + gossip + '"'}
      </p>
      <button className="inputSubmit" onClick={randomGossip}>
        Listen to Gossip
      </button>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="blueRoom"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Enter the Blue Room
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="purpleRoom"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Enter the Purple Room
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="greenRoom"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Enter the Green Room
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="orangeRoom"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Enter the Orange Room
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="whiteRoom"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Enter the White Room
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="violetRoom"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Enter the Violet Room
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="blackRoom"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Enter the Black Room
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

const blueGossip = [
  "This wine is divine, aha!",
  "I love these paintings, so masterfully done. Beautiful.",
  "No no no, Lady Ebora didn't transmit the plague on the 24th!",
  "When this is all over, I don't know how we'll recover, but frankly, I don't care. This is a party to forget our troubles.",
  "What did you shay to me? I'm te Lohrd of Blahgandr Chastel. Youu canno defeat meh... *snooze*",
  "Is that man sleeping? We've only been awake three days. It's far too early to be sleeping!",
  "This party is incredible!",
  "I don't ever want to leave. Can I just stay here after the plague is over.",
  "Literally thousands of people are dead and not one of them had the decency to get Lord Eliand sick. He disgusts me with his indecency. Yes, that's him right over there.",
  "Yes, and if I were Viscount, I would challenge him to a duel!",
  "She said WHAT about Lady Fillind? There's no way Lord Fillind would stand for that.",
  "Who is that? Oh, I thought she was dead. Too bad.",
];

//input: function
//output: level
const BlueRoom = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");
  const [gossip, setGossip] = useState(
    blueGossip[Math.floor(Math.random() * blueGossip.length)]
  );

  const randomGossip = () => {
    bellCount++;
    if (bellCount >= 10) {
      playOneShot(bellSfx);
      bellCount = 0;
    }
    setGossip(blueGossip[Math.floor(Math.random() * blueGossip.length)]);
  };

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      bellCount++;
      if (bellCount >= 10) {
        playOneShot(bellSfx);
        bellCount = 0;
      }
      setErrText("");
      props.output([option]);
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        A great blue window casts the room into a bright hue. The stained glass
        is decorated by a baby curled around an umbilical chord. The walls are
        painted with bright flower buds yet to bloom. A central feature of
        rotating balls hangs from the cieling. Several dozen people chat in
        groups, spread far enough apart that eavesdropping on conversations
        would be simple.
      </p>
      <p className="secondaryText">
        {bellCount == 0
          ? "Everyone stops as the black clock tolls, sounding the hour. Its sickly bell overtaking all conversation with its incredible noise."
          : '"' + gossip + '"'}
      </p>
      <button className="inputSubmit" onClick={randomGossip}>
        Listen to Gossip
      </button>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="greatHall"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Return to the Great Hall
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

const purpleGossip = [
  'But what about Lady Ebore, what happened to her?" "Oh, she died too my dear. You cannot tranmit the disease without first having it.',
  "Lord Greylin really knows how to throw a party!",
  "If that's true, then it's likely that Lord Fesco either transmitted the plague on the thirteenth, or he transmitted it in Mansion La'Platte.",
  "Have you seen what Lady Greshin is wearing? It's just so... demure. She needs to lighten up a bit.",
  "This is my favorite song! Dance of the Ravens has such a gorgeous refrain.",
  "Where do you suppose Lord Greylin got the money for a mansion such as this?",
  "I heard Lord Lophran *intentionally* spread the plague about. Dreadful.",
  "Can you believe this? Ugh, I chipped my nail, and I left my servants behind to come to this stupid party! Where's a servant when you need one?",
  "Oh yes m'boy, fetch me another glass of wine!",
];

//input: function
//output: level
const PurpleRoom = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");
  const [gossip, setGossip] = useState(
    purpleGossip[Math.floor(Math.random() * purpleGossip.length)]
  );

  const randomGossip = () => {
    bellCount++;
    if (bellCount >= 10) {
      playOneShot(bellSfx);
      bellCount = 0;
    }
    setGossip(purpleGossip[Math.floor(Math.random() * purpleGossip.length)]);
  };

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      bellCount++;
      if (bellCount >= 10) {
        playOneShot(bellSfx);
        bellCount = 0;
      }
      setErrText("");
      props.output([option]);
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        A great purple window casts the room in a rich hue. The window shows a
        purple lily in bloom. People vibrantly dance and sing with one another
        here, and only a few can be seen chatting in groups, though it should be
        quite easy to eavesdrop even with the vibrant music that is playing.
      </p>
      <p className="secondaryText">
        {bellCount == 0
          ? "Everyone stops as the black clock tolls, sounding the hour. Its sickly bell overtaking all conversation with its incredible noise."
          : '"' + gossip + '"'}
      </p>
      <button className="inputSubmit" onClick={randomGossip}>
        Listen to Gossip
      </button>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="greatHall"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Return to the Great Hall
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

const greenGossip = [
  "Without you, I don't know what I would do! My stupid husband couldn't satisfy me for anything.",
  "I left my kids behind to come to this party. I'm glad I did!",
  "I can tell you one thing, Lord Crosca didn't fall ill by Maester Ullun's hands, and he certainly didn't get sick on the 25th.",
  "Can you get me a drink sweety? I'm parched. Oh, you're not a serving girl? hah! You're dressed like one!",
  "My wife owns the clinic on green avenue. As soon as people started getting sick, we closed the doors. No way we were going to catch that illness. It was the smartest thing we ever did.",
  "I'm so glad I made it to this party. These hors d'oeuvres are to die for!",
  "I love you so much. We are so blessed to be here together.",
];

//input: function
//output: level
const GreenRoom = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");
  const [gossip, setGossip] = useState(
    greenGossip[Math.floor(Math.random() * greenGossip.length)]
  );

  const randomGossip = () => {
    bellCount++;
    if (bellCount >= 10) {
      playOneShot(bellSfx);
      bellCount = 0;
    }
    setGossip(greenGossip[Math.floor(Math.random() * greenGossip.length)]);
  };

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      bellCount++;
      if (bellCount >= 10) {
        playOneShot(bellSfx);
        bellCount = 0;
      }
      setErrText("");
      props.output([option]);
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        A great green window casts the room in a lively hue. A tree decorates
        the window, and great cushions can be seen lining the walls and floors
        with a myriad of sofas strewn about. People are separated into intimate
        groups, rarely more than two people each group. Each person looks at
        their partner with heavy lidded eyes and whisper to each other, or kiss
        passionately. Hands explore each others' bodies. Despite the intimacy of
        the setting, there are some groups chatting in corners, and you feel you
        could still get some juicy eavesdropping in.
      </p>
      <p className="secondaryText">
        {bellCount == 0
          ? "Everyone stops as the black clock tolls, sounding the hour. Its sickly bell overtaking all conversation with its incredible noise."
          : '"' + gossip + '"'}
      </p>
      <button className="inputSubmit" onClick={randomGossip}>
        Listen to Gossip
      </button>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="greatHall"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Return to the Great Hall
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

const orangeGossip = [
  "We're safe in here. The walls of the mansion won't let anybody in.",
  "I heard Sister Genfry broke her vows to be with Lord Lophran. Poor sops are both dead now.",
  "I heard they fell ill at the physician's before Lord Lophran fell ill though. Do you think there's any merit to that?",
  "What is that hairdo? It looks like a bird climbed up into it. Disgusting.",
  "The Red Death certainly won't get us in here. Lord Greylin has the place locked up tight.",
  "These plants are beautiful. I wish I could share them with my sweet.",
  "I have a business to run, the people who have the red death should simply perish, and decrease the surplus population!",
];

//input: function
//output: level
const OrangeRoom = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");
  const [gossip, setGossip] = useState(
    orangeGossip[Math.floor(Math.random() * orangeGossip.length)]
  );

  const randomGossip = () => {
    bellCount++;
    if (bellCount >= 10) {
      playOneShot(bellSfx);
      bellCount = 0;
    }
    setGossip(orangeGossip[Math.floor(Math.random() * orangeGossip.length)]);
  };

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      bellCount++;
      if (bellCount >= 10) {
        playOneShot(bellSfx);
        bellCount = 0;
      }
      setErrText("");
      props.output([option]);
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        A grand orange window with a bright sun emblazoned on the surface casts
        the room in a brilliant hue. A grand indoor garden lines the walls and
        center island. Trees, flowers, bushes of all shapes and sizes stand tall
        and strong. The air is quiet here. People sit quietly on benches or
        whisper in small groups. There are plenty of opportunities to listen to
        gossip here.
      </p>
      <p className="secondaryText">
        {bellCount == 0
          ? "Everyone stops as the black clock tolls, sounding the hour. Its sickly bell overtaking all conversation with its incredible noise."
          : '"' + gossip + '"'}
      </p>
      <button className="inputSubmit" onClick={randomGossip}>
        Listen to Gossip
      </button>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="greatHall"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Return to the Great Hall
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

const whiteGossip = [
  "Can you keep it down? I'm trying to read what happens between Favio and Esmerelda!",
  "I can't believe our luck getting into this party. I can't think of a better way to wait out the plague.",
  "That's not possible, Lord Fesco transmitted the plague after Lady Ocato fell ill.",
  "My cousin got sick there too. That physician's office is terrible. Maester Ullun got everyone in that office sick that day. He should have been healing them, not making matters worse!",
  "If I knew any better, I'd say Duke Trello and Lady Wiscine are having an affair. Ah, who am I kidding, I'm having an affair with both of them!",
  "It's so quiet in here. Why are all these dusty books here anyways?",
];

//input: function
//output: level
const WhiteRoom = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");
  const [gossip, setGossip] = useState(
    whiteGossip[Math.floor(Math.random() * whiteGossip.length)]
  );

  const randomGossip = () => {
    bellCount++;
    if (bellCount >= 10) {
      playOneShot(bellSfx);
      bellCount = 0;
    }
    setGossip(whiteGossip[Math.floor(Math.random() * whiteGossip.length)]);
  };

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      bellCount++;
      if (bellCount >= 10) {
        playOneShot(bellSfx);
        bellCount = 0;
      }
      setErrText("");
      props.output([option]);
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        The white room has no window, instead it is lit by a grand chandelier of
        crystal high above your head. Thick carpets of white cover the floor and
        plush white recliners and sofas line the walls and chatting areas. The
        walls are covered in books and tapestries. Each book is either
        educational or romantic in nature. Several dozen people read in chairs,
        while many others chat quietly one with another. You can certainly
        eavesdrop here if you wished.
      </p>
      <p className="secondaryText">
        {bellCount == 0
          ? "Everyone stops as the black clock tolls, sounding the hour. Its sickly bell overtaking all conversation with its incredible noise."
          : '"' + gossip + '"'}
      </p>
      <button className="inputSubmit" onClick={randomGossip}>
        Listen to Gossip
      </button>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="greatHall"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Return to the Great Hall
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

const violetGossip = [
  "What time is it? Aw, who am I kidding, it's not like time matters in this place.",
  "When can we leave this place? Can't the plague just be over already?",
  "I'm certain that between Viscount Frederick and whoever got that poor person sick at the physician's, one transmitted on the 30th, and the other got Sister Winnen sick. Yes, I'm certain.",
  "Without this mansion to keep us all safe, we'd all be dead by now. We might be the last people alive in the city.",
  "My feet are beginning to hurt. Maybe I should take them off for a little while.",
  "Ugh, it's so dreadfully cold in this place. I wish they would put on a fire or something.",
  "Of course I brought my dog to the party, there's no way I'd leave my snookums out to catch that dreadful plague.",
  "I should really go to sleep. I haven't slept in two days.",
];

//input: function
//output: level
const VioletRoom = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");
  const [gossip, setGossip] = useState(
    violetGossip[Math.floor(Math.random() * violetGossip.length)]
  );

  const randomGossip = () => {
    bellCount++;
    if (bellCount >= 10) {
      playOneShot(bellSfx);
      bellCount = 0;
    }
    setGossip(violetGossip[Math.floor(Math.random() * violetGossip.length)]);
  };

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      bellCount++;
      if (bellCount >= 10) {
        playOneShot(bellSfx);
        bellCount = 0;
      }
      setErrText("");
      props.output([option]);
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        A grand violet window casts this room into dark colors, elongating the
        shadows and causing you to feel sleepy. Indeed several dozen people can
        be seen sleeping on sofas or plush beds. A few chat in circles around
        king sized beds while couples snuggle and offer sweet words one to
        another. Incense and lavendar give a calming smell to the air, and any
        pains you have seem to melt away in light of them. You should be able to
        eavesdrop here if you feel so inclined.
      </p>
      <p className="secondaryText">
        {bellCount == 0
          ? "Everyone stops as the black clock tolls, sounding the hour. Its sickly bell overtaking all conversation with its incredible noise."
          : '"' + gossip + '"'}
      </p>
      <button className="inputSubmit" onClick={randomGossip}>
        Listen to Gossip
      </button>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="greatHall"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Return to the Great Hall
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const BlackRoom = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");
  const [inputOne, setInputOne] = useState("crosca");
  const [inputTwo, setInputTwo] = useState("crosca");
  const [inputThree, setInputThree] = useState("crosca");
  const [inputFour, setInputFour] = useState("crosca");
  const navigate = useNavigate();

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    bellCount++;
    if (bellCount >= 10) {
      playOneShot(bellSfx);
      bellCount = 0;
    }
    setErrText("");
    let ans = ["ocato", "crosca", "winnen", "lophran"];
    if (
      option == "guess" &&
      ans[0] == inputOne &&
      ans[1] == inputTwo &&
      ans[2] == inputThree &&
      ans[3] == inputFour
    ) {
      props.output([option]);
    } else if (option == "guess") {
      setErrText(
        "You guess incorrectly and the red light grows brighter for a moment before you can feel some of your life force draining from you. Take 2d10 damage."
      );
    } else if (option != "") {
      props.output([option]);
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <div className="roomText">
        The black room has one large red window unornamented by any design. The
        black walls are likewise bare of ornamentation. A single grandfather
        clock stands in the center of the room, chiming loudly every hour. You
        have an unnerving feeling in this room, and not a single person stands
        with you. Only you and the clock remain in this room.
        <br />
        <br />
        You approach the clock. Its black wood shines in the red light, casting
        a long dark shadow in front of it. Even so, the clock face seems bright
        against the shadow. The pendulum ticks away.
        <br />
        <br />
        <p className="secondaryText">
          {bellCount == 0
            ? "The black clock tolls, sounding deep in your skull as the loud bell penetrates you with its sickly tone."
            : ""}
        </p>
        You notice words engraved into the rear of the clock, "Four people have
        died, fallen ill by four others' hands. Speak the names of transmitter
        and victim in pairs, and this dream will end.
        <br />
        <br />
        <span style={{ color: "grey" }}>
          Possible Transmitters: Lady Ebore, Viscount Frederick, Maester Ullun,
          Lord Fesco.
          <br />
          Possible Victims: Lord Crosca, Sister Winnen, Lady Ocato, Lord Lophran
          <br />
          Possible Location: Chateau Desolee, Castle Erenhar, Mansion La'Platte,
          Physician
          <br />
          Dates of Transmission: 13th, 24th, 25th, 30th
        </span>
      </div>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">Who was Lady Ebore's Victim?</label>
        <select
          className="inputField"
          type="text"
          onChange={(e) => {
            setInputOne(e.target.value);
          }}
        >
          <option value="crosca">Lord Crosca</option>
          <option value="winnen">Sister Winnen</option>
          <option value="ocato">Lady Ocato</option>
          <option value="lophran">Lord Lophran</option>
        </select>
        <label className="inputLabel">
          Who was Viscount Frederick's Victim?
        </label>
        <select
          className="inputField"
          type="text"
          onChange={(e) => {
            setInputTwo(e.target.value);
          }}
        >
          <option value="crosca">Lord Crosca</option>
          <option value="winnen">Sister Winnen</option>
          <option value="ocato">Lady Ocato</option>
          <option value="lophran">Lord Lophran</option>
        </select>
        <label className="inputLabel">Who was Maester Ullun's Victim?</label>
        <select
          className="inputField"
          type="text"
          onChange={(e) => {
            setInputThree(e.target.value);
          }}
        >
          <option value="crosca">Lord Crosca</option>
          <option value="winnen">Sister Winnen</option>
          <option value="ocato">Lady Ocato</option>
          <option value="lophran">Lord Lophran</option>
        </select>
        <label className="inputLabel">Who was Lord Fesco's Victim?</label>
        <select
          className="inputField"
          type="text"
          onChange={(e) => {
            setInputFour(e.target.value);
          }}
        >
          <option value="crosca">Lord Crosca</option>
          <option value="winnen">Sister Winnen</option>
          <option value="ocato">Lady Ocato</option>
          <option value="lophran">Lord Lophran</option>
        </select>
        <button
          className="inputSubmit"
          type="submit"
          value="guess"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Speak the Names
        </button>
        <button
          className="inputSubmit"
          type="submit"
          value="greatHall"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Return to the Great Hall
        </button>
        <label className="error">{errText}</label>
      </form>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2022/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};

//input rooms
const End = (props) => {
  playOneShot(bellSfx);
  return (
    <div>
      <div className="roomText">
        As you speak the names of both transmitter and victim, the clock tolls
        four times. The red window behind the clock becomes two grand wooden
        doors that open before you. You realize these are the same grand doors
        at the front of the great hall. As they open wide enough to see through,
        you confirm it is indeed another entry into the great hall. You step
        forward.
        <br />
        <br />
        As you cross the threshold, people cease their conversations as they
        stare at you. A glass drops and shatters as a woman puts a hand to her
        heart in shock. The band cuts off with a discordant note and soon enough
        the entire room is silent, staring at you as you enter the great hall.
        What's wrong with everybody?
        <br />
        <br />
        You look at your hands.
        <br />
        <br />
        You are covered in whispy red cloth. A bare amount of skin shows between
        the red strands. Your skin appears to be melted away, leaving the muscle
        bare underneath. Red sores pockmark this muscle and ooze puss and blood.
        You are not in pain. You are beyond pain. Your feet carry you forward.
        <br />
        <br />
        The muttering starts. One portly man cries "Shame on you for wearing
        such a mask! That is quite in poor taste!" another woman says, "I don't
        know I kind of like it. It has a morbid flare to it. It really makes a
        statement!" Finally, the great Lord Greylin stands from his high golden
        throne. "What is the meaning of this? Come closer that I might see you!"
        <br />
        <br />
        Your feet carry you slowly through the crowd. You catch sight of
        yourself in a great mirror. Your face is truly a sight to behold.
        <img
          className="banner"
          src="https://i.pinimg.com/564x/1d/55/ad/1d55ad8920fb7beb342bd67f6b140388.jpg"
        />
        Your hair is gone, and what is left of your flesh is pale and pockmarked
        with red sores. Muscle shines through much of your skin. You have no
        lips or nose. The red shroud covers you from head to toe.
        <br />
        <br />
        A cough sounds behind you. It is the portly man who called you out for
        your poor taste. Then a scream. The portly man is doubled over, vomiting
        blood onto the ground. The woman who complimented you screams as her
        beautiful face tears, revealing the muscle underneath. One by one people
        begin to feel the effects of the plague set in. Their screams fill the
        room in a chorus of pain.
        <br />
        <br />
        Nobody is safe and nobody escapes. The mansion fills with cries of pain
        as the plague comes to the mansion. You look back to the prince to see
        him running into the black room, where he knows nobody will follow.
        <br />
        <br />
        People run out of the blue room in terror, clawing at their stomachs as
        their guts fall through loose skin.
        <br />
        <br />
        People run out of the purple room, screaming. Their screams cry more
        loudly than any others. They spasm and dance as if their feet are on
        fire. One by one their feet fall off and they fall to the ground clawing
        at their ankles.
        <br />
        <br />
        Dozens run out of the green room, grasping at their throats and faces,
        making no sound as they cough and vomit blood and bile.
        <br />
        <br />
        People escape the orange room covered in a spiderweb of cuts, their skin
        splitting open at all angles. They scream and cry as flesh sloughs off
        in great sheets.
        <br />
        <br />
        Several crawl out of the white room, crying for anybody to help them.
        Their bones have become brittle, and even as you watch, those strong
        enough to make it out of the room crumble into a heap as their bones
        fail to keep even their crawling forms up.
        <br />
        <br />
        Only one person leaves the violet room. His skin is withered and dried.
        Even as you watch, his outer layer of skin drifts away, caught by a
        breeze. Then his muscle and sinew likewise blow away. And finally, only
        his bones are left, but soon enough, they too join the wind.
        <br />
        <br />
        Everyone is dead or dying. The red death has come to the grand Estate of
        Lord Greylin. Only one person remains. You enter the black room. The
        bell begins tolling, drowning out the sounds of the walking dead. Lord
        greylin grovels in front of the great black clock. His skin, unmarred by
        the plague, is perfect. Even as you step forward, he begs. You see a
        gleam of metal flash. A dagger is in his hand in a flash and he dashes
        towards you, cursing you for living.
        <br />
        <br />
        He falls flat on his face, dead, inches from where you stand.
        <br />
        <br />
        The clock ceases tolling, sending the mansion into utter silence. Crows
        squawk outside as the stink of death invades you. No screams can be
        heard. No moans remain. All are dead. The clock face opens, revealing a
        space where a key used to be and five rasps.
        <div className="keyAcquire">
          <b>You have acquired five rasps!</b> This used to be where a key was
          located before it was claimed by Alon! Make your way to an exit, or
          take your chances to find a second key. If you do escape, message
          @Beriale Wardhaven (Erik Manley) to let him know you found some rasps.{" "}
          <b>(NOTE: You can only claim the rasps from this room one time)</b>
        </div>
        You leave the black room with your rasps in hand. You pass corpses piled
        in heaps. Bugs have already begun their feast as flies mass in droves
        above the heaps of dead. You walk through the Greathall past the
        pristine buffet table, past the stand of instruments where the band once
        played. You walk past the fountain of wine, still pouring its red
        contents down the lip, though corpses now litter the wine.
        <br />
        <br />
        The large double door you entered from has been replaced by three new
        paths, leading out of the mansion and back into the castle beyond.
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
