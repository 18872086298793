import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/rooms/COG_Image__00041_.png";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/Disorienting.mp3";
import {
  CAPDEPTH,
  changeSong,
  GetNextRooms,
  GetRoomObjectByName,
  GlobalContext,
  playOneShot,
} from "../room_handler";
import { save } from "../../functions/save";
import { EntryPercResults, Perception } from "../../components/perception";

const bannerUrl = door;
const doorUrl = door;

const PARAMS = { floors: 6 };

const Stairs2 = () => {
  const [floor, setFloor] = useState(0);
  const InstanceVars = useContext(GlobalContext);
  const [nextRooms, changeNextRooms] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    PARAMS.floors = Math.ceil(Math.max(Math.random() * 6, 2));
    if (InstanceVars.current.nextRooms) {
      loadVals();
    } else {
      let nextRoomTemp = GetNextRooms(Stairs2_Obj, InstanceVars);
      save(InstanceVars.current, Stairs2_Obj, nextRoomTemp);
      InstanceVars.current.score.depth += 100;
      changeNextRooms(nextRoomTemp);
    }
  }, []);

  const loadVals = () => {
    let nextRoomTemp = [];
    InstanceVars.current.nextRooms.forEach((room) => {
      let tempRoom = GetRoomObjectByName(room.name);
      if (tempRoom) nextRoomTemp.push(tempRoom);
    });
    if (nextRoomTemp.length > 0) {
      delete InstanceVars.current.nextRooms;
      delete InstanceVars.current.currentRoom;
    } else {
      nextRoomTemp = GetNextRooms(
        Stairs2_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Stairs2_Obj.exits)
      );
    }
    changeNextRooms(nextRoomTemp);
  };

  const parseInput = () => {
    setFloor(floor + 1);
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    component = <Flight rooms={nextRooms} floor={floor} output={parseInput} />;
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Stairs (Down)</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        A flight of stone spiral stairs winds its way downward. You cannot see
        beyond the bend. Whatever Evil Magic keeps you from retreating also
        sbottoms you from taking a single stair backwards. If one stair is
        taken, one must continue to descend.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = (i = 0, navigate) => {
  return (
    <button
      key={Stairs2_Obj.name + toString(i)}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Stairs2_Obj.route);
      }}
    >
      <img src={Stairs2_Obj.image} />
      <span className="entryText">A stone stairway leading down</span>
      <EntryPercResults
        observation={{
          tier: 3,
          text: "A well trodden path.",
        }}
        spirituality={{
          tier: 4,
          text: "You sense the safety of this passage.",
        }}
      />
    </button>
  );
};

export var Stairs2_Obj = {
  name: "Stairs2",
  room: Stairs2,
  entry: Entryway,
  route: "/CityOfGold/stairs2",
  exits: 8,
  prob: 5,
  image: doorUrl,
  faction: "none",
};

export default Stairs2;

//input rooms
const Flight = (props) => {
  const navigate = useNavigate();

  const submitHandler = (e) => {
    e.preventDefault();
    props.output();
  };

  const getFloorText = () => {
    if (props.floor == 0) {
      return (
        <p>
          Unable to turn back, and with no way to go but down, you take your
          first steps. At the bottom of the flight you see a door. You can take
          this door, or you can continue down the stairs to the next door, but
          you cannot return.
        </p>
      );
    } else if (props.floor >= PARAMS.floors - 1) {
      return (
        <p>
          You continue down another flight of stairs. At the bottom of the
          flight you see a door. There are no more stairs downwards. You *must*
          take this door.
        </p>
      );
    } else {
      return (
        <p>
          You continue down another flight of stairs. At the bottom of the
          flight you see a door. You can take this door, or you can continue
          down the stairs to the next door, but you cannot return.
        </p>
      );
    }
  };

  //console.log(PARAMS.floors);

  return (
    <div>
      <div className="roomText">
        {getFloorText()}
        {props.floor < PARAMS.floors - 1 ? (
          <button className="inputSubmit" onClick={submitHandler}>
            Continue Downwards
          </button>
        ) : (
          ""
        )}
      </div>
      <Perception />
      <div className="nextHolder">
        {props.rooms && props.floor
          ? props.rooms[props.floor].entry(1, navigate)
          : ""}
      </div>
    </div>
  );
};
