import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Haunting Ambience B.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  getDamage,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://pyxis.nymag.com/v1/imgs/df1/026/67aa123dda816666660713c670bb9d0ac7-09-scary-stories-jangly-man.rhorizontal.w700.jpg";
const doorUrl = door;

var nextRooms;

const Black = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Black_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Black_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 3;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 2) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Black Room</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You enter a void. You strain your eyes to see beyond a few feet, but a
        curtain of magical darkness obscures your vision. Even if you can
        normally see through magical darkness, some strange quality of this
        darkness causes it to remain impenetrable. You put your hand in front of
        your face and see only a hazy outline in the gloom. You hear something
        skittering behind you, yet when you turn - Nothing. You sense nothing
        but a terrible stench of rotting meat in the air. Perhaps you're
        imagining things?
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Black_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Black_Obj.route);
      }}
    >
      <img src={Black_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Black_Obj = {
  name: "Black",
  room: Black,
  entry: Entryway,
  route: "/halloween2022/blackroom",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Black;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        Roll three observation checks or three stealth checks as you try to
        navigate to an exit.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You carefully creep forward. Through the haze of
          blackness, you see an irregular form crouched over something. Long
          limbs jut out at all angles. You see the outline of a foot sticking
          out of a shoulder and a hand grasping at the air not far in front of
          you. The pile of arms and hands shifts as you move around it. It
          doesn't notice you as you creep around. <br />
          <br /> As you creep around it, you see another monster in the gloom.
          You hear shifting to your left as you suspect a third monster in the
          darkness. You continue forward through it, your senses straining to
          make up for the lack of sight. You find a wall and move along it until
          your hand touches an irregularity. Finally, you see three paths.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  let damage = getDamage(["2d4", "1d10", "2d6", "2d8", "3d6"]);
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> As you carefully creep forward, you strain all your
          senses to hear or see or smell anything in the gloom. Something fleshy
          brushes your arm and you hear a grunt. You see the hazy outline of a
          long arm with six fingers reach out to grab you. You move away from
          the hand and realize the creature knows you're there. Long limbs jut
          out of it at all angles. You see the outline of a foot sticking out of
          a shoulder and a hand grasping at the air not far in front of you. The
          pile of arms and hands shifts as the creature turns to face you and
          move with a quick loping gate towards you. You back away, straight
          into the waiting clutches of another beast as it grips you. You see a
          third monster approaching in the gloom. Roleplay your escape as you
          break free, taking <b>{damage} damage</b> in the attempt. Eventually,
          you run into a wall that isn't stone. It could be a way out. If you
          are still alive, you can proceed.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2022/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
