import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/564x/3f/cc/4f/3fcc4f7a629f16e2989692ad0dc8a292--silent-hill-series-the-room.jpg";
const doorUrl = door;

var nextRooms;

const Butcher = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Butcher_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Butcher_Obj);
  }

  const parseInput = (input) => {
    switch (input[0]) {
      case "partOne":
        changeDisplay("partOne");
        break;
      case "partTwo":
        changeDisplay("partTwo");
        break;
      case "partThree":
        changeDisplay("partThree");
        break;
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "partOne":
        component = <PartOne output={parseInput} />;
        break;
      case "partTwo":
        component = <PartTwo output={parseInput} />;
        break;
      case "partThree":
        component = <PartThree rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Butcher</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        Dim light flickers on grimy grease-slick walls. Countertops line the
        walls with rusty knives on top next to rotten steaks. Old pots rusted
        and coated with grease hang on wicked hooks. The smell of rancid meat
        presses on you. Grease and muck drips from the ceiling. A door to a
        cellar stands open at the far end of the room, chill air wafting out as
        flies buzz around.{" "}
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Butcher_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Butcher_Obj.route);
      }}
    >
      <img src={Butcher_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Butcher_Obj = {
  name: "Butcher",
  room: Butcher,
  entry: Entryway,
  route: "/halloween2022/butcher",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Butcher;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partOne":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        You don't see any other way out. You must approach the cellar
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partOne"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Approach the Cellar
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const PartOne = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partTwo":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        The smell grows stronger as you approach the cellar. You start walking
        down, down, down, wooden stairs creek as you take the last few steps.
        Wicked hooks adorn the walls and hang from the ceiling. Red flame casts
        red light on red walls, coated in blood and fat. To your horror, the
        hooks are not empty. Dozens of human limbs hang from the hooks. Hands,
        arms, even heads and limbless torsoes, cut open along the ribs with the
        organs removed. You see a door on the far side of the room, but you must
        push through the forest of limbs to make it to the other side.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partTwo"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Walk Through the Forest of Limbs
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input: function
//output: level
const PartTwo = (props) => {
  const [errText, setErrText] = useState("");
  const [option, setOption] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (option != "") {
      setErrText("");
      switch (option) {
        case "partThree":
          props.output([option]);
          break;
      }
    } else {
      setErrText("Something went wrong");
    }
  };

  return (
    <div>
      <p className="roomText">
        As you disturb the hanging limbs, they fall into their neighbors,
        creating a soft wet slapping sound. Did that arm just... twitch? You
        look more closely. Oh yes, it definitely twitched. You move a litte
        faster through the forest toward the door. The limbs don't like this.
        They come to life, trying to grab at you and pull you. They might even
        succeed once or twice.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <button
          className="inputSubmit"
          type="submit"
          value="partThree"
          onClick={(e) => {
            setOption(e.target.value);
          }}
        >
          Run Through the Forest
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PartThree = (props) => {
  return (
    <div>
      <div className="roomText">
        You break through the other side of the forest of limbs and they calm
        down. Having passed through to the other side, two doors lie before you.
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
