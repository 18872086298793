import { NavLink } from "react-router-dom";
import "./Home.css";
import forest from "./DiyIcons/forest.png";
import castle from "./DiyIcons/castle.png";
import cogIcon from "./DiyIcons/CityOfGoldIcon.png";
import CharSelectMenu from "./Components/charSelectMenu.js";

export default function Home() {
  const CharSelectMenuPackage = CharSelectMenu();
  // console.log(CharSelectMenuPackage)

  const CharSelectHandler = () => {
    switch (String(CharSelectMenuPackage[2])) {
      case "undefined":
        return <></>;
      case "":
        return <></>;
      default:
        return CharSelectWarning();
    }
  };

  const CharSelectWarning = () => {
    let submissions;
    if (CharSelectMenuPackage[1] && CharSelectMenuPackage[1].submissions) {
      submissions = CharSelectMenuPackage[1].submissions;
    } else {
      // Displays nothing in the warning spot if submissions weren't requested.
      return <p></p>;
    }
    const selections = CharSelectMenuPackage[2];
    localStorage.setItem("selectedChars", JSON.stringify(selections));
    const warningChars = [];
    for (let i in selections) {
      if (selections[i].value in submissions) {
        warningChars.push(selections[i]);
      }
    }
    const warningData = {}; // warningData completes in this format: "characterName": ["DIYName1", "DIYName2"].
    for (let i in warningChars) {
      warningData[warningChars[i].value] = submissions[warningChars[i].value];
    }

    var warningResponse = ""; // Seperates the string to be rendered from warningData so nothing renders when warningData is empty.
    const noDuplicates = {};
    for (let char in warningData) {
      // For char in warningData.
      noDuplicates[char] = [];
      warningResponse = warningResponse.concat(
        `Warning! ${char} has already submitted: `
      );
      for (let i in warningData[char]) {
        // For warning in warningData[char].
        if (!noDuplicates[char].includes(warningData[char][i])) {
          warningResponse = warningResponse.concat(`${warningData[char][i]} `);
          noDuplicates[char].push(warningData[char][i]);
        }
      }
      warningResponse = warningResponse.concat(
        `this week! Any more submissions to this/these DIYs will not give more rewards.\n`
      );
    }

    return <p>{warningResponse}</p>;
  };

  const NavLinkHandler = (
    whichLink,
    maxPlayers = 100,
    selectedChars = CharSelectMenuPackage[2]
  ) => {
    const availableNavLinks = {
      GatheringWood: <NavLink to="GatheringWood" />,
      Halloween2022: <NavLink to="Halloween2022" />,
      CityOfGold: <NavLink to="CityOfGold" className="GatheringWood" />,
    };
    const charAmountCarrier = {};
    if (selectedChars || selectedChars.length === 0) {
      charAmountCarrier.amount = selectedChars.length;
    } else {
      charAmountCarrier.amount = 0;
    }
    if (charAmountCarrier.amount === 0) {
      return <div className="DIYButtonWarning">Choose a Character!</div>;
    }
    if (maxPlayers >= charAmountCarrier.amount) {
      return availableNavLinks[whichLink];
    } else {
      return <div className="DIYButtonWarning">Too many players selected!</div>;
    }
  };

  function autoPlayHandler(e) {
    // Let the user disable autoplay for accessability.
    localStorage.setItem("disableAutoPlay", String(e.target.checked));
  }

  return (
    <div className="home">
      <h1>Welcome to the DIY Homepage!</h1>
      <h2>Select the participating character(s):</h2>
      <div className="char-select-menu-container">
        {CharSelectMenuPackage[0]}
      </div>
      <div className="char-select-handler-container">{CharSelectHandler()}</div>
      <h2>Available DIYs:</h2>
      <nav>
        <ul className="DIYListContainer">
          <li>
            <img src={forest} alt="Forest" />
            {NavLinkHandler("GatheringWood", 1)}
            <p>Gathering Wood</p>
          </li>
          <li>
            <img src={castle} alt="Castle" />
            {NavLinkHandler("Halloween2022")}
            <p>Halloween 2022</p>
          </li>
          <li>
            <img src={cogIcon} alt="CityOfGold" />
            {NavLinkHandler("CityOfGold")}
            <p>City of Gold</p>
          </li>
        </ul>
      </nav>
      <label className="autoPlayContainer">
        Disable Music Auto-play:
        <input
          type="checkbox"
          onClick={autoPlayHandler}
          defaultChecked={JSON.parse(localStorage.getItem("disableAutoPlay"))}
        />
      </label>
    </div>
  );
}
