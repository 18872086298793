import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Entrance_Obj } from "./entrance";
import Player, { OneShot } from "../gui/player";
import DifficultyMeter from "../gui/difficultyMeter";
import { Outlet } from "react-router-dom";
import { Chute_Obj } from "./passage_rooms/chute";
import { Empty_Obj } from "./passage_rooms/empty";
import { Hallway_Obj } from "./passage_rooms/hallway";
import { Hole_Obj } from "./passage_rooms/hole";
import { Stairs_Obj } from "./passage_rooms/stairs";
import { Stairs2_Obj } from "./passage_rooms/stairs2";
import { Exit_Obj } from "./exit_rooms/exit";
import { Combat_Obj } from "./combat_rooms/combat";
import { Pit_Obj } from "./skill_rooms/pit_trap";
import ModShelf from "../components/ModShelf";
import { BlockedWell_Obj } from "./respite_rooms/blocked_well";
import { ManaWell_Obj } from "./respite_rooms/mana_well";
import { SmallWell_Obj } from "./respite_rooms/small_well";
import { Atlas_Obj } from "./skill_rooms/atlas";
import { Axes_Obj } from "./skill_rooms/axes";
import { Blinded_Obj } from "./skill_rooms/blinded";
import { Grass_Obj } from "./skill_rooms/grass";
import { Murky_Water_Obj } from "./skill_rooms/murky_water";
import { Nappy_Waters_Obj } from "./skill_rooms/nappy_waters";
import { Remnant_Obj } from "./skill_rooms/remnant";
import { Sapping_Tree_Obj } from "./skill_rooms/sapping_tree";
import { Skywalker_Obj } from "./skill_rooms/skywalker";
import { Thorns_Obj } from "./skill_rooms/thorn";
import { Wonderwall_Obj } from "./skill_rooms/wonderwall";
import { Small_Hoard_Obj } from "./treasure_rooms/small_hoard";
import { Combat2_Obj } from "./combat_rooms/combat2";
import { Boss_Combat_Obj } from "./treasure_rooms/boss_combat";
import { Med_Hoard_Obj } from "./treasure_rooms/med_hoard";
import { Old_Battleground_Obj } from "./treasure_rooms/old_battleground";

import { dynamicEnemies } from "./combat_rooms/dynamicEnemies.js"

const ARENAS = require("./combat_rooms/arenas.json");
const ENEMIES = require("./combat_rooms/enemies.json");

ENEMIES.enemies = [ ...ENEMIES.enemies, ...dynamicEnemies]

//depth at which the peak difficulty is reached.
export const CAPDEPTH = 50;

export const Rooms = {
  passage_rooms: {
    name: "passage",
    rooms: [
      Chute_Obj,
      Empty_Obj,
      Hallway_Obj,
      Hole_Obj,
      Stairs_Obj,
      Stairs2_Obj,
    ],
    startChance: 40,
    endChance: 15,
  },
  respite_rooms: {
    name: "respite",
    rooms: [BlockedWell_Obj, ManaWell_Obj, SmallWell_Obj],
    startChance: 15,
    endChance: 5,
  },
  skill_rooms: {
    name: "skill",
    rooms: [
      Pit_Obj,
      Atlas_Obj,
      Axes_Obj,
      Blinded_Obj,
      Grass_Obj,
      Murky_Water_Obj,
      Nappy_Waters_Obj,
      Remnant_Obj,
      Sapping_Tree_Obj,
      Skywalker_Obj,
      Thorns_Obj,
      Wonderwall_Obj,
    ],
    startChance: 30,
    endChance: 40,
  },
  combat_rooms: {
    name: "combat",
    rooms: [Combat_Obj, Combat2_Obj],
    startChance: 20,
    endChance: 30,
  },
  treasure_rooms: {
    name: "treasure",
    rooms: [
      Small_Hoard_Obj,
      Boss_Combat_Obj,
      Med_Hoard_Obj,
      Old_Battleground_Obj,
    ],
    startChance: 7,
    endChance: 20,
  },
  exit: {
    name: "exit",
    rooms: [Exit_Obj],
    startChance: 20,
    endChance: 20,
  },
};

export const GetRandomRoom = (InstanceVars, currentRoom) => {
  //select the type of room.
  try {
    let total = 0;
    for (const set in Rooms) {
      if (Rooms[set].rooms.length > 0) {
        total += InstanceVars.current.room_chances[set].chance;
        InstanceVars.current.room_chances[set].total = total;
      } else {
        InstanceVars.current.room_chances[set].total = 0;
      }
    }
    let rand = Math.random() * total;
    let type = Rooms.exit;
    for (const set in Rooms) {
      if (rand < InstanceVars.current.room_chances[set].total) {
        type = Rooms[set];
        break;
      }
    }
    //console.log(type);

    //get rooms of a territory
    let terChance = 0;
    switch (InstanceVars.current.territory_metric) {
      case 0:
        terChance = 1;
        break;
      case 1:
        terChance = 0.75;
        break;
      case 2:
        terChance = 0.5;
        break;
      case 3:
        terChance = 0.25;
        break;
      case 4:
        terChance = 0;
        break;
      default:
        terChance = 0;
    }

    let chosenFaction = "theokyr";
    if (Math.random() <= terChance) {
      chosenFaction = "scourge";
    }

    //filter rooms down to rooms of a single faction
    let roomsList = type.rooms.filter((room) => {
      if (currentRoom && currentRoom.name === room.name) {
        return false;
      }
      if (!room.faction || room.faction === "none") {
        return true;
      } else if (room.faction === "theokyr") {
        if (room.faction === chosenFaction) {
          return true;
        } else {
          return false;
        }
      } else if (room.faction === "scourge") {
        if (room.faction === chosenFaction) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });

    //select the individual room.
    total = 0;
    for (let i = 0; i < roomsList.length; i++) {
      if (roomsList[i]) {
        total += roomsList[i].prob;
        roomsList[i].total = total;
      }
    }
    rand = Math.random() * total;
    let chosenRoom = Rooms.exit.rooms[0];
    for (let i = 0; i < roomsList.length; i++) {
      if (rand < roomsList[i].total) {
        chosenRoom = roomsList[i];
        break;
      }
    }
    //console.log(chosenRoom);
    //console.log(Rooms);
    return chosenRoom;
  } catch (e) {
    console.log(e);
  }
};

export const GetRoomOfType = (type, InstanceVars, current) => {
  let room;
  let counter = 0;
  let setType;
  for (const set in Rooms) {
    if (Rooms[set].name == type) {
      setType = Rooms[set];
      break;
    }
  }
  if (!setType) {
    return GetRandomRoom(InstanceVars, current);
  }

  while (!room && counter < 20) {
    room = setType.rooms[Math.floor(setType.rooms.length * Math.random())];
    counter++;
  }

  if (!room) {
    return GetRandomRoom(InstanceVars, current);
  } else {
    return room;
  }
};

export const GetRoomObjectByName = (roomName) => {
  let tempRoom;
  for (const set in Rooms) {
    Rooms[set].rooms.forEach((room) => {
      if (room.name === roomName) {
        tempRoom = room;
        return room;
      }
    });
  }
  return tempRoom;
};

// TODO: remove overrideExits and get the exits from current.exit
export const GetNextRooms = (current, InstanceVars, overrideExits) => {
  //get the next rooms in the rotation.
  let next = [];
  let counter = 0;
  let exitNum = Math.max(overrideExits ? overrideExits : current.exits, 1);
  // console.log(exitNum);
  while (next.length < exitNum && counter <= 20) {
    let temp = GetRandomRoom(InstanceVars, current);
    if (InstanceVars.current.depth >= 50) {
      temp = GetRoomOfType("exit", InstanceVars, current);
      next.push(temp);
    } else if (
      Rooms.treasure_rooms.rooms.includes(current) &&
      !Rooms.exit.rooms.includes(temp)
    ) {
      next.push(temp);
    } else if (
      ["stairs", "hole", "chute"].some((str) =>
        current.name.toLowerCase().includes(str)
      ) &&
      Rooms.exit.rooms.includes(temp)
    ) {
      counter += 1;
      continue;
    } else if (next.length === 0 && Rooms.exit.rooms.includes(temp)) {
      counter += 1;
      continue;
    } else {
      next.push(temp);
    }

    counter += 1;
  }

  if (next.length === 0)
    next.push(GetRoomOfType("exit", InstanceVars, current));

  //reset depth
  if (current == Entrance_Obj) {
    InstanceVars.current.depth = 0;
  } else {
    InstanceVars.current.depth += 1;
  }

  updateRoomChances(InstanceVars);

  return next;
};

export const GetDifficulty = (InstanceVars, tier = 3) => {
  let lowerBound = 10;
  let upperBound = 16;
  let hazard = InstanceVars.current.hazard_metric;
  switch (hazard) {
    case 0:
      upperBound = 16;
      break;
    case 1:
      upperBound = 18;
      break;
    case 2:
      upperBound = 20;
      break;
    case 3:
      upperBound = 22;
      break;
    case 4:
      upperBound = 24;
      break;
    case 5:
      upperBound = 26;
      break;
    case 6:
      upperBound = 30;
      break;
    case 7:
      upperBound = 40;
      break;
    case 8:
      lowerBound = 20;
      upperBound = 40;
      break;
    default:
      upperBound = 16;
      lowerBound = 10;
  }

  if (tier > 5 || tier < 0) {
    throw "Tier cannot be higher than five or lower than zero";
  }
  let diffCurve = 1;
  switch (tier) {
    case 0:
      diffCurve = 40;
      break;
    case 1:
      diffCurve = 20;
      break;
    case 2:
      diffCurve = 10;
      break;
    case 3:
      diffCurve = 6;
      break;
    case 4:
      diffCurve = 3;
      break;
    case 5:
      diffCurve = 1;
      break;
    default:
      diffCurve = 10;
  }

  //account for depth
  diffCurve = Math.max(
    diffCurve - diffCurve * (0.9 / CAPDEPTH) * InstanceVars.current.depth,
    0.1
  );
  let rovingLowerBound =
    lowerBound +
    ((upperBound - lowerBound) / 2) * (InstanceVars.current.depth / CAPDEPTH);
  let rovingUpperBound = rovingLowerBound + (upperBound - lowerBound) / 2;
  return Math.round(
    rovingLowerBound +
      Math.pow(Math.random(), diffCurve) * (rovingUpperBound - rovingLowerBound)
  );
};

export const GetDamage = (InstanceVars, tier = 3) => {
  let lowerBound = 1;
  let upperBound = 10;
  let hazard = InstanceVars.current.hazard_metric;
  switch (hazard) {
    case 0:
      upperBound = 10;
      break;
    case 1:
      upperBound = 12;
      break;
    case 2:
      upperBound = 14;
      break;
    case 3:
      upperBound = 16;
      break;
    case 4:
      upperBound = 18;
      break;
    case 5:
      upperBound = 20;
      break;
    case 6:
      upperBound = 30;
      break;
    case 7:
      upperBound = 40;
      break;
    case 8:
      lowerBound = 5;
      upperBound = 40;
      break;
    default:
      lowerBound = 1;
      upperBound = 10;
  }

  if (tier > 5 || tier < 0) {
    throw "Tier cannot be higher than five or lower than zero";
  }
  let diffCurve = 1;
  switch (tier) {
    case 0:
      diffCurve = 40;
      break;
    case 1:
      diffCurve = 20;
      break;
    case 2:
      diffCurve = 10;
      break;
    case 3:
      diffCurve = 6;
      break;
    case 4:
      diffCurve = 3;
      break;
    case 5:
      diffCurve = 1;
      break;
    default:
      diffCurve = 10;
  }

  //account for depth
  diffCurve = Math.max(
    diffCurve - diffCurve * (0.9 / CAPDEPTH) * InstanceVars.current.depth,
    0.1
  );

  let rovingLowerBound =
    lowerBound +
    ((upperBound - lowerBound) / 2) * (InstanceVars.current.depth / CAPDEPTH);
  let rovingUpperBound = rovingLowerBound + (upperBound - lowerBound) / 2;
  return Math.round(
    rovingLowerBound +
      Math.pow(Math.random(), diffCurve) * (rovingUpperBound - rovingLowerBound)
  );
};

export const getEnemies = (InstanceVars, size = "small") => {
  let combMet = InstanceVars.current.combat_metric;
  let terMet = InstanceVars.current.territory_metric;

  //console.log(InstanceVars.current);

  //select faction
  let terChance = 0;
  switch (terMet) {
    case 0:
      terChance = 1;
      break;
    case 1:
      terChance = 0.75;
      break;
    case 2:
      terChance = 0.5;
      break;
    case 3:
      terChance = 0.25;
      break;
    case 4:
      terChance = 0;
      break;
    default:
      terChance = 0;
  }

  let faction = "theokyr";
  if (Math.random() <= terChance) {
    faction = "scourge";
  }

  //select arena
  let validArenas = ARENAS.arenas.filter(
    (map) =>
      (map.size === size ||
        (size === "boss" && (map.size === "large" || map.size === "boss"))) &&
      (map.faction === "neutral" || map.faction === faction)
  );
  const arena = validArenas[Math.floor(Math.random() * validArenas.length)];

  //set up lower and upper bounds for individual enemies.
  let lowerBound = 10;
  let upperBound = 100;
  let minTier = 0;
  let maxTier = 2;

  //set up a multiplier based on combat metric.
  let combMult = 1;
  switch (combMet) {
    case 0:
      combMult = 1;
      minTier = 0;
      maxTier = 2;
      break;
    case 1:
      combMult = 1;
      upperBound = 150;
      minTier = 0;
      maxTier = 3;
      break;
    case 2:
      combMult = 1.1;
      upperBound = 200;
      minTier = 1;
      maxTier = 4;
      break;
    case 3:
      combMult = 1.2;
      lowerBound = 50;
      upperBound = 250;
      minTier = 2;
      maxTier = 5;
      break;
    case 4:
      combMult = 1.4;
      lowerBound = 50;
      upperBound = 300;
      minTier = 3;
      maxTier = 6;
      break;
    case 5:
      combMult = 1.5;
      lowerBound = 100;
      upperBound = 350;
      minTier = 4;
      maxTier = 7;
      break;
    case 6:
      combMult = 1.6;
      lowerBound = 100;
      upperBound = 400;
      minTier = 5;
      maxTier = 8;
      break;
    case 7:
      combMult = 1.8;
      lowerBound = 150;
      upperBound = 450;
      minTier = 6;
      maxTier = 9;
      break;
    case 8:
      combMult = 2.0;
      lowerBound = 200;
      upperBound = 500;
      minTier = 7;
      maxTier = 10;
      break;
    default:
      combMult = 1;
      lowerBound = 10;
      upperBound = 100;
  }

  //create a multiplier based on the size of the map.
  let mapMult = 1;
  switch (size) {
    case "small":
      mapMult = 0.8;
      break;
    case "medium":
      mapMult = 1;
      break;
    case "large":
      mapMult = 1.25;
      break;
    case "boss":
      mapMult = 1.5;
      break;
  }

  //create a multiplier based on the number of characters in the group.
  let playerMult = Math.min(
    20,
    Math.max(
      1,
      20 * Math.pow(InstanceVars.current.num_characters_alias / 10, 1.3)
    )
  );

  //create a random curve for difficulty based on depth.
  let diffCurve = Math.max(
    1.8 - 1.8 * (0.8 / CAPDEPTH) * InstanceVars.current.depth,
    0.1
  );
  //create a random multiplier based on the curve in the previous step
  let randomMult = Math.pow(Math.random(), diffCurve);

  let [
    flatModMult,
    bossModMult,
    commModMult,
    strongModMult,
    weakModMult,
    boundModMult,
    lowerTierAdd,
    upperTierAdd,
  ] = getCombatModMultipliers(InstanceVars);

  upperBound *= boundModMult;
  lowerBound *= boundModMult;
  maxTier = Math.max(0, Math.min(10, maxTier + upperTierAdd));
  minTier = Math.min(maxTier, Math.max(0, minTier + lowerTierAdd));

  //calculate the random number of points allotted for the combat.
  let rovingLowerBound =
    lowerBound +
    ((upperBound - lowerBound) / 2) * (InstanceVars.current.depth / CAPDEPTH);
  let rovingUpperBound = rovingLowerBound + (upperBound - lowerBound) / 2;
  let randPoints =
    (rovingLowerBound + (rovingUpperBound - rovingLowerBound) * randomMult) *
    combMult *
    mapMult *
    playerMult *
    flatModMult;

  // console.log("lowerBound: ", lowerBound);
  // console.log("upperBound: ", upperBound);
  // console.log("randomMult: ", randomMult);
  // console.log("combMult: ", combMult);
  // console.log("mapMult: ", mapMult);
  // console.log("playerMult: ", playerMult);
  // console.log(randPoints);

  let validSquads = ENEMIES.Squads.filter((group) =>
    size != "boss" ? group.boss === 0 : group.boss > 0
  );
  let squad = validSquads[Math.floor(Math.random() * validSquads.length)];

  // console.log(validSquads);
  // console.log(squad);

  //set up variables for each class of enemy
  let bossEnemyPickingTier = maxTier;
  let bossEnemyTotalPoints = randPoints * squad.boss * bossModMult;
  let commandEnemyPickingTier = Math.max(maxTier - 1, minTier);
  let commandEnemyTotalPoints = randPoints * squad.commander * commModMult;
  let strongEnemyPickingTier = Math.max(maxTier - 2, minTier);
  let strongEnemyTotalPoints = randPoints * squad.strong * strongModMult;
  let weakEnemyPickingTier = minTier;
  let weakEnemyTotalPoints = randPoints * squad.weak * weakModMult;

  let validEnemies = ENEMIES.enemies
    .filter(
      (group) =>
        group.tier >= minTier &&
        group.tier <= maxTier &&
        group.score <= randPoints &&
        group.faction === faction
    )
    .sort((a, b) => {
      if (a.score < b.score) return -1;
      if (a.score > b.score) return 1;
      return 0;
    });

  //choose boss enemies
  let bossEnemy = [];
  let bossEnemyActualPoints = 0;
  if (bossEnemyTotalPoints != 0) {
    let tempEnemies = validEnemies.filter(
      (enemy) =>
        enemy.tier == bossEnemyPickingTier &&
        enemy.score <= bossEnemyTotalPoints
    );
    if (tempEnemies.length > 0) {
      let pick = Math.floor(Math.random() * tempEnemies.length);
      let enemyType = tempEnemies[pick];
      let num = Math.floor(bossEnemyTotalPoints / enemyType.score);
      if (num > 0) {
        bossEnemyActualPoints = enemyType.score * num;
        bossEnemy = new Array(num).fill(enemyType);
      }
    }
  }

  //choose commander enemies
  let commandEnemy = [];
  let commandEnemyActualPoints = 0;
  commandEnemyTotalPoints += bossEnemyTotalPoints - bossEnemyActualPoints;
  if (commandEnemyTotalPoints != 0) {
    let tempEnemies = validEnemies.filter(
      (enemy) =>
        enemy.tier == commandEnemyPickingTier &&
        enemy.score <= commandEnemyTotalPoints
    );
    if (tempEnemies.length > 0) {
      let pick = Math.floor(Math.random() * tempEnemies.length);
      let enemyType = tempEnemies[pick];
      let num = Math.floor(commandEnemyTotalPoints / enemyType.score);
      if (num > 0) {
        commandEnemyActualPoints = enemyType.score * num;
        commandEnemy = new Array(num).fill(enemyType);
      }
    }
  }

  //choose strong enemies
  let strongEnemy = [];
  let strongEnemyActualPoints = 0;
  strongEnemyTotalPoints += commandEnemyTotalPoints - commandEnemyActualPoints;
  if (strongEnemyTotalPoints != 0) {
    let tempEnemies = validEnemies.filter(
      (enemy) =>
        enemy.tier == strongEnemyPickingTier &&
        enemy.score <= strongEnemyTotalPoints
    );
    if (tempEnemies.length > 0) {
      let pick = Math.floor(Math.random() * tempEnemies.length);
      let enemyType = tempEnemies[pick];
      let num = Math.floor(strongEnemyTotalPoints / enemyType.score);
      if (num > 0) {
        strongEnemyActualPoints = enemyType.score * num;
        strongEnemy = new Array(num).fill(enemyType);
      }
    }
  }

  //choose weak enemies
  let weakEnemy = [];
  let weakEnemyActualPoints = 0;
  weakEnemyTotalPoints += strongEnemyTotalPoints - strongEnemyActualPoints;
  if (weakEnemyTotalPoints != 0) {
    let tempEnemies = validEnemies.filter(
      (enemy) =>
        enemy.tier <= weakEnemyPickingTier &&
        enemy.score <= weakEnemyTotalPoints
    );
    if (tempEnemies.length > 0) {
      let pick = Math.floor(Math.random() * tempEnemies.length);
      let enemyType = tempEnemies[pick];
      let num = Math.floor(weakEnemyTotalPoints / enemyType.score);
      if (num > 0) {
        weakEnemyActualPoints = enemyType.score * num;
        weakEnemy = new Array(num).fill(enemyType);
      }
    }
  }
  const allEnemies = {
    boss: bossEnemy,
    commander: commandEnemy,
    strong: strongEnemy,
    weak: weakEnemy,
  };
  const allPoints =
    bossEnemyActualPoints +
    commandEnemyActualPoints +
    strongEnemyActualPoints +
    weakEnemyActualPoints;

  //console.log(allEnemies);
  //console.log(squad);

  return [allEnemies, arena, allPoints];
};

const getCombatModMultipliers = (InstanceVars) => {
  let flatMult = 1;
  let bossMult = 1;
  let commMult = 1;
  let strongMult = 1;
  let weakMult = 1;
  let boundMult = 1;
  let lowerTierAdd = 0;
  let upperTierAdd = 0;
  InstanceVars.current.modifiers.forEach((mod) => {
    switch (mod.name) {
      case "Horde":
        upperTierAdd -= 1;
        flatMult += 0.5;
        break;
      case "Elite Enemies":
        boundMult += 0.5;
        lowerTierAdd += 1;
        flatMult -= 0.25;
        break;
      default:
        break;
    }
  });

  return [
    flatMult,
    bossMult,
    commMult,
    strongMult,
    weakMult,
    boundMult,
    lowerTierAdd,
    upperTierAdd,
  ];
};

export const updateRoomChances = (InstanceVars) => {
  const depth = InstanceVars.current.depth;
  var d = Math.min(depth / CAPDEPTH, 1);

  for (const type in Rooms) {
    InstanceVars.current.room_chances[type].chance =
      Rooms[type].startChance +
      (Rooms[type].endChance - Rooms[type].startChance) * d;
  }
};

export const changeSong = (url) => {
  const player = document.getElementById("music");
  if (player && player.src.indexOf(url) == -1) {
    player.src = url;
    player.volume = 0.5;
  }
};

export const playOneShot = (url) => {
  const player = document.getElementById("oneShot");
  if (player && player.src != url) {
    player.src = url;
    player.volume = 0.5;
  }
};

export const GlobalContext = createContext();

const CurrentRoom = () => {
  const InstanceVars = useRef({
    characters: [],
    num_characters_alias: 0,
    level: 0,
    depth: 0,
    difficultyRamp: 0.5,
    combat_metric: 1,
    hazard_metric: 1,
    modifiers: [],
    territory_metric: 0,
    treasure_rating: 0,
    room_chances: {
      passage_rooms: {
        name: "passage",
        chance: Rooms.passage_rooms.startChance,
        total: 0,
      },
      respite_rooms: {
        name: "respite",
        chance: Rooms.respite_rooms.startChance,
        total: 0,
      },
      skill_rooms: {
        name: "skill",
        chance: Rooms.skill_rooms.startChance,
        total: 0,
      },
      combat_rooms: {
        name: "combat",
        chance: Rooms.combat_rooms.startChance,
        total: 0,
      },
      treasure_rooms: {
        name: "treasure",
        chance: Rooms.treasure_rooms.startChance,
        total: 0,
      },
      exit: { name: "exit", chance: Rooms.exit.startChance, total: 0 },
    },
    treasures_earned: [],
    rasps_earned: 0,
    extra_curses: [],
    score_mult: 0,
    score: { combat: 0, depth: 0, skill: 0, escape: 0 },
  });
  return (
    <GlobalContext.Provider value={InstanceVars}>
      <div className="roomHolder">
        <Player song={""} />
        <OneShot />
        <ModShelf />
        <Outlet />
      </div>
    </GlobalContext.Provider>
  );
};

export default CurrentRoom;
