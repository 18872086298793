import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../gui/difficultyMeter";
import doorSfx from "../res/sfx/Misc II.wav";
import song from "../res/music/Darker Atmos.wav";
import { changeSong, GlobalVars, playOneShot } from "./room_handler";

const bannerUrl =
  "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/25af0516362765.562aa9000ed9a.jpg";

const Death = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    playOneShot(doorSfx);
  }, []);
  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Death</h1>
      <img className="banner" src={bannerUrl} />
      <div className="descriptionText">
        <h2 className="deathText">You are dead</h2>
        You become a ghost in a random part of the maze with your memories
        intact. If you are not resurrected in an hour, you will automatically
        come back to life. You must wait an hour before trying again.
        <br />
        <br />
        [Experience created by Erik Manley and Farwulf Svarti]
        <button
          className="inputSubmit"
          onClick={() => {
            navigate("/halloween2022/");
          }}
        >
          Return to the Entrance
        </button>
      </div>
    </div>
  );
};

export default Death;
