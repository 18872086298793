import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDamage,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://thumbs.dreamstime.com/b/twisted-manila-rope-isolated-black-background-39922428.jpg";
const doorUrl = door;

var nextRooms;

const Tightrope = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    nextRooms = GetNextRooms(Tightrope_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Tightrope_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 2) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Tightrope</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You stand on a stone platform over a black abyss. The bottom of the
        abyss cannot be seen. The room is lit by everburning torches along the
        walls that are well out of reach from the platform. 300 feet away (~90
        meters) another platform can be seen and you think you see the
        silhouette of a door. A swaying Tightrope stretches between your
        platform and the one in the far distance.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Tightrope_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Tightrope_Obj.route);
      }}
    >
      <img src={Tightrope_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Tightrope_Obj = {
  name: "Tightrope",
  room: Tightrope,
  entry: Entryway,
  route: "/halloween2022/tightrope",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Tightrope;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        In order to proceed, you must cross the Tightrope. Make three Agility
        (Dex) checks to safely walk across it, OR make three Athletics (Str)
        checks to hang upside down and use your arms and legs to crawl across.
        Input the results of your rolls into the boxes below.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> You step off the rope onto the far platform, having
          succeeded at your task. Taking a deep breath, you look at the path
          before you.
        </div>
      </div>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  const lb = [];
  for (let i = 0; i < 40; i++) {
    lb.push(<br key={i} />);
  }

  let damage = getDamage(["1d6", "1d8", "2d4", "1d10", "2d6"]);

  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> You lose your balance (agility) or you lose your grip
          (athletics) and fall into the abyss. You fall.
          {lb.map((i) => i)}
          And keep falling.
          {lb.map((i) => i)}
          And keep falling.
          {lb.map((i) => i)}
          <h3 style={{ color: "aqua", textAlign: "center", fontSize: "5em" }}>
            SPLASH
          </h3>
          You fall into a deep pool of water. You take <b>{damage} damage</b>{" "}
          from your fall! If this doesn't kill you, you break the surface of the
          water with a splutter. On the far end of the room is a platform with a
          door.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2022/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
