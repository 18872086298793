import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  getDamage,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://www.total-croatia-news.com/images/news/tortureum-torture-museum-zagreb-2.jpg";
const doorUrl = door;

var nextRooms;

const Blades = () => {
  const [display, changeDisplay] = useState("pre");
  const [pass, setPass] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Blades_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Blades_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    setPass(successes);
    changeDisplay("pass");
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView successes={pass} rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Blades</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You enter a room and see a veritable gauntlet of blades. Pendulum blades
        swing from overhead, poles with blades at all angles spin, sawblades
        move along the ground in grooves at regular intervals.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Blades_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Blades_Obj.route);
      }}
    >
      <img src={Blades_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Blades_Obj = {
  name: "Blades",
  room: Blades,
  entry: Entryway,
  route: "/halloween2022/blades",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Blades;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        You think you can see a way through. Roll three Agility (Dex) checks and
        input them into the boxes below.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  const navigate = useNavigate();
  let damage = getDamage(["d8", "d10", "d10", "d10", "d12"]);
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          You make it to the other side. You take{" "}
          {3 - props.successes > 0 ? (
            <b style={{ color: "red" }}>
              {3 - props.successes}
              {damage}
            </b>
          ) : (
            <b style={{ color: "green" }}>No</b>
          )}{" "}
          Damage! Roleplay your success or death. If you are still alive, you
          have two doors before you and can proceed.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2022/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
