import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../rooms/room_handler";
import { useNavigate } from "react-router-dom";

export const save = (instanceVars, curRoom, nextRooms) => {
  const localSave = {
    time: getWeekInfo(),
    iVars: instanceVars,
    cRoom: curRoom,
    nRooms: nextRooms,
  };
  localStorage.setItem("COG_save", JSON.stringify(localSave));
};

export const LoadButton = () => {
  const [iVars, setIVars] = useState();
  const [cRoom, setCRoom] = useState();
  const [nRooms, setNRooms] = useState();
  const [showButton, setShowButton] = useState(false);
  const instanceVars = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    let [tempV, tempC, tempR] = load();
    if (tempV) {
      setIVars(tempV);
      setCRoom(tempC);
      setNRooms(tempR);
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, []);

  const clickHandler = (e) => {
    e.preventDefault();
    instanceVars.current = { ...iVars, currentRoom: cRoom, nextRooms: nRooms };
    navigate(cRoom.route);
  };

  return (
    <div className="loadHolder">
      {showButton ? (
        <>
          <span className="inputLabel">There was a saved game detected:</span>
          <span className="inputLabel">depth: {iVars.depth}</span>
          <input
            type="button"
            className="inputSubmit"
            value="Load Game"
            onClick={clickHandler}
          />
          <span className="inputLabel">
            <b>NOTE:</b> If you proceed without loading you will lose your
            progress
          </span>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export const deleteSave = () => {
  localStorage.removeItem("COG_save");
};

const load = () => {
  const localSave = JSON.parse(localStorage.getItem("COG_save"));
  if (localSave && localSave.time && localSave.time === getWeekInfo()) {
    return [localSave.iVars, localSave.cRoom, localSave.nRooms];
  }
  localStorage.removeItem("COG_save");
  return [null, null, null];
};

const getWeekInfo = () => {
  let date = new Date();
  let year = new Date(date.getFullYear(), 0, 1);
  let days = Math.floor((date - year) / (24 * 60 * 60 * 1000));
  let week = Math.ceil((date.getDay() + 2 + days) / 7);
  let info = week.toString() + year.getFullYear().toString() + "2";
  return info;
};
