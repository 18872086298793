import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/rooms/COG_Image__00283_.png";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import banner from "../../res/rooms/COG_Image_00009_.png";
import song from "../../res/music/Disorienting.mp3";
import {
  CAPDEPTH,
  changeSong,
  GetNextRooms,
  GetRoomObjectByName,
  GlobalContext,
  playOneShot,
} from "../room_handler";
import RandomRoomTreasure from "../../components/Loot";
import { save } from "../../functions/save";
import { EntryPercResults, Perception } from "../../components/perception";

const bannerUrl = banner;
const doorUrl = door;

const Old_Battleground = () => {
  const InstanceVars = useContext(GlobalContext);
  const [nextRooms, changeNextRooms] = useState([]);
  const [toggleUnveil, changeToggleUnveil] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (InstanceVars.current.nextRooms) {
      loadVals();
    } else {
      let nextRoomTemp = GetNextRooms(
        Old_Battleground_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Old_Battleground_Obj.exits)
      );
      save(InstanceVars.current, Old_Battleground_Obj, nextRoomTemp);
      InstanceVars.current.score.depth += 100;
      changeNextRooms(nextRoomTemp);
    }
  }, [InstanceVars]);

  const loadVals = () => {
    let nextRoomTemp = [];
    InstanceVars.current.nextRooms.forEach((room) => {
      let tempRoom = GetRoomObjectByName(room.name);
      if (tempRoom) nextRoomTemp.push(tempRoom);
    });
    if (nextRoomTemp.length > 0) {
      delete InstanceVars.current.nextRooms;
      delete InstanceVars.current.currentRoom;
    } else {
      nextRoomTemp = GetNextRooms(
        Old_Battleground_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Old_Battleground_Obj.exits)
      );
    }
    changeNextRooms(nextRoomTemp);
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Old battleground</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        An old battleground filled with twisted skeletons adorned in ancient
        armor. Ornate weapons lie about in various states of disrepair. You can
        certainly find treasure among the corpses, given enough time.
      </p>
      {!toggleUnveil ? (
        <>
          <p className="roomText">Would you like to search the corpses?</p>
          <input
            value="Search the Corpses"
            type="button"
            className="takeTreasureButton"
            onClick={() => {
              changeToggleUnveil(true);
            }}
          />{" "}
        </>
      ) : (
        <RandomRoomTreasure chance={1} chanceForCurse={0.2} tier={3} />
      )}
      <Perception />
      <div className="nextHolder">
        {nextRooms.map((room, i) => {
          return room.entry(i, navigate);
        })}
      </div>
    </div>
  );
};

const Entryway = (i = 0, navigate) => {
  return (
    <button
      key={Old_Battleground_Obj.name + toString(i)}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Old_Battleground_Obj.route);
      }}
    >
      <img src={Old_Battleground_Obj.image} />
      <span className="entryText">A dark cave leading into the unknown</span>
      <EntryPercResults
        observation={{
          tier: 4,
          text: "The path ahead is fairly undisturbed.",
        }}
        spirituality={{
          tier: 5,
          text: "A feeling of conflicted sanctity resides in this direction.",
        }}
      />
    </button>
  );
};

export var Old_Battleground_Obj = {
  name: "Old_Battleground",
  room: Old_Battleground,
  entry: Entryway,
  route: "/CityOfGold/old_battleground",
  exits: 1,
  prob: 2,
  image: doorUrl,
};

export default Old_Battleground;
