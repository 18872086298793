import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://cdna.artstation.com/p/assets/images/images/011/488/134/large/phillip-michaels-castle4.jpg?1529856234";
const doorUrl =
  "https://cdna.artstation.com/p/assets/images/images/011/488/134/large/phillip-michaels-castle4.jpg?1529856234";

var nextRooms;

const Hallway = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    nextRooms = GetNextRooms(Hallway_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Hallway_Obj);
  }

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Hallway</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        Your footsteps echo in the long hallway and leave prints in the
        undisturbed dust blanketing the floor. Dull torches burn in sconces
        along the walls, sustaining their sickly flame off the residual magic in
        the air. Two doors lie on each side of the hallway with one door at the
        far end.
      </p>
      <p className="roomText">Choose a door. Choose wisely.</p>
      <DemonScript />
      <div className="nextHolder">
        {nextRooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Hallway_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Hallway_Obj.route);
      }}
    >
      <img src={Hallway_Obj.image} />
      <span className="entryText">A dark hallway stretches before you.</span>
    </button>
  );
};

export var Hallway_Obj = {
  name: "Hallway",
  room: Hallway,
  entry: Entryway,
  route: "/halloween2022/hallway",
  exits: 5,
  prob: 5,
  image: doorUrl,
};

export default Hallway;
