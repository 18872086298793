import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Death from "./rooms/death";
import Acidpit from "./rooms/death_rooms/acidpit";
import Blink from "./rooms/death_rooms/blink";
import Boulder from "./rooms/death_rooms/boulder";
import Gargoyle from "./rooms/death_rooms/gargoyle";
import Riddles from "./rooms/death_rooms/riddle";
import Spider from "./rooms/death_rooms/spider";
import Spikepit from "./rooms/death_rooms/spikepit";
import Spikes from "./rooms/death_rooms/spikes";
import Vampire from "./rooms/death_rooms/vampire";
import Werewolf from "./rooms/death_rooms/werewolf";
import Entrance from "./rooms/entrance";
import Exit from "./rooms/exit_rooms/exit";
import DoorsRiddle from "./rooms/key_rooms/doorsRiddle";
import ForestKey from "./rooms/key_rooms/forestKey";
import Gambling from "./rooms/key_rooms/gambling";
import Masquerade from "./rooms/key_rooms/masquerade";
import Slime from "./rooms/key_rooms/slimeKey";
import Butcher from "./rooms/odd_rooms/butcher";
import Catacombs from "./rooms/odd_rooms/catacombs";
import Classroom from "./rooms/odd_rooms/classroom";
import Dolls from "./rooms/odd_rooms/doll";
import Family from "./rooms/odd_rooms/family";
import Fault from "./rooms/odd_rooms/fault";
import FunHouse from "./rooms/odd_rooms/funhouse";
import Gold from "./rooms/odd_rooms/gold";
import Gore from "./rooms/odd_rooms/gore";
import Masks from "./rooms/odd_rooms/masks";
import Noise from "./rooms/odd_rooms/noise";
import Nursery from "./rooms/odd_rooms/nursery";
import Visitor from "./rooms/odd_rooms/visitor";
import Aquaeduct from "./rooms/passage_rooms/aquaeduct";
import Bridge from "./rooms/passage_rooms/bridge";
import Chute from "./rooms/passage_rooms/chute";
import Dungeon from "./rooms/passage_rooms/dungeon";
import Empty from "./rooms/passage_rooms/empty";
import Hallway from "./rooms/passage_rooms/hallway";
import Hole from "./rooms/passage_rooms/hole";
import Sewer from "./rooms/passage_rooms/sewer";
import Stairs from "./rooms/passage_rooms/stairs";
import Stairs2 from "./rooms/passage_rooms/stairs2";
import CurrentRoom from "./rooms/room_handler";
import Barrier from "./rooms/skill_rooms/barrier";
import Black from "./rooms/skill_rooms/black";
import Blades from "./rooms/skill_rooms/blades";
import Ghost from "./rooms/skill_rooms/ghost";
import HellHound from "./rooms/skill_rooms/hellhound";
import Holes from "./rooms/skill_rooms/holes";
import Madness from "./rooms/skill_rooms/madness";
import Mirrors from "./rooms/skill_rooms/mirrors";
import PuzzleBox from "./rooms/skill_rooms/puzzle";
import Rodents from "./rooms/skill_rooms/rodents";
import Shadow from "./rooms/skill_rooms/shadow";
import Snakes from "./rooms/skill_rooms/snake";
import Tightrope from "./rooms/skill_rooms/tightrope";

const HalloweenRouterHandler = () => {
  return (
    <Routes>
      <Route path="/" element={<CurrentRoom />}>
        <Route index element={<Entrance />} />
        <Route path="tightrope" element={<Tightrope />} />
        <Route path="stairs" element={<Stairs />} />
        <Route path="hallway" element={<Hallway />} />
        <Route path="exit" element={<Exit />} />
        <Route path="chute" element={<Chute />} />
        <Route path="nursery" element={<Nursery />} />
        <Route path="funhouse" element={<FunHouse />} />
        <Route path="forest" element={<ForestKey />} />
        <Route path="stairs2" element={<Stairs2 />} />
        <Route path="exit2" element={<Werewolf />} />
        <Route path="spikes" element={<Spikes />} />
        <Route path="boulder" element={<Boulder />} />
        <Route path="vampire" element={<Vampire />} />
        <Route path="gargoyle" element={<Gargoyle />} />
        <Route path="room1" element={<Spikepit />} />
        <Route path="blink" element={<Blink />} />
        <Route path="holes" element={<Holes />} />
        <Route path="blades" element={<Blades />} />
        <Route path="rodents" element={<Rodents />} />
        <Route path="cave1" element={<Slime />} />
        <Route path="acidpit" element={<Acidpit />} />
        <Route path="classroom" element={<Classroom />} />
        <Route path="masks" element={<Masks />} />
        <Route path="catacombs" element={<Catacombs />} />
        <Route path="gore" element={<Gore />} />
        <Route path="gold" element={<Gold />} />
        <Route path="family" element={<Family />} />
        <Route path="dolls" element={<Dolls />} />
        <Route path="fault" element={<Fault />} />
        <Route path="noise" element={<Noise />} />
        <Route path="butcher" element={<Butcher />} />
        <Route path="hole" element={<Hole />} />
        <Route path="aquaeduct" element={<Aquaeduct />} />
        <Route path="sewer" element={<Sewer />} />
        <Route path="dungeon" element={<Dungeon />} />
        <Route path="bridge" element={<Bridge />} />
        <Route path="empty" element={<Empty />} />
        <Route path="snakes" element={<Snakes />} />
        <Route path="barrier" element={<Barrier />} />
        <Route path="visitor" element={<Visitor />} />
        <Route path="masquerade" element={<Masquerade />} />
        <Route path="gambling" element={<Gambling />} />
        <Route path="doors" element={<DoorsRiddle />} />
        <Route path="blackroom" element={<Black />} />
        <Route path="shadow" element={<Shadow />} />
        <Route path="puzzle" element={<PuzzleBox />} />
        <Route path="death" element={<Death />} />
        <Route path="ghost" element={<Ghost />} />
        <Route path="hellhound" element={<HellHound />} />
        <Route path="madness" element={<Madness />} />
        <Route path="mirrors" element={<Mirrors />} />
        <Route path="spider" element={<Spider />} />
        <Route path="riddles" element={<Riddles />} />
      </Route>
    </Routes>
  );
};

export default HalloweenRouterHandler;
