import { useContext, useState } from "react";
import "./ModShelf.css";
import { GlobalContext } from "../rooms/room_handler";

const ModShelf = (props) => {
  const [toggleOpen, setToggleOpen] = useState(0);
  const InstanceVars = useContext(GlobalContext);
  console.log(InstanceVars);
  return (
    <div open-shelf={toggleOpen} className="ModShelf">
      <div className="ModShelfHolder">
        {InstanceVars.current.characters.length === 0 ? (
          ""
        ) : (
          <div className="ModShelfCharHolder">
            <h3>Characters</h3>
            {InstanceVars.current.characters.map((char, i) => {
              return (
                <div key={char.name + i} className="ModShelfCharacter">
                  <span>{char.name}:</span>{" "}
                  <div>
                    <input type="number" />/<input type="number" />
                    <label>hp</label>
                  </div>
                  <div>
                    <input type="number" />
                    /30 <label>thp</label>
                  </div>
                  <div>
                    <label>Notes:</label>
                    <input type="text" />
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {InstanceVars.current.modifiers.length === 0 ? (
          ""
        ) : (
          <div className="ModShelfModHolder">
            <h3>Modifiers</h3>
            {InstanceVars.current.modifiers.map((mod, i) => {
              return (
                <div key={mod.name + i}>
                  <span>
                    <b>{mod.name}:</b>
                  </span>{" "}
                  <span>{mod.description}</span>
                </div>
              );
            })}
          </div>
        )}
        {InstanceVars.current.treasures_earned.length === 0 ? (
          ""
        ) : (
          <div className="ModShelfTreasureHolder">
            <h3>Treasures</h3>
            {InstanceVars.current.treasures_earned.map((item, i) => {
              return (
                <div key={item.name + i}>
                  <span>
                    <b>{item.name}</b>
                  </span>{" "}
                  <span>
                    <b>Type:</b> {item.type}
                  </span>
                  <span>
                    <b>Effect:</b> {item.effect}
                  </span>
                  <span>
                    <b>Description:</b> {item.description}
                  </span>
                  {item.curse ? (
                    <span>
                      <b>Curse:</b> {item.curse.name} <b>Curse Effect: </b>{" "}
                      {item.curse.effect} <b>Curse Cure Conditions: </b>{" "}
                      {item.curse.cureConditions.map((e, i, a) => {
                        return (
                          i + 1 + ") " + e + (i == a.length - 1 ? "" : " -- ")
                        );
                      })}
                    </span>
                  ) : (
                    ""
                  )}
                  <span>
                    <b>Value:</b> {item.value} rasps
                  </span>
                </div>
              );
            })}
          </div>
        )}
        {InstanceVars.current.rasps_earned <= 0 ? (
          ""
        ) : (
          <span className="ModShelfRaspsHolder">
            <b>Rasps Earned:</b> {InstanceVars.current.rasps_earned}
          </span>
        )}
        {InstanceVars.current.extra_curses.length === 0 ? (
          ""
        ) : (
          <div className="ModShelfCursesHolder">
            <h3>Other Curses</h3>
            {InstanceVars.current.extra_curses.map((curse, i) => {
              return (
                <div key={curse.name + i}>
                  <span>
                    <b>{curse.name}:</b>
                  </span>{" "}
                  <span>{curse.effect}</span>{" "}
                  <span>
                    Cure Conditions:{" "}
                    {curse.cureConditions.map((e, i, a) => {
                      return (
                        i + 1 + ") " + e + (i == a.length - 1 ? "" : " -- ")
                      );
                    })}
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div open-shelf={toggleOpen} className="ModShelfButtonHolder">
        <input
          type="button"
          id="modShelfButton"
          onClick={() => setToggleOpen(toggleOpen == 0 ? 1 : 0)}
          open-shelf={toggleOpen}
        />
        <label open-shelf={toggleOpen} htmlFor="modShelfButton">
          {">"}
        </label>
      </div>
    </div>
  );
};

export default ModShelf;
