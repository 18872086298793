import "./Demonic.css";
import { useState } from "react";

const script = [
  { text: "Lakh", translation: "here" },
  { text: "nokha", translation: "lies" },
  { text: "an", translation: "the" },
  { text: "shwi", translation: "seventh" },
  { text: "barkh", translation: "son" },
  { text: "diu", translation: "of" },
  { text: "Ramuta", translation: "Ramuta" },
  { text: "N'darta", translation: "devout" },
  { text: "Alhud", translation: "only" },
  { text: "ani", translation: "they" },
  { text: "tam'maz", translation: "untainted" },
  { text: "gaw", translation: "in" },
  { text: "djimma", translation: "blood" },
  { text: "in", translation: "may" },
  { text: "laiz", translation: "speak" },
  { text: "shma", translation: "name" },
  { text: "u", translation: "and" },
  { text: "iskakh", translation: "retrieve" },
  { text: "ouva", translation: "that" },
  { text: "emne", translation: "which" },
  { text: "zawe", translation: "was" },
  { text: "tmur", translation: "entombed" },
  { text: "l'am", translation: "with" },
  { text: "qatih", translation: "him" },
];

const DemonScript = () => {
  const [randIndex] = useState(Math.floor(Math.random() * script.length));
  const [randShow] = useState(Math.random());
  return randShow < 0.1 ? (
    <div className="demonText">
      You see a number of words in an unknown language masterfully carved into
      the wall. You also see some scratches next to one of the words as if
      someone has carved into it over many hours with a knife. As you
      investigate closer, you realize the scratchings are in common.
      <br />
      <b>Unknown Text:</b>{" "}
      <span className="demonFont">{script[randIndex].text}</span>
      <br />
      <b>Scratchings:</b> {script[randIndex].translation}
    </div>
  ) : (
    ""
  );
};

export default DemonScript;
