import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/564x/0b/e9/3c/0be93cd50cf3afb4871dc026f205a279.jpg";
const doorUrl = door;

var nextRooms;

const Empty = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    nextRooms = GetNextRooms(Empty_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Empty_Obj);
  }

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Empty</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        An unnerving feeling washes over you as you enter the room. You look
        around and see it completely empty. Bare stone walls with nothing
        adorning them. Amidst all that you have seen, all that you have
        experienced here, nothing is more off putting. No room is simply empty.
      </p>
      <p className="roomText">
        You might make a perception check to find something in this room, but
        you would find nothing. There are three paths leading out of the room.
        Overcome your trust issues! It should be fine..right?
      </p>
      <DemonScript />
      <div className="nextHolder">
        {nextRooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Empty_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Empty_Obj.route);
      }}
    >
      <img src={Empty_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Empty_Obj = {
  name: "Empty",
  room: Empty,
  entry: Entryway,
  route: "/halloween2022/empty",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Empty;
