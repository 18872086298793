import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "./room_handler";
import "./loot_divvy.css";
import postSubmittionData from "../../../GoogleAPI/postReward";
import { useNavigate } from "react-router-dom";
import { deleteSave } from "../functions/save";

const ALLTREASURE = require("../data/treasure.json");

const Loot_Divvy = () => {
  const InstanceVars = useContext(GlobalContext);
  const [unclaimedItems, setUnclaimedItems] = useState([]);
  const [unclaimedRasps, setUnclaimedRasps] = useState(0);
  const [claimedItems, setClaimedItems] = useState({});
  const [claimedRasps, setClaimedRasps] = useState({});
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedClaimedItem, setSelectedClaimedItem] = useState({});
  const [errorText, setErrorText] = useState("");
  const [score, setScore] = useState(0);
  const [favor, setFavor] = useState(0);
  const [claimedFavor, setClaimedFavor] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    /* testing Vars */
    // if (InstanceVars.current.characters.length <= 0) {
    //   InstanceVars.current.characters = [
    //     { name: "bologna" },
    //     { name: "king" },
    //     { name: "banana" },
    //     { name: "pie" },
    //     { name: "apples" },
    //   ];
    //   InstanceVars.current.rasps_earned = 25;
    //   let tempTreasure = [...ALLTREASURE.treasures];
    //   for (let i = 0; i < 9; i++) {
    //     let tempItem = tempTreasure.splice(
    //       Math.floor(Math.random() * tempTreasure.length),
    //       1
    //     );
    //     InstanceVars.current.treasures_earned =
    //       InstanceVars.current.treasures_earned.concat(tempItem);
    //   }
    //   InstanceVars.current.score.combat = 3000;
    //   InstanceVars.current.score.depth = 400;
    //   InstanceVars.current.score.skill = 2500;
    //   InstanceVars.current.score.escape = 1000;
    //   InstanceVars.current.score_mult = 1.35;
    // }
    /*end testing vars*/

    //score var setup
    let tempScore = Math.round(
      (InstanceVars.current.score.combat +
        InstanceVars.current.score.depth +
        InstanceVars.current.score.skill +
        InstanceVars.current.score.escape) *
        InstanceVars.current.score_mult
    );
    let tempFavor = Math.round(tempScore / 1000);
    setScore(tempScore);
    setFavor(tempFavor);

    //treasure var setup
    if (
      InstanceVars.current.treasures_earned &&
      InstanceVars.current.treasures_earned.length > 0
    ) {
      setUnclaimedItems([...InstanceVars.current.treasures_earned]);
    }
    setUnclaimedRasps(InstanceVars.current.rasps_earned);

    let tempClaimed = {};
    let tempSelectedClaimed = {};
    let tempClaimedRasps = {};
    let tempClaimedFavor = {};
    if (
      InstanceVars.current.characters &&
      InstanceVars.current.characters.length > 0
    ) {
      InstanceVars.current.characters.forEach((char) => {
        tempClaimed[char.name] = [];
        tempSelectedClaimed[char.name] = "";
        tempClaimedRasps[char.name] = 0;
        tempClaimedFavor[char.name] = 0;
      });
    }
    setClaimedRasps({ ...tempClaimedRasps });
    setClaimedItems({ ...tempClaimed });
    setSelectedClaimedItem({ ...tempSelectedClaimed });
    setClaimedFavor({ ...tempClaimedFavor });
    // console.log(InstanceVars);
  }, []);

  const giveItemToHandler = (e, character) => {
    // console.log(unclaimedItems);
    // console.log(claimedItems);
    // console.log(selectedItem);
    if (selectedItem == "") {
      console.log("nothing selected");
      return;
    }
    let claimedTemp = { ...claimedItems };
    let tempItem = unclaimedItems.find((item) => item.name === selectedItem);
    claimedTemp[character].push(tempItem);

    let deleteIndex = unclaimedItems.findIndex(
      (item) => item.name === selectedItem
    );
    let unclaimedTemp = [...unclaimedItems];
    unclaimedTemp.splice(deleteIndex, 1);

    // console.log(claimedTemp);
    // console.log(deleteIndex);
    // console.log(unclaimedTemp);

    setSelectedItem("");
    setClaimedItems(claimedTemp);
    setUnclaimedItems(unclaimedTemp);
  };

  const removeItemFromHandler = (e, character) => {
    // console.log(unclaimedItems);
    // console.log(claimedItems);
    // console.log(selectedClaimedItem[character]);
    if (selectedClaimedItem[character] == "") {
      console.log("nothing selected");
      return;
    }

    let unclaimedTemp = [...unclaimedItems];
    let tempItem = claimedItems[character].find(
      (item) => item.name === selectedClaimedItem[character]
    );
    unclaimedTemp.push(tempItem);

    let deleteIndex = claimedItems[character].findIndex(
      (item) => item.name === selectedClaimedItem[character]
    );
    let claimedTemp = { ...claimedItems };
    claimedTemp[character].splice(deleteIndex, 1);

    // console.log(claimedTemp);
    // console.log(deleteIndex);
    // console.log(unclaimedTemp);

    let selectedTemp = { ...selectedClaimedItem };
    selectedTemp[character] = "";
    setSelectedClaimedItem(selectedTemp);
    setClaimedItems(claimedTemp);
    setUnclaimedItems(unclaimedTemp);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    //this is where Ender's submission function goes.
    try {
      InstanceVars.current.characters.forEach((char) => {
        // console.log(char.name + "'s claimed rewards:");
        // console.log(favor - claimedFavor[char.name] * 2);
        // console.log(claimedFavor[char.name]);
        // console.log(claimedRasps[char.name]);
        // console.log(claimedItems[char.name]);
        if (unclaimedRasps < 0) {
          throw "Unclaimed rasps cannot be less than 0. Reduce the number of rasps given to people and resubmit.";
        }

        //set up the item string.
        let itemRewards = "";
        claimedItems[char.name].forEach((item) => {
          itemRewards +=
            "Name: " +
            item.name +
            "; Type: " +
            item.type +
            "; Tier: " +
            item.tier +
            "; Effect: " +
            item.effect +
            "; Description: " +
            item.description +
            (item.curse
              ? "; Curse: " +
                item.curse.name +
                "; Curse Effect: " +
                item.curse.effect +
                "; Cure Condition(s): " +
                item.curse.cureConditions.map((e, i, a) => {
                  return i + 1 + ") " + e + (i == a.length - 1 ? "" : " -- ");
                })
              : "") +
            "; Item Value: " +
            (item.value + (item.curse ? item.curse.value : 0)) +
            (item.set != null ? "; Item Set: " + item.set : "") +
            "\n";
        });

        //set up the submission object.
        let submission = {
          characterName: char.name,
          DIYName: "City of Gold (QM: Tempestia)",
          advFavor: favor - claimedFavor[char.name] * 2,
          champFavor: Number(claimedFavor[char.name]),
          rasps: claimedRasps[char.name],
          otherRewards: itemRewards,
          otherData: "",
        };

        // console.log(submission);
        postSubmittionData(submission)
          .then(() => console.log("submission for " + char.name + " accepted"))
          .catch((e) =>
            console.log(
              "Error on submission for " + char.name + "; Error: " + e
            )
          );
      });
    } catch (e) {
      setErrorText(e);
    }
    deleteSave();
    navigate("/");
  };

  return (
    <div className="lootDivvyHolder">
      <div className="lootDivvyHeader">
        <h2>Loot</h2>
        <div>
          <span>
            {InstanceVars.current.death
              ? "You died and lost all treasures and loot found."
              : "Congratulations on escaping the ruins with your treasures! Here's what you found:"}
          </span>
          <br />
          <span className="lootDivvyRaspsDisplay">
            <b>Rasps: </b>
            {InstanceVars.current.rasps_earned}
            {InstanceVars.current.extra_curses.length === 0 ? (
              ""
            ) : (
              <span>
                <br />
                <b>Other Curses: </b>
                {InstanceVars.current.extra_curses.map((curse, i) => {
                  return (
                    <div key={curse.name + i}>
                      <span>
                        <b>{curse.name}:</b>
                      </span>{" "}
                      <span>{curse.effect}</span>{" "}
                      <span>
                        Cure Conditions:{" "}
                        {curse.cureConditions.map((e, i, a) => {
                          return (
                            i + 1 + ") " + e + (i == a.length - 1 ? "" : " -- ")
                          );
                        })}
                      </span>
                    </div>
                  );
                })}
              </span>
            )}
          </span>

          {InstanceVars.current.treasures_earned &&
          InstanceVars.current.treasures_earned.length > 0 ? (
            <div className="lootDivvyItemsDisplay">
              <b>Items: </b>
              <ul>
                {InstanceVars.current.treasures_earned.map((item) => {
                  return (
                    <li>
                      <b>{item.name}</b>
                      <br />
                      <b>Type: </b>
                      {item.type}
                      <br />
                      <b>Effect: </b>
                      {item.effect}
                      <br />
                      {item.curse ? (
                        <>
                          <b>{item.curse.name}: </b>
                          {item.curse.effect}
                          <br /> <b>Cure Conditions: </b>{" "}
                          {item.curse.cureConditions.map((e, i, a) => {
                            return (
                              i +
                              1 +
                              ") " +
                              e +
                              (i == a.length - 1 ? "" : " -- ")
                            );
                          })}
                          <br />
                          {""}
                        </>
                      ) : (
                        ""
                      )}
                      <b>Description: </b>
                      {item.description}
                      <br />
                      <b>Value: </b>
                      {item.value}
                      <br />
                      {item.set ? (
                        <>
                          <b>Set: </b>
                          {item.set}
                          <br />
                          {""}
                        </>
                      ) : (
                        ""
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            ""
          )}
        </div>
        <h2>Score</h2>
        <div className="lootDivvyFavorDisplay">
          <span>
            <b>Depth Score: </b>
            {InstanceVars.current.score.depth}
          </span>
          <span>
            <b>Skill Score: </b>
            {InstanceVars.current.score.skill}
          </span>
          <span>
            <b>Combat Score: </b>
            {InstanceVars.current.score.combat}
          </span>
          <span>
            <b>Escape Score: </b>
            {InstanceVars.current.score.escape}
          </span>
          <span>
            <b>Score Multiplier: </b>
            {InstanceVars.current.score_mult}
          </span>
          <span>
            <b>Total Score: </b>
            {score}
          </span>
          <span>
            <b>Favor Earned (score/1000): </b>
            {favor}
          </span>
        </div>
        <div></div>
      </div>
      <div className="lootDivvyPlayers">
        <h3>Divvy up your loot among the players</h3>
        <div className="lootDivvyUnclaimedHolder">
          <div className="lootDivvyRaspsLeft">
            <b
              style={
                unclaimedRasps < 0 ? { color: "red" } : { color: "inherit" }
              }
            >
              Rasps Left:
            </b>{" "}
            {unclaimedRasps}
          </div>
          {InstanceVars.current.treasures_earned &&
          InstanceVars.current.treasures_earned.length > 0 ? (
            <div className="lootDivvyItemsLeft">
              <b>Items Left:</b>
              <select
                size={5}
                value={selectedItem}
                onChange={(e) => {
                  setSelectedItem(e.target.value);
                }}
              >
                <option>{""}</option>
                {unclaimedItems
                  ? unclaimedItems.map((item) => {
                      return <option>{item.name}</option>;
                    })
                  : ""}
              </select>
              <div className="lootDivvyItemsButtons">
                {InstanceVars.current.characters
                  ? InstanceVars.current.characters.map((char) => {
                      return (
                        <input
                          type="button"
                          onClick={(e) => {
                            giveItemToHandler(e, char.name);
                          }}
                          value={"Give Item to " + char.name}
                        />
                      );
                    })
                  : ""}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="lootDivvyClaimedHolder">
          {InstanceVars.current.characters
            ? InstanceVars.current.characters.map((char) => {
                return (
                  <div>
                    <span className="lootDivvyCharacterName">{char.name}</span>
                    {InstanceVars.current.treasures_earned &&
                    InstanceVars.current.treasures_earned.length > 0 ? (
                      <div className="lootDivvyCharacterItems">
                        <span>Items:</span>
                        <select
                          size={5}
                          value={selectedClaimedItem[char.name]}
                          onChange={(e) => {
                            let tempSelected = { ...selectedClaimedItem };
                            tempSelected[char.name] = e.target.value;
                            setSelectedClaimedItem(tempSelected);
                          }}
                        >
                          <option>{""}</option>
                          {claimedItems && claimedItems[char.name]
                            ? claimedItems[char.name].map((item) => {
                                return <option>{item.name}</option>;
                              })
                            : ""}
                        </select>
                        <input
                          type="button"
                          onClick={(e) => {
                            removeItemFromHandler(e, char.name);
                          }}
                          value={"Remove Selected"}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <span>Rasps:</span>
                    <input
                      type="number"
                      value={claimedRasps[char.name]}
                      min={0}
                      onChange={(e) => {
                        let tempRasps = { ...claimedRasps };
                        let tempPreVal = tempRasps[char.name];
                        tempRasps[char.name] = e.target.value;
                        setClaimedRasps(tempRasps);
                        setUnclaimedRasps(
                          unclaimedRasps - (e.target.value - tempPreVal)
                        );
                      }}
                    />
                    <div className="lootDivvyFavorSelector">
                      <span>Champion Favor:</span>
                      <input
                        type="number"
                        value={claimedFavor[char.name]}
                        min={0}
                        max={Math.floor(favor / 2)}
                        onChange={(e) => {
                          let tempFavor = { ...claimedFavor };
                          tempFavor[char.name] = e.target.value;
                          setClaimedFavor(tempFavor);
                        }}
                      />
                      <span
                        style={
                          favor - claimedFavor[char.name] * 2 < 0
                            ? { color: "red" }
                            : { color: "inherit" }
                        }
                      >
                        Adventurer Favor: {favor - claimedFavor[char.name] * 2}
                      </span>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
        <input
          type="button"
          value="Submit Treasures and Exit"
          onClick={submitHandler}
          className="divvySubmitButton"
        />
        <span className="divvyErrorMessage">{errorText}</span>
      </div>
    </div>
  );
};

export default Loot_Divvy;
