import { useEffect, useState } from "react";
import door from "../res/generic_door.png";
import song from "../res/music/Down Time Piano.wav";
import sfx from "../res/sfx/Magic Spell P.wav";
import { useNavigate } from "react-router-dom";
import {
  changeSong,
  GetNextRooms,
  GlobalVars,
  playOneShot,
} from "./room_handler";
import DifficultyMeter from "../gui/difficultyMeter";

const bannerUrl =
  "https://i.pinimg.com/564x/be/fa/7b/befa7b1282427a01f8462fc0419b1869.jpg";
const doorUrl = door;

var nextRooms;

const Entrance = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    nextRooms = GetNextRooms(Entrance_Obj);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Entrance_Obj);
  }

  const parseInput = (input) => {
    GlobalVars.level = input;
    GlobalVars.keys = 0;
    GlobalVars.depth = 0;
    //make it slightly easier for low level players.
    if (GlobalVars.level <= 10) {
      GlobalVars.difficultyRamp = 0.3333;
      GlobalVars.maxDifficulty = 24;
    }
    changeDisplay("post");
  };

  const getComponent = () => {
    let component;
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "post":
        component = <PostInput rooms={nextRooms} />;
        break;
      default:
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Entrance</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        The Castle of Miitha. Built long ago by a demon to safeguard five keys.
        You leave your weapons and enchanted items at the door. Your magic is
        likewise blocked by the chaotic magics at work here. Venture forth,
        brave traveller, and claim the treasures of the deep.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Entrance_Obj.name}
      className="entry"
      onClick={navigate(Entrance_Obj.route)}
    >
      <img src={Entrance_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export const Entrance_Obj = {
  name: "Entrance",
  room: Entrance,
  entry: Entryway,
  route: "/halloween2022",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Entrance;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (input > 0 && input <= 20) {
      setErrText("");
      props.output([input]);
    } else {
      setErrText("Please input a valid level");
    }
  };

  return (
    <div>
      <h2>PLEASE READ EVERYTHING BEFORE STARTING</h2>
      <p className="roomText">
        Welcome to Castle Miitha. You are about to embark on an experience
        intended for both frights and fun. This is a roleplay experience
        intended to be played primarily in the Syndicate discord server, using
        this website as a facilitator for roleplay.
        <br />
        <br />
        Once you enter, <b>there is no going back.</b> If you refresh the page
        at any point, you will automatically be sent back to the beginning. If
        this happens unintentionally, roleplay it as the castle's strangeness
        sending you back to the beginning and start over. If you ever need to
        start over, all progress and items gained are lost.
        <br />
        <br />
        <b>DON'T CHEAT.</b> If you refresh the page to dodge the consequences of
        a poor roll or to avoid a particular encounter, that is considered
        cheating. If you are found to be cheating, the castle's magic will bar
        you from entering again for the duration of the event.
        <br />
        <br />
        <b>PHOBIA WARNING</b> -- There are many rooms in this castle filled with
        frightening things. During the course of this event, you WILL run into
        things intended to frighten and potentially trigger phobias. If you feel
        you will have a problem roleplaying these events in the discord server,
        I suggest you turn back now.
        <br />
        <br />
      </p>
      <h2>How to Play</h2>
      <p className="roomText">
        Roleplay your experience in one of the overflow channels. Try to get a
        channel specific to your runthrough that nobody else is in. In order for
        your runthrough to count, you must tag @Beriale Wardhaven (Erik Manley)
        or @farwulf 'Crow' Svarti when you start a run. Favor will be given for
        good roleplay. The more effort you put into it, the more favor you'll
        get.
        <br />
        <br />
        Because this adventure is a roleplay experience, you must roleplay your
        character moving through each room. Describe what your character sees,
        feels, or thinks. Describe your character's reactions to events.
        <br />
        <br />
        You CAN copy and paste descriptions that occur into the discord and
        count that as part of your roleplay. Ideally, anyone reading your
        roleplay should be able to follow you through the castle and read enough
        context for it to flow like a story.
        <br />
        <br />
        <b>This website is NOT where you play.</b> It is only a guide to
        facilitate your roleplay. If you do not roleplay it out, it does not
        happen. End of Story.
        <br />
        <br />
        The rooms are randomized, so it's difficult to enter the castle with a
        group, as people are quickly separated. If you choose to enter together,
        you will be split up, but compare which rooms you're in so you can meet
        up with each other later on.
        <br />
        <br />
        During the course of your adventure you'll encounter prompts telling you
        to roll with input boxes and submission buttons (much like the input box
        and button seen below).
        <br />
        <br />
        You will use your discord dice to roll those skill checks along with any
        bonuses from skills, abilities, and feats that your character actually
        has access to. That number will be compared to a number that will not be
        shown to you. You will simply succeed, or fail, and consequences will be
        doled out accordingly.
        <br />
        <br />
        There are some puzzles that exist within the halls of the castle. If you
        cannot figure them out, feel free to compare notes with other
        characters. Sharing information is intended to be an <b>
          in character
        </b>{" "}
        roleplay opportunity. Please avoid sharing information out of character.
        <br />
        <br />
        If you run into a bug, please take a screenshot and post it to Erik
        Manley. He will take a look and try to fix it. You will not be penalized
        for problems due to a bug.
        <br />
        <br />
      </p>
      <h2>Restrictions</h2>
      <p className="roomText">
        There are a few restrictions to what you can do: The castle will not
        allow you to enter with any weapons or enchanted items, nor will it
        allow you to cast magic, or use given abilities/advanced class
        abilities. Any attempts to fly will immediately return you out of the
        castle with none of the goodies you may have found.
        <br />
        <br />
        You also can't use any feat that requires a Questmaster's Input. The
        circumstances of each roll should be clear, so if you're not sure, the
        answer is probably no.
        <br />
        <br />
        We're going off of the honor system here, don't ruin it for everybody.
        Cheating will only ruin the story for you and your friends. Also, if
        your character dies, they die, no arguing. Sometimes you'll just take
        damage. If your HP hits 0, obviously, you'll die to whatever damaged
        you. If you are downed at any point, you instantly die. The upside is
        that you cannot gain levels of resurrection sickness by dying in this
        area, and you can't lose any personal items in the castle, since you
        can't bring any with you. If an NPC dies, they will appear in a random
        area outside the castle with no memory of their experience inside the
        castle. If a player dies, they become a ghost. If no player is around to
        resurrect them, they will be brought back to life NO SOONER than one
        hour after death occurs (real time) with no resurrection sickness. They
        will appear outside the castle in a random location with their memory
        intact. Lost limbs and other status effects must be dealt with the
        normal way.
        <br />
        <br />
        You may make multiple attempts, however, you can only start an attempt{" "}
        <b>ONCE every HOUR.</b> It also gets more difficult the higher level you
        are as well as the longer you stay inside. Do not stay too long lest the
        darkness take you forever.
        <br />
        <br />
      </p>
      <h2>The Objective</h2>
      <p className="roomText">
        Your objective, should you choose to accept it is to find keys and
        escape. There are five keys hidden throughout the castle behind devilish
        challenges. You will not be told which rooms are key rooms. If you do
        manage to find a key, you can choose to then find an exit, or continue
        searching for keys. If you manage to leave with a key, ping @Beriale
        Wardhaven (Erik Manley) to report it. He will record the success and
        change the code to reflect the missing key if others enter that room.
        <br />
        <br />
      </p>
      <h2>Summary</h2>
      <ul>
        <li>Post your RP and rolls in an overflow channel</li>
        <li>
          Tag @Beriale Wardhave (Erik Manley) or @Farwulf 'Crow' Svarti at the
          start of your quest
        </li>
        <li>More RP = More Favor</li>
        <li>
          You can't use weapons, magic, given abilities, AC's, and you can't
          fly. Standard feats and skill rolls can be used.
        </li>
        <li>
          Anyone who dies in the castle will be brought back to life after an
          hour in a random location outside of the castle with nothing they
          gathered during their time in the castle. NPC's lose their memory,
          PC's don't.
        </li>
        <li>
          There is a one hour (real time) mandatory cooldown if you decide not
          to complete a runthrough, or if you die.
        </li>
        <li>
          If you think something might be cheating, don't do it. I will not
          hesitate to ban someone from participation if cheating might be
          occurring
        </li>
      </ul>
      <p className="roomText">
        <br />
        <br />
        If you accept these terms, please proceed by entering your character
        level in the box below.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">Please input your level:</label>
        <input
          className="inputField"
          type="text"
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Proceed Forward
        </button>
        <label className="error">{errText}</label>
      </form>
      <h2>A Few Ideas to Spice Things Up</h2>
      <ul>
        <li>
          There are a number of wayward travellers stuck in this castle. Some
          may have been stuck there recently, while others may have been there
          for a while. Possibly a long while.
        </li>
        <li>You'll certainly run across bones of past travellers.</li>
        <li>
          Feel free to roll a few extra skill checks if you can find a reason to
          in order to spice up the roleplay.
        </li>
        <li>
          Choose a few phobias your character has and have fun with them. It's
          not likely they're perfectly stoic about every challenge laid before
          them. It will be more fun for you and for me to read if your character
          has an emotional reaction to the horrors of the castle.
        </li>
        <li>
          Feel free to add horror flare to your roleplay. Your character may
          hear whispers, they may see terrible visions, they may have nightmares
          come to life. They may see things out of the corner of their eye.
          There's a lot of strange magic in this castle and a lot of strange
          things can happen. Have fun with it. (As long as it doesn't give you a
          benefit)
        </li>
      </ul>
    </div>
  );
};

//input rooms
const PostInput = (props) => {
  playOneShot(sfx);
  return (
    <div>
      <p className="roomText">
        You enter the castle, perhaps for the first time and perhaps not. It
        seems quiet enough. Still, you feel vulnerable without your weapons,
        armor, or magic.
        <br />
        <br />
        Stone tile floors echo your footsteps to high rafters. Faded banners of
        a long gone house stand proud on walls lit by ever burning candles. One
        grand ornate window sheds eerie light in from the outside world.
        <br />
        <br />
        The great doors of the castle close behind you, three more doors lead
        out of the entry hall. Who knows what could lie behind them?
      </p>
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};
