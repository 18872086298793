import "./HalloweenMain.css";
import HalloweenRouterHandler from "./HalloweenRouterHandler";

function HalloweenMain() {
  return (
    <div className="HalloweenApp">
      <HalloweenRouterHandler />
    </div>
  );
}

export default HalloweenMain;
