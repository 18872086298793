import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/rooms/COG_Image__00283_.png";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/Disorienting.mp3";
import banner from "../../res/rooms/COG_Image__00153_.png";
import {
  CAPDEPTH,
  changeSong,
  GetDifficulty,
  GetNextRooms,
  GetRoomObjectByName,
  GlobalContext,
  playOneShot,
} from "../room_handler";
import SkillCheckSelect from "../../components/SkillCheckSelect";
import RandomRoomTreasure from "../../components/Loot";
import { save } from "../../functions/save";
import { EntryPercResults, Perception } from "../../components/perception";

const bannerUrl = banner;
const doorUrl = door;

const Blinded = () => {
  const [display, changeDisplay] = useState("pre");
  const InstanceVars = useContext(GlobalContext);
  const [nextRooms, changeNextRooms] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (InstanceVars.current.nextRooms) {
      loadVals();
    } else {
      let nextRoomTemp = GetNextRooms(
        Blinded_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Blinded_Obj.exits)
      );
      save(InstanceVars.current, Blinded_Obj, nextRoomTemp);
      InstanceVars.current.score.depth += 100;
      changeNextRooms(nextRoomTemp);
    }
  }, []);

  const loadVals = () => {
    let nextRoomTemp = [];
    InstanceVars.current.nextRooms.forEach((room) => {
      let tempRoom = GetRoomObjectByName(room.name);
      if (tempRoom) nextRoomTemp.push(tempRoom);
    });
    if (nextRoomTemp.length > 0) {
      delete InstanceVars.current.nextRooms;
      delete InstanceVars.current.currentRoom;
    } else {
      nextRoomTemp = GetNextRooms(
        Blinded_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Blinded_Obj.exits)
      );
    }
    changeNextRooms(nextRoomTemp);
  };

  const parseInput = (input) => {
    if (input == "next") {
      InstanceVars.current.score.skill += 300;
      changeDisplay("pass");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "pre":
      default:
        component = <PreInput output={parseInput} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Blinded</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        The moment you enter this room, a blinding flash assaults you without
        warning.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = (i = 0, navigate) => {
  return (
    <button
      key={Blinded_Obj.name + i}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Blinded_Obj.route);
      }}
    >
      <img src={Blinded_Obj.image} />
      <span className="entryText">A dark cave leading into the unknown</span>
      <EntryPercResults
        mysticism={{ tier: 3, text: "You sense light magic at work ahead." }}
        spirituality={{
          tier: 5,
          text: "Residual feelings of shocked surprise come from this direction.",
        }}
      />
    </button>
  );
};

export var Blinded_Obj = {
  name: "Blinded",
  room: Blinded,
  entry: Entryway,
  route: "/CityOfGold/blinded",
  exits: 4,
  prob: 5,
  image: doorUrl,
  faction: "none",
};

export default Blinded;

//input: function
//output: level
const PreInput = (props) => {
  const [ableToContinue, setAbleToContinue] = useState(false);
  const navigate = useNavigate();

  //console.log(props.output);
  const submitHandler = (selection) => {
    let blank = false;
    selection.forEach((sel) => {
      if (sel.pass === undefined) blank = true;
      return;
    });
    if (!blank) {
      console.log("next");
      setAbleToContinue(true);
    }
  };

  return (
    <div>
      <p className="roomText">
        A blinding flash assaults you without warning! Roll a spirituality (spi)
        check to sense the assault coming, or an endurance (body) check to
        recover from the flash quickly.
      </p>
      <span className="inputLabel">
        Choose a skill to use and then roll it. Input the number into the box.
        If you have a way of feasibly instant-succeeding on the check, select
        "instant." <br />
        Natural 1: input 0 in your box. <br />
        Natural 20: Add 1d6 to the roll and input the final score into your box.
      </span>
      <SkillCheckSelect
        options={[
          { method: "spirituality (spi)", diffTier: 5, damTier: 0 },
          { method: "endurance (body)", diffTier: 3, damTier: 0 },
        ]}
        noDamage
        succeedEffect="You manage to escape the blindness, but your vision is still blurry. You suffer -2 to all checks that require sight for 2 rooms."
        specialEffect="You receive the 'blinded' status effect for 3 rooms."
        callback={submitHandler}
      />
      <input
        type="button"
        value="Continue"
        className="skillCheckButton skillCheckSubmit"
        disabled={!ableToContinue}
        onClick={() => props.output("next")}
      />
      <input
        type="button"
        value="Dead"
        className="skillCheckButton skillCheckDeath"
        disabled={!ableToContinue}
        onClick={() => {
          navigate("/CityOfGold/death");
        }}
      />
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>Survived!</b> You make it to the other side. Where do you go from
          here?
        </div>
        <RandomRoomTreasure
          chance={0.05}
          chanceForCurse={0.2}
          tier={Math.floor(Math.random() * 3)}
        />
      </div>
      <Perception />
      <div className="nextHolder">
        {props.rooms.map((room, i) => {
          return room.entry(i, navigate);
        })}
      </div>
    </div>
  );
};
