import {
  useContext,
  useEffect,
  useState,
  useReducer,
  useCallback,
} from "react";
import door from "../res/generic_door.png";
import song from "../res/music/Mother of Deceit.wav";
import banner from "../res/banners/CityOfGoldEntrance.png";
import sfx from "../res/sfx/Infected Mind Ambience.mp3";
import { useNavigate } from "react-router-dom";
import {
  CAPDEPTH,
  changeSong,
  GetNextRooms,
  GlobalContext,
  playOneShot,
} from "./room_handler";
import DifficultyMeter from "../gui/difficultyMeter";
import seedrandom from "seedrandom";
import GetModifiers from "../functions/modifiers";
import { LoadButton } from "../functions/save";
import { Perception } from "../components/perception";

const bannerUrl = banner;
const doorUrl = door;

var nextRooms;

const Entrance = () => {
  const [display, changeDisplay] = useState("pre");
  const InstanceVars = useContext(GlobalContext);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    nextRooms = GetNextRooms(Entrance_Obj, InstanceVars);
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Entrance_Obj, InstanceVars);
  }

  const parseInput = (input, stage) => {
    // console.log(input);
    if (stage === "one") {
      InstanceVars.current.characters = input;
      InstanceVars.current.depth = 0;
      let maxLevel = Math.max(...input.map((item) => item.level));
      InstanceVars.current.level = maxLevel;
      changeDisplay("mods");
    } else if (stage === "two") {
      changeDisplay("post");
    }
  };

  const getComponent = () => {
    let component;
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "mods":
        component = <ModSelection output={parseInput} />;
        break;
      case "post":
        component = <PostInput rooms={nextRooms} />;
        break;
      default:
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Entrance</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        The ancient city of Nyktoros. Built long ago by ancient Elysians and
        banished from the surface for unknown reasons. Buildings of stone and
        gold still stand, a monument to Elysian engineering, but not untouched
        by the ravages of time or the former inhabitants that still dwell here.
        What dangers await below? What treasures did the Elysians leave behind?
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Entrance_Obj.name}
      className="entry"
      onClick={navigate(Entrance_Obj.route)}
    >
      <img src={Entrance_Obj.image} />
      <span className="entryText">
        Heavy wooden doors, long rotted with age hang open on rusty hinges.
      </span>
    </button>
  );
};

export const Entrance_Obj = {
  name: "Entrance",
  room: Entrance,
  entry: Entryway,
  route: "/",
  exits: 3,
  prob: 5,
  image: doorUrl,
};

export default Entrance;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");

  const [numCharacters, setNumCharacters] = useState(1);
  const [characters, setCharacters] = useState([{}]);

  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    console.log(JSON.parse(localStorage.getItem("selectedChars")));
    let savedChars = JSON.parse(localStorage.getItem("selectedChars"));
    let tempChars = [];
    let tempNum = 0;
    for (let i = 0; i < savedChars.length; i++) {
      tempChars[i] = { name: savedChars[i].value, level: 1 };
      tempNum++;
    }
    setNumCharacters(tempNum);
    setCharacters(tempChars);
  }, []);

  //console.log(props.output);
  const submitHandler = (e) => {
    setErrText("");
    let error = 0;
    for (let item of e) {
      if (item.name === undefined || item.name === "") {
        setErrText("Please input a name into all boxes.");
        error = 1;
        break;
      } else if (
        item.level === undefined ||
        item.level === "" ||
        parseInt(item.level) > 20 ||
        parseInt(item.level < 1)
      ) {
        error = 1;
        setErrText("Please input a level between 1 and 20 into all boxes.");
        break;
      }
    }

    if (error == 0) props.output(e, "one");
  };

  return (
    <div>
      <h2>PLEASE READ EVERYTHING BEFORE STARTING</h2>
      <p className="roomText">
        Welcome to city of Nyktoros. You are about to embark on an experience
        intended to entertain and reward the courageous. This is a roleplay
        experience intended to be played primarily in the Syndicate discord
        server, using this website as a facilitator for roleplay.
        <br />
        <br />
        Once you enter, <b>there is no going back.</b> If you refresh the page
        at any point, you will automatically be sent back to the beginning. If
        this happens unintentionally, roleplay it as the city's strangeness
        sending you back to the beginning and start over. If you ever need to
        start over, all progress and items gained are lost.
        <br />
        <br />
        <b>DON'T CHEAT.</b> If you refresh the page to dodge the consequences of
        a poor roll or to avoid a particular encounter, that is considered
        cheating. If you are found to be cheating, the city's magic will bar you
        from entering again until the DIY manager can review the incident.
        <br />
        <br />
        <b>PHOBIA WARNING</b> -- There are many caves, caverns, and buildings in
        this city filled with frightening things. During the course of this
        event, you WILL run into things intended to frighten and they may
        trigger phobias. If you feel you will have a problem roleplaying these
        events in the discord server, I suggest you turn back now.
        <br />
        <br />
      </p>
      <h2>How to Play</h2>
      <p className="roomText">
        Roleplay your experience in one of the overflow channels on the Realms
        of the Syndicate server. Try to get a channel specific to your
        runthrough that nobody else is in. In order for your runthrough to
        count, you must tag @Beriale Wardhaven (Erik Manley) when you start a
        run. Favor will be given for good roleplay. The more effort you put into
        it, the more favor you'll get. Favor is also given based on how deep you
        go during your run.
        <br />
        <br />
        Because this adventure is a roleplay experience, you must roleplay your
        character moving through each room. Describe what your character sees,
        feels, or thinks. Describe your character's reactions to events.
        <br />
        <br />
        You CAN copy and paste descriptions that occur into the discord and
        count that as part of your roleplay. Ideally, anyone reading your
        roleplay should be able to follow you through the city and read enough
        context for it to flow like a story.
        <br />
        <br />
        <b>This website is NOT where you play.</b> It is only a guide to
        facilitate your roleplay. If you do not roleplay it out, it does not
        happen. End of Story.
        <br />
        <br />
        You are encouraged to play as a group with other people. One person runs
        the website while everyone roleplays. The facilitator should share their
        screen or copy-paste descriptions and images to other players.
        <br />
        <br />
        During the course of your adventure you'll encounter prompts telling you
        to roll with input boxes and submission buttons (much like the input box
        and button seen below).{" "}
        <b>
          You DO have access to all feats, skills, weapons, inventory, and other
          abilities of your character while on this DIY.
        </b>
        <br />
        <br />
        You will use your discord dice to roll skill checks along with any
        bonuses from skills, abilities, and feats that your character actually
        has access to. That number will be compared to a number that will not be
        shown to you. You will simply succeed, or fail, and consequences will be
        doled out accordingly.
        <br />
        <br />
        There are some puzzles that exist within the city. If you cannot figure
        them out, feel free to compare notes with other characters. Sharing
        information is intended to be an <b>in character</b> roleplay
        opportunity. Please avoid sharing information out of character.
        <br />
        <br />
        If you run into a bug, please take a screenshot and post it to Erik
        Manley. He will take a look and try to fix it. You will not be penalized
        for problems due to a bug.
        <br />
        <br />
      </p>
      <h2>Saving Your Game</h2>
      <p className="roomText">
        The game saves your progress automatically. It gets saved to your
        current computer, so if you switch computers or delete your
        cache/cookies, you will lose your run. You will be prompted to load your
        game on this screen if there is a game available. If there is not, you
        will not be shown the option.
        <br />
        <br />
        If you die or submit your run, your save game is automatically deleted.{" "}
        <b>
          If you do not complete your run by the end of the week, it will also
          be automatically deleted.
        </b>
        <br />
        <br />
        <b>Save scumming will not be tolerated.</b> Save scumming is when you
        reload your saved game to escape an unfavorable outcome. If you are
        found to be save scumming, you will be banned from the DIY.
      </p>
      <h2>Restrictions</h2>
      <p className="roomText">
        There are a few restrictions to what you can do:
        <br />
        You can't use any feat that requires a Questmaster's Input, since there
        is no questmaster present.
        <br />
        <br />
        <b>
          Mana resets only happen in specific locations within the city.
        </b>{" "}
        <ul>
          <li>
            Mana resets do not happen on the normal timer during combat or
            otherwise.{" "}
          </li>
          <li>
            Combat abilities also do not regenerate outside of mana resets (if
            they require them).
          </li>{" "}
          <li>
            You can sap mana from enemies using abilities{" "}
            <b>(twice per enemy)</b>.
          </li>{" "}
          <li>
            If you have an ability that allows you to regenerate mana outside of
            mana resets, you can only regenerate mana{" "}
            <b>until your mana equals a quarter of your total mana pool.</b> If
            you are in a room that allows mana resets, you can use your
            abilities to regenerate your mana to full.
          </li>
        </ul>
        <br />
        <br />
        We're going off of the honor system here, don't ruin it for everybody.
        Cheating will only ruin the story for you and your friends. Also, if
        your character dies, they die, no arguing. Sometimes you'll just take
        damage. If your HP hits 0, obviously, you'll die to whatever damaged
        you. If you are downed at any point, and don't have any way to heal
        yourself, you die. If a player dies, they become a ghost as normal and
        gain resurrection sickness if applicable.
        <br />
        <br />
        You can only enter the city <b>ONCE per WEEK.</b> It also gets more
        difficult the longer you stay inside. Do not stay too long lest the
        darkness take you forever.
        <br />
        <br />
      </p>
      <h2>The Objective</h2>
      <p className="roomText">
        Your objective, should you choose to accept it is to find treasure and
        escape. There is quite a bit of treasure to be found in the city. If you
        do manage to find treasure, you can choose to then find an exit, or
        continue searching for more treasure.
        <br />
        <br />
      </p>
      <h2>Summary</h2>
      <ul>
        <li>Post your RP and rolls in an overflow channel</li>
        <li>Tag @Beriale Wardhave (Erik Manley) at the start of your quest</li>
        <li>More RP = More Favor</li>
        <li>
          All weapons, magic, feats, given abilities, etc. can be used. However,
          Magic resets ONLY happen in specific rooms.
        </li>
        <li>Follow the normal rules for death within the city.</li>
        <li>You can only start an attempt once per week.</li>
        <li>
          If you think something might be cheating, don't do it. I will not
          hesitate to ban someone from participation if cheating might be
          occurring
        </li>
        <li>You can only attempt this DIY once per week.</li>
      </ul>
      <p className="roomText">
        <br />
        <br />
        If you accept these terms, please proceed by entering the character
        names and levels for each participating character below.
      </p>

      <form className="inputHolder">
        {Array(numCharacters)
          .fill("")
          .map((u, num) => {
            return (
              <div
                key={"character_" + num + "_holder"}
                className="characterInputHolder"
              >
                <div
                  key={"character_" + num + "_name_holder"}
                  className="characterInput"
                >
                  <label
                    key={"character_" + num + "_name"}
                    className="inputLabel"
                  >
                    Character {num + 1} name:
                  </label>
                  <input
                    className="inputField"
                    type="text"
                    disabled
                    value={characters[num].name}
                  ></input>
                </div>
                <div
                  key={"character_" + num + "_level_holder"}
                  className="characterInput"
                >
                  <label
                    key={"character_" + num + "_level"}
                    className="inputLabel"
                  >
                    level:
                  </label>
                  <input
                    className="inputField"
                    type="number"
                    max="20"
                    min="1"
                    required
                    value={characters[num].level}
                    onChange={(e) => {
                      let temp = characters;
                      if (temp[num] != undefined) {
                        temp[num] = { ...temp[num], level: e.target.value };
                      } else {
                        temp[num] = { level: e.target.value };
                      }
                      setCharacters(temp);
                      forceUpdate();
                    }}
                  ></input>
                </div>
              </div>
            );
          })}
        <LoadButton />
        <button
          className="inputSubmit"
          type="button"
          onClick={() => submitHandler(characters)}
        >
          Proceed Forward
        </button>
        <label className="error">{errText}</label>
      </form>
      <h2>How to reach the City</h2>
      <ul>
        <li>Go to the Turning Page Tavern from denny's tavern</li>
        <li>
          Talk to Beliah and request a Griffon to the base of Mount Ormond.
        </li>
        <li>
          The Griffons will fly for about 6 hours before arriving in Northern
          Erebus.
        </li>
        <li>
          Walk into the cave at the base of Mount Ormond (you don't need to
          search for it)
        </li>
        <li>Start the DIY</li>
      </ul>
      <h2>A Few Ideas to Spice Things Up</h2>
      <ul>
        <li>
          There are a number of intelligent nymphs stuck in the city. Some may
          have been stuck there recently, while others may have been there for a
          while. Possibly a long while.
        </li>
        <li>
          You'll certainly run across bones of past explorers and fallen nymphs.
        </li>
        <li>
          Feel free to roll a few extra skill checks if you can find a reason to
          in order to spice up the roleplay.
        </li>
        <li>
          Choose a few phobias your character has and have fun with them. It's
          not likely they're perfectly stoic about every challenge laid before
          them. It will be more fun for you and for me to read if your character
          has an emotional reaction to the horrors of the city.
        </li>
        <li>
          Feel free to add horror flare to your roleplay. Your character may
          hear whispers, they may see terrible visions, they may have nightmares
          come to life. They may see things out of the corner of their eye.
          There's a lot of strange magic in this city and a lot of strange
          things can happen. Have fun with it. (As long as it doesn't give you a
          benefit)
        </li>
      </ul>
    </div>
  );
};

//modifier selection
const ModSelection = (props) => {
  const [combatLvl, setCombatLvl] = useState(0);
  const [hazardLvl, setHazardLvl] = useState(0);
  const [territory, setTerritory] = useState(0);
  const [modifiers, setModifiers] = useState([]);
  const [scoreMult, setScoreMult] = useState(1);
  const [treasureRating, setTreasureRating] = useState(0);
  const [characterNum, setCharacterNum] = useState(0);

  const InstanceVars = useContext(GlobalContext);

  useEffect(() => {
    //create the weekly changing random generator
    let date = new Date();
    let year = new Date(date.getFullYear(), 0, 1);
    let days = Math.floor((date - year) / (24 * 60 * 60 * 1000));
    let week = Math.ceil((date.getDay() + 2 + days) / 7);
    let seed = week.toString() + year.getFullYear().toString() + "2";
    let myRand = new seedrandom(seed);
    let temp = Math.floor(5 * myRand());
    setTerritory(temp);
    InstanceVars.current.territory_metric = temp;
    let mods = GetModifiers(seed);
    setModifiers(mods);
    setCharacterNum(InstanceVars.current.characters.length);
  }, []);

  useEffect(() => {
    setScoreMult(setScoreMultiplier());
    setTreasureRating(Math.floor((combatLvl + hazardLvl) / 4));
  }, [combatLvl, hazardLvl]);

  const setLevel = (type, num) => {
    if (type === "combat") {
      setCombatLvl(num);
    } else if (type === "hazard") {
      setHazardLvl(num);
    }
  };
  const incLevel = (type) => {
    if (type === "combat" && combatLvl < 8) {
      setCombatLvl(combatLvl + 1);
    } else if (type === "hazard" && hazardLvl < 8) {
      setHazardLvl(hazardLvl + 1);
    }
  };
  const decLevel = (type) => {
    if (type === "combat" && combatLvl > 0) {
      setCombatLvl(combatLvl - 1);
    } else if (type === "hazard" && hazardLvl > 0) {
      setHazardLvl(hazardLvl - 1);
    }
  };

  const getTerritory = () => {
    switch (territory) {
      case 0:
        return "Deep Scourge Territory";
      case 1:
        return "Shallow Scourge Territory";
      case 2:
        return "No man's land";
      case 3:
        return "Shallow Theokyr Territory";
      case 4:
        return "Deep Theokyr Territory";
      default:
        return "No man's land";
    }
  };

  const getCombatText = () => {
    switch (combatLvl) {
      case 0:
        return "1-3";
      case 1:
        return "2-5";
      case 2:
        return "5-8";
      case 3:
        return "8-10";
      case 4:
        return "10-12";
      case 5:
        return "12-15";
      case 6:
        return "15-20";
      case 7:
        return "20";
      case 8:
        return "20+";
      default:
        return "1-3";
    }
  };

  const getHazardText = () => {
    switch (hazardLvl) {
      case 0:
        return "10-16";
      case 1:
        return "10-18";
      case 2:
        return "10-20";
      case 3:
        return "10-22";
      case 4:
        return "10-24";
      case 5:
        return "10-26";
      case 6:
        return "10-30";
      case 7:
        return "10-40";
      case 8:
        return "20-40";
      default:
        return "10-16";
    }
  };

  const getTreasureText = () => {
    switch (treasureRating) {
      case 0:
        return "Trifles";
      case 1:
        return "Trinkets";
      case 2:
        return "Treasures";
      case 3:
        return "Relics";
      case 4:
        return "Artifacts";
      default:
        return "Trifles";
    }
  };

  const setScoreMultiplier = () => {
    let level = InstanceVars.current.level;
    let mult =
      -0.005 *
        Math.pow(-(((1.3685 * (combatLvl + hazardLvl)) / 16) * 20) + level, 3) +
      1;
    return Math.round(Math.max(Math.min(mult, 3), 0.5) * 100) / 100;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    InstanceVars.current = {
      ...InstanceVars.current,
      combat_metric: combatLvl,
      hazard_metric: hazardLvl,
      modifiers: modifiers,
      territory_metric: territory,
      treasure_rating: treasureRating,
      score_mult: scoreMult,
      num_characters_alias: characterNum,
    };
    props.output(e, "two");
  };

  return (
    <div>
      <p className="roomText"></p>
      <div className="territory_holder">
        <h2>Territory:</h2>
        <span>{getTerritory()}</span>
      </div>
      <div className="mod_changer_holder">
        <h2>Combat</h2>
        <img
          src={"/city_of_gold_res/enemies/ravager.png"}
          alt="enemies_image"
        />
        <span>Choose how intense you want the combat to be:</span>
        <input
          type="button"
          className="mod_decLevel"
          onClick={() => {
            decLevel("combat");
          }}
        />
        {Array(9)
          .fill(0)
          .map((u, num) => {
            return (
              <input
                key={"button_" + num}
                type="button"
                className={
                  "mod_tick" + (combatLvl >= num ? " mod_tick_red" : "")
                }
                onClick={() => setLevel("combat", num)}
              />
            );
          })}
        <input
          type="button"
          className="mod_incLevel"
          onClick={() => {
            incLevel("combat");
          }}
        />
        <span className="mod_desc_text">
          Appropriate for Levels: {getCombatText()}
        </span>
      </div>
      <div className="mod_changer_holder">
        <h2>Hazards</h2>
        <img src={require("../res/rooms/pit_trap.png")} alt="pit_trap_image" />
        <span>Choose how intense you want the hazards to be:</span>
        <input
          type="button"
          className="mod_decLevel"
          onClick={() => {
            decLevel("hazard");
          }}
        />
        {Array(9)
          .fill(0)
          .map((u, num) => {
            return (
              <input
                key={"button_hazard_" + num}
                type="button"
                className={
                  "mod_tick" + (hazardLvl >= num ? " mod_tick_red" : "")
                }
                onClick={() => setLevel("hazard", num)}
              />
            );
          })}
        <input
          type="button"
          className="mod_incLevel"
          onClick={() => {
            incLevel("hazard");
          }}
        />
        <span className="mod_desc_text">
          Target Number (DC) Range: {getHazardText()}
        </span>
      </div>
      <div className="score_mod_holder">
        <span>Highest Level: {InstanceVars.current.level}</span>
        {" | "}
        <span>Score Multiplier: {scoreMult}x</span>
        {" | "}
        <span>Treasure Rating: {getTreasureText()}</span>
      </div>
      <div className="artificial_char_num_holder">
        <span>
          You can use the following option to increase or decrease the number of
          characters that are used to determine the number of enemies you run
          into during combat encounters. This will not affect your multipliers,
          but will affect the points you earn, the treasure you find, and the XP
          you gain from combat encounters.
        </span>
        <br />
        <span>
          Default: {InstanceVars.current.characters.length} characters | Actual:{" "}
          {characterNum} characters
        </span>
        <input
          type="number"
          min="1"
          max="10"
          value={characterNum}
          onChange={(e) => setCharacterNum(e.target.value)}
        />
      </div>
      <div className="modifier_text_holder">
        <h2>Modifiers:</h2>
        {modifiers.map((item, index) => {
          return (
            <span key={"mod_text_" + index}>
              {item.name}: {item.description}
            </span>
          );
        })}
      </div>
      <input
        type="button"
        className="inputSubmit"
        value="Enter the Ruins"
        onClick={submitHandler}
      />
    </div>
  );
};

//input rooms
const PostInput = (props) => {
  const navigate = useNavigate();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  playOneShot(sfx);
  return (
    <div>
      <p className="roomText">
        You enter the cave that leads down to the ruins of Nyktoros. Moisture
        beads on the walls and drips into puddles of still water as the cold
        damp of the cave leads you deeper. A recent break in the wall of the
        cave allows just enough space to squeeze through. There's enough space
        to bring your gear, but any creature of large size or greater would find
        it impossible to squeeze through.
        <br />
        <br />
        You come into a greathall of golden stone, ruined by time. A pool of
        clear water sits in the center with a small camp next to it. A small
        stream leads from a hole in the wall into the pool, and from the pool to
        another hold further into the room. Bits and bobs and other assorted
        curios litter the floor around the pool. Rotted wooden furniture is
        strewn about the hall. Bald rats with barbed tails lap at the pool of
        water, uncaring of your trespass.
        <br />
        <br />
        You see a wretched creature come out of a side cave as you enter.
        Hunched low to the ground. She eyes you cautiously as you enter. She
        speaks, her voice raspy and unused, but vaguely threatening. "More
        adventurers c-come to brave the buried streets of Nyktoros? I have
        scouted well, yess... Others have come, found great treasure they did...
        yesss... Shall I lead you to areas with treasure? Many traps, many
        dangers, but if you are weak, I can lead you to a safer area... yess...
        Or if you are brave I can show you where the real treasures are."
        <br />
        <br />
        If she recognizes you, she will greet you, though she remembers no
        names, and she gives none for herself. When you are ready, she leads you
        deep into the caves and catacombs of the city, leading you to where she
        recently scouted.
        <br />
        <br />
        She stops in a dark cave, pointing to a crawlspace, "Here, start
        exploring here. Treasure ahead."
        <br />
        <br />
        After entering the crawlspace, three paths lie ahead of you.
      </p>
      <Perception />
      <div className="nextHolder">
        {props.rooms.map((room, i) => {
          return room.entry(i, navigate);
        })}
      </div>
    </div>
  );
};
