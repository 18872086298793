import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/generic_door.png";
import doorSfx from "../../res/sfx/Door Open II.wav";
import song from "../../res/music/Insidious.mp3";
import {
  changeSong,
  getDifficulty,
  GetNextRooms,
  GlobalVars,
  getDamage,
  playOneShot,
} from "../room_handler";
import DemonScript from "../../components/Demonic";

const bannerUrl =
  "https://i.pinimg.com/564x/a2/a3/e3/a2a3e3aadd6c1afddd11b9f2203b6889.jpg";
const doorUrl = door;

var nextRooms;

const Mirrors = () => {
  const [display, changeDisplay] = useState("pre");

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (!nextRooms) {
      nextRooms = GetNextRooms(Mirrors_Obj);
    }
  }, []);

  if (!nextRooms) {
    nextRooms = GetNextRooms(Mirrors_Obj);
  }

  const parseInput = (input) => {
    let rollTier = 2;
    let successes = 0;
    let difficulty = getDifficulty(rollTier);
    for (let i = 0; i < input.length; i++) {
      if (input[i] >= difficulty) {
        successes += 1;
      }
    }
    if (successes >= 2) {
      changeDisplay("pass");
    } else {
      changeDisplay("fail");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pre":
        component = <PreInput output={parseInput} />;
        break;
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "fail":
        component = <FailCheckView rooms={nextRooms} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={GlobalVars.depth}
        ramp={GlobalVars.difficultyRamp}
        maxRamp={GlobalVars.maxRamp}
      />
      <h1>Mirrors</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        You come face to face with yourself. No, a reflection of yourself.
        Slightly warped in proportion. Hundreds of mirrors surround you, leading
        down halls and curves, forming a maze of mirrors.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = () => {
  const navigate = useNavigate();
  return (
    <button
      key={Mirrors_Obj.name}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Mirrors_Obj.route);
      }}
    >
      <img src={Mirrors_Obj.image} />
      <span className="entryText">
        A heavy wooden door leading into the unknown
      </span>
    </button>
  );
};

export var Mirrors_Obj = {
  name: "Mirrors",
  room: Mirrors,
  entry: Entryway,
  route: "/halloween2022/mirrors",
  exits: 2,
  prob: 5,
  image: doorUrl,
};

export default Mirrors;

//input: function
//output: level
const PreInput = (props) => {
  const [errText, setErrText] = useState("");
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [input3, setInput3] = useState("");

  //console.log(props.output);
  const submitHandler = (e) => {
    e.preventDefault();
    if (!isNaN(input) || !isNaN(input2) || !isNaN(input3)) {
      setErrText("");
      props.output([input, input2, input3]);
    } else {
      setErrText("Please input a valid number");
    }
  };

  return (
    <div>
      <p className="roomText">
        Roll three observation (perc) OR history (mind) checks to help you find
        your way through the maze.
      </p>

      <form className="inputHolder" onSubmit={submitHandler}>
        <label className="inputLabel">
          Input your three scores below. <br />
          Natural 1: input 0 in all boxes. <br />
          Natural 20: Add 1d6 to the roll and input the final score into all
          boxes.
        </label>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput2(e.target.value);
          }}
        ></input>
        <input
          className="inputField"
          type="number"
          min="-5"
          max="60"
          required
          onChange={(e) => {
            setInput3(e.target.value);
          }}
        ></input>
        <button className="inputSubmit" type="submit">
          Submit Roll
        </button>
        <label className="error">{errText}</label>
      </form>
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>PASSED!</b> While confusing at first, you manage to orient yourself
          and after a few bad turns, you manage to make your way through the
          maze of mirrors. At the end, you see two paths out of this room.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
    </div>
  );
};

//input rooms
const FailCheckView = (props) => {
  const navigate = useNavigate();
  let damage = getDamage(["2d4", "2d6", "3d4", "4d4", "3d6"]);
  return (
    <div>
      <div className="roomText">
        <div className="checkFail">
          <b>FAILED!</b> You've been here before, or have you? It's difficult to
          keep track of where you are or where you've been. One might even say
          you are quite lost. You look at yourself in the mirror, though this
          reflection looks off...its hand reaches out and grabs you, pulling
          into the mirror. Glass shatters as you enter it, and you shatter along
          with it, floating through a dark room of endless mirrors reflecting
          you or itself. You float haphazardly towards another mirror and smash
          into it, returning to the real world as you are thrown against the
          wall near the exit, taking <b>{damage} damage.</b> If you survive, you
          stand and see two paths out of the maze.
        </div>
      </div>
      <DemonScript />
      <div className="nextHolder">
        {props.rooms.map((room) => {
          return room.entry();
        })}
      </div>
      <button
        className="inputSubmit"
        onClick={() => {
          navigate("/halloween2022/death");
        }}
      >
        Click this if you are Dead
      </button>
    </div>
  );
};
