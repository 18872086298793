import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DifficultyMeter from "../../gui/difficultyMeter";
import door from "../../res/rooms/COG_Image__00283_.png";
import doorSfx from "../../res/sfx/Walking_OneShot.wav";
import song from "../../res/music/Disorienting.mp3";
import banner from "../../res/rooms/COG_Image__00179_.png";
import {
  CAPDEPTH,
  changeSong,
  GetDifficulty,
  GetNextRooms,
  GetRoomObjectByName,
  GlobalContext,
  playOneShot,
} from "../room_handler";
import SkillCheckSelect from "../../components/SkillCheckSelect";
import RandomRoomTreasure from "../../components/Loot";
import { save } from "../../functions/save";
import { EntryPercResults, Perception } from "../../components/perception";

const bannerUrl = banner;
const doorUrl = door;

const Murky_Water = () => {
  const [display, changeDisplay] = useState("pre");
  const InstanceVars = useContext(GlobalContext);
  const [nextRooms, changeNextRooms] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSong(song);
    if (InstanceVars.current.nextRooms) {
      loadVals();
    } else {
      let nextRoomTemp = GetNextRooms(
        Murky_Water_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Murky_Water_Obj.exits)
      );
      save(InstanceVars.current, Murky_Water_Obj, nextRoomTemp);
      InstanceVars.current.score.depth += 100;
      changeNextRooms(nextRoomTemp);
    }
  }, []);

  const loadVals = () => {
    let nextRoomTemp = [];
    InstanceVars.current.nextRooms.forEach((room) => {
      let tempRoom = GetRoomObjectByName(room.name);
      if (tempRoom) nextRoomTemp.push(tempRoom);
    });
    if (nextRoomTemp.length > 0) {
      delete InstanceVars.current.nextRooms;
      delete InstanceVars.current.currentRoom;
    } else {
      nextRoomTemp = GetNextRooms(
        Murky_Water_Obj,
        InstanceVars,
        Math.ceil(Math.random() * Murky_Water_Obj.exits)
      );
    }
    changeNextRooms(nextRoomTemp);
  };

  const parseInput = (input) => {
    if (input == "next") {
      InstanceVars.current.score.skill += 200;
      changeDisplay("pass");
    }
  };

  const getComponent = () => {
    let component;
    //console.log(PARAMS.pass);
    switch (display) {
      case "pass":
        component = <PassCheckView rooms={nextRooms} />;
        break;
      case "pre":
      default:
        component = <PreInput output={parseInput} />;
        break;
    }
    return component;
  };

  return (
    <div className="room">
      <DifficultyMeter
        value={InstanceVars.current.depth}
        cap={CAPDEPTH}
        score={InstanceVars.current.score}
      />
      <h1>Murky Water</h1>
      <img className="banner" src={bannerUrl} />
      <p className="descriptionText">
        A stone ledge next to a pool filled with murky water. Mounds of mud cake
        the stone floors and walls. Mold grows on many surfaces. A partial cave
        in blocks the headspace and much of the pool, and you cannot see to the
        other side of the pool. There's no flying past this, you must go through
        the water.
      </p>
      {getComponent()}
    </div>
  );
};

const Entryway = (i = 0, navigate) => {
  return (
    <button
      key={Murky_Water_Obj.name + i}
      className="entry"
      onClick={() => {
        playOneShot(doorSfx);
        navigate(Murky_Water_Obj.route);
      }}
    >
      <img src={Murky_Water_Obj.image} />
      <span className="entryText">A dark cave leading into the unknown</span>
      <EntryPercResults
        observation={{
          tier: 3,
          text: "A putrid rotting scent comes from this direction.",
        }}
        mysticism={{
          tier: 4,
          text: "You sense simple blood magic at work ahead.",
        }}
        spirituality={{
          tier: 5,
          text: "A feeling of hunger touches your consiousness from this direction.",
        }}
      />
    </button>
  );
};

export var Murky_Water_Obj = {
  name: "Murky_Water",
  room: Murky_Water,
  entry: Entryway,
  route: "/CityOfGold/murky_water",
  exits: 4,
  prob: 5,
  image: doorUrl,
  faction: "none",
};

export default Murky_Water;

//input: function
//output: level
const PreInput = (props) => {
  const [ableToContinue, setAbleToContinue] = useState(false);
  const navigate = useNavigate();

  //console.log(props.output);
  const submitHandler = (selection) => {
    let blank = false;
    selection.forEach((sel) => {
      if (sel.pass === undefined) blank = true;
      return;
    });
    if (!blank) {
      console.log("next");
      setAbleToContinue(true);
    }
  };

  return (
    <div>
      <p className="roomText">
        With no choice but to get into the water, you begin wading across it. It
        smells disgusting and it's thick with plant matter. When you get out on
        the other side, you find monstrous leeches the size of your pinky
        attached to you, magically draining your vitality and magical capacity.
        Roll a Mysticism (mind) to understand enough about the leech's magic to
        force them to safely release their hold, or roll a non-magical healing
        (spirit) check to deal with the aftermath of peeling them off by hand.
        One person can roll for the whole group, but must roll a different check
        for each person.
      </p>
      <span className="inputLabel">
        Choose a skill to use using the drop down menu and then roll it. Input
        the number into the box. <br /> If you have a way of feasibly
        instant-succeeding on the check, select "instant." <br />
        Natural 1: input 0 in your box. <br />
        Natural 20: Add 1d6 to the roll and input the final score into your box.
      </span>
      <SkillCheckSelect
        options={[
          { method: "mysticism (mind)", diffTier: 2, damTier: 0 },
          { method: "non-magical healing (spirit)", diffTier: 3, damTier: 0 },
        ]}
        noDamage
        specialEffect="You feel sick from the magical effect of the leeches. For the next 5 rooms, you suffer -1 to all rolls using Body or Mind. -- this is a magical disease that cannot be cleansed."
        callback={submitHandler}
      />
      <input
        type="button"
        value="Continue"
        className="skillCheckButton skillCheckSubmit"
        disabled={!ableToContinue}
        onClick={() => props.output("next")}
      />
      <input
        type="button"
        value="Dead"
        className="skillCheckButton skillCheckDeath"
        disabled={!ableToContinue}
        onClick={() => {
          navigate("/CityOfGold/death");
        }}
      />
    </div>
  );
};

//input rooms
const PassCheckView = (props) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="roomText">
        <div className="checkPass">
          <b>You made it through!</b> You made it through the water and picked
          the leeches off of you. What path do you take next?
        </div>
        <RandomRoomTreasure
          chance={0.05}
          chanceForCurse={0.2}
          tier={Math.floor(Math.random() * 3)}
        />
      </div>
      <Perception />
      <div className="nextHolder">
        {props.rooms.map((room, i) => {
          return room.entry(i, navigate);
        })}
      </div>
    </div>
  );
};
